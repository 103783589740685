/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactElement, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isDispatched } from "@ctra/utils";

import {
  DataDescriptorEntity,
  Enterprise,
  EnterpriseAppState,
  FarmEntity,
  Insights,
  KPIInsightSettingsList,
  KPIInsightSettingsState
} from "@ctra/api";

interface Meta {
  isLoading: boolean;
}

interface BaseProps {
  children: (insightSettings: KPIInsightSettingsState, meta: Meta) => ReactElement | null;
}

export interface WithFarmFilterProps {
  farmID: FarmEntity["id"];
  children: (insightSettings: KPIInsightSettingsState[FarmEntity["id"]], meta: Meta) => ReactElement | null;
}

export interface WithDataDescriptorFilterProps {
  farmID: FarmEntity["id"];
  dataDescriptorID: DataDescriptorEntity["id"];
  children: (insightSettings: KPIInsightSettingsList, meta: Meta) => ReactElement | null;
}

export function InsightSettingsProvider(
  props: WithDataDescriptorFilterProps
): ReturnType<WithDataDescriptorFilterProps["children"]>;

export function InsightSettingsProvider(
  props: WithFarmFilterProps
): ReturnType<WithFarmFilterProps["children"]>;

export function InsightSettingsProvider(props: BaseProps): ReturnType<BaseProps["children"]>;

/**
 * Insight settings provider
 * @param farmID
 * @param dataDescriptorID
 * @param children
 * @constructor
 * @todo consider renaming to KPIInsightSettingsProvider
 */
export function InsightSettingsProvider({ farmID, dataDescriptorID, children }: Record<string, any>): any {
  const dispatch = useDispatch();

  /**
   * Get insight settings for all farms
   */
  const insightSettings = useSelector<EnterpriseAppState, KPIInsightSettingsState>(
    Enterprise.entities.getInsightSettings
  );

  /**
   * Tell whether the fetching action has been dispatched
   */
  const dispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, Insights.types.FETCH_KPI_INSIGHT_SETTINGS)
  );

  useEffect(() => {
    if (!dispatched) {
      dispatch(Insights.actions.fetchKPIInsightSettings.start());
    }
  }, [dispatch, dispatched]);

  /**
   * Get the settings for the given farm
   */
  const perFarm = farmID ? insightSettings[farmID] : null;

  /**
   * Get the settings for the given data descriptor
   */
  const perDataDescriptor = perFarm ? perFarm[dataDescriptorID] : null;

  return children(dataDescriptorID ? perDataDescriptor : farmID ? perFarm : insightSettings, {
    isLoading: !dispatched
  });
}

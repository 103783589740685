import { FC } from "react";

import { memoize } from "@ctra/utils";
import { Empty } from "@ctra/components";
import { ChartEntity, MetaType } from "@ctra/api";

/**
 * Chart View Base class
 */
class ChartViewBase<M extends MetaType = MetaType> {
  /**
   * The underlying chart entity
   */
  protected entity: ChartEntity;
  protected metadata: M;

  constructor(chart: ChartEntity, meta: M) {
    this.entity = chart;
    this.metadata = meta;
  }

  @memoize
  identify(): string {
    return this.entity.id;
  }

  /**
   * Get the chart component
   * @return {React.FC<Record<string, unknown>>}
   */
  @memoize
  getComponent(): FC<Record<string, unknown>> {
    return Empty;
  }

  /**
   * Get the chart metadata
   * @return {M}
   */
  @memoize
  getMetadata(): M {
    return this.metadata;
  }

  /**
   * Get the chart config
   * @note using Record<string, unknown>, the types are specified in the subclasses
   * @return {Record<string, unknown>}
   */
  @memoize
  getConfig(): Record<string, unknown> {
    return {};
  }
}

export { ChartViewBase };

import { FC } from "react";
import { useFormikContext } from "formik";
import * as _ from "lodash";

import { Input, Select, Checkbox, Form, MailOutlined, Space, Typography } from "@ctra/components";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { ReferralRole } from "@ctra/api";

import { Invitation } from "../../../settings/components/Users/typings";
import { GACategories } from "../../analytics";

const { Text } = Typography;

const {
  settings: {
    users: {
      form: { labels, placeholders, role: roleCopy }
    }
  }
} = Content;

/**
 * Referral form
 * @return {JSX.Element}
 * @constructor
 */
export const ReferralForm: FC = () => {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext<Invitation & { consent: boolean }>();

  return (
    <Form layout="vertical" requiredMark="optional">
      <Input type="hidden" name="id" />
      <Form.Item required name="firstName" label={t<string>(labels.firstName)}>
        <Input
          required
          disabled={isSubmitting}
          data-gtm-category={GACategories.network}
          data-gtm-action="First name"
          placeholder={t<string>(placeholders.firstName)}
          name="firstName"
        />
      </Form.Item>
      <Form.Item required name="lastName" label={t<string>(labels.lastName)}>
        <Input
          required
          disabled={isSubmitting}
          placeholder={t<string>(placeholders.lastName)}
          name="lastName"
          data-gtm-category={GACategories.network}
          data-gtm-action="Last name"
        />
      </Form.Item>
      <Form.Item required name="email" label={t<string>(labels.email)}>
        <Input
          required
          type="email"
          disabled={isSubmitting}
          prefix={<MailOutlined />}
          placeholder={t<string>(placeholders.email)}
          name="email"
          data-gtm-category={GACategories.network}
          data-gtm-action="Email"
        />
      </Form.Item>
      <Form.Item name="org" label={t<string>(labels.org)}>
        <Input
          disabled={isSubmitting}
          placeholder={t<string>(placeholders.org)}
          name="org"
          data-gtm-category={GACategories.network}
          data-gtm-action="Organization"
        />
      </Form.Item>
      <Form.Item required name="role" label={t<string>(labels.role)}>
        <Select
          disabled={isSubmitting}
          placeholder={t<string>(placeholders.role)}
          name="role"
          data-gtm-category={GACategories.network}
          data-gtm-action="Role"
        >
          {_.map(_.values(ReferralRole), (role) => (
            <Select.Option key={role} value={role}>
              {t<string>(roleCopy(role))}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="consent" style={{ marginBottom: "0" }}>
        <Space>
          <Checkbox
            name="consent"
            data-gtm-category={GACategories.network}
            data-gtm-action="Data Sharing consent"
          />
          <Text>
            {t<string>(labels.consent)}{" "}
            <a rel="noreferrer" href="https://connecterra.ai/data-sharing/" target="_blank">
              {t<string>(labels.readMore)}
            </a>
          </Text>
        </Space>
      </Form.Item>
    </Form>
  );
};

import { FC } from "react";
import * as _ from "lodash";

import { Steps, Icon, Dot } from "@ctra/components";

import {
  useInsightResolutions,
  useInsightValidation,
  ResolutionWidget,
  ValidationQuestion,
  ResolutionTagList
} from "@insights";

import styles from "./OnboardingInsightValidation.module.less";

const { Step } = Steps;

/**
 * Regular insight validation
 * @constructor
 */
export const OnboardingInsightValidation: FC = () => {
  const { validation: validationEntity } = useInsightValidation();

  const {
    unresolve,
    meta: { isResolved }
  } = useInsightResolutions();

  const { validation } = validationEntity;

  return (
    <Steps current={isResolved ? 1 : 0} className={styles.Steps} direction="vertical">
      <Step
        icon={<Icon component={Dot} />}
        title={<ValidationQuestion highlighted={!isResolved} step={1} validation={validation} />}
        description={isResolved ? <ResolutionTagList includeValidation={false} /> : <ResolutionWidget />}
      />
      {isResolved && (
        <Step
          icon={<Icon component={Dot} />}
          title={
            <ValidationQuestion highlighted rollback={_.ary(unresolve, 0)} step={2} validation={validation} />
          }
        />
      )}
    </Steps>
  );
};

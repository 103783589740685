import { FC, useMemo, useState } from "react";
import * as _ from "lodash";
import Markdown from "react-markdown";

import {
  Button,
  PlusOutlined,
  Row,
  Typography,
  Analytics,
  Modal,
  TabsProps,
  DeleteOutlined,
  Space,
  AntDesignInput,
  Spin,
  DraggableTabs,
  EditOutlined,
  message,
  CopyOutlined,
  ChartComparison,
  Icon,
  Grid,
  Dropdown,
  DownOutlined,
  SettingOutlined,
  Tooltip
} from "@ctra/components";

import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { Enterprise } from "@ctra/api";
import { GAEvent } from "@ctra/analytics";

import { CompiledRoutes } from "@routes";
import { useFarm } from "@farms";
import { useDataDictionary } from "@base";
import { GACategories, useSavedCharts } from "@analytics";
import { ChartList } from "../ChartList";
import { CopyDashboardModal } from "../CopyDashboardModal";

import { useTabs } from "./hooks";

import styles from "./DashboardPage.module.less";

const { confirm } = Modal;
const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

const {
  V3: { Empty }
} = Analytics;

export const commonModalProps = {
  className: styles.Modal,
  closable: true,
  icon: false,
  okButtonProps: { className: styles.OkBtn }
};

export const compareChartsKey = "analysis";

/**
 * Dashboard Page with tabs
 * @returns
 */
export const DashboardPage: FC = () => {
  const { t } = useTranslation();
  const { farm } = useFarm();
  const { md } = useBreakpoint();

  const {
    dataDescriptors,
    meta: { isLoading: isDataDictionaryLoading }
  } = useDataDictionary();

  const [copyModalVisible, setCopyModalVisible] = useState(false);

  const {
    savedCharts,
    api: { updateTabOrder, renameTab },
    meta: { isLoading, isUpdating, tabsUpdating }
  } = useSavedCharts();

  const {
    analytics: {
      v3: {
        tooltips,
        dashboard,
        analysis,
        addDashboard,
        addCta,
        pageDescription,
        confirm: {
          newTab,
          removeTab,
          renameTab: renameTabCopy,
          copyTab,
          error: { duplicateTab }
        },
        empty
      }
    },
    gamePlan: {
      actions: {
        delete: { confirm: confirmCopy }
      }
    },
    impactTracking: {
      add: { cta }
    },
    timeline: {
      createEvent: { submit }
    }
  } = Content;

  /**
   * Route to config page
   */
  const handleAdd = (groupID?: string) => {
    Enterprise.history.push({
      pathname: CompiledRoutes.app.analytics.dashboard.addChart.index({
        dashboard: groupID
      })
    });
  };

  /**
   * Handle tab renaming with confirmation
   * @param groupID
   */
  const handleRename = (groupID: string) => {
    const { groupName } = _.find(savedCharts, { id: groupID }) || {};
    let newTabLabel = groupName;

    confirm({
      ...commonModalProps,
      okText: t(submit),
      title: (
        <Space>
          <EditOutlined />
          {t(renameTabCopy.title)}
        </Space>
      ),
      content: (
        <Space direction="vertical">
          <Text>{t(renameTabCopy.description)}</Text>
          <AntDesignInput
            placeholder={t(newTab.inputPlaceholder)}
            defaultValue={groupName}
            onChange={({ target: { value } }) => {
              newTabLabel = value;
            }}
          />
        </Space>
      ),
      onOk() {
        if (_.includes(_.map(savedCharts, "groupName"), newTabLabel)) {
          message.error(t(duplicateTab));
        } else {
          GAEvent(
            GACategories.dashboards,
            "Rename dashboard",
            JSON.stringify({ newTabLabel, groupName, farm: farm?.name })
          );

          newTabLabel && renameTab(groupID, newTabLabel);
        }
      }
    });
  };

  /**
   * Initial items memoized
   */
  const initialItems = useMemo(
    () =>
      !_.isEmpty(dataDescriptors) && !isDataDictionaryLoading
        ? _.map(savedCharts, ({ cards, groupName, id }) => ({
            label: groupName,
            key: id,
            children: (
              <>
                <Row justify="end">
                  {md ? (
                    <>
                      <Button
                        type="link"
                        icon={<EditOutlined />}
                        className={styles.Link}
                        onClick={() => handleRename(id)}
                      >
                        {t(renameTabCopy.title)}
                      </Button>
                      <Button
                        type="link"
                        icon={<CopyOutlined />}
                        className={styles.Link}
                        onClick={() => setCopyModalVisible(true)}
                      >
                        {t(copyTab.title)}
                      </Button>
                    </>
                  ) : (
                    <Tooltip title={<Markdown>{t(tooltips.dashboard)}</Markdown>}>
                      <Dropdown
                        trigger={["click"]}
                        menu={{
                          items: [
                            { key: "1", label: t(renameTabCopy.title), onClick: () => handleRename(id) },
                            {
                              key: "2",
                              label: t(removeTab.title),
                              onClick: () => handleEdit && handleEdit("empty", "remove")
                            },
                            { key: "3", label: t(copyTab.title), onClick: () => setCopyModalVisible(true) }
                          ]
                        }}
                      >
                        <Space className={styles.Dropdown}>
                          <SettingOutlined />
                          {t(dashboard)}
                          <DownOutlined />
                        </Space>
                      </Dropdown>
                    </Tooltip>
                  )}
                  <Tooltip title={<Markdown>{t(tooltips.addChart)}</Markdown>}>
                    <Button
                      type="link"
                      className={styles.Link}
                      icon={<PlusOutlined />}
                      onClick={() => handleAdd(id)}
                    >
                      {t(addCta)}
                    </Button>
                  </Tooltip>
                </Row>
                {_.isEmpty(cards) ? (
                  <Empty
                    className={styles.Empty}
                    variant="charts"
                    title={t(empty.charts.title)}
                    description={
                      <Space size="middle" direction="vertical">
                        <Button
                          type="link"
                          data-gtm-category={GACategories.dashboards}
                          data-gtm-action="Go to chart configurator"
                          onClick={() => handleAdd(id)}
                        >
                          {t(empty.charts.cta)}
                        </Button>
                        {t(empty.charts.description)}
                      </Space>
                    }
                  />
                ) : (
                  <ChartList charts={cards} groupName={groupName} groupID={id} />
                )}
              </>
            )
          }))
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [savedCharts, farm, dataDescriptors, md]
  );

  const { onChange, onEdit, activeKey, items } = useTabs(initialItems);

  /**
   * Add or remove a dashboard
   * @param key
   * @param action
   */
  const handleEdit: TabsProps["onEdit"] = (key, action) => {
    if (action === "remove") {
      /**
       * Opens up a confirmation modal
       */
      confirm({
        ...commonModalProps,
        okText: t(confirmCopy.yes),
        cancelText: t(confirmCopy.no),
        title: (
          <Space>
            <DeleteOutlined />
            {t(removeTab.title)}
          </Space>
        ),
        content: t(removeTab.description),
        onOk() {
          GAEvent(GACategories.dashboards, "Remove dashboard", JSON.stringify({ removed: key }));

          onEdit(key, action);
        }
      });
    }

    if (action === "add") {
      let newTabLabel: string;
      confirm({
        ...commonModalProps,
        okText: t(cta),
        title: (
          <Space>
            <PlusOutlined />
            {t(newTab.title)}
          </Space>
        ),
        content: (
          <Space direction="vertical">
            <Text>{t(newTab.description)}</Text>
            <AntDesignInput
              placeholder={t(newTab.inputPlaceholder)}
              onChange={({ target: { value } }) => {
                newTabLabel = value;
              }}
            />
          </Space>
        ),
        onOk() {
          if (_.includes(_.map(savedCharts, "groupName"), newTabLabel)) {
            message.error(t(duplicateTab));
          } else {
            GAEvent(GACategories.dashboards, "Add dashboard", JSON.stringify({ added: newTabLabel }));

            onEdit(key, action, newTabLabel);
          }
        }
      });
    }
  };

  const addBtn = (
    <Space size="large">
      <Button
        type="secondary"
        size="large"
        icon={<Icon component={ChartComparison} />}
        data-gtm-category={GACategories.dashboards}
        data-gtm-action="Start Analysis"
        onClick={() =>
          Enterprise.history.push({
            /**
             * not a good practise, but gets the job done
             */
            pathname: CompiledRoutes.app.analytics.dashboard.browse.chart({
              dashboard: activeKey,
              chartID: compareChartsKey
            })
          })
        }
      >
        {t(analysis)}
      </Button>
    </Space>
  );

  return (
    <>
      <Title level={6} className={styles.Header}>
        {t(pageDescription)}
      </Title>
      <Spin spinning={isLoading || isUpdating || tabsUpdating || isDataDictionaryLoading}>
        {_.isEmpty(items) ? (
          <>
            <Row justify="space-between">
              <Button
                icon={<PlusOutlined />}
                data-gtm-category={GACategories.dashboards}
                data-gtm-action="Add dashboard"
                type="secondary"
                size="large"
                onClick={() => handleEdit("empty", "add")}
              >
                {t(addDashboard)}
              </Button>
              {addBtn}
            </Row>
            <Empty
              className={styles.Empty}
              variant="dashboards"
              title={t(empty.dashboards.title)}
              description={
                <Space size="middle" direction="vertical">
                  <Button type="link" onClick={() => handleEdit("empty", "add")}>
                    {t(empty.dashboards.cta)}
                  </Button>
                  \ {t(empty.dashboards.description)}
                </Space>
              }
            />
          </>
        ) : (
          <DraggableTabs
            handleDrag={updateTabOrder}
            className={styles.Tabs}
            type={md ? "editable-card" : "line"}
            onChange={onChange}
            activeKey={activeKey}
            onEdit={handleEdit}
            items={items}
            tabBarExtraContent={md && addBtn}
          />
        )}
      </Spin>
      <CopyDashboardModal
        visible={copyModalVisible}
        onCancel={() => setCopyModalVisible(false)}
        group={activeKey}
      />
    </>
  );
};

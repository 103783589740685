import { Analytics } from "@ctra/components";

const { CorrelationType } = Analytics;

export const correlationDelta = (value: number): Analytics.CorrelationType => {
  if (value >= -0.05 && value <= 0.05) {
    return CorrelationType.zero;
  } else if (value > 0.05) {
    return CorrelationType.positive;
  } else return CorrelationType.negative;
};

/**
 * strength of correlation
 */
export const correlationStrength = (absoluteValue: number): number => {
  if (absoluteValue >= 0.8 && absoluteValue <= 1) {
    return 100;
  } else if (absoluteValue >= 0.5 && absoluteValue < 0.8) {
    return 60;
  } else {
    return 20;
  }
};

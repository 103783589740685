import { useEffect, useContext, createContext, FC, useState } from "react";
import * as _ from "lodash";

import { huePalette, ColorType, localColorStoreKey, Optional } from "@ctra/utils";
import { DataPointsData } from "@ctra/api";

import { useChartContext } from "../ChartContext";

type ContextType = {
  getColor: (cacheKey: Optional<string>, itemKey: Optional<string>) => ColorType;
};

const DefaultContext = createContext<ContextType>({
  getColor: () => ({ hue: 0, saturation: 0, luminosity: 0 })
});

/**
 * Here, we generate color from our color palette (provided by the design team) to be used by a group of items
 *
 * This function takes in a cacheKey and an item key and assigns the next available color to the item
 *
 * At the moment, we can generate 26 unique colors from this.
 * If you ever need to increase this number, you need to play around with the divisor factor
 * and the gap between the saturation and luminosity
 *
 * We have 13 colors in the list from design. To get the 14th color, we do the following:
 * 14 % 13 = 1, then set the hue to palette[1], saturation to 75 (_.floor(14/13)) and luminosity to saturation - 50
 * @param {string} cacheKey
 * @param {string} itemKey
 * @param {Record<string, unknown>} cacheObject
 * @param {Array<number>} list
 * @return {ColorType}
 */
const assignColor = (
  cacheKey: string,
  itemKey: string,
  cacheObject: Record<string, unknown>,
  list = huePalette
): ColorType => {
  const cacheSize = _.size(cacheObject);
  const sizeIndex = cacheSize % list.length;
  const hue = list[sizeIndex];

  /**
   * Numbers ranging from 0 - list.length will produce 0, then 1
   * @todo deal with more rounds than only 2
   */
  const divisorFactor = _.floor(cacheSize / list.length) % 2;

  const saturation = divisorFactor === 0 ? 90 : 50;
  const luminosity = saturation - (divisorFactor === 0 ? 35 : 5);

  return { hue, saturation, luminosity };
};

/**
 * Series color provider
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _SeriesColorProvider: FC = ({ children }) => {
  const { meta } = useChartContext<DataPointsData>();

  const [colors, setColors] = useState<Record<string, Record<string, ColorType>>>(
    JSON.parse(
      _.defaultTo(
        localStorage.getItem(localColorStoreKey),
        JSON.stringify({
          /**
           * default color for each behavior
           */
          behavior: {
            eatingPct: { hue: 0, saturation: 100, luminosity: 27 },
            ruminationPct: { hue: 96, saturation: 100, luminosity: 27 },
            lyingPct: { hue: 164, saturation: 100, luminosity: 27 },
            standingPct: { hue: 208, saturation: 100, luminosity: 27 },
            restingPct: { hue: 61, saturation: 100, luminosity: 28 },
            otherPct: { hue: 276, saturation: 100, luminosity: 50 }
          }
        })
      )
    )
  );

  /**
   * Gets the color from the local store
   * @todo allow use of generics
   * @param {string} cacheKey
   * @param {string} itemKey
   * @return {ColorType}
   */
  const getColor: ContextType["getColor"] = (cacheKey, itemKey) => {
    const color = cacheKey && itemKey ? _.get(colors, [cacheKey, itemKey]) : null;

    return _.defaultTo(color, {
      hue: 0,
      saturation: 0,
      luminosity: 0
    });
  };

  /**
   * Save colors to the local storage
   */
  useEffect(() => {
    localStorage.setItem(localColorStoreKey, JSON.stringify(colors));
  }, [colors]);

  /**
   * Assign new colors when the seriesType, seriesKeys changes
   */
  useEffect(() => {
    if (meta && meta.series) {
      const {
        series: { keys: seriesKeys },
        seriesType,
        seriesField
      } = meta;

      /**
       * If seriesKeys is empty, we use the seriesField as the keys
       * @type {Record<string, string>}
       */
      const keys = _.isEmpty(seriesKeys) && seriesField ? { [seriesField]: seriesField } : seriesKeys;

      if (_.isString(seriesType)) {
        /**
         * Pick the color list already assigned to the series type, eg. "farm colors"
         * @type {Record<string, ColorType>}
         */
        const cacheObject: Record<string, ColorType> = _.get(colors, _.toString(seriesType), {});

        /**
         * Assign new colors to items which does exist in the above list
         * @type {Record<string, ColorType>}
         */
        const newColors = _.reduce<typeof keys, Record<string, ColorType>>(
          keys,
          (result, seriesKey, fieldName) => {
            /**
             * Tell if aa color is already in the list
             * @type {Exclude<Record<string, ColorType>[keyof Record<string, ColorType>], undefined> | null}
             */
            const existingColor = _.get(cacheObject, fieldName, null);

            if (!existingColor) {
              /**
               * Assign a unique color
               * @type {ColorType}
               */
              result[fieldName] = assignColor(_.toString(seriesType), fieldName, {
                ...cacheObject,
                ...result
              });
            }

            return result;
          },
          {}
        );

        if (!_.isEmpty(newColors)) {
          setColors({
            ...colors,
            [_.toString(seriesType)]: {
              ...colors[_.toString(seriesType)],
              ...newColors
            }
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta]);

  return <DefaultContext.Provider value={{ getColor }}>{children}</DefaultContext.Provider>;
};

export const SeriesColorContext = {
  Provider: _SeriesColorProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook for the series color context
 */
export const useSeriesColor = (): ContextType => useContext(DefaultContext);

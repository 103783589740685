import * as _ from "lodash";

declare global {
  interface Window {
    CTRA_MOBILE_WRAPPER: boolean;
    CTRA_DEVICE_INFO: Record<string, unknown>;
  }
}

/**
 * Tell if the device is iOs
 */
export const isIosDevice = () => {
  const isMedianMobile = _.get(window, ["CTRA_MOBILE_WRAPPER"], false);
  const medianPlatform = _.get(window, ["CTRA_DEVICE_INFO", "platform"], null);
  const userAgent = navigator.userAgent;

  // Check for iPhone, iPad, or iPod
  const isIOSDevice = /iPhone|iPad|iPod/i.test(userAgent);

  // Check for iOS versions
  const iosVersionMatch = userAgent.match(/OS (\d+)_\d+/);
  const iosVersion = iosVersionMatch ? parseInt(iosVersionMatch[1], 10) : null;

  return (isIOSDevice && iosVersion !== null) || (isMedianMobile && medianPlatform === "ios");
};

export enum GACategory {
  timeline = "Farm timeline",
  eventPage = "Event page"
}

export enum GAActions {
  addEvent = "Add event",
  updateEvent = "Update event",
  closeEvent = "Close event",
  closeFarmTimeline = "Close farm timeline",
  closeWhileChartZoom = "Close farm timeline in chart zoom",
  closeWhileComparing = "Close 'Events during this period'",
  openAddEvent = "Open 'add event'",
  openEvent = "Open event",
  openFarmTimeline = "Open farm timeline",
  cannotOpenFarmTimeline = "Try to open disabled farm timeline",
  openWhileChartZoom = "Open farm timeline in chart zoom",
  openWhileComparing = "Open 'Events during this period'",
  pickTimePeriod = "Change time",
  saveDetails = "Save details",
  selectCategory = "Select event category",
  selectEvent = "Select event",
  selectScope = "Select scope",
  skipDetails = "Skip details",
  updateDetails = "Update details",
  addNewMetric = "Add new metric",
  deleteMetric = "Delete metric"
}

export enum EventListURLParams {
  selectedEvent = "selectedEvent",
  timelineStatus = "timelineStatus"
}

export enum TimelineStatus {
  open = "open",
  closed = "closed"
}

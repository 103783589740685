import React, { FC } from "react";
import Markdown from "react-markdown";

import { GenericEventView, testIds, useEvent } from "@events";
import { Col, Row, Typography } from "@ctra/components";

const { Text } = Typography;

/**
 * Custom Event View component
 * @constructor
 */
export const CustomEventView: FC<{ open?: boolean }> = (props) => {
  const { getDescription } = useEvent();

  return (
    <GenericEventView {...props}>
      <Row data-testid={testIds.entity.entry.description} gutter={[0, 8]}>
        {getDescription() && (
          <Col span={24}>
            <Markdown>{getDescription()}</Markdown>
          </Col>
        )}
      </Row>
    </GenericEventView>
  );
};

import { FC } from "react";

/**
 * High risk cow insight validation
 * @return {React.ReactNode}
 * @constructor
 */
export const AjinomotoHighRiskCowInsightValidation: FC = () => {
  return null;
};

import hsl from "hsl-to-hex";

import { ColorType } from "./utils";

/**
 * Converts hsl to hex
 * @param color
 * @returns
 */
export const hslToHex = (color: ColorType): string => {
  const { hue, saturation, luminosity } = color;
  return hsl(hue, saturation, luminosity);
};

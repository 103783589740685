import { memoize } from "@ctra/utils";
import { Column } from "@ctra/charts";

import { DataPointsBase } from "./DataPointsBase";
import { ChartViewBase } from "./Base";

/**
 * Column chart view class
 */
class ColumnChart extends DataPointsBase {
  /**
   * Get the chart component
   * @return {ReturnType<typeof ChartViewBase.prototype.getComponent>}
   */
  @memoize
  getComponent(): ReturnType<typeof ChartViewBase.prototype.getComponent> {
    return Column;
  }
}

export { ColumnChart };

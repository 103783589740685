import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import * as _ from "lodash";

import { isFulfilled } from "@ctra/utils";
import { TabsProps } from "@ctra/components";
import { Enterprise, EnterpriseAppState, SavedCards } from "@ctra/api";

import { useFarm } from "@farms";
import { CompiledRoutes } from "@routes";
import { TargetKey, useSavedCharts } from "@analytics";

/**
 * Custom hook to handle the tab logic
 * @param list
 * @returns
 */
export const useTabs = (list: TabsProps["items"]) => {
  const { farm } = useFarm();
  const [activeKey, setActiveKey] = useState(_.first(list)?.key);
  const [items, setItems] = useState(list || []);
  const { dashboard: groupID } = useParams<{ dashboard: string }>();

  const {
    savedCharts,
    api: { addTab, removeTab }
  } = useSavedCharts();

  useEffect(() => {
    if (list) {
      setItems(list);
    }
  }, [list]);

  useEffect(() => {
    if (groupID) {
      setActiveKey(groupID);
    }
  }, [groupID]);

  /**
   * Point to the correct url with dashboard selection
   * @param dashboard
   * @returns
   */
  const setUrl = (dashboard: string) =>
    Enterprise.history.push(CompiledRoutes.app.analytics.dashboard.index({ dashboard }));

  /**
   * Set the active key
   */
  const onChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey);
    setUrl(newActiveKey);
  };

  /**
   * Set the first tab as active when farm is switched
   * or get the tab from the url
   */
  const farmRef = useRef(farm?.id);

  useEffect(() => {
    if (farm) {
      if (farm.id !== farmRef.current) {
        const defaultTab = _.first(list)?.key;
        defaultTab && onChange(defaultTab);
        farmRef.current = farm.id;
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farm, list]);

  /**
   * Tell whether a new card is added
   */
  const tabAdded = useSelector<EnterpriseAppState, boolean>((state) =>
    isFulfilled(state, SavedCards.types.ADD_TAB)
  );

  /**
   * Select the newly created tab
   */
  useEffect(() => {
    if (tabAdded) {
      const defaultTab = _.last(savedCharts)?.id;
      defaultTab && onChange(defaultTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabAdded]);

  /**
   * Add a new tab with no charts
   * @param name
   */
  const add = (name: string) => {
    const newPanes = [...items];
    newPanes.push({ label: name, key: name });
    setItems(newPanes);
  };

  /**
   * Remove a tab
   * @param targetKey
   */
  const remove = (targetKey: TargetKey) => {
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setItems(newPanes);
    newActiveKey && onChange(newActiveKey);
  };

  /**
   * Add or remove a tab
   * @param targetKey
   * @param action
   * @param name
   */
  const onEdit = (targetKey: TargetKey, action: "add" | "remove", name?: string) => {
    if (action === "add" && name) {
      addTab(name);
      add(name);
    } else {
      removeTab(targetKey);
      remove(targetKey);
    }
  };

  return { onChange, onEdit, add, remove, activeKey, setActiveKey, items };
};

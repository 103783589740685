import { memoize } from "@ctra/utils";
import { Area } from "@ctra/charts";

import { DataPointsBase } from "./DataPointsBase";
import { ChartViewBase } from "./Base";

/**
 * Area chart view class
 */
class AreaChart extends DataPointsBase {
  /**
   * get the chart component
   * @return {ReturnType<typeof ChartViewBase.prototype.getComponent>}
   */
  @memoize
  getComponent(): ReturnType<typeof ChartViewBase.prototype.getComponent> {
    return Area;
  }
}

export { AreaChart };

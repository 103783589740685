import { FC } from "react";

import { Spin, Skeleton } from "@ctra/components";
import { useInsight } from "@insights";
import { InsightResolutionContext } from "@insights";

import { InsightValidationContext } from "../../providers";

/**
 * Insight validation factory
 * @constructor
 */
export const InsightValidation: FC = () => {
  const {
    insight: { id },
    getComponents,
    meta: { isLoading }
  } = useInsight();

  const ValidationComponent = getComponents().Validation;

  return (
    <Skeleton active loading={isLoading}>
      <InsightValidationContext.Provider genericInsightID={id}>
        <InsightValidationContext.Consumer>
          {({ meta: { isLoading: isValidating } }) => (
            <InsightResolutionContext.Provider>
              <Spin spinning={isValidating}>
                <ValidationComponent />
              </Spin>
            </InsightResolutionContext.Provider>
          )}
        </InsightValidationContext.Consumer>
      </InsightValidationContext.Provider>
    </Skeleton>
  );
};

import { createContext, FC, useContext } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import * as _ from "lodash";

import { isFulfilled, isRejected, Nullable, parseQuery } from "@ctra/utils";

import {
  Enterprise,
  EnterpriseAppState,
  EventEntity,
  Events,
  FarmEntity,
  NormalizedEvent,
  TimelineEventFormValues
} from "@ctra/api";

import { useFarm } from "@farms";
import { Routes } from "@routes";

export enum CreateEventURLParams {
  createEventKey = "createEvent",
  editEventKey = "editEvent",
  farm = "farm"
}

interface BaseContext {
  api: {
    open: (
      eventListHash: EventEntity["id"],
      farmID?: FarmEntity["id"],
      action?: Exclude<CreateEventURLParams, "farm">
    ) => void;
    close: () => void;
  };
  searchParams: {
    eventListHash: Nullable<string>;
    farmID: Nullable<FarmEntity["id"]>;
  };
  meta: {
    isDefault: boolean;
    eventID: Nullable<EventEntity["id"]>;
    isEventCreated: boolean;
    isEventUpdated: boolean;
    isCreateEventFailed: boolean;
    isUpdateEventFailed: boolean;
  };
}

const DefaultContext = createContext<BaseContext>({
  api: {
    open: _.noop,
    close: _.noop
  },
  searchParams: {
    eventListHash: null,
    farmID: null
  },
  meta: {
    isDefault: true,
    eventID: null,
    isEventCreated: false,
    isEventUpdated: false,
    isCreateEventFailed: false,
    isUpdateEventFailed: false
  }
});

/**
 * Context provider to help creating an Ask Ida tracker
 * @param children
 */
const _CreateEventDialogContextProvider: FC = ({ children }) => {
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);
  const { farm: farmContext } = useFarm();
  const match = useRouteMatch<{ id: EventEntity["id"] }>(`${Routes.app.timeline.event.index}*`);
  const eventID = match?.params.id;

  const {
    meta: { isDefault }
  } = useCreateEventDialog();

  if (!isDefault) {
    console.error("Do not nest CreateEventDialogContext.Provider, it will cause problems.");
  }

  const {
    createEvent,
    editEvent,
    farm: farmURIReference
  } = parseQuery(
    searchParams,
    CreateEventURLParams.createEventKey,
    CreateEventURLParams.editEventKey,
    CreateEventURLParams.farm
  );

  const farmID = _.defaultTo(farmURIReference, farmContext?.id);

  /**
   * Tell if the event is created
   */
  const [isEventCreated, createdEventPayload] = useSelector<
    EnterpriseAppState,
    [boolean, TimelineEventFormValues | NormalizedEvent]
  >((state) => isFulfilled(state, Events.types.CREATE_EVENT, { withPayload: true }));

  /**
   * Tell if the event is updated
   */
  const [isEventUpdated, updatedEventPayload] = useSelector<
    EnterpriseAppState,
    [boolean, TimelineEventFormValues | NormalizedEvent | null]
  >((state) => {
    return eventID
      ? isFulfilled(state, Events.types.UPDATE_EVENT, { withPayload: true, primaryValue: eventID })
      : [false, null];
  });

  /**
   * Tell if creating the event is failed
   * @type {boolean}
   */
  const isCreateEventFailed = useSelector<EnterpriseAppState, boolean>((state) =>
    isRejected(state, Events.types.CREATE_EVENT)
  );

  /**
   * Tell if updating the event is failed
   * @type {boolean}
   */
  const isUpdateEventFailed = useSelector<EnterpriseAppState, boolean>((state) =>
    eventID ? isRejected(state, Events.types.UPDATE_EVENT, { primaryValue: eventID }) : false
  );

  /**
   * Open the dialog (using the given origin)
   * @param {EventEntity["id"]} eventListHash
   * @param {FarmEntity["id"]} farmID
   * @param {Exclude<CreateEventURLParams, "farm">} action
   */
  const open = (
    eventListHash: EventEntity["id"],
    farmID?: FarmEntity["id"],
    action: Exclude<CreateEventURLParams, "farm"> = CreateEventURLParams.createEventKey
  ) => {
    searchParams.set(action, eventListHash);

    if (farmID) {
      searchParams.set(CreateEventURLParams.farm, farmID.toString());
    }

    Enterprise.history.push({ pathname, search: searchParams.toString() });
  };

  /**
   * Close the dialog
   */
  const close = () => {
    searchParams.delete(CreateEventURLParams.createEventKey);
    searchParams.delete(CreateEventURLParams.editEventKey);
    searchParams.delete(CreateEventURLParams.farm);

    Enterprise.history.push({ pathname, search: searchParams.toString() });
  };

  return (
    <DefaultContext.Provider
      value={{
        api: {
          open,
          close
        },
        searchParams: {
          eventListHash:
            searchParams.has(CreateEventURLParams.createEventKey || CreateEventURLParams.editEventKey) &&
            _.isEmpty(createEvent || editEvent)
              ? Events.utils.makeEventHash(farmID ? +farmID : void 0)
              : createEvent || editEvent,
          farmID: farmID ? +farmID : null
        },
        meta: {
          isDefault: false,
          eventID:
            isEventCreated || isEventUpdated
              ? ((updatedEventPayload ? updatedEventPayload : createdEventPayload) as NormalizedEvent).result
              : null,
          isEventCreated,
          isEventUpdated,
          isCreateEventFailed,
          isUpdateEventFailed
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const CreateEventDialogContext = {
  Provider: _CreateEventDialogContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * @returns {void}
 */
export const useCreateEventDialog = (): BaseContext => useContext<BaseContext>(DefaultContext);

import { memoize, Units, UnitSystem } from "@ctra/utils";
import * as _ from "lodash";

import { BulbCircle } from "@ctra/components";
import { DataDescriptorEntity, Enterprise as App } from "@ctra/api";
import { Enterprise, i18nTranslate } from "@ctra/i18n";

import { OnboardingInsightValidation } from "@insights";
import { useLocalization } from "@base";

import { OnboardingInsightBody } from "../insight-body";
import { GenericInsightBase } from "./Base";
import { string } from "yup";

class OnboardingInsight extends GenericInsightBase {
  @memoize
  getComponents(): Omit<ReturnType<typeof GenericInsightBase.prototype.getComponents>, "Body"> & {
    Body: typeof OnboardingInsightBody;
  } {
    return {
      ...super.getComponents(),
      Body: OnboardingInsightBody,
      Validation: OnboardingInsightValidation,
      Icon: BulbCircle,
      ListIcon: BulbCircle
    };
  }

  @memoize
  getMetadata(): Record<string, any> {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { unitSystem: globalUnitSystem } = useLocalization();
    const { data, variant_id, ...rest } = super.getMetadata();
    const metric = App.entities.getDataDescriptor(App.store.getState(), { id: variant_id });
    const units = _.get(metric, ["valueProperties", "units"]);

    /**
     * Convert value to global unit system
     * @param {number} value
     * @returns {number}
     */
    const convert = (value: number): number =>
      Units.convertToUnitSystem(value, units, {
        from: UnitSystem.metric,
        to: globalUnitSystem,
        precision: metric.valueProperties.decimals
      });

    const convertedData = data.map((item: { x: number; y: number }) => ({
      x: item.x,
      y: convert(item.y)
    }));

    return {
      data: convertedData,
      variant_id,
      ...rest
    };
  }

  @memoize
  getMetric(): DataDescriptorEntity {
    return App.entities.getDataDescriptor(App.store.getState(), { id: this.getMetadata().variant_id });
  }

  @memoize
  getMetricName(): string {
    const { kpi } = Enterprise;
    const metric = this.getMetric();
    const typeName = _.get(metric, ["dataProperties", "typeName"]);

    return i18nTranslate(kpi.displayName(typeName), {
      variant: "short",
      case: null,
      makeDefaultValue: true
    });
  }

  /**
   * Get translation params
   * @type {(() => {value_thi_65: string, value_thi_75: string, value_change_pct: string, direction: string | null}) & ReturnType<typeof _.memoize>}
   * @private
   */
  private getTranslationParams: (() => {
    value_thi_65: string;
    value_thi_75: string;
    value_change_pct: string;
    metric: string;
    direction: string | null;
  }) &
    ReturnType<typeof _.memoize> = _.memoize(() => {
    const { value_thi_65, value_thi_75, value_change_pct, variant_id, year } = super.getMetadata();

    const { unitSystem: globalUnitSystem } = useLocalization();
    const metric = App.entities.getDataDescriptor(App.store.getState(), { id: variant_id });
    const metricName = this.getMetricName();
    const units = _.get(metric, ["valueProperties", "units"]);
    const unit = _.get(units, globalUnitSystem, _.get(units, UnitSystem.metric));

    /**
     * Convert value to global unit system
     * @param {number} value
     * @returns {number}
     */
    const convert = (value: number): number =>
      Units.convertToUnitSystem(value, units, {
        from: UnitSystem.metric,
        to: globalUnitSystem,
        precision: metric.valueProperties.decimals
      });

    return {
      metric: metricName,
      value_thi_65: Units.format(convert(value_thi_65), unit, void 0, {
        precision: metric.valueProperties.decimals
      }),
      value_thi_75: Units.format(convert(value_thi_75), unit, void 0, {
        precision: metric.valueProperties.decimals
      }),
      value_change_pct: Units.format(Math.abs(value_change_pct), "%", void 0, {
        precision: 2
      }),
      direction: value_thi_65 < value_thi_75 ? "increase" : value_thi_65 > value_thi_75 ? "decrease" : null,
      year
    };
  });

  /**
   * Get insight title
   * @param params {{}}
   */
  @memoize
  getTitle(params?: Record<string, unknown>): string {
    const {
      entity: { title }
    } = Enterprise.insights;

    return i18nTranslate(title(this.entity.insightType), {
      metricName: this.getMetricName(),
      ...this.getMetadata(),
      ...params
    });
  }

  /**
   * Get insight description
   * @param userParams {{}}
   */
  @memoize
  getDescription(userParams?: Record<string, unknown>): string {
    const {
      insights: {
        entity: { description }
      }
    } = Enterprise;

    const params = {
      ...this.getTranslationParams(),
      ...userParams
    };

    return i18nTranslate(description(this.entity.insightType), params);
  }
}

export { OnboardingInsight };

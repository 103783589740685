import { memoize } from "@ctra/utils";

/**
 * X axis data type base class
 */
class Base {
  /**
   * Get the translated label
   * @param label
   * @returns
   */
  @memoize
  getTranslatedLabel(label: string | number, options: { long: boolean }): string {
    return label.toString();
  }

  /**
   * Get the config for x axis
   *
   * As charts evolve, if heatmap has a different config from line chart,
   * this method should be renamed to getHeatmapConfig, getLineConfig etc
   * For now, they all share similar config, so one config is enough
   * @returns
   */
  @memoize
  getConfig(): Record<string, unknown> {
    return {};
  }
}

export { Base };

import { createContext, FC, useEffect, Dispatch, useState, SetStateAction, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as _ from "lodash";

import { isDispatched, isPending, Nullable } from "@ctra/utils";
import {
  GenericInsightEntity,
  Enterprise,
  Insights,
  InsightWorkflowState,
  EnterpriseAppState
} from "@ctra/api";

interface ProviderProps {
  /**
   * Query to load in odata
   * @note omit to delay loading
   */
  query?: Record<string, unknown>; // @todo use odata-query
  /**
   * List name
   */
  list?: string;
  /**
   * Filter the insights by their workflow state
   */
  filter?: InsightWorkflowState | "all";
}

interface ContextType {
  insights: Nullable<Array<GenericInsightEntity>>;
  insightStateFilter: InsightWorkflowState | "all";
  setInsightStateFilter: Dispatch<SetStateAction<InsightWorkflowState | "all">>;
  meta: { isLoading: boolean; unseenCount: number; toFollowUpCount: number; listName?: string };
}

/**
 * Make a default context for insight list values
 */
const DefaultContext = createContext<ContextType>({
  insights: [],
  insightStateFilter: InsightWorkflowState.toCheck,
  setInsightStateFilter: _.noop,
  meta: { isLoading: true, unseenCount: 0, toFollowUpCount: 0 }
});

/**
 * Get a list of insights from the back-end
 * @param {Record<string, unknown> | undefined} query
 * @param {string | undefined} list
 * @param {InsightWorkflowState | undefined} filter
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @returns {JSX.Element}
 */
export const _InsightListProvider: FC<ProviderProps> = ({ query, list, filter, children }) => {
  const dispatch = useDispatch();
  const [insightStateFilter, setInsightStateFilter] = useState(
    _.defaultTo(filter, InsightWorkflowState.toCheck)
  );

  /**
   * See if we have a match for our query in the store
   */
  const insights = useSelector<EnterpriseAppState, Nullable<Array<GenericInsightEntity>>>((state) =>
    Enterprise.entities.getInsightList(state, { query, list })
  );

  /**
   * Tell if the insights have been already fetched
   */
  const hasFetched = useSelector<EnterpriseAppState, boolean>(
    (state) =>
      !!query &&
      isDispatched(state, Insights.types.FETCH_INSIGHTS, {
        primaryValue: Insights.utils.makeQueryHash(query)
      })
  );

  /**
   * Tell whether the insights are loading
   */
  const isLoading = useSelector<EnterpriseAppState, boolean>(
    (state) =>
      !query ||
      isPending(state, Insights.types.FETCH_INSIGHTS, {
        primaryValue: Insights.utils.makeQueryHash(query)
      })
  );

  useEffect(() => {
    if (!hasFetched && query) {
      dispatch(Insights.actions.fetchInsights.start(query, list));
    }
    /* eslint-disable react-hooks/exhaustive-deps,  */
  }, [query, list, insights]);

  /**
   * filtering locally based on the filter passed instead of individually fetching via oData calls
   * because we still need to be able to show the count badges
   */
  const filterInsight = (insightState: InsightWorkflowState) =>
    _.filter(insights, (insight) => insight.workflowState === insightState);

  /**
   * classify the insight list into sub categories
   */
  const insightList = {
    [InsightWorkflowState.toCheck]: filterInsight(InsightWorkflowState.toCheck),
    [InsightWorkflowState.toFollowUp]: filterInsight(InsightWorkflowState.toFollowUp),
    [InsightWorkflowState.done]: filterInsight(InsightWorkflowState.done)
  };

  /**
   * count of the unseen insights
   * it can only be unseen if it's in to check
   */
  const unseenCount = insights
    ? _.filter(insightList[InsightWorkflowState.toCheck], (insight) => insight.seenBy.length === 0).length
    : 0;

  return (
    <DefaultContext.Provider
      value={{
        insights: insightStateFilter === "all" ? insights : insightList[insightStateFilter],
        insightStateFilter,
        setInsightStateFilter,
        meta: {
          listName: list,
          isLoading,
          unseenCount,
          toFollowUpCount: insightList[InsightWorkflowState.toFollowUp].length
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const InsightListContext = {
  Provider: _InsightListProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Make a hook to access the context in FCs
 */
export const useInsightlist = (): ContextType => useContext(DefaultContext);

import { Debug } from "@ctra/utils";

Debug.create("analytics", "Analytics", { prefixColor: "#A52019", transports: false });

export { default } from "./components";

export * from "./routes";
export * from "./components";
export * from "./providers";
export * from "./analytics";
export * from "./testing";
export * from "./utils";

import { useEffect, useContext, createContext, FC } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import * as _ from "lodash";

import {
  DataDescriptorEntity,
  EnterpriseAppState,
  Enterprise,
  ChartDataSourceType,
  UnitSystemConversionMap,
  ChartEntity,
  Charts
} from "@ctra/api";

import { UnitSystem } from "@ctra/utils";

import { useLocalization } from "@base";
import { Routes } from "@routes";

import { ChartAPIProviderProps, ContextType } from "./typings";

import { useChartFilters } from "../ChartFilterContext";
import { useChart, useKPISupport } from "../../hooks";
import { useViews } from "../../hooks/useViews";

/**
 * Default Chart API Context
 */
const DefaultContext = createContext<ContextType>({
  chart: "Empty context: no chart" as unknown as ChartEntity,
  dataDescriptor: "Empty context: no data descriptor" as unknown as DataDescriptorEntity,
  api: {
    setChart: _.noop
  },
  meta: {
    unitSystem: UnitSystemConversionMap[UnitSystem.metric],
    supportedViews: {},
    farms: { hasNoSupport: [], hasSupport: [] },
    sourceType: ChartDataSourceType.dataPoints
  }
});

/**
 * Provide an API for displaying/updating charts and set meta data used to initialize filter context values
 * @param {string} dataDescriptorID
 * @param {number | undefined} farmID
 * @param {string | undefined} defaultView
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @returns {JSX.Element}
 */
const _ChartAPIProvider: FC<ChartAPIProviderProps> = ({
  dataDescriptorID,
  farmID,
  defaultView,
  children
}) => {
  const { unitSystem: contextUnitSystem } = useLocalization();
  const { pathname } = useLocation();
  const isAnalysisPage = pathname === Routes.app.analytics.compareCharts.index;

  const {
    api: { setISODuration, setMetadata }
  } = useChartFilters();

  /**
   * Resolve the chart object and a basic chart switching API
   * using the data descriptor id.
   */
  const supportedViews = useViews(dataDescriptorID);
  const [chart, setChart] = useChart(dataDescriptorID, defaultView && supportedViews[defaultView]);

  const {
    dataProperties: { minimumDisplayInterval, offset },
    filters: filterDefinitions
  } = chart;

  /**
   * Farm support information
   * Sieves out the farms ids that are not supported
   */
  const { hasSupport, hasNoSupport } = useKPISupport(dataDescriptorID, farmID ? [farmID] : undefined);

  /**
   * get isImperialSupported flag
   * if isImperialSupported is true, we can inject unit system as filter
   */
  const dataDescriptorEntity = useSelector<EnterpriseAppState, DataDescriptorEntity>((state) =>
    Enterprise.entities.getDataDescriptor(state, { id: dataDescriptorID })
  );

  const {
    flags: { isImperialSupported }
  } = dataDescriptorEntity;

  const unitSystem = isImperialSupported
    ? UnitSystemConversionMap[contextUnitSystem]
    : UnitSystemConversionMap[UnitSystem.metric];

  useEffect(() => {
    const isBackendAggregated = _.some(chart.filters, "isBackendAggregated");

    setMetadata((metadata) => ({
      ...metadata,
      flags: { ...metadata.flags, isBackendAggregated },
      supportedfarmIDs: hasSupport
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart.id]);

  /**
   * Set the parent filter context based on the current chart config
   * This only runs when there is no section in the context and is not an analysis page
   * @todo check where this effect is even used before this grows into a conditional mess !!
   */
  useEffect(() => {
    if (!isAnalysisPage) {
      const isBackendAggregated = _.some(chart.filters, "isBackendAggregated");
      setISODuration(minimumDisplayInterval);

      setMetadata((metadata) => ({
        ...metadata,
        flags: { ...metadata.flags, isBackendAggregated },
        supportedISODurations: Charts.utils.supportedDurations,
        filterDefinitions,
        offset
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chart.id]);

  return (
    <DefaultContext.Provider
      value={{
        dataDescriptor: dataDescriptorEntity,
        chart,
        api: {
          setChart
        },
        meta: {
          supportedViews,
          unitSystem,
          farms: {
            hasSupport,
            hasNoSupport
          },
          sourceType: ChartDataSourceType.dataPoints
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const ChartAPIContext = {
  Provider: _ChartAPIProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook for Chart context
 * @returns
 */
export const useChartAPI = (): ContextType => useContext<ContextType>(DefaultContext);

import { EventCategory } from "@ctra/api";

/**
 * All the categories and subcategories
 */
const categories = [
  {
    id: EventCategory.feed,
    children: [
      {
        id: "Ration",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "FeedMixing",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "Concentrates",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "FeedingSchedule",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "FeedAccessibility",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "Grazing",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "Pasture",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "WaterAvailability",
        scope: ["Farm", "HerdGroup"]
      }
    ]
  },
  {
    id: EventCategory.housing,
    children: [
      {
        id: "StockingDensity",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "Bedding",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "GroupChange",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "BarnAdjustment",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "NewBarn",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "NewCubicles",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "HoofBath",
        scope: ["Farm", "HerdGroup"]
      }
    ]
  },
  {
    id: EventCategory.farmEquipment,
    children: [
      {
        id: "AutomatedFeeding",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "AutomatedFeedPushing",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "VentilationSystem",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "MilkSystemProblem",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "ManureSystemProblem",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "FeedingSystemProblem",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "MilkRobot",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "PowerOutage",
        scope: ["Farm", "HerdGroup"]
      }
    ]
  },
  {
    id: EventCategory.weather,
    children: [
      {
        id: "HeatStress",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "HeavyRainfall",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "Cold",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "StrongWinds",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "TemperatureChange",
        scope: ["Farm", "HerdGroup"]
      }
    ]
  },
  {
    id: EventCategory.management,
    children: [
      {
        id: "NewFarmEmployee",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "ProtocolChange",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "Agitation",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "ManagementError",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "HoofTrimming",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "Vaccinations",
        scope: ["Farm", "HerdGroup"]
      }
    ]
  },
  {
    id: EventCategory.other,
    children: [
      {
        id: "DiseaseOutbreak",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "InternetOutage",
        scope: ["Farm", "HerdGroup"]
      },
      {
        id: "Other",
        scope: ["Farm", "HerdGroup"]
      }
    ]
  }
];

export default categories;

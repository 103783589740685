import { ChartDataPointXAxisType } from "@ctra/api";
import { Base } from "./Base";
import { Day } from "./Day";
import { WeekDay } from "./WeekDay";
import { Month } from "./Month";

type RegisteredSeries = typeof Base | typeof Day | typeof WeekDay | typeof Month;

/**
 * A factory to create a nice API around the x axis data types
 * @example
 * ```ts
 * Factory.create(type);
 * // instanceof Day extends Base
 * ```
 */
class Factory {
  private static _registry = new Map();

  /**
   * Register an xAxis type
   * @param {ChartDataPointXAxisType} type
   * @param {I} cls
   */
  static register<I extends RegisteredSeries>(type: ChartDataPointXAxisType, cls: I): void {
    if (!Factory._registry.has(type)) {
      Factory._registry.set(type, cls);
    } else {
      throw new Error(`${type} axis type is already in the registry`);
    }
  }

  /**
   * Create an instance of an xAxis type
   * @param {ChartDataPointXAxisType} type
   * @return {InstanceType<I>}
   */
  static create<I extends RegisteredSeries>(type?: ChartDataPointXAxisType): InstanceType<I> {
    let cls = Factory._registry.get(type);

    if (!cls) {
      console.warn(`${type} is not yet in the registry. Using XAxis Base as constructor`);
      cls = Base;
    }

    return new cls();
  }
}

Factory.register(ChartDataPointXAxisType.group, Base);
Factory.register(ChartDataPointXAxisType.series, Base);
Factory.register(ChartDataPointXAxisType.date, Day);
Factory.register(ChartDataPointXAxisType.weekDay, WeekDay);
Factory.register(ChartDataPointXAxisType.month, Month);

export { Factory };

import { useState, useEffect, Dispatch, SetStateAction, useRef } from "react";
import moment from "moment";
import * as _ from "lodash";

import { ChartTimePeriod, ISODuration } from "@ctra/api";
import { iso8601Ago, Nullable } from "@ctra/utils";
import { DateMethod } from "../providers";

/**
 * @param {<ISODuration>} inputIsoDuration
 * @param {{startDate: string, endDate: string} | Record<string, never>} inputTimePeriod
 * @param {DateMethod} currentDateMethod
 * @param {number} offset
 * @return {[ChartTimePeriod, React.Dispatch<React.SetStateAction<ChartTimePeriod>>]}
 */
export const useTimePeriod = (
  {
    isoDuration: inputIsoDuration,
    timePeriod: inputTimePeriod,
    currentDateMethod
  }: {
    isoDuration?: Nullable<ISODuration>;
    timePeriod?: ChartTimePeriod;
    currentDateMethod?: DateMethod;
  } = {},
  offset: number
): [ChartTimePeriod, Dispatch<SetStateAction<ChartTimePeriod>>] => {
  const firstUpdate = useRef<boolean>(true);

  /**
   * Get the default display interval as start date string,
   * which is basically picking a date in the past which
   * matches the default duration + data lag offset.
   */
  const isoStartDate = inputIsoDuration ? iso8601Ago(inputIsoDuration, offset) : void 0;

  /**
   * Select input time period if date method is custom
   */
  const startDate = _.get(
    currentDateMethod === DateMethod.custom ? inputTimePeriod : isoStartDate,
    "startDate",
    isoStartDate
  );

  /**
   * Get the end date string using the data offset value.
   * This does not change as we assume the users would be interested in
   * "N units ago ... now". The data offset is taken into account.
   */
  const isoEndDate = inputIsoDuration
    ? moment().subtract(offset, "seconds").startOf("hour").toISOString()
    : void 0;

  /**
   * Select input time period if date method is custom
   */
  const endDate = _.get(
    currentDateMethod === DateMethod.custom ? inputTimePeriod : isoEndDate,
    "endDate",
    isoEndDate
  );

  /**
   * Prepare a state for time period switching
   * Start with date strings of the default display interval or nothing
   */
  const [timePeriod, setTimePeriod] = useState<ChartTimePeriod>(
    startDate && endDate ? { startDate, endDate } : {}
  );

  /**
   * Update time period whenever date strings change from dropdown
   */
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      /**
       * Any invalid time period falls back to the previous one until set properly
       */
      setTimePeriod(startDate && endDate ? { startDate, endDate } : timePeriod);
    }
    // eslint-disable-next-line
  }, [startDate, endDate]);

  return [timePeriod, setTimePeriod];
};

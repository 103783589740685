export enum GACategories {
  chartZoom = "Zoomed chart"
}

export enum GAActions {
  changeChartType = "Change chart type",
  changeISODuration = "Change time period",
  expandChart = "Expand chart",
  collapseChart = "Collapse expanded chart",
  goToCompareCharts = "Go to Compare Charts",
  selectDIMGroup = "Select DIM group",
  selectHerdGroup = "Select herd group",
  selectPen = "Select pen",
  toggleEventAnnotations = "Toggle event annotations",
  toggleFarmTimeline = "Toggle farm timeline",
  addToFavourites = "Add to favourites",
  removeFromFavourites = "Remove from favourites",
  showAnomalies = "Show anomalies",
  hideAnomalies = "Hide anomalies"
}

export enum GALabels {
  custom = "Custom"
}

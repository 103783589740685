import { FC, memo } from "react";
import * as _ from "lodash";
import { compose } from "lodash/fp";
import { Column as AntColumn } from "@ant-design/charts";

import { withChartWrapper, withSeriesColor } from "../../../hocs";
import { useColumnConfig } from "./useColumnConfig";

/**
 * Column Component
 * @returns
 */
const ColumnComponent: FC = memo(
  () => {
    const { config } = useColumnConfig();

    return <AntColumn {...config} />;
  },
  (prev, next) => _.isEqual(prev, next)
);

ColumnComponent.displayName = "Column";

/**
 * Full Column chart
 */
export const Column: FC = compose(withChartWrapper, withSeriesColor)(ColumnComponent);

import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { PlotEvent } from "@ant-design/charts";
import * as _ from "lodash";
import { useDeepCompareEffect } from "use-deep-compare";

import {
  ChartFilters,
  Charts,
  ExtendedEventEntity,
  Filter,
  MetricEntity,
  filterToSeriesMap,
  SavedCard,
  V3DisplayFilter,
  Breadcrumbs,
  Enterprise,
  EventSourceType
} from "@ctra/api";

import {
  Charts as ChartUI,
  CtraLayout,
  SwitchWithLabel,
  Row,
  Col,
  Typography,
  Button,
  PlusCircleOutlined,
  ArrowLeftOutlined
} from "@ctra/components";

import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { Nullable, parseQuery } from "@ctra/utils";
import { GAEvent } from "@ctra/analytics";

import { useFarm } from "@farms";
import { CompiledRoutes, URIState } from "@routes";
import { EventListContext } from "@events";
import { useDataDictionary } from "@base";
import { useSavedCharts, GACategories } from "@analytics";

import {
  Chart,
  ChartAPIContext,
  ChartFilterContext,
  EventList,
  ISODurationFilter,
  useTimePeriod
} from "@chart";

import { ChartSelector } from "../ChartSelector";
import styles from "./AddChartPage.module.less";

const { WidgetWrapper, ContentWrapper } = CtraLayout;
const { ChartVariant } = ChartUI;
const { Paragraph } = Typography;
const {
  components: { Breadcrumb }
} = Breadcrumbs;

const {
  analytics: {
    correlations: { pageDescription, events },
    v3: {
      config: { cta }
    },
    config: {
      metric: { displayName }
    }
  }
} = Content;

/**
 * Chart configurator page
 * @return {JSX.Element}
 * @constructor
 */
export const AddChartPage: FC = () => {
  const { t } = useTranslation();

  const [showEvents, setShowEvents] = useState<Record<EventSourceType, boolean>>(
    _.mapValues({ ..._.invert(_.toPlainObject(EventSourceType)) }, () => false) as Record<
      EventSourceType,
      boolean
    >
  );

  const [eventList, setEventList] = useState<ExtendedEventEntity[]>([]);
  const { farm: farmContext } = useFarm();

  const {
    metrics,
    dataDescriptors,
    meta: { isLoading },
    groups: { families, projectionMetrics },
    api: { extractChartInfo }
  } = useDataDictionary();

  const {
    savedCharts: allCharts,
    api: { saveChart },
    meta: { isUpdating }
  } = useSavedCharts();

  const { dashboard: groupID, metricID: initialMetricID } =
    useParams<{ dashboard: string; metricID: string }>();

  const { search } = useLocation<URIState>();
  const searchParams = new URLSearchParams(search);
  const query = parseQuery(searchParams, "source", "variant");
  const initialSourceID = _.defaultTo(query.source, void 0);
  const initialVariantID = _.defaultTo(query.variant, void 0);

  const group = _.find(allCharts, { id: groupID });
  const groupName = _.get(group, "groupName");

  /**
   * Handle a metric value change
   * @param {MetricEntity["id"]} metric
   * @param {string} source
   * @param {string} variant
   * @returns {{metric: string, variant: string, source: any}}
   */
  const handleMetricChanges = (metric: MetricEntity["id"], source?: string, variant?: string) => {
    const initialSources = _.get(families, metric);
    const initialSourceID = source || _.minBy(initialSources, "familyIndex")?.id;
    const initialVariants = _.get(metrics, [initialSourceID || metric, "variants"]);

    /**
     * Get the farm type variant
     */
    const farmVariant = _.find(initialVariants, (variant) =>
      _.isEqual(_.get(dataDescriptors, [variant, "dataProperties", "type"]), "farm")
    );

    /**
     * If no farm selected, then use the per farm variant, otherwise use the variant passed. Default to the first one
     */
    const initialVariantID = (
      !farmContext ? farmVariant : _.defaultTo(variant, farmVariant || _.first(initialVariants))
    ) as string;

    return { metric, source: initialSourceID, variant: initialVariantID };
  };

  /**
   * Handle saving a chart to a dashboard tab
   *
   * @param {MetricEntity["id"]} variantID
   * @param {string} view
   * @param {ChartFilters} dataFilters
   * @param {SavedChart["displayFilters"]} displayFilters
   */
  const handleSave = (
    variantID: MetricEntity["id"],
    view: string,
    dataFilters: ChartFilters,
    displayFilters?: SavedCard["displayFilters"]
  ) => {
    const dateRange = isoDuration ? [isoDuration] : [timePeriod.startDate, timePeriod.endDate];
    /**
     * convert filters into type + value format
     */
    const convertedDataFilters = _.map(
      dataFilters,
      (value, key) =>
        ({
          type: _.get(filterToSeriesMap, key),
          values: value
        } as Filter)
    );

    if (groupID && groupName) {
      saveChart(variantID, groupID, groupName, {
        chartType: view,
        dateRange,
        dataFilters: convertedDataFilters,
        displayFilters
      });

      Enterprise.history.push(CompiledRoutes.app.analytics.dashboard.index({ dashboard: groupID }));
    } else {
      throw new Error("Group ID or Group Name is missing.");
    }
  };

  const [chart, setChart] = useState(handleMetricChanges(initialMetricID, initialSourceID, initialVariantID));
  const isProjectionMetric = _.includes(projectionMetrics, chart.metric);
  const initialISODuration = "P3M";
  const [isoDuration, setISOduration] = useState<Nullable<string>>(initialISODuration);
  const [initialTimePeriod] = useTimePeriod({ isoDuration }, _.defaultTo(0, 86400));

  /**
   * If projection metric, add a month to the end date
   */
  useEffect(() => {
    if (isProjectionMetric) {
      initialTimePeriod.endDate = moment(initialTimePeriod.endDate).add(1, "months").toISOString();
      setISOduration(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProjectionMetric]);

  const [timePeriod, setTimePeriod] = useState(initialTimePeriod);
  const eventPanelHidden = _.isEmpty(eventList);
  const chartInfo = extractChartInfo(chart);

  const metricName = t(displayName(chartInfo.nameToDisplay), {
    makeDefaultValue: true
  });

  const { metric, source, variant } = chart;

  useDeepCompareEffect(() => {
    setEventList([]);
  }, [chart]);

  useDeepCompareEffect(() => {
    if (eventList.length > 0 && !_.some(showEvents)) {
      setEventList([]);
    }
  }, [showEvents]);

  /**
   * Value change handler for dropdowns associated with metric/source/variant changes
   */
  const handleChange = (_metric: string, source?: string, variant?: string) =>
    setChart((chart) =>
      _.isEqual(chart.metric, metric) ? handleMetricChanges(_metric, source, variant) : chart
    );

  const ref = useRef<any>();

  return (
    <EventListContext.Provider farmID={farmContext?.id}>
      {groupName && (
        <Breadcrumb
          path={CompiledRoutes.app.analytics.dashboard.index({ dashboard: groupID })}
          title={groupName}
        />
      )}
      <Breadcrumb title={metricName} />
      <Button
        size="large"
        type="secondary"
        onClick={() =>
          Enterprise.history.push(
            CompiledRoutes.app.analytics.dashboard.addChart.index({
              dashboard: groupID
            })
          )
        }
        icon={<ArrowLeftOutlined />}
      >
        {t(cta.back)}
      </Button>
      <WidgetWrapper
        className={styles.WidgetWrapper}
        loading={isLoading}
        title={<Paragraph>{t<string>(pageDescription)}</Paragraph>}
      >
        <Row gutter={[24, 0]} wrap={false}>
          <Col flex={1}>
            <Row className={styles.Options} justify="end" align="middle">
              {farmContext && (
                <EventListContext.Consumer>
                  {({ events: eventList }) =>
                    _.size(eventList) > 0 && (
                      <>
                        <Col>
                          <SwitchWithLabel
                            data-gtm-category={GACategories.configurator}
                            data-gtm-action="Toggle aiModel annotations"
                            // inverted the showEvents because of render delay
                            data-gtm-info={JSON.stringify({ visible: !showEvents })}
                            defaultChecked={showEvents[EventSourceType.aiModel]}
                            onChange={(checked) =>
                              setShowEvents((current) => ({ ...current, [EventSourceType.aiModel]: checked }))
                            }
                            size="small"
                            label={t(events, {
                              variant: "ai"
                            })}
                          />
                        </Col>
                        <Col className={styles.RightMargin}>
                          <SwitchWithLabel
                            data-gtm-category={GACategories.configurator}
                            data-gtm-action="Toggle events"
                            // inverted the showEvents because of render delay
                            data-gtm-info={JSON.stringify({ visible: !showEvents })}
                            defaultChecked={_.every(_.omit(showEvents, EventSourceType.aiModel))}
                            onChange={(checked) =>
                              setShowEvents(({ AIModel, ...rest }) => ({
                                AIModel,
                                ..._.mapValues(rest, () => checked)
                              }))
                            }
                            size="small"
                            label={t(events, { variant: "events" })}
                          />
                        </Col>
                      </>
                    )
                  }
                </EventListContext.Consumer>
              )}
              <Col>
                <ISODurationFilter
                  data-gtm-category={GACategories.configurator}
                  data-gtm-action="Change ISO duration"
                  durations={Charts.utils.supportedDurations}
                  duration={isoDuration}
                  timePeriod={timePeriod}
                  onDurationChange={setISOduration}
                  onTimePeriodChange={setTimePeriod}
                />
              </Col>
            </Row>
            <ContentWrapper padded={false} className={styles.Wrapper}>
              <ChartFilterContext.Provider
                isoDuration={isoDuration}
                timePeriod={timePeriod}
                currentValues={{ isoDuration, timePeriod }}
              >
                <ChartFilterContext.Consumer>
                  {({ series, filters }) =>
                    variant && (
                      <ChartAPIContext.Provider dataDescriptorID={variant} farmID={farmContext?.id}>
                        <ChartAPIContext.Consumer>
                          {({ chart: { view } }) => {
                            /**
                             * current display filters
                             */

                            const displayFilters = series.active.length
                              ? ([
                                  {
                                    type: "series",
                                    values: series.active
                                  }
                                ] as V3DisplayFilter[])
                              : void 0;

                            /**
                             * Metric name for GA
                             */
                            const name = _.get(metrics, [metric, "name"]);
                            const metricName = t(displayName(_.defaultTo(name, metric)), {
                              makeDefaultValue: true
                            });

                            return (
                              <>
                                <Row wrap={false} align="middle">
                                  <Col flex={1}>
                                    <ChartSelector
                                      metric={metric}
                                      source={source}
                                      variant={variant}
                                      handleChange={handleChange}
                                    />
                                  </Col>
                                </Row>
                                <Row wrap={false}>
                                  <Col span={24} className={styles.Chart}>
                                    <Chart
                                      ref={ref}
                                      variant={ChartVariant.V3}
                                      viewOptions={{
                                        showEvents,
                                        zoomEnabled: false,
                                        showSupportedViews: true,
                                        showToolbar: false,
                                        showAnnotations: true
                                      }}
                                      config={{
                                        onReady: (plot) => {
                                          ref.current = plot;

                                          plot.on("element:click", (event: PlotEvent) => {
                                            const shape = _.get(event, ["data", "shape"]);
                                            const eventList = _.get(event, ["data", "data", "events"]);

                                            GAEvent(
                                              GACategories.configurator,
                                              "Open event",
                                              JSON.stringify({ eventList })
                                            );

                                            if (shape === "farm-event-point") {
                                              setEventList(() => eventList);
                                            }
                                          });
                                        }
                                      }}
                                    />
                                  </Col>
                                </Row>
                                <Row justify="end" className={styles.Buttons}>
                                  <Button
                                    loading={isUpdating}
                                    className={styles.RightMargin}
                                    icon={<PlusCircleOutlined />}
                                    size="large"
                                    type="primary"
                                    data-gtm-category={GACategories.dashboards}
                                    data-gtm-action="Save metric to dashboard"
                                    data-gtm-info={JSON.stringify({ metric: metricName })}
                                    onClick={() => handleSave(variant, view, filters, displayFilters)}
                                  >
                                    {t(cta.save, { isSaved: false })}
                                  </Button>
                                  <Button
                                    size="large"
                                    type="secondary"
                                    data-gtm-category={GACategories.dashboards}
                                    data-gtm-action="Cancel saving"
                                    onClick={() =>
                                      Enterprise.history.push(
                                        CompiledRoutes.app.analytics.dashboard.addChart.index({
                                          dashboard: groupID
                                        })
                                      )
                                    }
                                  >
                                    {t(cta.cancel)}
                                  </Button>
                                </Row>
                              </>
                            );
                          }}
                        </ChartAPIContext.Consumer>
                      </ChartAPIContext.Provider>
                    )
                  }
                </ChartFilterContext.Consumer>
              </ChartFilterContext.Provider>
            </ContentWrapper>
          </Col>
          {eventPanelHidden ? null : (
            <Col span={5}>
              <EventList
                variant={
                  _.every(eventList, (event) => event.source.type === EventSourceType.aiModel)
                    ? EventList.variant.insights
                    : EventList.variant.events
                }
                data={eventList}
                handleClose={() => setEventList([])}
              />
            </Col>
          )}
        </Row>
      </WidgetWrapper>
    </EventListContext.Provider>
  );
};

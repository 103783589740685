import { FC } from "react";
import * as _ from "lodash";

import { Steps, Icon, Smile, Dot, Typography } from "@ctra/components";
import { InsightValidation as ValidationValues, ValidationLookup } from "@ctra/api";
import { Enterprise, useTranslation } from "@ctra/i18n";

import {
  useInsightResolutions,
  useInsightValidation,
  ResolutionWidget,
  ValidationQuestion,
  ValidationButtons,
  ResolutionTagList
} from "@insights";

import styles from "./RegularInsightValidation.module.less";

const { Step } = Steps;
const { Text } = Typography;

/**
 * Regular insight validation
 * @constructor
 */
export const RegularInsightValidation: FC = () => {
  const { t } = useTranslation();

  const { validation: validationEntity, validate } = useInsightValidation();
  const {
    resolutions,
    unresolve,
    meta: { isResolved }
  } = useInsightResolutions();

  const {
    insightValidations: { validationValue }
  } = Enterprise;

  const { validation } = validationEntity;

  /**
   * Get current step based on the validation value
   */
  const getCurrentStep = () => {
    switch (validation) {
      case ValidationValues.na: {
        return 0;
      }
      case ValidationValues.maybe:
      case ValidationValues.no:
      case ValidationValues.yes: {
        return isResolved ? 2 : 1;
      }
    }
  };

  /**
   * Handle validation attempt and make sure the unresolve doesn't update workflow state
   * because its already being updated via the validation.
   * @param value
   */
  const onValidate = _.flow(validate, _.partial(unresolve, { updatesWorkflowState: false }));

  return (
    <Steps current={getCurrentStep()} className={styles.Steps} direction="vertical">
      <Step
        icon={<Icon component={Smile} className={styles.Smile} />}
        title={<ValidationQuestion highlighted={validation === ValidationValues.na} step={1} />}
        description={
          validation === ValidationValues.na ? (
            <ValidationButtons
              options={[ValidationValues.yes, ValidationValues.maybe, ValidationValues.no]}
            />
          ) : (
            <Text type="secondary" className={styles.Link}>
              {t<string>(validationValue[ValidationLookup[validation]])}
            </Text>
          )
        }
      />
      {validation !== ValidationValues.na && !!_.keys(resolutions).length && (
        <Step
          icon={<Icon component={Dot} />}
          title={
            <ValidationQuestion
              highlighted={!isResolved}
              rollback={isResolved ? void 0 : _.partial(onValidate, ValidationValues.na)}
              step={2}
              validation={validation}
            />
          }
          description={isResolved ? <ResolutionTagList includeValidation={false} /> : <ResolutionWidget />}
        />
      )}
      {validation !== ValidationValues.na && isResolved && (
        <Step
          icon={<Icon component={Dot} />}
          title={
            /**
             * If there are no resolutions for the selected validation,
             * then we just change the validation to NA, else we unresolve.
             * Using _.ary(unresolve, 0) instead of just unresolve
             * because rollback sends an unwanted event argument which needs to be avoided.
             */
            <ValidationQuestion
              highlighted
              rollback={
                _.isEmpty(resolutions) ? _.partial(onValidate, ValidationValues.na) : _.ary(unresolve, 0)
              }
              step={3}
              validation={validation}
            />
          }
        />
      )}
    </Steps>
  );
};

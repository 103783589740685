import React, { useRef } from "react";
import { useLocation } from "react-router-dom";

import { MediaQueryDynamicImport, Drawer, Typography } from "@ctra/components";
import { Enterprise as App, EventEntity } from "@ctra/api";
import { Enterprise, useTranslation } from "@ctra/i18n";
import { useGoogleAnalytics, GAContext } from "@ctra/analytics";

import { URIState } from "@routes";
import { testIds, GACategories, GAActions } from "@chart";
import { EventListURLParams, EventList } from "@events";
import { useFarm } from "@farms";

import { DataDictionaryContext } from "@base";

const { Title } = Typography;

/**
 * Zoomed chart view with farm events (if a farm is selected)
 * @constructor
 */
export const ChartZoom = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { state, pathname, search } = useLocation<URIState>();
  const { farm } = useFarm();
  const { trackEvent } = useGoogleAnalytics();

  const {
    events: {
      list: { headers }
    }
  } = Enterprise;

  const searchParams = new URLSearchParams(search);

  if (state) {
    const { zoom } = state;

    return zoom && zoom.dataDescriptorID ? (
      <div data-testid={testIds.chartZoom.wrapper}>
        <MediaQueryDynamicImport mobile={() => import("./Mobile")} desktop={() => import("./Desktop")}>
          {(Component) => (
            <DataDictionaryContext.Provider>
              <Component
                dataDescriptorID={zoom.dataDescriptorID}
                farmID={farm?.id}
                filters={zoom.filters}
                legendValues={zoom.legendValues}
                timePeriod={zoom.timePeriod}
                isoDuration={zoom.isoDuration}
                view={zoom.view}
                displayFilters={zoom.displayFilters}
                dashboard={zoom.dashboard}
              >
                <GAContext.Provider value={{ category: GACategories.chartZoom }}>
                  <Drawer
                    mask={false}
                    getContainer={false}
                    style={{
                      position: "absolute",
                      top: 1,
                      bottom: 1,
                      right: 1,
                      height: "auto"
                    }}
                    width={500}
                    open={searchParams.has(EventListURLParams.selectedEvent)}
                    onClose={() => {
                      searchParams.delete(EventListURLParams.selectedEvent);
                      App.history.push({ pathname, search: searchParams.toString(), state });
                    }}
                    afterOpenChange={(open) => {
                      trackEvent(GAActions.toggleFarmTimeline, {
                        label: open ? "open" : "close"
                      });
                    }}
                  >
                    <Title level={3}>{t<string>(headers.title)}</Title>
                    <EventList
                      compact
                      limit={100}
                      highlight={searchParams.get(EventListURLParams.selectedEvent) as EventEntity["id"]}
                    />
                  </Drawer>
                </GAContext.Provider>
              </Component>
            </DataDictionaryContext.Provider>
          )}
        </MediaQueryDynamicImport>
      </div>
    ) : null;
  }
  return null;
};

import { useState, useRef } from "react";
import * as _ from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";

import { ChartFilters } from "@ctra/api";
import { Nullable } from "@ctra/utils";

export type FilterSetter = <A extends keyof ChartFilters>(
  filterName: A,
  value: ChartFilters[A],
  options?: { asPreset?: boolean }
) => void;

/**
 * Merge two filter objects without undefined values
 * @param {Nullable<ChartFilters>} a
 * @param {Nullable<ChartFilters>} b
 * @returns {ChartFilters}
 */
const merge = (a: Nullable<ChartFilters>, b: Nullable<ChartFilters>): ChartFilters => {
  return _.omitBy(
    _.mapValues(
      {
        ...a,
        ...b
      },
      _.compact
    ),
    _.isEmpty
  ) as ChartFilters;
};

/**
 * API hook to to update chart filters
 * @param defaultFilters - these come from the chart object, initially empty
 * @param presetFilters - developer-presets
 */
export const useFilter = (
  defaultFilters: Nullable<ChartFilters>,
  presetFilters?: ChartFilters
): [ChartFilters, FilterSetter, () => void] => {
  /**
   * We combine the preset and the user defined filters.
   * The preset always have priority. Eg. via props we can set a
   * list of farm ids which will apply to the chart and can be overridden
   * by the user interface. The default filters come from the BE
   * baked in the chart config or set by Redux.
   *
   * Using a ref keeps track of the incoming filters to
   * so changes from the outside may be detected.
   * If the props of the wrapper component change,
   * the filters must be updated.
   */
  const filterRef = useRef(merge(defaultFilters, presetFilters));

  const [filters, setFilters] = useState<ChartFilters>(filterRef.current);

  useDeepCompareEffect(() => {
    const updatedFilters = merge(defaultFilters, presetFilters);

    /**
     * Prevent a setState loop by checking if the filters actually
     * got updated from the outside.
     */
    if (!_.isEqual(updatedFilters, filterRef.current)) {
      setFilters(updatedFilters);
      filterRef.current = updatedFilters;
    }
  }, [defaultFilters, presetFilters]);

  /**
   * Update a given filter with the provided value
   * @param filterName
   * @param value
   */
  const updateFilter: FilterSetter = (filterName, value) => {
    setFilters(
      /**
       * Apply the filter value as it is overriding anything else
       */
      merge(filters, { [filterName]: value })
    );
  };

  /**
   * Reset the filters to the initial state
   */
  const reset = () => {
    setFilters(merge(defaultFilters, presetFilters));
  };

  return [filters, updateFilter, reset];
};

import { FC, ComponentType, PropsWithChildren } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import * as _ from "lodash";

import { Row, Col } from "@ctra/components";
import { getDisplayName, classname } from "@ctra/utils";
import { DataPointsData } from "@ctra/api";

import { useChartContext } from "../../providers";
import { LegendPosition } from "../../typings";

import styles from "./withLegend.module.less";
import { testIds } from "../testing";

/**
 * Map legend positions to the styles
 */
const positionToStyleMap: Record<LegendPosition, string> = {
  [LegendPosition.top]: styles.Top,
  [LegendPosition.bottom]: styles.Bottom,
  [LegendPosition.left]: styles.Left,
  [LegendPosition.right]: styles.Right
};

/**
 * withLegend hoc
 *
 * Places a passed in Legend component in a specified position around the Wrapped component
 * @param {React.ComponentType} Legend
 * @param {{conditionalRender: boolean}} options
 * @return {(WrappedComponent: React.ComponentType<P>) => React.FC<P>}
 */
export function withLegend<P extends PropsWithChildren<Record<string, unknown>>>(
  Legend: ComponentType,
  options: { conditionalRender: boolean }
): (WrappedComponent: ComponentType<P>) => FC<P> {
  return function (WrappedComponent: ComponentType<P>): FC<P> {
    const { conditionalRender } = options;

    const WithLegend: FC<P> = (props: P) => {
      const {
        meta,
        config: { legend }
      } = useChartContext<DataPointsData>();

      const keys = _.get(meta, ["series", "keys"], {});

      /**
       * Only show the series if they are more than one.
       * This applies for when the legend prop is a Legend component.
       * Slider legends always have conditionalRender set as false.
       */
      const showLegend = _.isBoolean(legend) ? legend : conditionalRender ? _.size(keys) > 1 : true;

      /**
       * Pick a default legend position
       * @type {string|null}
       */
      const position = _.get(legend, "position", null);

      return (
        <Row
          className={classname(styles.Wrapper, showLegend ? _.get(positionToStyleMap, position) : null)}
          data-testid={testIds.withLegend.wrapper}
          gutter={[16, 0]}
        >
          <Col flex={1} className={classname(styles.ChartWrapper, "ctra-chart-chartsWrapper")}>
            <WrappedComponent {...props} />
          </Col>
          <Col className={classname("ctra-chart-legendWrapper")}>{showLegend && <Legend />}</Col>
        </Row>
      );
    };

    WithLegend.displayName = `WithLegend(${getDisplayName(WrappedComponent)})`;
    return hoistNonReactStatics(WithLegend, WrappedComponent);
  };
}

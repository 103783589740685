import { FC, useState } from "react";

import {
  CtraLayout,
  Typography,
  EnvironmentOutlined,
  Collapse,
  DownOutlined,
  UpOutlined
} from "@ctra/components";

import { Enterprise, useTranslation } from "@ctra/i18n";

import { useFarm } from "../../providers";
import { testIds } from "../testing";
import styles from "./FarmDetails.module.less";

const { Paragraph, Title } = Typography;
const { Panel } = Collapse;
const { AsyncLayout } = CtraLayout;

/**
 * Farm "business card" for md and lg screens
 * @constructor
 */
export const FarmDetails: FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { farm } = useFarm();
  const { t } = useTranslation();

  const {
    layouts: {
      dashboard: { farmDetails }
    }
  } = Enterprise;

  return (
    <AsyncLayout className={styles.Wrapper}>
      <Collapse
        expandIcon={() => {
          /* istanbul ignore next */
          return collapsed ? <UpOutlined /> : <DownOutlined />;
        }}
        expandIconPosition="end"
        onChange={() => {
          /* istanbul ignore next */
          setCollapsed(!collapsed);
        }}
        ghost
      >
        <Panel
          key="farm-details"
          header={
            <Title level={4} data-testid={testIds.details.farmName}>
              <EnvironmentOutlined />
              {t<string>(farmDetails.title, { name: farm ? farm.name : null })}
            </Title>
          }
        >
          {farm && (
            <Paragraph data-test-id={testIds.details.address}>
              {farm.address && farm.city ? `${farm.address}, ${farm.city}` : farm.address || farm.city}
              {farm.postCode && (
                <>
                  <br />
                  {farm.postCode}
                </>
              )}
              {farm.country && (
                <>
                  <br />
                  {farm.country}
                </>
              )}
            </Paragraph>
          )}
        </Panel>
      </Collapse>
    </AsyncLayout>
  );
};

/**
 * A stricter way to parse integers
 *
 * @param value
 * @returns
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/parseInt#a_stricter_parse_function
 */
export const strictParseInt = (value: string) => {
  return /^[-+]?(\d+|Infinity)$/.test(value) ? Number(value) : NaN;
};

import { FC, lazy, useEffect } from "react";
import { useRouteMatch, useLocation } from "react-router-dom";

import { MediaQueryDynamicImport, message } from "@ctra/components";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { EventEntity } from "@ctra/api";

import { FarmContext } from "@farms";
import { Routes } from "@routes";

import { CreateEventURLParams, EventContext, useCreateEventDialog } from "../../providers";

const CreateEventWizardV2 = lazy(() => import("../WizardV2"));

const FarmWrapper: FC = ({ children }) => {
  const {
    searchParams: { farmID }
  } = useCreateEventDialog();

  return farmID ? (
    <FarmContext.Provider local={true} farmID={farmID ? +farmID : void 0}>
      {children}
    </FarmContext.Provider>
  ) : (
    <>{children}</>
  );
};

const EventWrapper: FC = ({ children }) => {
  const match = useRouteMatch<{ id: EventEntity["id"] }>(`${Routes.app.timeline.event.index}*`);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  return searchParams.has(CreateEventURLParams.editEventKey) && match?.params.id ? (
    <EventContext.Provider eventID={match.params.id}>{children}</EventContext.Provider>
  ) : (
    <>{children}</>
  );
};

/**
 * Create a timeline event
 * @constructor
 */
const CreateEvent: FC = () => {
  const { t } = useTranslation();

  const {
    api: { close },
    searchParams: { eventListHash },
    meta: { isEventCreated, isEventUpdated, isCreateEventFailed, isUpdateEventFailed }
  } = useCreateEventDialog();

  const {
    timeline: {
      createEvent: { success, fail }
    }
  } = Content;

  useEffect(() => {
    if (isEventCreated) {
      message.success(t<string>(success));
      close();
    } else if (isCreateEventFailed) {
      message.error(t<string>(fail));
    }
    // eslint-disable-next-line
  }, [isEventCreated, isCreateEventFailed]);

  useEffect(() => {
    if (isEventUpdated) {
      message.success(t<string>(success));
      close();
    } else if (isUpdateEventFailed) {
      message.error(t<string>(fail));
    }
    // eslint-disable-next-line
  }, [isEventUpdated, isUpdateEventFailed]);

  return eventListHash ? (
    <MediaQueryDynamicImport mobile={() => import("./Mobile")} desktop={() => import("./Desktop")}>
      {(Component) => (
        <FarmWrapper>
          <EventWrapper>
            <Component>
              <CreateEventWizardV2 />
            </Component>
          </EventWrapper>
        </FarmWrapper>
      )}
    </MediaQueryDynamicImport>
  ) : null;
};

export default CreateEvent;

import { ComponentProps, createContext, FC, useContext, useState } from "react";
import * as _ from "lodash";

import { Alert } from "@ctra/components";

interface ContextType {
  messages: Record<string, ComponentProps<typeof Alert>>;
  api: {
    add: (id: string, props: ComponentProps<typeof Alert>) => void;
    remove: (id: string) => void;
  };
}

const DefaultContext = createContext<ContextType>({
  messages: {},
  api: {
    add: _.noop,
    remove: _.noop
  }
});

/**
 * User list context provider
 * @param {"compat" | "consent"} source
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _AlertContextProvider: FC = ({ children }) => {
  const [messages, setMessages] = useState<Record<string, ComponentProps<typeof Alert>>>({});

  return (
    <DefaultContext.Provider
      value={{
        messages,
        api: {
          add: (id, props) => setMessages((prev) => ({ ...prev, [id]: props })),
          remove: (id) => setMessages((prev) => _.omit(prev, id))
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const AlertContext = {
  Provider: _AlertContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Make a hook to access the context in FCs
 */
export const useAlerts = (): ContextType => useContext(DefaultContext);

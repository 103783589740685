import * as Yup from "yup";

import { useTranslation, Enterprise } from "@ctra/i18n";
import { Invitation } from "../../../../settings/components/Users/typings";

const {
  settings: {
    users: {
      form: { validation }
    }
  }
} = Enterprise;

type ValidationSchema = Yup.SchemaOf<Omit<Invitation, "invitation">>;

/**
 * Validation schema for the invitation form
 */
export const useValidationSchema = (): (() => ValidationSchema) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    id: Yup.string().nullable(true),
    firstName: Yup.string().defined(t<string>(validation.firstNameRequired)),
    lastName: Yup.string().defined(t<string>(validation.lastNameRequired)),
    email: Yup.string().defined(t<string>(validation.emailRequired)),
    org: Yup.string(),
    role: Yup.string().defined(t<string>(validation.roleRequired))
  });

  return () => validationSchema;
};

import { FC } from "react";
import { AjinomotoTwoStepValidation } from "../AjinomotoTwoStepValidation";

/**
 * Blood test insight "validation"
 * @param {React.PropsWithChildren<{}>} props
 * @return {JSX.Element}
 * @constructor
 */
export const AjinomotoBloodTestInsightValidation: FC = (props) => <AjinomotoTwoStepValidation {...props} />;

import * as _ from "lodash/fp";

/**
 * Get the stacked max value
 * @param data
 * @param groupByProperty
 * @param sumProperty
 * @returns
 */
export const getStackedMax = <T extends Record<string, unknown>>(
  data: Array<T>,
  groupByProperty: keyof T,
  sumProperty: keyof T
): number | undefined => {
  //@ts-ignore
  return _.max(
    _.map((items) => {
      //@ts-ignore
      return _.reduce((acc, item) => acc + item[sumProperty], 0, items);
      //@ts-ignore
    })(_.groupBy(groupByProperty, data))
  );
};

/**
 * Get the stacked min value
 * @param data
 * @param groupByProperty
 * @param sumProperty
 * @returns
 */
export const getStackedMin = <T extends Record<string, unknown>>(
  data: Array<T>,
  groupByProperty: keyof T,
  sumProperty: keyof T
): number | undefined => {
  return _.min(
    _.map((items) => {
      //@ts-ignore
      return _.min(_.map((item) => item[sumProperty])(items));
      //@ts-ignore
    })(_.groupBy(groupByProperty, data))
  );
};

/**
 * Returns the minimum value for a particular property from an array of objects
 * @param {Array<T>} data
 * @param {keyof T} byProperty
 * @param {number} precision
 * @return {number}
 */
export function getMinValue<T extends Record<string, unknown>>(data: Array<T>, byProperty: keyof T): number {
  return _.compose(
    _.defaultTo(0),
    _.get(byProperty),
    _.minBy((datum: T) => datum[byProperty])
  )(data);
}

/**
 * Returns the maximum value for a particular property from an array of objects
 * @param {Array<T>} data
 * @param {keyof T} byProperty
 * @param {number} precision
 * @return {number}
 */
export function getMaxValue<T extends Record<string, unknown>>(data: Array<T>, byProperty: keyof T): number {
  return _.compose(
    _.defaultTo(0),
    _.get(byProperty),
    _.maxBy((datum: T) => datum[byProperty])
  )(data);
}

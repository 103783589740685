import * as _ from "lodash";

/**
 * Get thee inherited property names of the given object
 * @param obj
 * @param BaseClass
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export const getInheritedPropertyNames = <T extends Object, B extends Function>(obj: T, BaseClass: B) => {
  const props = new Set();

  do {
    Object.getOwnPropertyNames(obj).forEach((p) => props.add(p));
  } while ((obj = obj instanceof BaseClass ? Object.getPrototypeOf(obj) : null));

  return _.without(Array.from(props), "constructor");
};

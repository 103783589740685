import { useState, useEffect, createContext, useContext, FC } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as _ from "lodash";

import { Enterprise, EnterpriseAppState, FarmEntity, FarmList, PensList, HerdGroupList } from "@ctra/api";
import { Nullable } from "@ctra/utils";

import { getStorageKey } from "../../../../utils/versioning";

interface ContextType {
  farm: Nullable<FarmEntity>;
  pens: PensList;
  herdGroups: HerdGroupList;
  setFarmContext: (farmID: Nullable<FarmEntity["id"]>) => void;
}

/**
 * farm context session key
 */
export const sessionKey = `${getStorageKey({ persist: true })}.farmContext`;

/**
 * Default farm context
 */
export const DefaultFarmContext = createContext<ContextType>({
  farm: null,
  pens: {},
  herdGroups: {},
  setFarmContext: _.noop
});

/**
 * Custom farm context provider which sets the farm context based on the selected farm.
 * For persistence, it uses session storage and clears the key when a user logs out/closes the tab.
 * @note Due to the fact that some pages in the app uses farmID as a query param for their business logic,
 * external URLS will make use of farmContext as their query param which will then be deleted once the farm context
 * has been set. This helps us avoid a conflict in query param names.
 * @param local
 * @param defaultFarmID
 * @param children
 * @constructor
 */
const FarmContextProvider: FC<{ local?: boolean; farmID?: FarmEntity["id"] }> = ({
  local,
  farmID: defaultFarmID,
  children
}) => {
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const farmContextSearchParam = searchParams.get("farmContext");

  /**
   * Fetch the initial farmID from the url farmContext param or the session storage
   */
  const [farmID, setFarmID] = useState<Nullable<FarmEntity["id"]>>(
    defaultFarmID || farmContextSearchParam || JSON.parse(sessionStorage.getItem(sessionKey) as string)
  );

  /**
   * Get the farm list
   */
  const farmList = useSelector<EnterpriseAppState, FarmList>((state) =>
    Enterprise.entities.getFarmList(state)
  );

  /**
   * Get the pen list from the store
   * @type {PensList}
   */
  const pens = useSelector<EnterpriseAppState, PensList>((state) =>
    farmID ? Enterprise.entities.getPens(state, { farmID }) : {}
  );

  /**
   * Get the herd group list from the store
   * @type {HerdGroupList}
   */
  const herdGroups = useSelector<EnterpriseAppState, HerdGroupList>((state) =>
    farmID ? Enterprise.entities.getHerdGroups(state, { farmID }) : {}
  );

  useEffect(() => {
    if (defaultFarmID !== farmID) {
      setFarmID(defaultFarmID);
    }
    // eslint-disable-next-line
  }, [defaultFarmID]);

  useEffect(() => {
    /**
     * Set the farm context and delete the farmContext from the URL param
     * when the farmContextSearchParam changes
     */
    setFarmContext(farmID);
    searchParams.delete("farmContext");

    Enterprise.history.push({ pathname, search: searchParams.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmContextSearchParam]);

  useEffect(() => {
    return () => {
      if (!local) {
        sessionStorage.removeItem(sessionKey);
      }

      /**
       * Remove the selected farm object from session storage when the user logs out
       */
      setFarmID(null);
    };
  }, [local]);

  useEffect(() => {
    const farmIDs = _.keys(farmList) as unknown as Array<FarmEntity["id"]>;

    /**
     * If the user has only one farm,
     * go ahead and set it in the context
     */
    if (_.eq(farmIDs.length, 1)) {
      setFarmContext(Number(_.first(farmIDs)));
    }
    // eslint-disable-next-line
  }, [farmList]);

  /**
   * Save selected farm object to session storage and set the context value
   * @param farmID
   */
  const setFarmContext = (farmID: Nullable<FarmEntity["id"]>) => {
    /**
     * This ensures that we do not lose farm data on browser page refresh
     */
    if (!local) {
      sessionStorage.setItem(sessionKey, JSON.stringify(farmID));
    }

    setFarmID(farmID);
  };

  /**
   * Find the farm in the store
   * @todo check what happens if the farm list is not yet loaded
   */
  const farm = farmID ? farmList[farmID] : null;

  return (
    <DefaultFarmContext.Provider value={{ farm, pens, herdGroups, setFarmContext }}>
      {children}
    </DefaultFarmContext.Provider>
  );
};

export const FarmContext = {
  Provider: FarmContextProvider,
  Consumer: DefaultFarmContext.Consumer
};

/**
 * Hook for farm context
 * @returns {void}
 */
export const useFarm = (): ContextType => useContext<ContextType>(DefaultFarmContext);

import { memoize } from "@ctra/utils";
import { Line } from "@ctra/charts";

import { DataPointsBase } from "./DataPointsBase";
import { ChartViewBase } from "./Base";

/**
 * Line Chart View class
 */
class LineChart extends DataPointsBase {
  /**
   * get the chart component
   */
  @memoize
  getComponent(): ReturnType<typeof ChartViewBase.prototype.getComponent> {
    return Line;
  }
}

export { LineChart };

import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import * as _ from "lodash";

import { GA } from "@ctra/analytics";
import { EnterpriseAppState, UserEntity, Enterprise } from "@ctra/api";
import { useFarm, GALabels as FarmGALabels } from "@farms";

import { useLocalization } from "../../providers";

/**
 * Google Analytics setup
 * @return {null}
 * @constructor
 */
export const GoogleAnalytics: FC = () => {
  const { unitSystem, locale } = useLocalization();
  const { farm } = useFarm();

  const { id, username, userGroups } = useSelector<EnterpriseAppState, Partial<UserEntity>>((state) => {
    let user: Partial<UserEntity> = {};

    try {
      user = Enterprise.entities.getLoggedInUser(state);
    } catch (e) {}

    return user;
  });

  const isImpersonating = useSelector<EnterpriseAppState, boolean>(Enterprise.entities.isImpersonating);
  const farmID = _.defaultTo(farm?.id.toString(), "NULL");
  const farmName = _.defaultTo(farm?.name, FarmGALabels.allFarms);
  const userGroup = _.defaultTo(_.first(userGroups)?.name, "NULL");

  useEffect(() => {
    GA.gtag("set", "user_properties", {
      user_name: `${isImpersonating ? " [IMP]" : ""}${username}`,
      farm_id: farmID,
      farm_name: farmName,
      user_group: userGroup,
      unit_system: unitSystem
    });
  }, [id, username, farmID, farmName, userGroup, unitSystem, isImpersonating]);

  useEffect(() => {
    GA.gtag("set", "user_id", `${id}${isImpersonating ? "-impersonated" : ""}`);
  }, [id, isImpersonating]);

  useEffect(() => {
    GA.gtag("set", "language", locale);
  }, [locale]);

  return null;
};

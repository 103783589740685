import { ComponentProps, FC } from "react";
import { Dropdown, Menu } from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import * as _ from "lodash";

import { InsightWorkflowState } from "@ctra/api";
import { classname } from "@ctra/utils";

import { Button } from "../../../../elements";
import styles from "./StatusDropdown.module.less";

export interface StatusDropdownProps extends ComponentProps<typeof Dropdown> {
  /**
   * disabled state
   */
  disabled?: boolean;
  /**
   * loading state
   */
  loading?: boolean;
  /**
   * current selection
   */
  selected: InsightWorkflowState;
  /**
   * click handler
   */
  onClick: ComponentProps<typeof Menu>["onClick"];
  /**
   * dropdown labels
   */
  labels: {
    [InsightWorkflowState.toCheck]: string;
    [InsightWorkflowState.toFollowUp]: string;
    [InsightWorkflowState.done]: string;
  };
}

/**
 * Insight dialog status dropdown component
 * @param {boolean | undefined} loading
 * @param {boolean | undefined} disabled
 * @param {InsightWorkflowState} selected
 * @param {{"[InsightWorkflowState.toCheck]": string, "[InsightWorkflowState.toFollowUp]": string, "[InsightWorkflowState.done]": string}} labels
 * @param {((info: MenuInfo) => void) | undefined} onClick
 * @param {string | undefined} className
 * @param {Pick<StatusDropdownProps & {children?: React.ReactNode | undefined}, "menu" | "autoFocus" | "arrow" | "trigger" | "dropdownRender" | "onOpenChange" | "open" | "destroyPopupOnHide" | "align" | "getPopupContainer" | "prefixCls" | "transitionName" | "placement" | "overlayClassName" | "overlayStyle" | "forceRender" | "mouseEnterDelay" | "mouseLeaveDelay" | "openClassName" | "children" | "overlay" | "visible" | "onVisibleChange">} rest
 * @return {JSX.Element}
 * @constructor
 */
const StatusDropdown: FC<StatusDropdownProps> = ({
  loading,
  disabled,
  selected,
  labels,
  onClick,
  className,
  ...rest
}) => {
  const isDone = selected === InsightWorkflowState.done;

  return (
    <Dropdown
      className={classname("ctra-insightDialog-statusDropdown", styles.StatusDropdown, className)}
      disabled={disabled}
      trigger={["click"]}
      placement="bottomLeft"
      dropdownRender={() => (
        <Menu selectedKeys={[selected]} onClick={onClick}>
          {isDone ? (
            <Menu.Item key={InsightWorkflowState.toFollowUp}>
              {labels[InsightWorkflowState.toFollowUp]}
            </Menu.Item>
          ) : (
            <Menu.Item key={InsightWorkflowState.done}>{labels[InsightWorkflowState.done]}</Menu.Item>
          )}
        </Menu>
      )}
      {...rest}
    >
      <Button className={_.get(styles, _.upperFirst(selected))}>
        {labels[selected]}
        {!disabled && (loading ? <LoadingOutlined /> : <DownOutlined />)}
      </Button>
    </Dropdown>
  );
};

export default StatusDropdown;

import * as _ from "lodash";
import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Enterprise as App,
  EnterpriseAppState,
  Invitation,
  InvitationList,
  InvitationType,
  ReferralEntity,
  ReferralStatus,
  ReferralType
} from "@ctra/api";

import { GAEvent } from "@ctra/analytics";
import { isFulfilled, isPending, isDispatched } from "@ctra/utils";
import { Button } from "@ctra/components";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";

import { useFarmList } from "@farms";

import { useAlerts } from "../AlertContext";
import { useSession } from "@auth";
import { GACategories } from "@network";

interface ContextType {
  sent: InvitationList;
  received: InvitationList;
  pending: Array<ReferralEntity>;
  api: {
    handleAccept: (code: string) => void;
    handleDecline: (code: string) => void;
    handleDelete: (id: ReferralEntity["id"]) => void;
  };
  meta: {
    isLoading: boolean;
  };
}

const DefaultContext = createContext<ContextType>({
  sent: {},
  received: {},
  pending: [],
  api: {
    handleAccept: _.noop,
    handleDecline: _.noop,
    handleDelete: _.noop
  },
  meta: {
    isLoading: false
  }
});

const {
  network: {
    referrals: {
      alerts: { invitationAccepted }
    }
  }
} = Content;

/**
 * Invitation context
 * @param children
 * @param params
 * @private
 */
const _InvitationContextProvider: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const { farmList } = useFarmList();
  const dispatch = useDispatch();
  const [needsRefresh, setNeedsRefresh] = useState<boolean>();
  const { t } = useTranslation();

  const {
    api: { reset }
  } = useSession();

  const {
    api: { add, remove }
  } = useAlerts();

  /**
   * Tell if the user has farms
   * @type {boolean}
   */
  const hasFarms = !_.isEmpty(farmList);

  /**
   * Get the received invitations
   * @type {InvitationList}
   */
  const received = useSelector<EnterpriseAppState, InvitationList>((state) =>
    App.entities.getReferrals(state, { variant: InvitationType.received })
  );

  /**
   * Get the sent invitations
   * @type {InvitationList}
   */
  const sent = useSelector<EnterpriseAppState, InvitationList>((state) =>
    App.entities.getReferrals(state, { variant: InvitationType.sent })
  );

  /**
   * Tell if the invitation list has been dispatched
   * @type {boolean}
   */
  const dispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, Invitation.types.FETCH_INVITATION_LIST)
  );

  /**
   * Filter and get only the invitations not accepted yet
   * @type {ReferralEntity[]}
   */
  const pending = _.filter(received, ({ type, status }) => {
    const isPending = !_.isEqual(status, ReferralStatus.accepted);
    const mayAccept = hasFarms || _.isEqual(type, ReferralType.farmerToUser);

    return isPending && mayAccept;
  });

  /**
   * Tell if the invitation list is loading
   * @type {boolean}
   */
  const isFetching = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Invitation.types.FETCH_INVITATION_LIST)
  );

  /**
   * Tell is an invitation is being accepted
   * @type {boolean}
   */
  const isAccepting = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Invitation.types.ACCEPT_INVITATION)
  );

  /**
   * Tell if the invitation is deleting
   * @type {boolean}
   */
  const isDeleting = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Invitation.types.DELETE_INVITATION)
  );

  /**
   * Tell if an invitation is accepted
   * @type {boolean}
   */
  const accepted = useSelector<EnterpriseAppState, boolean>((state) =>
    isFulfilled(state, Invitation.types.ACCEPT_INVITATION)
  );

  /**
   * Accept the invitation
   * @param {string} code
   */
  const handleAccept = (code: string) => {
    const invitation = _.find(received, { code });

    if (invitation) {
      const { type } = invitation;

      /**
       * The reason we never set it to false is because
       * the notification should always stay visible, even
       * if the user accepts another type of invitation in the meantime.
       * After the refresh this will go away.
       */
      if (type === ReferralType.farmerToUser) {
        setNeedsRefresh(true);
      }

      GAEvent(GACategories.network, "Accept referral", JSON.stringify({ type, code }));
      dispatch(Invitation.actions.acceptInvitation.start(code));
    }
  };

  /**
   * Delete the invitation
   * @param {ReferralEntity["id"]} id
   */
  const handleDelete = (id: ReferralEntity["id"]) => {
    GAEvent(GACategories.network, "Delete referral", JSON.stringify({ id }));
    dispatch(Invitation.actions.deleteInvitation.start(id));
  };

  useEffect(() => {
    if (!dispatched) {
      dispatch(Invitation.actions.fetchInvitationList.start());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatched]);

  /**
   * If invitation is accepted, remove any params (invitation)
   * flush and reload
   */
  useEffect(() => {
    if (accepted && needsRefresh) {
      add("invitationAccepted", {
        showIcon: true,
        message: t<string>(invitationAccepted.title), // "Invitation accepted successfully",
        description: t<string>(invitationAccepted.description), // "Please reload the app to see the updates.",
        action: (
          <Button
            type="primary"
            onClick={() => {
              GAEvent(GACategories.network, "Reload app after invitation accepted");
              reset();
            }}
          >
            {t<string>(invitationAccepted.cta)}
          </Button>
        ),
        type: "success"
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accepted, needsRefresh]);

  return (
    <DefaultContext.Provider
      value={{
        sent,
        received,
        pending,
        api: {
          handleAccept,
          handleDelete,
          handleDecline: _.noop
        },
        meta: {
          isLoading: isAccepting || isFetching || isDeleting
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const InvitationContext = {
  Provider: _InvitationContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Make a hook to access the context in FCs
 */
export const useInvitation = (): ContextType => useContext(DefaultContext);

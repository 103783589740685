import { G2 } from "@ant-design/plots";
import * as _ from "lodash";

import { Line } from "@ctra/api";
import { Nullable } from "@ctra/utils";

export enum AnomalyPoint {
  lead = "lead",
  mid = "mid",
  trail = "trail",
  join = "join"
}

export type Coordinates = Array<
  (NonNullable<G2.Types.ShapeInfo["points"]>[number] & { type?: AnomalyPoint }) | null
>;

/**
 * Find orphan points in the data surrounded by no values
 * @param {Coordinates | NonNullable<ShapeInfo["points"]>} points
 * @param {Line} data
 * @return {Coordinates}
 */
export const findOrphans = (
  points: Coordinates | NonNullable<G2.Types.ShapeInfo["points"]>,
  data: Line
): Coordinates => {
  const result: Coordinates = [];

  _.forEach(data, (point, idx, collection) => {
    /**
     * Find the previous data point
     * @type {any}
     */
    const previous = _.get(collection, [idx - 1], {} as Line[number]);

    /**
     * Find the next data point
     * @type {any}
     */
    const next = _.get(collection, [idx + 1], {} as Line[number]);

    if (_.isNil(previous.y) && _.isNil(next.y)) {
      result.push(points[idx]);
    }
  });

  return result;
};

/**
 * Find the nearest valid point in the data given an index and direction
 * @param {Coordinates} points
 * @param {number} idx
 * @param {1 | -1} direction
 * @returns {any | undefined}
 */
const findNearestValid = (
  points: Coordinates,
  idx: number,
  direction: 1 | -1
): Coordinates[number] | undefined => {
  for (let i = idx + direction; i >= 0 && i < points.length; i += direction) {
    const point = points[i];

    if (point && !isNaN(_.get(point, ["y"])) && !isNaN(_.get(point, ["x"]))) {
      return point;
    }
  }
};

/**
 * Corrct NaN values by replacing them with the nearest valid value
 * @param {Coordinates} points
 * @returns {Coordinates}
 */
const correctNaN = (points: Coordinates): Coordinates => {
  return _.map(points, (point, idx, collection) => {
    const prev = findNearestValid(collection, idx, -1);
    const next = findNearestValid(collection, idx, 1);

    if ((point && _.isNaN(_.get(point, ["y"]))) || _.isNaN(_.get(point, ["x"]))) {
      return {
        ...point,
        x: _.get(next, ["x"], _.get(prev, ["x"], 0)),
        y: _.get(next, ["y"], _.get(prev, ["y"], 0))
      };
    }

    return point;
  });
};

/**
 * Partition the data into two sets based on the predicate
 * @param {NonNullable<ShapeInfo["points"]>} points
 * @param {Line} data
 * @param {(point: Line[number]) => boolean} predicate
 * @return {[Coordinates, Coordinates]}
 */
export const partition = (
  points: Coordinates | NonNullable<G2.Types.ShapeInfo["points"]>,
  data: Line,
  predicate: (point: Nullable<Line[number]>) => boolean
): [Coordinates, Coordinates] => {
  const left: Coordinates = [];
  const right: Coordinates = [];

  _.forEach(data, (point, idx, collection) => {
    /**
     * Find the next data point
     * @type {any}
     */
    const next = _.get(collection, [idx + 1], {} as Line[number]);

    /**
     * Find the previous data point
     * @type {any}
     */
    const previous = _.get(collection, [idx - 1], {} as Line[number]);

    /**
     * Check if the current point matches the predicate
     * @type {boolean}
     */
    const matchesCurrent = predicate(point);

    /**
     * Check if the previous point one matches the predicate
     * @type {boolean}
     */
    const matchesPrevious = idx > 0 && predicate(previous);

    /**
     * Check if the next point one matches the predicate
     * @type {boolean}
     */
    const matchesNext = idx < collection.length - 1 && predicate(next);

    if (matchesPrevious || matchesCurrent || matchesNext) {
      let type: AnomalyPoint = AnomalyPoint.mid;

      if (matchesCurrent) {
        /**
         * Current one matches the predicate
         */
        if (idx === 0) {
          type = AnomalyPoint.lead;
        } else if (idx === collection.length - 1) {
          type = AnomalyPoint.trail;
        } else {
          type = AnomalyPoint.mid;
        }
      } else if (matchesPrevious && matchesNext) {
        /**
         * Previous and next one matches the predicate
         */
        type = AnomalyPoint.join;
      } else if (matchesPrevious) {
        /**
         * Previous one matches the predicate
         */
        type = AnomalyPoint.trail;
      } else if (matchesNext) {
        /**
         * Next one matches the predicate
         */
        type = AnomalyPoint.lead;
      }

      left.push({
        ...points[idx],
        type
      });
      right.push(predicate(point) ? null : points[idx]);
    } else {
      left.push(null);
      right.push(points[idx]);
    }
  });

  return [correctNaN(left), correctNaN(right)];
};

/**
 * Make a path of orphan points
 * @param {Coordinates} points
 * @return {Array<[string, Nullable<number>, Nullable<number>]>}
 */
export const makeOrphanLine = (points: Coordinates): Array<[string, Nullable<number>, Nullable<number>]> => {
  const path: Array<[string, Nullable<number>, Nullable<number>]> = [];

  _.forEach(points, (point, idx, collection) => {
    const current = _.defaultTo(point, {}) as G2.Types.Point;

    if (current.x && current.y) {
      path.push(["M", current.x - 5, current.y]);
      path.push(["L", current.x + 5, current.y]);
    }
  });

  return path;
};

type Path = Array<[string, Nullable<number>, Nullable<number>]>;

/**
 * Make a path of connecting points
 * @param {Coordinates} points
 * @param {(prev: Point, current: Point) => ("L" | "M")} getAction
 * @return {Array<[string, Nullable<number>, Nullable<number>]>}
 */
export const makeLine = (
  points: Coordinates,
  getAction: (prev: G2.Types.Point, current: G2.Types.Point, collection: Coordinates) => "L" | "M" = (prev) =>
    prev.x && prev.y ? "L" : "M"
): Path => {
  const path: Path = [];

  _.forEach(points, (point, idx, collection) => {
    const current = _.defaultTo(point, {}) as G2.Types.Point;
    const prev = _.defaultTo(collection[idx - 1], {}) as G2.Types.Point;

    if (current.x && current.y) {
      const action = getAction(prev, current, collection);

      /**
       * Push the line section to the path
       */
      path.push([action, current.x, current.y]);
    }
  });

  return path;
};

/**
 * Simplify path by dropping the middle points in the sections
 * @param {Coordinates} points
 * @return {Coordinates}
 */
export const simplify = (points: Coordinates): Coordinates =>
  _.reduce(
    points,
    (acc, point, idx, collection) => {
      const { type } = _.defaultTo(point, {});
      const prev = collection[idx - 1];
      const next = collection[idx + 1];
      const midPoint = type ? type === AnomalyPoint.mid : !!prev && !!next;

      if (!midPoint) {
        if (point && next && !idx) {
          acc.push({
            ...point,
            y: _.get(next, ["y"], _.get(point, ["y"]))
          });
        } else if (point && prev && idx === collection.length - 1) {
          acc.push({
            ...point,
            y: _.get(prev, ["y"], _.get(point, ["y"]))
          });
        } else {
          if (type === AnomalyPoint.join) {
            acc.push(point);
          }

          acc.push(point);
        }
      }

      return acc;
    },
    [] as Coordinates
  );

type Point = G2.Types.Point & { type: AnomalyPoint };

/**
 * Refill the missing y values with the nearest valid y value
 * @param {Coordinates} points
 * @return {Coordinates}
 */
export const refill = (points: Coordinates): Coordinates =>
  _.map(points, (point, idx, collection) => {
    /**
     * Previous point with valid y value
     * @type {Point | undefined}
     */
    const prev = _.findLast(_.take(collection, idx), (p: Point) => p && !_.isNaN(p.y));

    /**
     * Next point with valid y value
     * @type {Point | undefined}
     */
    const next = _.find(
      _.takeRight(collection, collection.length - idx - 1),
      (p: Point) => p && !_.isNaN(p.y)
    );

    if (point) {
      /**
       * Tell if the y value is NaN
       * @type {boolean}
       */
      const isValid = !_.isNaN(_.get(point, ["y"]));
      const { type } = point;

      if (!isValid) {
        /**
         * Pick a new y value from the previous or next valid point
         * @type {any}
         */
        const yNew = _.get(
          type === AnomalyPoint.lead ? next : prev,
          ["y"],
          _.get(type === AnomalyPoint.trail ? next : prev, ["y"], 0)
        );

        return {
          ...point,
          y: yNew
        };
      }
    }

    return point;
  });

import { Dispatch, SetStateAction } from "react";

import { ChartTimePeriod, ISODuration, ChartFilters, ChartFilterDefinition, FarmEntity } from "@ctra/api";
import { Nullable } from "@ctra/utils";

import { FilterSetter, ISODurationSetter } from "@chart";

export interface FilterMetadata {
  filterDefinitions: Array<ChartFilterDefinition>;
  supportedISODurations: Array<ISODuration>;
  supportedfarmIDs: Array<FarmEntity["id"]>;
  flags: { isBackendAggregated: boolean };
  offset?: number;
  showFilters?: boolean;
}

export enum DateMethod {
  custom = "custom",
  iso = "iso"
}

export type FilterProviderAPI = {
  /**
   * set current ISO duration value (to be converted to a time period)
   */
  setISODuration: ISODurationSetter;
  /**
   * set a given filter to a given value
   */
  setFilter: FilterSetter;
  /**
   * reset all filters to their default values
   */
  reset: () => void;
  /**
   * set the series on the chart
   */
  setSeries: Dispatch<
    SetStateAction<{
      available: Record<string, string>;
      active: Array<string>;
    }>
  >;
  /**
   * set time period from within custom date range selector
   */
  setTimePeriod: Dispatch<SetStateAction<ChartTimePeriod>>;
  /**
   * set filter metadata
   */
  setMetadata: Dispatch<SetStateAction<FilterMetadata>>;
  /**
   * set the current date range deciding method
   */
  setCurrentDateMethod: Dispatch<SetStateAction<DateMethod>>;
};

export type FilterProviderProps = {
  series?: string[];
  filters?: ChartFilters;
  isoDuration?: Nullable<ISODuration>;
  timePeriod?: ChartTimePeriod;
  durationOptions?: Array<ISODuration>;
  //forced global values
  currentValues?: {
    isoDuration?: Nullable<ISODuration>;
    timePeriod?: ChartTimePeriod;
  };
};

export type ContextType = {
  filters: ChartFilters;
  timePeriod: ChartTimePeriod;
  isoDuration: Nullable<ISODuration>;
  series: {
    available: Record<string, string>;
    active: Array<string>;
  };
  api: FilterProviderAPI;
  metadata: FilterMetadata;
  currentDateMethod?: DateMethod;
};

import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, DeleteOutlined, Popconfirm } from "@ctra/components";
import { EnterpriseAppState, Events } from "@ctra/api";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { isPending } from "@ctra/utils";

import { testIds, useEvent } from "@events";

/**
 * Custom event call to action component
 * @constructor
 */
export const CustomEventCta: FC = () => {
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState<boolean>(false);
  const { t } = useTranslation();

  const {
    events: {
      list: {
        entry: {
          cta: {
            remove: { confirm, yes, no }
          }
        }
      }
    }
  } = Content;

  const {
    event: {
      id,
      source: { type, id: sourceID }
    }
  } = useEvent();

  const dispatch = useDispatch();

  /**
   * Tell if the given event is being deleted
   */
  const isDeleting = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Events.types.DELETE_EVENT, { primaryValue: id })
  );

  return (
    <Popconfirm
      visible={confirmDeleteVisible}
      placement="topRight"
      title={t<string>(confirm)}
      onConfirm={() => {
        dispatch(Events.actions.deleteEvent.start(id, type, sourceID));
      }}
      okText={t<string>(yes)}
      okButtonProps={{ loading: isDeleting }}
      onCancel={() => setConfirmDeleteVisible(false)}
      cancelText={t<string>(no)}
    >
      <Button
        data-testid={testIds.cta.button.delete}
        onClick={() => setConfirmDeleteVisible(true)}
        size="small"
        icon={<DeleteOutlined />}
      />
    </Popconfirm>
  );
};

/* eslint @typescript-eslint/no-explicit-any: 0 */

import { ComponentType, FC } from "react";
import * as _ from "lodash";

import { EventEntity, Enterprise as App, EventScope, EventContext } from "@ctra/api";
import { memoize, TS } from "@ctra/utils";
import { i18nTranslate, Enterprise as Content } from "@ctra/i18n";
import { DownOutlined } from "@ctra/components";

import { GenericEventCta } from "@events";
import moment from "moment";

export enum EventOrigin {
  enterprise = "enterprise",
  classic = "classic"
}

/**
 * Generic Events class to give a structured API on the event entities
 */
class EventBase<C extends EventContext = EventContext> {
  /**
   * The underlying event entity
   */
  protected entity: EventEntity<C>;

  constructor(event: EventEntity<C>) {
    this.entity = event;
  }

  /**
   * Get the metadata for the events
   */
  @memoize
  getContext(): Record<string, unknown> {
    return _.defaultTo(this.entity.context, {});
  }

  /**
   * Get event subCategory
   * @param params {{}}
   */
  @memoize
  getTitle(params?: Record<string, unknown>): string {
    const {
      entity: { title: titleCopy }
    } = Content.events;

    const {
      subCategory,
      title,
      source: { type: sourceType },
      scope: { type: scopeType, ids: scopeIDs }
    } = this.entity;

    let herdGroup = null;

    if ([EventScope.herdGroup, EventScope.pen].includes(scopeType)) {
      const herdGroups = _.pick(_.keyBy(App.entities.getHerdGroups(App.store.getState()), "name"), scopeIDs);
      const pens = _.pick(_.keyBy(App.entities.getPens(App.store.getState()), "name"), scopeIDs);
      const merged = _.merge(herdGroups, pens);

      if (!_.isEmpty(merged)) {
        herdGroup = _.join(_.map(merged, "name"), ", ");
      }
    }

    return i18nTranslate(titleCopy(_.defaultTo(subCategory, "Other")), {
      title: _.defaultTo(title, null),
      scope: [EventScope.herdGroup, EventScope.pen].includes(scopeType) ? EventScope.herdGroup : scopeType,
      herdGroup,
      sourceType,
      ...params
    });
  }

  /**
   * Get event description
   * @param params {{}}
   */
  @memoize
  getDescription(params?: Record<string, unknown>): string {
    const {
      description: originalDescription,
      source: { type }
    } = this.entity;

    const {
      entity: { description }
    } = Content.events;

    return i18nTranslate(description(type), {
      ...params,
      originalDescription: _.trim(originalDescription) || null
    });
  }

  /**
   * Events have different components used for rendering it.
   * @todo override in subclass when necessary
   */
  @memoize
  getComponents(): Record<"View" | "CTA" | "ExpandIcon" | "Page", ComponentType<any> | FC<any>> {
    return {
      View: () => null,
      CTA: GenericEventCta,
      ExpandIcon: DownOutlined,
      Page: () => null
    };
  }

  /**
   * Start moment
   */
  @memoize
  getStartMoment(): moment.Moment {
    return TS.asMoment(this.entity.startAt);
  }

  /**
   * End moment
   */
  @memoize
  getEndMoment(): moment.Moment {
    return TS.asMoment(this.entity.endAt);
  }

  /**
   * Certain entities of a type may not be supported, while some others are
   * @example GenericInsight: not all insight types are registered
   * @todo implement in subclass
   */
  isSupported(): boolean {
    return true;
  }
}

export { EventBase };

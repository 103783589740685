import { FC, ComponentType, PropsWithChildren } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";

import { getDisplayName } from "@ctra/utils";
import { AnnotationsContext } from "../../providers";

/**
 * Wrap component with annotations
 * @param WrappedComponent
 * @returns
 */
export function withAnnotations<P extends PropsWithChildren<Record<string, unknown>>>(
  WrappedComponent: ComponentType<P>
): FC<P> {
  const WithAnnotations: FC<P> = (props: P) => (
    <AnnotationsContext.Provider>
      <WrappedComponent {...props} />
    </AnnotationsContext.Provider>
  );

  WithAnnotations.displayName = `WithAnnotations(${getDisplayName(WrappedComponent)})`;
  return hoistNonReactStatics(WithAnnotations, WrappedComponent);
}

import { FC } from "react";
import * as _ from "lodash";

import { Button, Icon, Barn, AntDesignSelect, AntDesignInput, Grid } from "@ctra/components";
import { Enterprise, useTranslation } from "@ctra/i18n";
import { useGoogleAnalytics } from "@ctra/analytics";
import { FarmEntity } from "@ctra/api";
import { Nullable } from "@ctra/utils";

import { useFarm, useFarmList } from "../../providers";
import { GAActions, GALabels } from "../../analytics";

import styles from "./FarmSelector.module.less";
import { useRouteMatch } from "react-router-dom";
import { Routes } from "@routes";

const { Group } = AntDesignInput;
const { useBreakpoint } = Grid;

/**
 * Farm selector component for navigation bar in header
 * @return {JSX.Element}
 * @constructor
 */
export const FarmSelector: FC = ({ children }) => {
  const { farm, setFarmContext } = useFarm();
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const { farmList, isLoading } = useFarmList();
  const { md, lg } = useBreakpoint();
  const analyticsDashboardContext = useRouteMatch(`${Routes.app.analytics.dashboard.index}/*`);

  return (
    <Group compact className={styles.InputGroup}>
      {lg && (
        <Button
          size={md ? "middle" : "large"}
          className={styles.Icon}
          icon={<Icon component={Barn} />}
          disabled
        />
      )}
      <AntDesignSelect
        showSearch
        className={styles.Select}
        disabled={_.isEmpty(farmList) || isLoading || !!analyticsDashboardContext}
        loading={isLoading}
        value={farm?.id || null}
        showArrow={true}
        size={md ? "middle" : "large"}
        filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
        options={[
          { label: t<string>(Enterprise.navigation.main.allFarms), value: null },
          ..._.map(farmList, ({ name, id }) => ({ label: name, value: id }))
        ]}
        onSelect={(farmID: Nullable<FarmEntity["id"]>) => {
          setFarmContext(farmID);

          if (farmID) {
            const farm = farmList[farmID];

            if (farm) {
              trackEvent(GAActions.selectFarm, { label: _.defaultTo(farm.name, void 0) });
            } else {
              console.error(`Farm with ID ${farmID} not found`);
            }
          } else {
            trackEvent(GAActions.selectFarm, { label: GALabels.allFarms });
          }
        }}
      />
      {children}
    </Group>
  );
};

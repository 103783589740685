import React, { ComponentProps } from "react";
import * as _ from "lodash";
import { Space, Select } from "antd";
import { LabeledValue } from "antd/es/select";

import { DefaultTFuncReturn } from "@ctra/i18n";
import { classname, Nullable } from "@ctra/utils";

import styles from "./MultiDropdown.module.less";

export type MultiDropdownProps<T = string | number> = Omit<
  ComponentProps<typeof Select>,
  "onSelect" | "onChange"
> & {
  /**
   * max number of options to allow, a new selection replaces the oldest one
   */
  limit?: Nullable<number>;
  /**
   * label to display in front of the select field
   */
  label?: string | React.ReactNode;
  /**
   * placeholder
   */
  placeholder?: string | React.ReactNode;
  /**
   * possible values
   */
  values: Array<T>;
  /**
   * selected values
   */
  selection: Array<T>;
  /**
   * Handler to call when the user clicks on the list items
   */
  onChange: (selection: Array<T>) => void;
  /**
   * Get a human readable label from the value
   * @param {string} key
   */
  getLabel?: (key: T) => T | DefaultTFuncReturn;
  /**
   * open state (mainly for unit tests)
   */
  open?: boolean;
  /**
   * filter options by input
   */
  filterOption?: ComponentProps<typeof Select>["filterOption"];
  /**
   * disabled
   */
  disabled?: ComponentProps<typeof Select>["disabled"];
};

/**
 * Multi-select dropdown with simple API
 * @param {number | null | undefined} limit
 * @param {string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} label
 * @param {Array<V>} values
 * @param {Array<V>} selection
 * @param {<V>(selection: Array<V>) => void} onChange
 * @param {(<V>(key: V) => (DefaultTFuncReturn | V)) | undefined} getLabel
 * @param {boolean | undefined} open
 * @param {any} className
 * @param {Pick<(<ValueType=any, OptionType=DefaultOptionType extends BaseOptionType | DefaultOptionType>(props: (SelectProps<ValueType, OptionType> & {children?: React.ReactNode} & {ref?: React.Ref<BaseSelectRef> | undefined})) => React.ReactElement) & {SECRET_COMBOBOX_MODE_DO_NOT_USE: string, Option: typeof Option, OptGroup: typeof OptGroup} extends React.JSXElementConstructor<infer P> ? P : ((<ValueType=any, OptionType=DefaultOptionType extends BaseOptionType | DefaultOptionType>(props: (SelectProps<ValueType, OptionType> & {children?: React.ReactNode} & {ref?: React.Ref<BaseSelectRef> | undefined})) => React.ReactElement) & {SECRET_COMBOBOX_MODE_DO_NOT_USE: string, Option: typeof Option, OptGroup: typeof OptGroup} extends keyof JSX.IntrinsicElements ? JSX.IntrinsicElements[(<ValueType=any, OptionType=DefaultOptionType extends BaseOptionType | DefaultOptionType>(props: (SelectProps<ValueType, OptionType> & {children?: React.ReactNode} & {ref?: React.Ref<BaseSelectRef> | undefined})) => React.ReactElement) & {SECRET_COMBOBOX_MODE_DO_NOT_USE: string, Option: typeof Option, OptGroup: typeof OptGroup}] : {}) & {limit?: Nullable<number>, label?: string | React.ReactNode, placeholder?: string | React.ReactNode, values: Array<V>, selection: Array<V>, onChange: <V>(selection: Array<V>) => void, getLabel?: <V>(key: V) => (DefaultTFuncReturn | V), open?: boolean, filterOption?: React.ComponentProps<typeof Select>["filterOption"], disabled?: React.ComponentProps<typeof Select>["disabled"]} & {children?: React.ReactNode | undefined}, keyof React.PropsWithChildren<MultiDropdownProps<V>> extends ("getLabel" | "selection" | "onChange" | "values" | "limit" | "className" | "label" | "open") ? never : keyof React.PropsWithChildren<MultiDropdownProps<V>>>} rest
 * @return {React.ReactElement}
 * @constructor
 */
function MultiDropdown<V extends string | number>({
  limit,
  label,
  values,
  selection,
  placeholder,
  onChange,
  getLabel = (key) => key,
  open,
  className,
  ...rest
}: React.PropsWithChildren<MultiDropdownProps<V>>): React.ReactElement {
  /**
   * Handle change of selection
   * @param state
   */
  const handleChange = (state: Array<LabeledValue>) => {
    const values = _.map(limit ? _.takeRight(state, limit) : state, "value");

    onChange(values as Array<V>);
  };

  return (
    <Space direction="horizontal">
      {label}
      <Select
        open={open}
        className={classname("ctra-charts-multiDropdown", styles.Select, className)}
        // @ts-ignore
        value={_.map(selection, (value) => ({ value, label: getLabel(value) }))}
        style={{ minWidth: 100 }}
        allowClear
        placeholder={placeholder}
        showArrow
        labelInValue
        maxTagCount={3}
        mode="multiple"
        onChange={handleChange}
        {...rest}
      >
        {_.without(values, ...selection).map((value) => (
          <Select.Option key={value} value={value}>
            {getLabel(value)}
          </Select.Option>
        ))}
      </Select>
    </Space>
  );
}

export default MultiDropdown;

import React, { FC, useState } from "react";
import * as _ from "lodash";
import { SlideDown } from "react-slidedown";

import {
  CtraLayout,
  Col,
  DownOutlined,
  LoadingOutlined,
  PageHeader,
  Row,
  Skeleton,
  Tag,
  Timeline,
  Typography
} from "@ctra/components";

import { Enterprise, useTranslation } from "@ctra/i18n";
import { classname } from "@ctra/utils";
import { ExtendedEventEntity, GroupedEventList } from "@ctra/api";
import { useGoogleAnalytics } from "@ctra/analytics";

import { useFarm } from "@farms";
import { InsightTypeContext } from "@insights";

import {
  EventContext,
  EventEmpty,
  EventEntry,
  EventListContext,
  GAActions,
  testIds,
  CreateEventDialogContext
} from "@events";

import { AddEventButton } from "../AddEventButton";
import styles from "./EventList.module.less";
import "react-slidedown/lib/slidedown.css";

const { Link, Paragraph } = Typography;
const { AsyncLayout } = CtraLayout;
export const DEFAULT_DISPLAY_COUNT = 3;

type EventListProps = {
  /**
   * Render the list with|w/o borders and padding
   */
  compact?: boolean;
  /**
   * Render the list with|w/o a header
   */
  renderHeader?: boolean;
  /**
   * Use a limited amount of events and add a "show more" button
   */
  limit?: number;
  /**
   * Add a highlight to the given event
   */
  highlight?: ExtendedEventEntity["id"];
};

/**
 * Events List
 * @param {boolean | undefined} compact
 * @param {boolean | undefined} renderHeader
 * @param {number | undefined} limit
 * @param {string | undefined} highlight
 * @return {JSX.Element}
 * @constructor
 */
export const EventList: FC<EventListProps> = ({
  compact,
  renderHeader,
  limit = DEFAULT_DISPLAY_COUNT,
  highlight
}) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const { t } = useTranslation();
  const { farm } = useFarm();
  const { trackEvent } = useGoogleAnalytics();

  const {
    events: {
      list: { headers, show, loading }
    },
    layouts: {
      section: { markedNew }
    }
  } = Enterprise;

  return (
    <InsightTypeContext.Provider>
      <InsightTypeContext.Consumer>
        {({ meta: { isLoading: isInsightTypeListLoading } }) => (
          <EventListContext.Provider farmID={farm?.id} grouped={true} limit={showMore ? void 0 : limit}>
            <EventListContext.Consumer<GroupedEventList>>
              {({ events, meta: { count, isLoading: isEventListLoading, hash } }) => {
                /**
                 * Initial fetching should display a spinner and an empty list
                 */
                const isFetching = isInsightTypeListLoading || (_.isEmpty(events) && isEventListLoading);

                /**
                 * Background updating should just display a spinner in the title
                 */
                const isUpdating = !_.isEmpty(events) && isEventListLoading;

                return (
                  <AsyncLayout
                    loading={isFetching}
                    tip={t<string>(loading)}
                    className={classname(compact ? styles.CompactWrapper : null)}
                    data-testid={testIds.list.wrapper}
                  >
                    {renderHeader && (
                      <PageHeader
                        title={
                          <>
                            {t<string>(headers.title)}{" "}
                            <Tag className={styles.Tag} color="#f50">
                              {t<string>(markedNew)}
                            </Tag>
                            {isUpdating && <LoadingOutlined />}
                          </>
                        }
                        extra={farm ? <AddEventButton reference={hash} /> : null}
                      />
                    )}
                    <Skeleton className={styles.Skeleton} loading={isFetching}>
                      {_.isEmpty(events) ? (
                        <EventEmpty />
                      ) : (
                        <Row>
                          <Col span={24} className={styles.List}>
                            <Timeline>
                              <SlideDown>
                                {_.map(events, (group, label) => (
                                  <div key={label}>
                                    <Paragraph className={styles.GroupLabel}>{_.capitalize(label)}</Paragraph>
                                    <div
                                      className={classname(
                                        /**
                                         * If we have less than DEFAULT_DISPLAY_COUNT events, hide the timeline tail
                                         * that connects the show more button
                                         */
                                        count < DEFAULT_DISPLAY_COUNT ? styles.HideLastTail : null
                                      )}
                                    >
                                      {_.map(group, (event) => (
                                        <EventContext.Provider eventID={event.id} key={event.id}>
                                          <EventEntry highlighted={event.id === highlight} />
                                        </EventContext.Provider>
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              </SlideDown>
                              {count > limit && (
                                <Timeline.Item
                                  dot={<DownOutlined rotate={showMore ? 180 : 0} />}
                                  className={styles.ShowMore}
                                >
                                  <Link
                                    onClick={() => {
                                      /* istanbul ignore next */
                                      trackEvent(
                                        showMore ? GAActions.closeFarmTimeline : GAActions.openFarmTimeline
                                      );
                                      setShowMore(!showMore);
                                    }}
                                  >
                                    {t<string>(show, {
                                      variant: !showMore ? "more" : "less"
                                    })}
                                  </Link>
                                </Timeline.Item>
                              )}
                            </Timeline>
                          </Col>
                        </Row>
                      )}
                    </Skeleton>
                  </AsyncLayout>
                );
              }}
            </EventListContext.Consumer>
          </EventListContext.Provider>
        )}
      </InsightTypeContext.Consumer>
    </InsightTypeContext.Provider>
  );
};

import { FC, useEffect, useRef } from "react";
import * as _ from "lodash";
import * as Sentry from "@sentry/react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Alert, Col, Grid, Row } from "@ctra/components";
import { Breadcrumbs, Enterprise as App, EventSourceType, FarmEntity, GroupedEventList } from "@ctra/api";
import { Optional } from "@ctra/utils";
import { Enterprise, useTranslation } from "@ctra/i18n";

import { useFarm } from "@farms";
import { CompiledRoutes, Routes } from "@routes";
import { UserListContext } from "@base";

import { EventContext, EventListContext } from "../../providers";
import { FarmTimeline } from "../FarmTimeline";
import { EventPage } from "../EventPage";
import styles from "./FarmTimelinePage.module.less";

const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * Farm timeline page
 * @return {JSX.Element}
 * @constructor
 */
export const FarmTimelinePage: FC = () => {
  const { farm } = useFarm();
  const farmIdRef = useRef<Optional<FarmEntity["id"]>>(farm?.id);
  const { t } = useTranslation();
  const { md } = Grid.useBreakpoint();

  const {
    impactTracking: { pageHeader, empty, noFarm }
  } = Enterprise;

  useEffect(() => {
    if (farm?.id && farmIdRef.current !== farm.id) {
      farmIdRef.current = farm.id;
      App.history.push(Routes.app.timeline.index);
    }
  }, [farm?.id]);

  return (
    <section className={styles.PageContent}>
      <Breadcrumbs.components.Breadcrumb
        path={Routes.app.timeline.index}
        title={t<string>(Enterprise.navigation.main.timeline)}
      />
      {farm?.id ? (
        <UserListContext.Provider>
          <EventListContext.Provider grouped farmID={farm?.id} exclude={[EventSourceType.aiModel]}>
            {md && (
              <Row className={styles.Headline}>
                <Col>{t(pageHeader)}</Col>
              </Row>
            )}
            <EventListContext.Consumer<GroupedEventList>>
              {({ events, meta: { isLoading } }) => {
                /**
                 * Get the first event of the first group
                 * @type {EventEntity | undefined}
                 */
                const selectedEvent = _.get(_.values(events), [0, 0]);

                /**
                 * Make a list of all event ids
                 * @type {string[]}
                 */
                const eventIdList = _.map(_.flatten(_.values(events)), "id");

                return (
                  <Row gutter={[16, 16]}>
                    <Col md={8} span={24}>
                      <FarmTimeline />
                    </Col>
                    <Switch>
                      <SentryRoute
                        path={Routes.app.timeline.event.index}
                        render={({
                          match: {
                            params: { id }
                          }
                        }) =>
                          eventIdList.includes(id as string) ? (
                            <Col md={16} span={24}>
                              <EventContext.Provider eventID={id as string}>
                                <EventPage />
                              </EventContext.Provider>
                            </Col>
                          ) : (
                            <Redirect to={Routes.app.timeline.index} />
                          )
                        }
                      />
                      <SentryRoute>
                        {_.isEmpty(events) && !isLoading && (
                          <Col md={16} span={24}>
                            <Alert
                              type="info"
                              showIcon
                              message={t(empty.message)}
                              description={t(empty.description)}
                            />
                          </Col>
                        )}
                        {selectedEvent && (
                          <Redirect to={CompiledRoutes.app.timeline.event.index({ id: selectedEvent.id })} />
                        )}
                      </SentryRoute>
                    </Switch>
                  </Row>
                );
              }}
            </EventListContext.Consumer>
          </EventListContext.Provider>
        </UserListContext.Provider>
      ) : (
        <Alert type="info" showIcon message={t(noFarm.message)} description={t(noFarm.description)} />
      )}
    </section>
  );
};

import { ComponentProps, FC } from "react";

import { EventCategory } from "@ctra/api";
import { Nullable } from "@ctra/utils";
import { CompassOutlined, Icon } from "@ctra/components";

import { eventCategoryMap } from "../../utils";

interface EventIconProps extends ComponentProps<typeof Icon> {
  category?: Nullable<EventCategory>;
  withCircle?: boolean;
}

/**
 * Icon wrapper for any event category
 * @constructor
 */
export const EventIcon: FC<EventIconProps> = ({ category, withCircle, ...rest }) => {
  const [iconWithoutCircle, iconWithCircle] = category ? eventCategoryMap[category] : [null, null, ""];
  const icon = withCircle ? iconWithCircle : iconWithoutCircle;

  return icon ? <Icon component={icon} {...rest} /> : <CompassOutlined {...rest} />;
};

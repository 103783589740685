import { createContext, FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import { Enterprise, EnterpriseAppState, GenericInsightEntity, Insights } from "@ctra/api";
import { isPending, Optional } from "@ctra/utils";
import { GenericInsight } from "@insights";
import { GenericInsightBase } from "../../../processing/Base";
import { useLocalization } from "@base";

interface ProviderProps extends Record<string, unknown> {
  genericInsightID: GenericInsightEntity["id"];
  list?: string;
}

/**
 * Base meta which contains the permanent keys
 */
type BaseMeta = {
  isLoading: boolean;
  isUpdating: boolean;
};

/**
 * Base context with optional insight and meta with added keys
 */
type BaseContext<M extends Optional<Record<string, unknown>> = undefined> = {
  insight?: GenericInsightEntity;
  meta: M extends undefined ? BaseMeta : BaseMeta & M;
};

/**
 * A bit more smartness here, we can assume an insight to be present,
 * so there is no need for ! assertions and conditions in the child component
 * where an insight is given, eg. which render with the expectation of
 * and insight entity in the context.
 */
type ContextType<
  /**
   * insight interface to implement
   */
  C extends GenericInsightBase,
  /**
   * props passed to the provider ending up in the context as metadata
   */
  M extends Optional<Record<string, unknown>> = undefined
> = C & { insight: GenericInsightEntity } & BaseContext<M>;

const DefaultContext = createContext<BaseContext>({
  meta: {
    isLoading: true,
    isUpdating: false
  }
});

/**
 * Insight context provider
 * @param children
 * @param genericInsightID
 * @param list
 * @param meta
 * @private
 */
const _InsightContextProvider: FC<ProviderProps> = ({ children, genericInsightID, list, ...meta }) => {
  const dispatch = useDispatch();
  const { unitSystem } = useLocalization();

  /**
   * Try to get the insight from the store
   */
  const insight = useSelector<EnterpriseAppState, GenericInsightEntity>((state) =>
    Enterprise.entities.getInsightsByID(state, { id: genericInsightID })
  );

  /**
   * Tell if the insight is updating
   */
  const isUpdating = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Insights.types.UPDATE_INSIGHT, {
      primaryValue: genericInsightID
    })
  );

  /**
   * Make an API from the insight if present
   */
  const [insightApi, setInsightApi] = useState<Optional<ReturnType<typeof GenericInsight.create>>>(
    insight ? GenericInsight.create(insight) : void 0
  );

  /**
   * Serialize the API
   */
  const [serializedApi, setSerializedApi] = useState<Record<string, unknown>>(
    insightApi ? GenericInsight.serialize(insightApi) : {}
  );

  /**
   * Update when the insight changes
   */
  useEffect(() => {
    if (insight) {
      if (!insightApi || !insightApi.isEqual(insight)) {
        const newApi = GenericInsight.create(insight);

        setInsightApi(newApi);
        setSerializedApi(GenericInsight.serialize(newApi));
      }
    } else {
      setInsightApi(void 0);
      setSerializedApi({});

      /**
       * Make a simple filter: get the insight by ID
       */
      const query = {
        expand: Insights.presets.expandDefaults(),
        filter: {
          GenericInsightID: genericInsightID
        }
      };

      dispatch(Insights.actions.fetchInsights.start(query, list));
    }
    // eslint-disable-next-line
  }, [insight, genericInsightID]);

  /**
   * Update when the unit system changes
   */
  useEffect(() => {
    if (insight) {
      const newApi = GenericInsight.create(insight);

      setInsightApi(newApi);
      setSerializedApi(GenericInsight.serialize(newApi));
    }
    // eslint-disable-next-line
  }, [unitSystem]);

  return (
    <DefaultContext.Provider
      value={{
        insight,
        ...serializedApi,
        meta: {
          ...meta,
          isLoading: _.isEmpty(serializedApi),
          isUpdating
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const InsightContext = {
  Provider: _InsightContextProvider,
  Consumer: DefaultContext.Consumer
};

export const useInsight = <
  C extends GenericInsightBase = GenericInsightBase,
  M extends Optional<Record<string, unknown>> = undefined
>(): M extends undefined ? ContextType<C> : ContextType<C, M> =>
  useContext(DefaultContext) as M extends undefined ? ContextType<C> : ContextType<C, M>;

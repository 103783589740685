import React, { FC } from "react";
import * as _ from "lodash";

import { Row, Typography, Empty, EmptyEventList, AntDesignSelect as Select } from "@ctra/components";
import { FarmEntity } from "@ctra/api";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { useFarm, useFarmList } from "@farms";

import styles from "./EventEmpty.module.less";

const { Paragraph } = Typography;
const { Option } = Select;

/**
 * Empty Events List
 * @constructor
 */
export const EventEmpty: FC = () => {
  const { t } = useTranslation();
  const { farm, setFarmContext } = useFarm();
  const { farmList, isLoading } = useFarmList();

  const {
    events: {
      empty: { title, description, action }
    }
  } = Content;

  const tParams = {
    farm: farm?.name || null
  };

  return (
    <Row justify="center" align="middle" className={styles.Wrapper}>
      <Empty
        image={<EmptyEventList />}
        className={styles.Empty}
        description={
          <>
            <Paragraph strong>{t<string>(title, tParams)}</Paragraph>
            <Paragraph>{t<string>(description, tParams)}</Paragraph>
          </>
        }
      >
        {!farm && (
          <Select
            loading={isLoading}
            placeholder={t<string>(action, tParams)}
            style={{ width: "100%" }}
            onSelect={(farmID: FarmEntity["id"]) => {
              // istanbul ignore next
              setFarmContext(farmID);
            }}
          >
            {_.map(farmList, ({ id, name }) => (
              <Option value={id} key={id}>
                {name}
              </Option>
            ))}
          </Select>
        )}
      </Empty>
    </Row>
  );
};

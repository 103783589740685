import { makeTestIds } from "@ctra/utils";

export const testIds = makeTestIds("charts", {
  chartZoom: {
    wrapper: "wrapper",
    mobile: {
      wrapper: "wrapper"
    }
  },
  chartExtra: {
    compareCharts: "compareCharts",
    zoom: "zoom"
  },
  chartEdit: {
    wrapper: "wrapper",
    mobile: {
      wrapper: "wrapper"
    }
  }
});

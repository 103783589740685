import { FC, ComponentProps } from "react";
import * as _ from "lodash";
import moment from "moment";

import { ISODuration, ChartTimePeriod } from "@ctra/api";
import { Enterprise, useTranslation, defaultLocale } from "@ctra/i18n";
import { Nullable, TS } from "@ctra/utils";
import { GAEvent } from "@ctra/analytics";

import {
  CalendarOutlined,
  Charts,
  Menu,
  AntDesignInput,
  AntDesignDatePicker,
  Space,
  DownOutlined
} from "@ctra/components";

import { useLocalization } from "@base";

import { GACategories } from "@analytics";
import styles from "./ISODurationFilter.module.less";

const { SimpleDropdown } = Charts;

interface ISODurationFilterProps {
  /**
   * available iso durations
   */
  durations: Array<ISODuration>;
  /**
   * selected duration
   */
  duration: Nullable<ISODuration>;
  /**
   * time period for pre-filled date range
   */
  timePeriod: ChartTimePeriod;
  /**
   * handle duration change
   * @param duration
   */
  onDurationChange: (duration: Nullable<ISODuration>) => void;
  /**
   * Handle custom date range changes
   * @param timePeriod
   */
  onTimePeriodChange: (timePeriod: ChartTimePeriod) => void;
  /**
   * disabled state
   */
  disabled?: boolean;
}

const { RangePicker } = AntDesignDatePicker;
const { Group } = AntDesignInput;
const { Item } = Menu;

/**
 * custom string as a constant to be used as key
 */
const custom = "custom";

/**
 * ISO duration switch
 * @param {string | null | undefined} duration
 * @param {Array<ISODuration>} durations
 * @param {{startDate: string, endDate: string} | Record<string, never>} timePeriod
 * @param {(duration: Nullable<ISODuration>) => void} onDurationChange
 * @param {(timePeriod: ChartTimePeriod) => void} onTimePeriodChange
 * @param {boolean | undefined} disabled
 * @returns {JSX.Element}
 * @constructor
 */
const ISODurationFilter: FC<ISODurationFilterProps> = ({
  duration,
  durations,
  timePeriod,
  onDurationChange,
  onTimePeriodChange,
  disabled
}) => {
  const { startDate, endDate } = timePeriod;
  const { t } = useTranslation();
  const { dateFormat } = useLocalization();

  const {
    gamePlan: {
      wizard: {
        form: { placeholder }
      }
    },
    chart: { timePeriod: timeLabel }
  } = Enterprise;

  const handleChange = (key: string | number) => {
    if (key === custom) {
      onDurationChange(null);
    } else {
      onDurationChange(key as ISODuration);
    }
  };

  /**
   * pre-filled date range when the range picker is made visible
   */
  const defaultRange: ComponentProps<typeof RangePicker>["defaultValue"] = [
    TS.asMoment(startDate),
    TS.asMoment(endDate)
  ];

  /**
   * 1) Convert moment array that comes from antd range picker to epochs
   * 2) Send epochs to time period setter
   */
  const handleTimeChange: ComponentProps<typeof RangePicker>["onChange"] = (values) => {
    if (values) {
      const [startDate, endDate] = values;

      const startDateISOString = startDate?.startOf("day").toISOString(true);
      const endDateEOD = endDate?.endOf("day");

      const endDateISOString = (endDateEOD?.isAfter(moment()) ? endDateEOD : endDateEOD)?.toISOString(true);

      onTimePeriodChange({
        startDate: startDateISOString,
        endDate: endDateISOString
      } as unknown as ChartTimePeriod);
    }
  };

  /**
   * extra "custom" item in the date drop down list
   */
  const customItem = <Item key={custom}>{t<string>(timeLabel(custom))}</Item>;

  return (
    <Group compact>
      <SimpleDropdown
        disabled={disabled}
        values={durations}
        currentValue={_.isNull(duration) ? custom : duration}
        onChange={({ key }) => {
          GAEvent(
            GACategories.configurator,
            "Change time period",
            `${t<string>(timeLabel(key), { lng: defaultLocale })}`
          );

          handleChange(key);
        }}
        showLabel={true}
        getLabel={(key, { isToggle }) => (
          <Space align="center">
            {key === duration && !_.isNull(duration) && <CalendarOutlined />}
            {key && t<string>(timeLabel(key))}
            {isToggle && <DownOutlined className={styles.Caret} />}
          </Space>
        )}
        // renderIcon={(key) => (key === duration && !isDatePicker ? <CalendarOutlined /> : null)}
        renderExtra={customItem}
      />
      {_.isNull(duration) && (
        <RangePicker
          disabled={disabled}
          format={dateFormat}
          dropdownClassName={styles.RangePicker}
          allowClear={false}
          placeholder={[t<string>(placeholder.startEpoch), t<string>(placeholder.endEpoch)]}
          value={defaultRange}
          onChange={(values, formatString) => {
            GAEvent(GACategories.configurator, "Change time period", "custom time");

            handleTimeChange(values, formatString);
          }}
        />
      )}
    </Group>
  );
};

export default ISODurationFilter;

import { FC } from "react";
import * as _ from "lodash";

import {
  Typography,
  CtraLayout,
  Row,
  Col,
  useCalendlyAppointment,
  Skeleton,
  Button,
  Alert,
  Grid
} from "@ctra/components";

import { parseLocoTemplate, useTranslation, Enterprise as Content } from "@ctra/i18n";
import { CookieKeys, Cookies, TS } from "@ctra/utils";

import { useCurrentUser } from "@auth";

import { GACategories } from "../../analytics";
import { redirectURL } from "../../../../utils/url";
import { InviteFarmForm } from "../InviteFarmForm/";
import { useAccount } from "../../providers";
import styles from "./NoFarmsWidget.module.less";
import { SandboxFlipSwitch } from "../SandboxFlipSwitch";

const { ContentWrapper } = CtraLayout;
const { Title, Paragraph, Link } = Typography;

const {
  overview: { noFarms }
} = Content;

/**
 * No farms widget
 * @return {JSX.Element}
 * @constructor
 */
export const NoFarmsWidget: FC = () => {
  const { t } = useTranslation();
  const { md } = Grid.useBreakpoint();

  const {
    progress,
    meta: { isLoading: isAccountLoading }
  } = useAccount();

  const {
    event,
    meta: { isLoading: isAppointmentLoading }
  } = useCalendlyAppointment();

  const {
    role,
    meta: { isLoading: isUserLoading },
    user: { firstName }
  } = useCurrentUser();

  const [currentStep] = _.defaultTo(
    _.find(progress, ([, completed]) => !completed),
    ["appointment", true]
  );

  /**
   * Parse farmer text content
   * @type {Array<string>}
   */
  const farmerContent = _.defaultTo(
    parseLocoTemplate(
      t<string>(noFarms.farmer.content, {
        step: currentStep,
        dateTime: event
          ? TS.asMoment(event.start_time, {
              parseZone: true
            })
              .local()
              .format("LLL")
          : null
      }),
      { trim: true, passthrough: true }
    ),
    []
  );

  /**
   * Success content for farmer
   */
  const successContent = event
    ? t<string>(noFarms.farmer.event, { dateTime: TS.asMoment(event.start_time).local().format("LLL") })
    : null;

  /**
   * Layout for farmer role
   * @type {JSX.Element | null}
   */
  const farmerLayout =
    role === "Farmer" ? (
      <Row gutter={24}>
        <Col span={24}>
          <Title level={3}>{t<string>(noFarms.title, { firstName, role })}</Title>
        </Col>
        <Col span={24}>
          {successContent ? (
            <Alert message={successContent} type="success" showIcon style={{ marginBottom: 24 }} />
          ) : null}
          {_.map(farmerContent, (p, idx) => (
            <Paragraph key={idx}>{p}</Paragraph>
          ))}
          {event ? null : (
            <Button
              data-gtm-category={GACategories.gettingStarted}
              data-gtm-action="Complete unfinished signup"
              type="primary"
              onClick={() => {
                Cookies.set(CookieKeys.referrer, "platform");
                window.open(redirectURL, "_self");
              }}
            >
              {t<string>(noFarms.farmer.cta, { step: currentStep })}
            </Button>
          )}
        </Col>
      </Row>
    ) : null;

  /**
   * Parse advisor text content
   * @type {Array<string>}
   */
  const advisorContent = _.defaultTo(
    parseLocoTemplate(t<string>(noFarms.advisor.content), { trim: true, passthrough: true }),
    []
  );

  /**
   * Layout for advisor role
   * @type {JSX.Element | null}
   */
  const advisorLayout = ["Other", "Advisor"].includes(role) ? (
    <Row gutter={24}>
      <Col span={24}>
        <Title level={3}>{t<string>(noFarms.title, { firstName, role })}</Title>
        {_.map(advisorContent, (p, idx) => (
          <Paragraph key={idx}>{p}</Paragraph>
        ))}
        <InviteFarmForm />
        <Paragraph>
          {t<string>(noFarms.advisor.ctaPlainText)}{" "}
          <Link
            data-gtm-category={GACategories.gettingStarted}
            data-gtm-action="Go to Signup app"
            onClick={() => {
              Cookies.set(CookieKeys.referrer, "platform");
            }}
            href={redirectURL}
          >
            {t<string>(noFarms.advisor.ctaHyperlink)}
          </Link>
        </Paragraph>
      </Col>
    </Row>
  ) : null;

  /**
   * Parse explorer text content
   * @type {Array<string>}
   */
  const explorerContent = _.defaultTo(
    parseLocoTemplate(t<string>(noFarms.explorer.content), { trim: true, passthrough: true }),
    []
  );

  /**
   * Layout for explorer role
   * @type {JSX.Element | null}
   */
  const explorerLayout =
    role === "Explorer" ? (
      <Row gutter={24}>
        <Col span={24}>
          <Title level={3}>{t<string>(noFarms.title, { firstName, role })}</Title>
          {_.map(explorerContent, (p, idx) => (
            <Paragraph key={idx}>{p}</Paragraph>
          ))}
          <Paragraph>
            <SandboxFlipSwitch type="primary" />
          </Paragraph>
          <Paragraph>
            {t<string>(noFarms.explorer.ctaPlainText)}{" "}
            <Link
              data-gtm-category={GACategories.gettingStarted}
              data-gtm-action="Go to Signup app"
              onClick={() => {
                Cookies.set(CookieKeys.referrer, "platform");
              }}
              href={redirectURL}
            >
              {t<string>(noFarms.explorer.ctaHyperlink)}
            </Link>
          </Paragraph>
        </Col>
      </Row>
    ) : null;

  return (
    <ContentWrapper padded={false} className={styles.Wrapper}>
      <Row>
        {md && (
          <Col span={8} className={styles.Sider}>
            <span className={styles.HugeText}>{t<string>(noFarms.sider, { role })}</span>
          </Col>
        )}
        <Col span={md ? 16 : 24} className={styles.Content}>
          <Skeleton active loading={isUserLoading || isAppointmentLoading || isAccountLoading}>
            {farmerLayout || advisorLayout || explorerLayout}
          </Skeleton>
        </Col>
      </Row>
    </ContentWrapper>
  );
};

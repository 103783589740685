import { FC, ReactNode } from "react";
import { EventIcon, useEvent } from "@events";

import { Enterprise, EventOrigin, EventStatus } from "@ctra/api";
import { ImpactTracking, ClockCircleTwoTone, CheckCircleTwoTone, Typography, Space } from "@ctra/components";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { useGoogleAnalytics } from "@ctra/analytics";

import { GAActions } from "@events";
import { CompiledRoutes } from "@routes";

const { TimelineItem } = ImpactTracking;
const { Text } = Typography;

/**
 * Farm timeline item component
 * @param {boolean | undefined} highlighted
 * @return {JSX.Element}
 * @constructor
 */
export const FarmTimelineItem: FC<{ highlighted?: boolean }> = ({ highlighted }) => {
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const { getTitle, getDescription, getStartMoment, getContext, event } = useEvent();

  const { status } = getContext();

  const {
    events: {
      entity: { status: statusCopy }
    }
  } = Content;

  let extra: ReactNode = null;

  /**
   * Add the extra based on the status
   * @todo this can be different for any type of event so be prepared for a factory pattern here
   */
  switch (status) {
    case EventStatus.done: {
      extra = (
        <Space>
          <CheckCircleTwoTone twoToneColor="#52c41a" />
          <Text size="sm" type="secondary" style={{ whiteSpace: "nowrap" }}>
            {t<string>(statusCopy(status))}
          </Text>
        </Space>
      );
      break;
    }
    case EventStatus.analysing:
    case EventStatus.processing: {
      extra = (
        <Space>
          <ClockCircleTwoTone twoToneColor="#1890ff" />
          <Text size="sm" type="secondary" style={{ whiteSpace: "nowrap" }}>
            {t<string>(statusCopy(status))}
          </Text>
        </Space>
      );
      break;
    }
  }

  return (
    <TimelineItem
      extra={extra}
      dot={<EventIcon withCircle category={event.category} />}
      highlighted={highlighted}
      title={getTitle()}
      description={getDescription()}
      date={getStartMoment().format("LL")}
      onClick={() => {
        trackEvent(GAActions.openEvent, {
          label: `[${event.id}] ${getTitle()}`
        });

        Enterprise.history.push(
          CompiledRoutes.app.timeline.event.index({
            id: event.id
          })
        );
      }}
      eventOrigin={event.context?.userID ? EventOrigin.manual : EventOrigin.automated}
    />
  );
};

import {
  FC,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useRef,
  useLayoutEffect
} from "react";
import * as _ from "lodash";

import { Optional } from "@ctra/utils";

interface ChartLegendProviderProps {
  initialValues?: Optional<Array<string>>;
}

type ContextType = {
  legendValues: Array<string>;
  initialValues: Array<string>;
  setLegendValues: Dispatch<SetStateAction<string[]>>;
};

/**
 * Default legends Context
 */
const DefaultContext = createContext<ContextType>({
  legendValues: [],
  initialValues: [],
  setLegendValues: _.noop
});

/**
 * Store the currently selected legend values at app level
 * @constructor
 */
export const _ChartLegendValuesProvider: FC<ChartLegendProviderProps> = ({ initialValues, children }) => {
  const [legendValues, setLegendValues] = useState<Array<string>>([]);
  const initialLegendValues = useRef(initialValues || []);

  /**
   * Need legend values on initial render only so the values aren't persisted in the update cycle
   * set it to [] after initial render
   */
  useLayoutEffect(() => {
    if (initialLegendValues.current.length) {
      initialLegendValues.current = [];
    }
  }, [initialValues]);

  return (
    <DefaultContext.Provider
      value={{ legendValues, initialValues: initialLegendValues.current, setLegendValues }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const ChartLegendValuesContext = {
  Provider: _ChartLegendValuesProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook for accessing currently selected legend values for a chart view
 * @returns
 */
export const useLegendValues = (): ContextType => useContext<ContextType>(DefaultContext);

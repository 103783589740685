import { useSelector } from "react-redux";
import * as _ from "lodash";

import { ChartEntity, ChartList, DataDescriptorEntity, Enterprise, EnterpriseAppState } from "@ctra/api";

/**
 * Get a list of available chart views in a data descriptor
 * @param dataDescriptorID
 */
export const useViews = (
  dataDescriptorID: DataDescriptorEntity["id"]
): Record<ChartEntity["view"], ChartEntity["id"]> => {
  /**
   * Resolve data descriptor from data descriptor id
   */
  const descriptor = useSelector<EnterpriseAppState, DataDescriptorEntity>((state) =>
    Enterprise.entities.getDataDescriptor(state, { id: dataDescriptorID })
  );

  const supportedCharts = descriptor?.supportedCharts;

  /**
   * Get the corresponding charts from the store
   */
  const chartEntities = useSelector<EnterpriseAppState, ChartList>((state) =>
    supportedCharts ? (Enterprise.entities.getChart(state, { id: supportedCharts }) as ChartList) : {}
  );

  return _.invert(_.mapValues(chartEntities, "view"));
};

/* eslint @typescript-eslint/no-non-null-assertion: 0 */

import { useRef, useState, useEffect, Key } from "react";
import * as _ from "lodash";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Row, Tabs, TabsProps } from "antd";

import styles from "./DraggableTabs.module.less";

const type = "DraggableTabNode";

interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  index: Key;
  moveNode: (dragIndex: Key, hoverIndex: Key) => void;
}

export interface DraggableTabsProps extends TabsProps {
  /**
   * drag handler
   */
  handleDrag: (newOrder: Array<Key>) => void;
}

const DraggableTabNode = ({ index, children, moveNode }: DraggableTabPaneProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ isOver }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: styles.Dropping
      };
    },
    drop: (item: { index: Key }) => {
      moveNode(item.index, index);
    }
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drop(drag(ref));

  return (
    <Row ref={ref} className={isOver ? styles.Dropping : styles.Tab}>
      {children}
    </Row>
  );
};

/**
 * Draggable tabs component that uses react-dnd
 * Taken as is from antd
 * @param tabProps
 * @returns
 */
const DraggableTabs: React.FC<DraggableTabsProps> = (props) => {
  const { items = [], handleDrag } = props;
  const [order, setOrder] = useState<Key[]>([]);
  /**
   * Reset the order when the items are changed e.g. farm change
   */
  useEffect(() => {
    setOrder([]);
  }, [items]);

  const moveTabNode = (dragKey: Key, hoverKey: Key) => {
    const newOrder = order.slice();

    items.forEach((item) => {
      if (item.key && newOrder.indexOf(item.key) === -1) {
        newOrder.push(item.key);
      }
    });

    const dragIndex = newOrder.indexOf(dragKey);
    const hoverIndex = newOrder.indexOf(hoverKey);

    newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, dragKey);

    handleDrag(newOrder);
    setOrder(newOrder);
  };

  const renderTabBar: TabsProps["renderTabBar"] = (tabBarProps, DefaultTabBar) => (
    <DefaultTabBar {...tabBarProps}>
      {(node) => (
        <DraggableTabNode key={node.key} index={node.key!} moveNode={moveTabNode}>
          {node}
        </DraggableTabNode>
      )}
    </DefaultTabBar>
  );

  const orderItems = _.sortBy(items, (item) => {
    const orderIndex = _.indexOf(order, item.key);
    return orderIndex !== -1 ? orderIndex : items.indexOf(item);
  });

  return (
    <DndProvider backend={HTML5Backend}>
      <Tabs renderTabBar={renderTabBar} {...props} items={orderItems} />
    </DndProvider>
  );
};

export default DraggableTabs;

export enum GACategories {
  acceptInvite = "Accept invite",
  account = "Account",
  forgotPassword = "Forgot password",
  login = "Login",
  resetPassword = "Reset password",
  signup = "Sign up",
  activation = "Activation"
}

export enum GAActions {
  changeAdvisorType = "Change advisor type",
  acceptInvite = "Accept invite",
  changeCompanyName = "Change company name",
  changeEmail = "Change email",
  changeFirstName = "Change first name",
  changeLastName = "Change last name",
  changePassword = "Change password",
  changeConfirmPassword = "Change confirm password",
  changePhoneNumber = "Change phone number",
  createPassword = "Create password",
  confirmPassword = "Confirm password",
  forgotPassword = "Forgot Password",
  login = "Log in",
  signup = "Sign up",
  logout = "Log out",
  resetPassword = "Reset Password",
  requestActivation = "Request Activation"
}

/* eslint-disable react-hooks/exhaustive-deps */
import * as _ from "lodash";
import { useLocation } from "react-router-dom";
import { createContext, FC, useContext, useEffect, useState } from "react";

import { isProduction } from "@ctra/utils";
import { Grid } from "@ctra/components";
import { makeTrackEvent } from "@ctra/analytics";
import { UserEntity } from "@ctra/api";

import { Routes } from "@routes";
import { useCurrentUser } from "@auth";

import { GAActions, GACategories } from "../../../analytics";
import { useLocalization } from "../../../providers";

type IntercomType = (
  action: string,
  options?: string | Record<string, unknown> | (() => void),
  meta?: Record<string, unknown>
) => void;

declare global {
  interface Window {
    Intercom: IntercomType;
  }
}

/**
 * @type {string}
 */
export const intercomLauncherClassName = "ctra-enterprise-intercom";

interface ContextType {
  /**
   * Intercom entity
   */
  entity: IntercomType;
  /**
   * Chat open or not
   */
  isChatOpen: boolean;
  /**
   * Count of unread messages
   */
  unreadCount: number;
}

/**
 * Make a default context for intercom state
 */
const DefaultContext = createContext<ContextType>({
  entity: () => void 0,
  isChatOpen: false,
  unreadCount: 0
});

interface ProviderProps extends Partial<UserEntity> {
  /**
   * default launcher visibilty
   */
  hideLauncher?: boolean;
}

/**
 * Intercom setup
 * @param username
 * @param hideLauncher
 */
export const _IntercomProvider: FC<ProviderProps> = ({ hideLauncher = true, children, ...props }) => {
  const { Intercom } = window;
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const { md } = Grid.useBreakpoint();
  const trackEvent = makeTrackEvent(GACategories.help);
  const { pathname } = useLocation();
  const { locale } = useLocalization();

  const {
    user: { firstName, lastName, email, userGroups }
  } = useCurrentUser();

  const { username } = props;

  /**
   * Launch event handler and cleanup
   */
  useEffect(() => {
    Intercom("boot", {
      app_id: isProduction() ? "pysg6fpw" : "bqmw0vq4",
      user_id: username,
      hide_default_launcher: hideLauncher,
      language_override: _.first(locale.split("-")),
      custom_launcher_selector: `.${intercomLauncherClassName}`,
      user_group: userGroups && userGroups.length ? _.map(userGroups, "name").join(", ") : null,
      user_type: "Enterprise",
      first_name: firstName,
      last_name: lastName,
      name: _.trim(`${firstName} ${lastName}`),
      email
    });

    return function cleanup() {
      Intercom("shutdown");
    };
  }, [Intercom, hideLauncher, username, firstName, lastName, email]);

  /**
   * Other interaction based event handlers
   */
  useEffect(() => {
    Intercom("onShow", () => {
      /* istanbul ignore next */
      trackEvent(GAActions.openChat);
      setIsChatOpen(true);
    });

    Intercom("onHide", () => {
      /* istanbul ignore next */
      trackEvent(GAActions.closeChat);
      setIsChatOpen(false);
    });

    // @ts-ignore - intercom issue, does not recognise the count
    Intercom("onUnreadCountChange", (count) => {
      setUnreadCount(count);
    });
  }, [Intercom]);

  /**
   * Update language
   */
  useEffect(() => {
    Intercom("update", {
      language_override: _.first(locale.split("-"))
    });
  }, [locale]);

  useEffect(() => {
    /**
     * Routes we are interested in tracking
     */
    const routes: Array<string> = [
      Routes.app.insights.index,
      Routes.app.analytics.index,
      Routes.app.analytics.compareCharts.index
    ];

    const map: Record<typeof routes[number], string> = {
      [Routes.app.insights.index]: "Initiate Insights feature",
      [Routes.app.analytics.index]: "Initiate Analytics feature",
      [Routes.app.analytics.compareCharts.index]: "Initiate Compare Charts feature"
    };

    if (!md && _.includes(routes, pathname)) {
      Intercom("trackEvent", map[pathname]);
    }
  }, [md, pathname]);

  return (
    <DefaultContext.Provider
      value={{
        entity: Intercom,
        isChatOpen,
        unreadCount
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const IntercomContext = {
  Provider: _IntercomProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Make a hook to access the context in FCs
 */
export const useIntercom = (): ContextType => useContext(DefaultContext);

import { useEffect, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import _ from "lodash";

import { getMinValue, getMaxValue } from "../utils";

/**
 * useMinMax hook
 * Ensures that the min and max values are not the same.
 *
 * Bar charts appear empty when the min and max values are the same
 * because the minimum value on the axis could be the chart's selected min which becomes same as the max.
 * @param data
 * @param byProperty
 * @returns
 */
export const useMinMax = <T extends Record<string, unknown>>(
  data: Array<T>,
  byProperty: keyof T
): { min: number; max: number } => {
  const [min, setMin] = useState<number>(getMinValue(data, byProperty));
  const [max, setMax] = useState<number>(getMaxValue(data, byProperty));

  useDeepCompareEffect(() => {
    setMin(getMinValue(data, byProperty));
    setMax(getMaxValue(data, byProperty));
  }, [data, byProperty]);

  /**
   * Handle cases when min and max are equal
   * Round them down (min) and up (max) when they are equal
   *
   * I don't think floor and ceil can ever return same value.
   * If they do, this might cause an infinite loop
   */
  useEffect(() => {
    if (_.isEqual(min, max)) {
      if (min === 0) {
        setMin(0);
        setMax(1);
      } else {
        const factor = Math.pow(10, Math.ceil(Math.log10(min))) / 10;

        /**
         * Use the factor to round the min and max values
         * @type {number}
         */
        const minA = Math.floor(min / factor) * factor;
        const maxA = Math.ceil(min / factor) * factor;

        /**
         * Simply add the factor to the min and max values
         * @type {number}
         */
        const minB = min - factor;
        const maxB = max + factor;

        setMin(minA === maxA ? minB : minA);
        setMax(minA === maxA ? maxB : maxA);
      }
    }
  }, [min, max]);

  return { min, max };
};

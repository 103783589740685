import { FC } from "react";

import { Icon } from "@ctra/components";
import { EventOrigin } from "@ctra/api";

import styles from "./EventOriginIcon.module.less";

export interface EventIconProps {
  /**
   * type which defines automated or manual
   */
  type: EventOrigin;
}

/**
 * Event origin icon for showing automated or manual
 * @param {EventOrigin} type
 * @return {JSX.Element}
 * @constructor
 */
const EventIcon: FC<EventIconProps> = ({ type }) => {
  const eventIcon = () => <div>{type === EventOrigin.automated ? "A" : "M"}</div>;

  return <Icon component={eventIcon} className={styles.EventOriginIcon} />;
};

export default EventIcon;

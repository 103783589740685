import React, { FC } from "react";
import { useLocation } from "react-router-dom";

import { Button } from "@ctra/components";
import { Enterprise } from "@ctra/api";

import { useEvent, testIds } from "@events";

/**
 * Generic Event call to action component
 * @constructor
 */
export const GenericEventCta: FC = () => {
  const { pathname, search } = useLocation();
  const { getContext } = useEvent();
  const { urlParams, ctaText } = getContext();

  /**
   * Build the search params and push to the new path
   */
  const handleClick = () => {
    const searchParams = new URLSearchParams(search);

    for (const [key, value] of urlParams as Map<string, string>) {
      searchParams.append(key, value);
    }

    Enterprise.history.push({
      pathname,
      search: searchParams.toString()
    });
  };

  return (
    <Button type="secondary" onClick={handleClick} data-testid={testIds.cta.wrapper}>
      {ctaText}
    </Button>
  );
};

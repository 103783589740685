import { FC } from "react";
import * as _ from "lodash";

import { Row, Col, Typography, Dropdown, InfoCircleOutlined } from "@ctra/components";
import { FarmEntity } from "@ctra/api";
import { Enterprise as Content, parseLocoTemplate, useTranslation } from "@ctra/i18n";
import { useGoogleAnalytics } from "@ctra/analytics";
import { useFarmList } from "@farms";

import { GAActions } from "../../../analytics";

import styles from "./FarmDropdown.module.less";

const { Text } = Typography;

interface DropDownProps {
  /**
   * change handler
   */
  onChange: (id: FarmEntity["id"]) => void;
}

/**
 * Farm selector for charts comparison
 * @param {(value: (((prevState: <FarmEntity["id"]>) => <FarmEntity["id"]>) | <FarmEntity["id"]>)) => void} setFarm
 * @return {JSX.Element}
 * @constructor
 */
export const FarmDropdown: FC<DropDownProps> = ({ onChange }) => {
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const { farmList } = useFarmList();

  const { label } = Content.analytics.compare.dropdowns;
  const [lead, dropdownTrigger, tail] = _.defaultTo(parseLocoTemplate(t<string>(label.farm)), []);

  return (
    <Row gutter={[16, 16]} align="middle" className={styles.FarmSelect}>
      <Col>
        <InfoCircleOutlined className={styles.Icon} />
      </Col>
      <Col>
        <Text>{lead}</Text>
        <Dropdown
          menu={{
            items: _.values(farmList).map(({ id, name }) => ({ key: id, label: name })),
            onClick: ({ key }) => {
              trackEvent(GAActions.selectFarm, {
                label: _.defaultTo(farmList[_.toNumber(key)]?.name, void 0)
              });

              onChange(_.toNumber(key));
            }
          }}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {dropdownTrigger}
          </a>
        </Dropdown>
        <Text>{tail}</Text>
      </Col>
    </Row>
  );
};

import * as _ from "lodash";

import { memoize } from "@ctra/utils";
import { Table as BaseTable } from "@ctra/charts";
import { TableMeta, ChartSeriesType } from "@ctra/api";
import { Enterprise, i18nTranslate } from "@ctra/i18n";

import { XAxisType } from "./xAxis";
import { ChartViewBase } from "./Base";

/**
 * Table view class
 */
class Table extends ChartViewBase<TableMeta> {
  /**
   * Get the chart component
   * @return {ReturnType<typeof ChartViewBase.prototype.getComponent>}
   */
  @memoize
  getComponent(): ReturnType<typeof ChartViewBase.prototype.getComponent> {
    return BaseTable;
  }

  /**
   * Get the metadata
   * @return {ReturnType<typeof ChartViewBase.prototype.getMetadata>}
   */
  @memoize
  getMetadata(): ReturnType<typeof ChartViewBase.prototype.getMetadata> {
    const {
      table: {
        columns: { header }
      }
    } = Enterprise.chart;

    return {
      /**
       * Tables do not use the same set of metas as line, bar etc
       * Hence no need extending ...super.getMetadata()
       */
      ...this.metadata,
      columns: _.map(this.metadata.columns, (column) => ({
        ...column,
        /**
         * Translate legends only if they are translatable
         */
        title: this.entity.flags.isSeriesTranslatable
          ? i18nTranslate(header(column.title as string))
          : column.title
      })),
      keyRows: this.getTranslatedKeyRowsLabels(this.metadata.keyRows, { long: false })
    };
  }

  /**
   * Translate keyRows based on the type
   * @param {Record<string, string>} keyRows
   * @param {{long: boolean}} options
   * @return {Record<string, string>}
   * @private
   */
  private getTranslatedKeyRowsLabels(
    keyRows: Record<string, string>,
    options: { long: boolean }
  ): Record<string, string> {
    const xAxisType = XAxisType.create(this.metadata.xType);

    return _.reduce<Record<string, string>, Record<string, string>>(
      keyRows,
      (acc, row) => {
        acc[row] = xAxisType.getTranslatedLabel(row, { long: options.long });

        return acc;
      },
      {}
    );
  }
}

export { Table };

import { FC, useEffect, createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as _ from "lodash";

import { isFulfilled, isPending } from "@ctra/utils";
import { EnterpriseAppState, AuthState, Auth, Enterprise as App, Session } from "@ctra/api";

interface ContextType {
  api: {
    reset: () => void;
  };
  meta: {
    isLoading: boolean;
  };
}

/**
 * Make a default context for user entities
 */
const DefaultContext = createContext<ContextType>({
  api: {
    reset: _.noop
  },
  meta: { isLoading: true }
});

/**
 * Session context to reload the app if necessary
 * @param children
 * @private
 */
const _SessionProvider: FC = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  /**
   * Get the token data
   */
  const {
    token: { accessToken, refresh }
  } = useSelector<EnterpriseAppState, AuthState>((state) => state.auth);

  /**
   * Tell if the token is being refreshed
   * @type {boolean}
   */
  const isRefreshing = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Auth.types.RENEW_TOKEN)
  );

  /**
   * Tell if the token has been renewed
   * @type {boolean}
   */
  const isTokenRefreshFulfilled = useSelector<EnterpriseAppState, boolean>((state) =>
    isFulfilled(state, Auth.types.RENEW_TOKEN)
  );

  useEffect(() => {
    if (isTokenRefreshFulfilled) {
      App.history.replace(pathname);
      App.persistor.flush();
      dispatch(Session.actions.reset());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenRefreshFulfilled]);

  return (
    <DefaultContext.Provider
      value={{
        api: {
          reset: () => {
            dispatch(Auth.actions.renewToken.start(accessToken, refresh, null));
          }
        },
        meta: { isLoading: isRefreshing }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const SessionContext = {
  Consumer: DefaultContext.Consumer,
  Provider: _SessionProvider
};

/**
 * Hook to expose API
 */
export const useSession = (): ContextType => useContext(DefaultContext);

import { makeTestIds } from "@ctra/utils";

/**
 * Make test ids for react
 */
export const testIds = makeTestIds("base", {
  featureTag: {
    wrapper: "wrapper"
  }
});

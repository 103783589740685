import moment from "moment";
import * as _ from "lodash";

import { ISODuration } from "@ctra/api";
import { Nullable } from "@ctra/utils";

/**
 * Takes iso duration and returns the closest supported interval in days
 * Supported time windows 30, 60, 90, 180, 270, 365, 1825
 * @param interval
 */
export const closestSupportedInterval = (interval: Nullable<ISODuration>): number => {
  const days = _.isNull(interval) ? 0 : moment.duration(interval).asDays();

  if (days <= 30) {
    return 30;
  } else if (days >= 30 && days <= 61) {
    return 60;
  } else if (days > 61 && days <= 91) {
    return 90;
  } else if (days > 91 && days <= 183) {
    return 180;
  } else if (days > 183 && days <= 274) {
    return 270;
  } else if (days > 274 && days <= 365) {
    return 365;
  } else if (days >= 365) {
    return 1825;
  } else {
    return 30;
  }
};

import { FC } from "react";

import { Skeleton, Spin } from "@ctra/components";
import { GenericInsightEntity } from "@ctra/api";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";

import { useDataDictionary } from "@base";
import { testIds, InsightContext, InsightBody, InsightHeader, MarkAsSeen } from "@insights";

interface InsightProps {
  genericInsightID: GenericInsightEntity["id"];
}

/**
 * Entry point for THE insight component
 * @todo make it scalable to work with EVERY POSSIBLE insight
 * @constructor
 */
export const Insight: FC<InsightProps> = ({ genericInsightID }) => {
  const { t } = useTranslation();

  const {
    meta: { isLoading: isDataDictionaryLoading }
  } = useDataDictionary();

  return (
    <InsightContext.Provider genericInsightID={genericInsightID}>
      <InsightContext.Consumer>
        {({ meta: { isLoading: isInsightLoading } }) => {
          const isLoading = isDataDictionaryLoading || isInsightLoading;

          return (
            <Spin
              data-testid={testIds.entity.wrapper}
              spinning={isLoading}
              tip={t<string>(Content.insights.modal.loading)}
            >
              <Skeleton active loading={isLoading}>
                {/* to the pattern of `Redirect`, this marks
                        the insight as seen by the current user */}
                <MarkAsSeen />
                <InsightHeader />
                <InsightBody />
              </Skeleton>
            </Spin>
          );
        }}
      </InsightContext.Consumer>
    </InsightContext.Provider>
  );
};

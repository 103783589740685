import { makeTestIds } from "@ctra/utils";
import { InsightValidation } from "@ctra/api";

/**
 * Make test ids for react
 */
export const testIds = makeTestIds("insights", {
  entity: {
    wrapper: "wrapper",
    details: {
      wrapper: "wrapper",
      mobile: {
        wrapper: "wrapper"
      },
      desktop: {
        wrapper: "wrapper",
        previousBtn: "previousBtn",
        nextBtn: "nextBtn"
      }
    },
    header: {
      farmName: "farmName",
      content: "content"
    },
    resolution: {
      widget: {
        button: "button",
        select: "select",
        addResolution: "addResolution",
        input: "input"
      },
      list: {
        tag: "tag"
      }
    },
    validation: {
      options: {
        [InsightValidation.yes]: "yes",
        [InsightValidation.no]: "no",
        [InsightValidation.maybe]: "maybe",
        [InsightValidation.na]: "na"
      },
      rollback: "rollback"
    }
  },
  list: {
    entry: {
      wrapper: "wrapper",
      resolution: "resolution"
    },
    emptyState: {
      enableInsights: {
        hasSettings: "hasSettings",
        hasNoSettings: "hasNoSettings"
      }
    }
  },
  extensions: {
    bloodScreeningSchedule: {
      wrapper: "wrapper",
      table: "table"
    },
    feedingSchedule: "feedingSchedule",
    milkDropChart: "milkDropChart",
    highRiskCowList: {
      wrapper: "wrapper",
      table: "table"
    }
  }
});

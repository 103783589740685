import { createContext, FC, useContext, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import {
  Enterprise,
  EnterpriseAppState,
  Preferences,
  UserPreferencesEntity,
  UserPreferencesAPI
} from "@ctra/api";

import { isPending, isDispatched, UnitSystem } from "@ctra/utils";

interface ContextType {
  api: {
    setUserPreferences: (preferences: UserPreferencesAPI) => void;
  };
  meta: {
    isLoading: boolean;
    isUpdating: boolean;
    delayed: boolean;
  };
  preferences: UserPreferencesEntity;
}

/**
 * Make default context for user preferences
 * @type {React.Context<ContextType>}
 */
const DefaultContext = createContext<ContextType>({
  api: {
    setUserPreferences: _.noop
  },
  meta: {
    isLoading: false,
    isUpdating: false,
    delayed: false
  },
  preferences: {
    timezone: null,
    locale: "en-US",
    unitSystem: UnitSystem.metric,
    sandbox: {
      isEnabled: false,
      farmId: 0
    }
  }
});

/**
 * User preferences provider
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _UserPreferencesProvider: FC = ({ children }) => {
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const [delayed, setDelayed] = useState(false);
  const dispatch = useDispatch();

  /**
   * Fetch user preferences from the store
   * @type {UserPreferencesEntity}
   */
  const userPreferences = useSelector<EnterpriseAppState, UserPreferencesEntity>(
    (state) =>
      _.merge(
        {
          sandbox: {
            isEnabled: false
          }
        },
        Enterprise.entities.getUserPreferences(state)
      ) as UserPreferencesEntity
  );

  /**
   * Tell if the user is logged in
   */
  const isLoggedIn = useSelector<EnterpriseAppState, boolean>(Enterprise.entities.isLoggedIn);

  /**
   * Tell if the user preferences fetching call has been dispatched
   * @type {boolean}
   */
  const hasDispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, Preferences.types.FETCH_USER_PREFERENCES)
  );

  /**
   * Tell if the value fetching call is in progress
   */
  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Preferences.types.FETCH_USER_PREFERENCES)
  );

  /**
   * Check whether the preference updation is in progress
   */
  const isUpdating = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Preferences.types.SET_USER_PREFERENCES)
  );

  /**
   * Start a timer to tell whether the loading takes a long time
   */
  useEffect(() => {
    const timeout = 1000;

    if (isLoading || isUpdating) {
      timer.current = setTimeout(() => {
        setDelayed(true);
      }, timeout);
    } else {
      if (delayed) {
        setDelayed(false);
      }

      if (timer.current) {
        clearTimeout(timer.current);
      }
    }

    return () => {
      if (delayed) {
        setDelayed(false);
      }

      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isUpdating]);

  /**
   * load user preferences
   */
  useEffect(() => {
    if (isLoggedIn && !hasDispatched) {
      dispatch(Preferences.actions.fetchUserPreferences.start());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, hasDispatched]);

  return (
    <DefaultContext.Provider
      value={{
        api: {
          /**
           * Set user preferences
           * @param {Partial<UserPreferencesEntity>} updates
           */
          setUserPreferences: (updates) => {
            if (isLoggedIn) {
              dispatch(Preferences.actions.setUserPreferences.start(updates));
            }
          }
        },
        meta: {
          delayed,
          isLoading,
          isUpdating
        },
        preferences: userPreferences
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const UserPreferencesContext = {
  Provider: _UserPreferencesProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook to get the user preferences
 * @return {ContextType}
 */
export const useUserPreferences = (): ContextType => useContext(DefaultContext);

import { FC, useState, cloneElement } from "react";
import * as _ from "lodash";

import { Space, Button, Spin, AntDesignSelect as Select, Row, Col, PlusOutlined } from "@ctra/components";
import { useTranslation, Enterprise } from "@ctra/i18n";
import { InsightResolutionEntity } from "@ctra/api";
import { useInsight, testIds, makeCustomInsightResolution, GACategories, GAActions } from "@insights";

import { useInsightResolutions } from "../../providers";
import styles from "./ResolutionWidget.module.less";

export const DEFAULT_NUMBER_OF_RESOLUTIONS = 5;

/**
 * Simple insight resolution widget
 * A user can only select one resolution
 * @constructor
 */
export const ResolutionWidget: FC<{ mayAddNew?: boolean }> = ({ mayAddNew } = { mayAddNew: true }) => {
  const { t } = useTranslation();

  const {
    resolve,
    resolutions: resolutionsSource,
    meta: { isResolving, isFetching }
  } = useInsightResolutions();

  const {
    meta: { isLoading: isInsightLoading },
    insight: { insightType }
  } = useInsight();

  const {
    insightResolutions: { title, customLabel, placeholder, other }
  } = Enterprise;

  /**
   * Take the first ${DEFAULT_NUMBER_OF_RESOLUTIONS} resolutions returned from source
   */
  const displayedResolutions: Array<InsightResolutionEntity> = _.orderBy(
    _.map(
      _.take(_.keys(resolutionsSource), DEFAULT_NUMBER_OF_RESOLUTIONS),
      (item) => resolutionsSource[item]
    ),
    "ordinal"
  );

  /**
   * Format the resolutions from source for use as select options
   */
  const optionsFromSource: Array<{ label: string; value: InsightResolutionEntity["id"] }> = _.map(
    resolutionsSource,
    ({ id, source, title: fallbackTitle }) => {
      const label = source === "preset" ? t<string>(title(id), { insightType }) : fallbackTitle;

      return {
        label,
        value: id
      };
    }
  );

  const [showSelectBox, setShowSelectBox] = useState<boolean>(false);
  const [options, setOptions] = useState(optionsFromSource);

  return (
    <>
      {/**
       * The use of a wrapper div with an id solves the position issues
       * pointed out in ant's select component document page.
       * See getPopupContainer prop in the documentation
       */}
      <div id={"widget"}>
        <Spin spinning={isFetching || isInsightLoading}>
          <Row gutter={[0, 8]} className={styles.Wrapper}>
            <Col span={24}>
              <Space size="small" style={{ marginBottom: 0 }} wrap>
                {_.map(displayedResolutions, (resolution) => {
                  const { id, source, title: fallbackTitle } = resolution;

                  return (
                    <Button
                      data-gtm-category={GACategories.insights}
                      data-gtm-action={GAActions.resolveInsight}
                      data-gtm-label={id}
                      data-testid={testIds.entity.resolution.widget.button}
                      disabled={isResolving}
                      onClick={_.partial(resolve, { [resolution.id]: resolution })}
                      key={id}
                    >
                      {source === "preset" ? t<string>(title(id), { insightType }) : fallbackTitle}
                    </Button>
                  );
                })}
                {mayAddNew && !showSelectBox && (
                  <Button
                    data-testid={testIds.entity.resolution.widget.button}
                    disabled={isResolving}
                    icon={<PlusOutlined />}
                    onClick={_.partial(setShowSelectBox, true)}
                  >
                    {t<string>(other)}
                  </Button>
                )}
              </Space>
            </Col>
            {mayAddNew && (
              <Col span={24}>
                <Row gutter={[0, 8]}>
                  {showSelectBox && (
                    <Col span={24}>
                      <Select
                        data-testid={testIds.entity.resolution.widget.select}
                        mode="tags"
                        size="large"
                        placeholder={t<string>(placeholder)}
                        options={options}
                        optionFilterProp={"label"}
                        value={[]}
                        getPopupContainer={() => document.getElementById("widget") as HTMLElement}
                        onSelect={(value: InsightResolutionEntity["id"]) => {
                          /**
                           * Because we are accepting custom resolutions,
                           * we need to make them on the fly as the user inputs them.
                           * This allows us to support adding/removing of custom resolutions
                           */
                          const resolution = resolutionsSource[value] || makeCustomInsightResolution(value);
                          resolve({ [resolution.id]: resolution });
                        }}
                        onSearch={(searchText) => {
                          /**
                           * Use trim to get rid of empty strings
                           */
                          // istanbul ignore next
                          if (_.trim(searchText)) {
                            const key = _.kebabCase(searchText);

                            const newOption = {
                              label: t<string>(customLabel, { label: searchText }),
                              value: searchText
                            };

                            /**
                             * Add the search text to the options only if it's not
                             * in the existing options
                             */
                            // istanbul ignore next
                            if (!_.includes(_.keys(resolutionsSource), key)) {
                              setOptions([...optionsFromSource, newOption]);
                            }
                          }
                        }}
                        dropdownRender={(menu) => {
                          /**
                           * Clone the menu and remove the placeholder option
                           * from the menu since we are using our own custom text
                           */
                          return cloneElement(menu, {
                            ...menu.props,
                            flattenOptions: _.filter(
                              menu.props.flattenOptions as Array<{
                                data: Record<string, unknown>;
                                key: string;
                                groupOption: boolean;
                              }>,
                              (option) => option.key !== "__RC_SELECT_TAG_PLACEHOLDER__"
                            )
                          });
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </Col>
            )}
          </Row>
        </Spin>
      </div>
    </>
  );
};

import { FC, useEffect, useState } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import _ from "lodash";

import {
  EnterpriseAppState,
  Invitation,
  ReferralEntity,
  ReferralFormValues,
  Breadcrumbs,
  Enterprise as API,
  ReferralType
} from "@ctra/api";

import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { GAEvent, useGoogleAnalytics } from "@ctra/analytics";
import { CtraLayout, message, Row, Col, Alert, Button, Typography } from "@ctra/components";
import { isPending, isFulfilled, useDidMount, isRejected, Nullable } from "@ctra/utils";

import { Routes } from "@routes";
import { useUserPreferences } from "@base";
import { GACategories } from "@network";

import { ReferralForm } from "../ReferralForm";
import { useValidationSchema } from "./hooks";
import styles from "./ReferralPage.module.less";

const { WidgetWrapper, ContentWrapper } = CtraLayout;
const { Title, Paragraph } = Typography;

const {
  components: { Breadcrumb }
} = Breadcrumbs;

const {
  settings: {
    users: {
      title,
      description,
      form: { error, success, submit, cancel }
    }
  }
} = Content;

/**
 * Invite new user or edit existing invitation
 * @return {JSX.Element}
 * @constructor
 */
export const ReferralPage: FC = () => {
  const [mayRedirect, setMayRedirect] = useState<boolean>(false);
  const [isMounting] = useDidMount();
  const validationSchema = useValidationSchema();
  const { trackEvent } = useGoogleAnalytics();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    preferences: { sandbox }
  } = useUserPreferences();

  const {
    params: { invitationId }
  } =
    useRouteMatch<{
      invitationId: NonNullable<ReferralEntity["id"]>;
    }>();

  /**
   * Look up the existing invitation shall there be any and use it as initial values for the form
   * @type {Record<string, unknown>}
   */
  const initialValues = useSelector<EnterpriseAppState, ReferralFormValues>((state) => {
    const { targetUser, sourceUser, ...rest } = _.get(state, ["invitations", "entities", invitationId], {
      targetUser: {}
    } as ReferralEntity);

    return { ...rest, ...targetUser };
  });

  /**
   * Check if the form is currently submitting
   * @type {boolean}
   */
  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    invitationId
      ? isPending(state, Invitation.types.UPDATE_INVITATION, { primaryValue: invitationId })
      : isPending(state, Invitation.types.CREATE_INVITATION)
  );

  /**
   * Check if the form has been submitted successfully
   * @type {boolean}
   */
  const isSuccessful = useSelector<EnterpriseAppState, boolean>((state) =>
    invitationId
      ? isFulfilled(state, Invitation.types.UPDATE_INVITATION, { primaryValue: invitationId })
      : isFulfilled(state, Invitation.types.CREATE_INVITATION)
  );

  /**
   * Check if the form submission has been rejected
   */
  const [isRequestRejected, payload] = useSelector<
    EnterpriseAppState,
    [boolean, Nullable<{ error: string; statusCode: number }>]
  >((state) =>
    invitationId
      ? isRejected(state, Invitation.types.UPDATE_INVITATION, {
          withPayload: true,
          primaryValue: invitationId
        })
      : isRejected(state, Invitation.types.CREATE_INVITATION, {
          withPayload: true
        })
  );

  /**
   * Display a message to the user based on the form submission status
   */
  useEffect(() => {
    if (isRequestRejected) {
      setMayRedirect(false);
    }

    if (isSuccessful) {
      message.success(t<string>(success, { variant: invitationId ? "update" : "create" }));
      setMayRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestRejected, isSuccessful]);

  return mayRedirect && !isMounting && !isLoading ? (
    <Redirect to={Routes.app.network.index} />
  ) : (
    <WidgetWrapper
      className={styles.Wrapper}
      loading={isLoading}
      title={
        <section>
          <Title level={5}>
            {t<string>(Content.settings.users.form.title, {
              variant: invitationId ? "edit" : "new",
              referralType: ReferralType.farmerToUser
            })}
          </Title>
          <Paragraph>{t<string>(description, { referralType: ReferralType.farmerToUser })}</Paragraph>
        </section>
      }
    >
      <Breadcrumb
        path={Routes.app.network.invite.index}
        title={t<string>(Content.settings.users.form.title, {
          variant: invitationId ? "edit" : "new",
          referralType: ReferralType.farmerToUser
        })}
      />
      <Formik<ReferralFormValues & { consent: boolean }>
        initialValues={{
          ...initialValues,
          consent: true
        }}
        onSubmit={({ consent, ...values }, { setSubmitting }) => {
          dispatch(
            values.id
              ? Invitation.actions.updateInvitation.start(values)
              : Invitation.actions.createInvitation.start(values)
          );

          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({ submitForm, values: { id, consent }, isSubmitting }) => {
          return (
            <ContentWrapper
              footer={
                <section>
                  <Row gutter={[16, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        data-gtm-category={GACategories.network}
                        data-gtm-action={`Submit referral form - ${
                          id ? "edit invitation" : "invite new user"
                        }`}
                        disabled={!!sandbox.isEnabled || !consent || isSubmitting}
                        loading={isSubmitting}
                        htmlType="submit"
                        onClick={submitForm}
                      >
                        {t<string>(submit, {
                          variant: id ? "edit" : "create"
                        })}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        data-gtm-category={GACategories.network}
                        data-gtm-action="Cancel creating/editing user referral"
                        onClick={() => API.history.push(Routes.app.network.index)}
                      >
                        {t<string>(cancel)}
                      </Button>
                    </Col>
                  </Row>
                </section>
              }
            >
              {isRequestRejected && (
                <Alert
                  closable
                  type="error"
                  message={t<string>(error, {
                    error: payload?.error,
                    statusCode: payload?.statusCode
                  })}
                />
              )}
              <ReferralForm />
            </ContentWrapper>
          );
        }}
      </Formik>
    </WidgetWrapper>
  );
};

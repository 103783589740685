import { makeTestIds } from "@ctra/utils";

/**
 * Make test ids for farm module components
 */
export const testIds = makeTestIds("farms", {
  details: {
    farmName: "farmName",
    address: "address"
  },
  selector: {
    title: {
      farmName: "farmName",
      allFarms: "allFarms"
    }
  }
});

import React, { FC } from "react";

import { GenericEventView, testIds, useEvent } from "@events";
import { Col, Row, Typography } from "@ctra/components";

const { Text } = Typography;

/**
 * View for GenericInsight-based events
 * @constructor
 */
export const GenericInsightEventView: FC<{ open?: boolean }> = (props) => {
  const { getDescription } = useEvent();

  return (
    <GenericEventView {...props}>
      <Row data-testid={testIds.entity.entry.description}>
        <Col flex={1}>
          <Text italic>{getDescription()}</Text>
        </Col>
      </Row>
    </GenericEventView>
  );
};

import { BarConfig, ColumnConfig, HeatmapConfig, LineConfig } from "@ant-design/charts";

export type LibraryConfigType = Omit<LineConfig | BarConfig | HeatmapConfig | ColumnConfig, "data">;

export enum LegendPosition {
  top = "top",
  bottom = "bottom",
  right = "right",
  left = "left"
}

export type SeriesType = Record<string, string>;

export type { HeatmapConfig };

import { ComponentProps, FC } from "react";
import { List, Row } from "antd";

import styles from "./EventList.module.less";
import { classname } from "@ctra/utils";
import { CloseOutlined } from "../../..";

export interface EventListProps extends ComponentProps<typeof List> {
  /**
   * list title
   */
  title: string;
  /**
   * handle close
   */
  handleClose?: () => void;
}

/**
 * Chart sidebar event list component
 * @param {string} title
 * @param {any} className
 * @param {Pick<EventListProps & {children?: React.ReactNode | undefined}, "children">} rest
 * @return {JSX.Element}
 * @constructor
 */
const EventList: FC<EventListProps> = ({ title, className, handleClose, ...rest }) => (
  <List
    header={
      <Row justify={"space-between"} align={"middle"}>
        {title}
        {handleClose && <CloseOutlined onClick={handleClose} />}
      </Row>
    }
    className={classname(styles.EventList, className)}
    {...rest}
  />
);

export default EventList;

/* eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/explicit-module-boundary-types */
import { FC, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Auth, Request, Session, EnterpriseAppState, AuthState } from "@ctra/api";
import { parseQuery } from "@ctra/utils";

/**
 * Authenticate with token
 * @return {null}
 * @constructor
 */
export const AuthenticateWithToken: FC = () => {
  const dispatch = useDispatch();
  const authState = useSelector<EnterpriseAppState, AuthState>((state) => state.auth);

  const { search } =
    useLocation<{
      provider?: string;
      refreshToken?: string;
    }>();

  const params = new URLSearchParams(search);

  /**
   * Get email, first name and last name from query params
   */
  const { provider, refreshToken } = parseQuery(params, "provider", "refreshToken");

  useEffect(() => {
    const now = moment();

    const { token } = authState;
    const { expires, refreshTokenExpires, accessToken, refresh } = token;

    if (accessToken && moment(expires).isAfter(now)) {
      Request.registerToken(accessToken);
    } else if (provider && refreshToken) {
      dispatch(Auth.actions.renewToken.start(null, refreshToken, provider));
    } else if (moment(expires).isBefore(now) && moment(refreshTokenExpires).isAfter(now)) {
      dispatch(Auth.actions.renewToken.start(accessToken, refresh, null));
    } else {
      dispatch(Session.actions.logout());
    }
  }, []);

  return null;
};

import { useRef, useState } from "react";
import { useDeepCompareEffect } from "use-deep-compare";

type Fn = (...params: any[]) => void;
type Queue = { fn: Fn; meta: Record<string, unknown> }[];
type Intf = { debounce: (callback: Fn, meta: Record<string, unknown>) => void };

/**
 * Debounce hook for any callbacks passed (not just the same function)
 * @param {number} delay
 * @param {boolean} head
 * @returns {Intf}
 */
export const useDebounce = (delay = 500, { head }: { head: boolean } = { head: false }): Intf => {
  const [queue, setQueue] = useState<Queue>([]);
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  useDeepCompareEffect(() => {
    if (queue.length) {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        const { fn } = head ? queue[0] : queue[queue.length - 1];

        fn();
        setQueue([]);
      }, delay);
    }
  }, [queue]);

  return {
    debounce: (callback: Fn, meta: Record<string, unknown> = {}) => {
      setQueue((current) =>
        current.concat({
          fn: callback,
          meta: meta
        })
      );
    }
  };
};

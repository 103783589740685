import { FC, ForwardedRef, memo, MutableRefObject } from "react";
import * as _ from "lodash";
import { compose } from "lodash/fp";
import { Mix as AntMix, MixConfig, Line as AntLine } from "@ant-design/charts";

import { withChartWrapper, withSeriesColor, withAnnotations } from "../../../hocs";
import { LibraryConfigType } from "../../../typings";
import { useLineConfig } from "./useLineConfig";

const isMix = (config: MixConfig | LibraryConfigType): config is MixConfig => _.has(config, "plots");

// const isMutableRef = <T = unknown>(ref: ForwardedRef<T>): ref is MutableRefObject<T> => _.has(ref, "current");

/**
 * Line component
 * @type {React.NamedExoticComponent<object>}
 */
const LineComponent: FC = memo(
  () => {
    const { config } = useLineConfig();

    return isMix(config) ? <AntMix {...config} /> : <AntLine {...config} />;
  },
  (prev, next) => _.isEqual(prev, next)
);

LineComponent.displayName = "Line";

/**
 * Full Line chart
 */
export const Line: FC = compose(withAnnotations, withChartWrapper, withSeriesColor)(LineComponent);

import { FC, ComponentType, PropsWithChildren } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";

import { getDisplayName } from "@ctra/utils";
import { RangeFilterContext } from "../../providers";

/**
 * Wrap component with filtering by range
 * @param {React.ComponentType<P>} WrappedComponent
 * @return {React.FC<P>}
 */
export function withRangeFilter<P extends PropsWithChildren<Record<string, unknown>>>(
  WrappedComponent: ComponentType<P>
): FC<P> {
  const WithRangeFilter: FC<P> = (props: P) => {
    return (
      <RangeFilterContext.Provider>
        <WrappedComponent {...props} />
      </RangeFilterContext.Provider>
    );
  };

  WithRangeFilter.displayName = `WithRangeFilter(${getDisplayName(WrappedComponent)})`;
  return hoistNonReactStatics(WithRangeFilter, WrappedComponent);
}

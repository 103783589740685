export const analytics = {
  _root: "/analytics",
  section: {
    _root: "/:sectionSlug",
    subsection: "/:subsectionSlug"
  },
  compareCharts: {
    _root: "/compare-charts"
  },
  dashboard: {
    _root: "/:dashboard",
    addChart: {
      _root: "/addChart",
      metric: "/:metricID"
    },
    editChart: {
      _root: "/editChart",
      chart: "/:chartID"
    },
    browse: {
      _root: "/browse",
      chart: "/:chartID"
    }
  }
};

import { FC, useState } from "react";
import * as _ from "lodash";

import { Collapse, Row, Col } from "@ctra/components";
import { useGoogleAnalytics } from "@ctra/analytics";
import { useEvent, testIds, GAActions } from "@events";

import styles from "./GenericEventView.module.less";

const { Panel } = Collapse;

/**
 * Generic Event View component
 * @constructor
 */
export const GenericEventView: FC<{ interactive?: boolean; open?: boolean }> = ({
  children,
  open = false,
  interactive = true
}) => {
  const [expanded, setExpanded] = useState<boolean>(open);
  const { trackEvent } = useGoogleAnalytics();

  const {
    event: { id },
    getTitle,
    getStartMoment,
    getEndMoment,
    isSupported,
    getComponents
  } = useEvent();

  const { CTA, ExpandIcon } = getComponents();

  /**
   * Tell if the event falls on the same day
   */
  const sameDayEvent = getStartMoment().isSame(getEndMoment(), "day");

  /**
   * Date format
   */
  const format = "MMM D";

  return (
    <Collapse
      defaultActiveKey={expanded ? [id] : void 0}
      bordered={false}
      className={styles.Collapse}
      data-testid={testIds.entity.entry.view}
      onChange={(key) => {
        /* istanbul ignore next */
        trackEvent(expanded ? GAActions.closeEvent : GAActions.openEvent);
      }}
      expandIcon={({ isActive }) => {
        /**
         * Using setTimeout to queue the setSate call
         * This is necessary to get rid of the warning below
         *
         * Cannot update a component (`EventEntry`) while
         * rendering a different component (`CollapsePanel`)
         */
        setTimeout(() => {
          setExpanded(!!isActive);
        }, 0);

        return (
          <div>
            {/* use expanded.toString() to avoid the non-string html parameter warning */}
            <ExpandIcon rotate={isActive ? 180 : 0} expanded={expanded.toString()} />
          </div>
        );
      }}
      expandIconPosition="end"
    >
      <Panel
        header={_.upperFirst(getTitle())}
        key={id}
        extra={
          <Row align="middle">
            {sameDayEvent ? (
              <Col flex={1}>{getStartMoment().format(format)}</Col>
            ) : (
              <Col flex={1}>
                {getStartMoment().format(format)} - {getEndMoment().format(format)}
              </Col>
            )}
            {interactive && isSupported() && expanded && (
              <Col
                onClick={(evt) => {
                  /* istanbul ignore next */
                  evt.stopPropagation();
                }}
              >
                <CTA />
              </Col>
            )}
          </Row>
        }
      >
        {children}
      </Panel>
    </Collapse>
  );
};

import { createContext, useContext, FC, useMemo, memo } from "react";
import * as _ from "lodash";
import { useDeepCompareMemo } from "use-deep-compare";

import { ExtendedEventEntity, LineData } from "@ctra/api";
import { TS } from "@ctra/utils";

import { useChartContext } from "../ChartContext";

export type ContextType = {
  eventGroups: Record<string, ExtendedEventEntity[]>;
};

const DefaultContext = createContext<ContextType>({
  eventGroups: {}
});

/**
 * Context provider for events
 * @todo rename to event provider
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _AnnotationsProvider: FC = memo(({ children }) => {
  const { events, data } = useChartContext<LineData>();

  /**
   * Group events to the dates which are available on the charts
   * @type {Record<string, Array<ExtendedEventEntity>>}
   */
  const eventGroups = useDeepCompareMemo(() => {
    /**
     * All the dates within the data
     * @type {string[]}
     */
    const dates = _.uniq(_.map(data, "x"));

    /**
     * Make a flat event list as some events will not fall on a chart date anyway
     * @type {ExtendedEventEntity[]}
     */
    const flatEventList = _.flatMap(_.values(events));

    return _.reduce<string, Record<string, Array<ExtendedEventEntity>>>(
      dates,
      (result, date, idx, collection) => {
        /**
         * Range start by converting the date to a Moment
         * @type {moment.Moment}
         */
        const rangeStart = TS.asMoment(date);

        /**
         * Detect if we are on the end of the loop
         * @type {boolean}
         */
        const isLast = !collection[idx + 1];

        /**
         * Make a range end by adding N days to the last date or using the next item in the loop
         * @type {moment.Moment}
         */
        const rangeEnd = isLast
          ? rangeStart.add(Math.abs(TS.asMoment(collection[idx - 1]).diff(rangeStart, "days")), "days")
          : TS.asMoment(collection[idx + 1]);

        /**
         * Collect the events which fall between the range ends
         * @type {ExtendedEventEntity[]}
         */
        const events = _.filter(flatEventList, (event) =>
          TS.asMoment(event.startAt).isBetween(rangeStart, rangeEnd, void 0, "[)")
        );

        if (events.length) {
          result[date] = events;
        }

        return result;
      },
      {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, data]);

  return <DefaultContext.Provider value={{ eventGroups }}>{children}</DefaultContext.Provider>;
});

_AnnotationsProvider.displayName = "AnnotationsProvider";

export const AnnotationsContext = {
  Consumer: DefaultContext.Consumer,
  Provider: _AnnotationsProvider
};

export const useAnnotations = (): ContextType => useContext<ContextType>(DefaultContext);

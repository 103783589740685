import { FC } from "react";
import * as _ from "lodash/fp";
import { Heatmap as AntHeatmap } from "@ant-design/charts";

import { withChartWrapper, withRangeFilter, withSeriesColor } from "../../../hocs";
import { useHeatmapConfig } from "./useHeatmapConfig";

/**
 * Heatmap Component
 * @returns
 */
const HeatmapComponent = () => {
  const { config } = useHeatmapConfig();

  return <AntHeatmap {...config} />;
};

/**
 * Full Heatmap chart
 */
export const Heatmap: FC = _.compose(withChartWrapper, withSeriesColor, withRangeFilter)(HeatmapComponent);

import { FC } from "react";
import * as _ from "lodash";

import { Col, EyeOutlined, EyeInvisibleOutlined, WarningOutlined, Space, Typography } from "@ctra/components";
import { GAEvent } from "@ctra/analytics";

import { ChartType } from "@ctra/api";
import { useChartContext } from "@ctra/charts";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";

import { GACategories } from "@analytics";

const { Text } = Typography;

/**
 * Anomaly view switch
 * @return {JSX.Element}
 * @constructor
 */
export const AnomalyToggle: FC = () => {
  const { t } = useTranslation();

  const {
    meta,
    viewOptions: { showAnomalies },
    api: { toggleAnomalies }
  } = useChartContext();

  const { hasAnomaly, hasMissingData } = _.defaultTo(meta, {});
  const Eye = showAnomalies ? EyeInvisibleOutlined : EyeOutlined;

  const {
    chart: {
      footer: { dataQuality }
    }
  } = Content;

  return (hasAnomaly || hasMissingData) && _.isEqual(meta.type, ChartType.basicLine) ? (
    <Col
      flex={1}
      style={{ cursor: "pointer" }}
      onClick={(evt) => {
        evt.stopPropagation();
        toggleAnomalies(!showAnomalies);

        GAEvent(GACategories.configurator, "Toggle anomalies", JSON.stringify({ visible: showAnomalies }));
      }}
    >
      <Space align="center">
        <Text type="danger">
          <WarningOutlined />
        </Text>
        <Text>
          {t(dataQuality, {
            state: showAnomalies ? "visible" : "hidden",
            reason: _.compact([hasAnomaly ? "anomalies" : null, hasMissingData ? "missingData" : null]).join(
              ","
            )
          })}
        </Text>
        <Eye style={{ color: "#009eb3" }} />
      </Space>
    </Col>
  ) : null;
};

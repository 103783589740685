import { FC, useState, useCallback } from "react";
import * as _ from "lodash";
import { useDeepCompareEffect } from "use-deep-compare";

import { TableData, Table as TableType } from "@ctra/api";
import { Table as AntTable } from "@ctra/components";

import { withChartWrapper } from "../../../hocs";
import { useChartContext } from "../../../providers";

import styles from "./Table.module.less";
import { filterTableBySeries } from "../../../utils";
import moment from "moment/moment";

/**
 * Table component
 * @returns
 */
export const TableComponent: FC = () => {
  const { series, data = [], meta } = useChartContext<TableData>();

  const { columns, xType, keyRows } = _.defaultTo(meta, {
    columns: [],
    keyRows: {}
  });

  /**
   * Update the rows of first column
   * The values are contained in the keyRows
   */
  const updateTableData = useCallback(
    (data: TableType) =>
      _.map(data, (item) => {
        const isXTypeValid = _.isString(xType);
        const xTypeValue = isXTypeValid ? item[xType] : void 0;

        const isDataValid =
          isXTypeValid && (_.isArray(xTypeValue) || _.isString(xTypeValue) || _.isNumber(xTypeValue));

        const overrides = isDataValid
          ? { [xType]: _.get(keyRows, _.isArray(xTypeValue) ? xTypeValue : [xTypeValue]) }
          : {};

        return _.isEmpty(overrides)
          ? item
          : {
              ...item,
              ...overrides
            };
      }),
    [keyRows, xType]
  );

  const [updatedData, setUpdatedData] = useState(updateTableData(data));

  useDeepCompareEffect(() => {
    setUpdatedData(updateTableData(data));
  }, [data]);

  const [filteredColumns, setFilteredColumns] = useState(columns);

  useDeepCompareEffect(() => {
    if (_.isArray(columns)) {
      const [first, ...rest] = columns;
      const { key } = first;

      setFilteredColumns(
        filterTableBySeries(
          [
            {
              ...first,
              ...(key === "date"
                ? {
                    sortOrder: "descend",
                    showSorterTooltip: false,
                    sorter: ({ key: keyA }: { key: string }, { key: keyB }: { key: string }) => {
                      if (moment(keyA).isValid() && moment(keyB).isValid()) {
                        return moment(keyA).isBefore(keyB) ? -1 : 1;
                      } else if (_.isString(keyA) && _.isString(keyB)) {
                        return keyA.localeCompare(keyB);
                      } else {
                        return 0;
                      }
                    }
                  }
                : {})
            },
            ...rest
          ],
          xType,
          series
        )
      );
    }
  }, [columns, xType, series]);

  return (
    <AntTable
      className={styles.Table}
      dataSource={updatedData}
      columns={filteredColumns}
      pagination={false}
      size="small"
    />
  );
};

/**
 * Full Table chart
 */
export const Table: FC = withChartWrapper(TableComponent, {
  wrapper: styles.Wrapper,
  zoomed: styles.Zoom
});

import { Filter, seriesToFilterMap } from "@ctra/api";
import * as _ from "lodash";

/**
 * Align the legends
 */
export const alignLegends = () => {
  const graphWrapperCollection = document.querySelectorAll(
    ".ctra-chart-graphWrapper"
  ) as NodeListOf<HTMLDivElement>;

  const legendWrapperCollection = document.querySelectorAll(
    ".ctra-chart-legendWrapper"
  ) as NodeListOf<HTMLDivElement>;

  /**
   * Get the max of the legend widths
   * @type {unknown}
   */
  const max = _.max(_.map(legendWrapperCollection, (item) => item.offsetWidth));

  /**
   * Apply the max width to all the graphs
   */
  _.forEach(graphWrapperCollection, (item) => {
    item.style.width = `calc(100% - ${max}px)`;
  });

  /**
   * Apply the max width to all the charts
   */
  _.forEach(legendWrapperCollection, (item) => {
    item.style.width = `${max}px`;
  });
};

/**
 * Reset legends
 */
export const resetLegends = () => {
  const graphWrapperCollection = document.querySelectorAll(
    ".ctra-chart-graphWrapper"
  ) as NodeListOf<HTMLDivElement>;

  const legendWrapperCollection = document.querySelectorAll(
    ".ctra-chart-legendWrapper"
  ) as NodeListOf<HTMLDivElement>;

  /**
   * Apply the auto width to all the charts
   */
  _.forEach(graphWrapperCollection, (item) => {
    item.style.width = "auto";
  });

  /**
   * Apply the auto width to all the legends
   */
  _.forEach(legendWrapperCollection, (item) => {
    item.style.width = "auto";
  });
};

/**
 * Change format of filters from type: "", value: "" to [key]: value
 */
export const formatFilters = (dataFilters: Filter[]) =>
  _.reduce(
    dataFilters,
    (acc: Record<string, unknown>, item) => {
      const newType = seriesToFilterMap[item.type];
      acc[newType] = item.values;
      return acc;
    },
    {}
  );

import { createContext, FC, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import { useDeepCompareEffect } from "use-deep-compare";

import { Enterprise, EnterpriseAppState, FarmList, Farms, FarmStatus } from "@ctra/api";
import { isDispatched, isPending } from "@ctra/utils";

interface ContextType {
  farmList: FarmList;
  isLoading: boolean;
}

const DefaultContext = createContext<ContextType>({
  farmList: {},
  isLoading: true
});

/**
 * Provider for farm list
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _FarmListContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  /**
   * Get the farm list from the store
   */
  const farmList = useSelector<EnterpriseAppState, FarmList>((state) => {
    return _.filter(Enterprise.entities.getFarmList(state), ({ status }) => status !== FarmStatus.Created);
  });

  /**
   * Tell whether the action has been dispatched (aka found in async state)
   */
  const dispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, Farms.types.FETCH_FARM_LIST)
  );

  /**
   * Tell if the farm list is being fetched
   * @type {boolean}
   */
  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Farms.types.FETCH_FARM_LIST)
  );

  useEffect(() => {
    if (!dispatched) {
      dispatch(Farms.actions.fetchFarmList.start("enterprise"));
    }
  }, [dispatch, dispatched]);

  useDeepCompareEffect(() => {
    /**
     * Reload farm list after a change to the farm list
     * e.g a farm is added after an invite is accepted
     */
    Enterprise.persistor.flush();
  }, [farmList]);

  return <DefaultContext.Provider value={{ farmList, isLoading }}>{children}</DefaultContext.Provider>;
};

/**
 * @type {{Consumer: React.ExoticComponent<React.ConsumerProps<ContextType>>, Provider: React.FunctionComponent<{}>}}
 */
export const FarmListContext = {
  Provider: _FarmListContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Make a hook for it
 * @return {ContextType}
 */
export const useFarmList: () => ContextType = () => useContext<ContextType>(DefaultContext);

import { ComponentType, FC } from "react";
import * as _ from "lodash";
import Markdown from "react-markdown";

import { Charts } from "@ctra/components";
import { ExtendedEventEntity, EventCategory, EventSourceType } from "@ctra/api";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";

import { EventContext, eventCategoryMap } from "@events";

import styles from "./EventList.module.less";
import { classname } from "@ctra/utils";

const { EventList: Wrapper, EventEntry } = Charts;

type Variant = {
  events: "events";
  insights: "insights";
};

interface EventListProps {
  data: ExtendedEventEntity[];
  handleClose?: () => void;
  variant?: keyof Variant;
}

/**
 * Event list
 * @param {string} date
 * @return {JSX.Element}
 * @constructor
 */
export const EventList: FC<EventListProps> & { variant: Variant } = ({
  variant = "events",
  data,
  handleClose
}) => {
  const { t } = useTranslation();

  const {
    events: { list }
  } = Content;

  /**
   * Get icon
   * @param {EventCategory} category
   * @return {React.ComponentType}
   */
  const getIcon = (category: EventCategory): ComponentType => {
    const [, icon] = eventCategoryMap[category];

    return icon;
  };

  return (
    <Wrapper
      title={t(list.header, { variant })}
      handleClose={handleClose}
      className={styles.List}
      dataSource={data}
      // @ts-ignore
      renderItem={({ id }) => (
        <EventContext.Provider eventID={id} key={id}>
          <EventContext.Consumer>
            {/* @ts-ignore */}
            {({ getTitle, getDescription, getStartMoment, getEndMoment, event: { category, source } }) => (
              <EventEntry
                className={classname(
                  source.type === EventSourceType.aiModel ? styles.AIModel : null,
                  styles.Entry
                )}
                icon={getIcon(category)}
                title={getTitle()}
                description={<Markdown>{_.trim(getDescription())}</Markdown>}
                start={getStartMoment()}
                end={getEndMoment()}
              />
            )}
          </EventContext.Consumer>
        </EventContext.Provider>
      )}
    />
  );
};

EventList.variant = {
  events: "events",
  insights: "insights"
};

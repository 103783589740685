import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import * as _ from "lodash";

import { ChartEntity, DataDescriptorEntity, Enterprise, EnterpriseAppState } from "@ctra/api";
import { Optional } from "@ctra/utils";

/**
 * Provide a chart switching API given a data descriptor id
 * @param {DataDescriptorEntity["id"]} dataDescriptorID
 * @param {<string>} defaultChartID
 * @return {[ChartEntity, React.Dispatch<React.SetStateAction<ChartEntity["id"]>>]}
 */
export const useChart = (
  dataDescriptorID: DataDescriptorEntity["id"],
  defaultChartID?: Optional<string>
): [ChartEntity, Dispatch<SetStateAction<ChartEntity["id"]>>] => {
  /**
   * Resolve data descriptor from data descriptor id
   */
  const dataDescriptor = useSelector<EnterpriseAppState, DataDescriptorEntity>((state) =>
    Enterprise.entities.getDataDescriptor(state, { id: dataDescriptorID })
  );

  if (!dataDescriptor) {
    throw new Error(`Data descriptor "${dataDescriptorID}" could not be located in the store.`);
  }

  const { supportedCharts } = dataDescriptor;
  const defaultMongoChartsID = defaultChartID || _.first(supportedCharts);

  if (!defaultMongoChartsID) {
    throw new Error(`Data descriptor "${dataDescriptorID}" does not have any charts.`);
  }

  /**
   * Prepare a state for chart ID switching.
   * Use the default Mongo chart ID to start with.
   */
  const [currentChartID, setCurrentChartID] = useState(defaultMongoChartsID);

  /**
   * If the data descriptor updates, update the chart ID as well
   */
  useEffect(() => {
    setCurrentChartID(defaultMongoChartsID);
  }, [defaultMongoChartsID]);

  /**
   * Resolve the current chart
   */
  const chart = useSelector<EnterpriseAppState, ChartEntity>(
    (state) => Enterprise.entities.getChart(state, { id: currentChartID }) as ChartEntity
  );

  return [chart, setCurrentChartID];
};

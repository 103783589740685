import * as _ from "lodash";

import { Cookies, CookieKeys } from "@ctra/utils";
import { GA } from "..";

/**
 * Util function for Google Analytics event tracking
 * @param category
 * @param action
 * @param info
 * @returns
 */
export const GAEvent = (category: string, action: string, info?: string) => {
  const cookieConsent = Cookies.get(CookieKeys.cookieConsent);

  return cookieConsent === "optional"
    ? GA.gtag("event", action, {
        category,
        action,
        info
      })
    : _.noop();
};

export enum GACategories {
  insights = "Insights",
  kpiInsights = "KPI insights"
}

export enum GAActions {
  closeInsight = "Close Insight",
  goToEnableInsight = "Go to Enable insight",
  goToSettings = "Go to Settings",
  hoverHelp = "Hover help",
  openInsight = "Open insight",
  selectPage = "Select page",
  resolveInsight = "Resolve Insight"
}

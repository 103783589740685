import { isLocal, isProduction, isBlueGreen } from "@ctra/utils";

/**
 * Redirect URL for the signup app
 * @type {string}
 */
export const redirectURL = isLocal()
  ? "http://localhost:3001"
  : isBlueGreen()
  ? "https://signup.deploy-app.connecterra.ai"
  : `https://signup.${isProduction() ? "" : "staging-"}app.connecterra.ai`;

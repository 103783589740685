import { useState, useEffect } from "react";
import moment from "moment";
import * as _ from "lodash";
import { Datum, HeatmapConfig } from "@ant-design/charts";

import { ChartDataPointXAxisType, HeatmapData } from "@ctra/api";

import { useChartContext, useRangeFilter, useSeriesColor } from "../../../providers";
import { useColorGradient, useCommonConfig } from "../../../hooks";
import { TS, Units } from "@ctra/utils";

/**
 * Hook that merges heatmap config with the common config
 * The common config coming from the main app overrides the preset config
 * @returns
 */
export const useHeatmapConfig = (): { config: HeatmapConfig } => {
  const { commonConfig } = useCommonConfig();
  const { getColor } = useSeriesColor();
  const { data: inputData = [], meta } = useChartContext<HeatmapData>();
  const [data, setData] = useState(inputData);

  const { seriesType, seriesField, axis } = _.defaultTo(meta, {});
  const colorGradient = useColorGradient(getColor(seriesType, seriesField));

  const {
    api: { filterByRange }
  } = useRangeFilter();

  useEffect(() => {
    setData(filterByRange(inputData));
  }, [filterByRange, inputData]);

  /**
   * Heatmap chart config
   * @type {{yAxis: {grid: null, line: {style: {stroke: string, lineWidth: number}}, tickLine: {length: number, style: {stroke: string, lineWidth: number}}}, xAxis: {grid: null, line: {style: {stroke: string, lineWidth: number}}, label: {formatter: (value: string) => string | undefined, rotate: number, offset: number, style: {fontSize: number}}, tickLine: {alignTick: boolean, length: number, style: {stroke: string, lineWidth: number}}}, xField: string, color: Array<string>, data: any[], meta: {x: {type: string}}, yField: string, tooltip: {shared: boolean, customContent: (_title, content) => HTMLElement, showTitle: boolean}, colorField: string}}
   */
  /* istanbul ignore next - testing this proved a pain especially the callbacks */
  const config: HeatmapConfig = {
    color: colorGradient,
    colorField: "z",
    data,
    meta: {
      x: {
        type: "cat"
      }
    },
    tooltip: {
      title: (title, { x }) => TS.asMoment(x).format("LL"),
      fields: ["x", "y", "z"],
      shared: false,
      formatter: (datum: Datum) => ({
        name: `${_.padStart(datum.y, 2, "0")}:00`,
        value: Units.format(datum.z, axis?.y.title || "")
      })
    },
    xAxis: {
      grid: null,
      label: {
        autoRotate: true,
        formatter: (value: string) => {
          const [key] = _.isString(value) ? value.split("T") : [value];
          const isoWeekDay = moment(value).second();

          return _.isEqual(meta.xType, ChartDataPointXAxisType.weekDay)
            ? moment().isoWeekday(isoWeekDay).format("dddd")
            : axis?.x.labels?.short[key];
        }
      },
      line: {
        style: {
          lineWidth: 0.5,
          stroke: "#000000"
        }
      },
      tickLine: {
        alignTick: true,
        length: 5,
        style: {
          lineWidth: 1,
          stroke: "#000000"
        }
      }
    },
    yAxis: {
      grid: null,
      line: {
        style: {
          lineWidth: 0.5,
          stroke: "#000000"
        }
      },
      tickLine: {
        length: 5,
        style: {
          lineWidth: 1,
          stroke: "#000000"
        }
      }
    },
    xField: "x",
    yField: "y"
  };

  return { config: _.merge({}, commonConfig, config) };
};

import { FC, createContext, useContext } from "react";
import ReactDOM from "react-dom";

interface Ctx {
  portal: string;
}

/**
 * Make a context for passing on the dom element ID for the extra buttons
 */
export const PortalContext = createContext<Ctx>({
  /**
   * Element id to use for the DOM node to portal to
   */
  portal: "portal"
});

/**
 * Hook for getting portal container
 * @return {ReturnType<typeof useContext>}
 */
export const usePortal = (): Ctx => useContext(PortalContext);

/**
 * Portals any children to the #game-plan-wizard-portal element
 * @param children
 * @constructor
 */
const Portal: FC = ({ children }) => (
  <PortalContext.Consumer>
    {({ portal }) => ReactDOM.createPortal(children, document.getElementById(portal) as Element)}
  </PortalContext.Consumer>
);

export default Portal;

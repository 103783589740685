import { FC, FunctionComponent, HTMLAttributes, SVGProps } from "react";
import * as _ from "lodash";

import { classname } from "@ctra/utils";

import * as Icons from "./assets";
import { Icon } from "../../../../elements";

import styles from "./CategoryIcon.module.less";

export type CategoryIconProps = {
  category: string;
};

/**
 * Returns the appropriate category icon
 * @param category
 * @returns
 */
const CategoryIcon: FC<CategoryIconProps & HTMLAttributes<HTMLDivElement>> = ({
  category,
  className,
  ...rest
}) => {
  /**
   * Get the icon by partial match
   * so similar categories can have similar icons
   * @param obj
   * @param partialKey
   * @returns
   */
  const findKeyByPartialMatch = (
    obj: Record<string, FunctionComponent<SVGProps<SVGSVGElement>>>,
    partialKey: string
  ) => {
    return _.defaultTo(
      _.findKey(obj, (value, key) => _.startsWith(_.upperFirst(partialKey), key)),
      ""
    );
  };

  return (
    <Icon
      className={classname(className, styles.Icon)}
      component={_.get(Icons, findKeyByPartialMatch(Icons, category))}
      {...rest}
    />
  );
};

export default CategoryIcon;

import * as React from "react";
import { FullscreenExitOutlined, FullscreenOutlined } from "@ant-design/icons";

import { Button } from "../../../elements";

export interface FullscreenButtonProps {
  /**
   * exit from full screen
   */
  exit?: boolean;
  /**
   * handle click
   */
  onClick: () => void;
}

/**
 * Full screen toggle
 * @param exit
 * @param onClick
 * @constructor
 */
const FullscreenButton: React.FC<FullscreenButtonProps> = ({ exit, onClick }) => (
  <Button type="text" icon={exit ? <FullscreenExitOutlined /> : <FullscreenOutlined />} onClick={onClick} />
);

export default FullscreenButton;

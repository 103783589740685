import { FC, createContext, useContext, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as _ from "lodash";

import {
  AccountState,
  DHMSDetails,
  EnterpriseAppState,
  FarmDetailsRequestPayload,
  Farms,
  User,
  UserInfoRequestPayload
} from "@ctra/api";

import { isDispatched, isFulfilled, isPending } from "@ctra/utils";
import { useCurrentUser } from "@auth";
import { useCalendlyAppointment } from "@ctra/components";

interface ContextType {
  accountDetails: Partial<UserInfoRequestPayload>;
  farmDetails: Partial<Omit<FarmDetailsRequestPayload, "preferences">>;
  dhms: Partial<DHMSDetails>;
  progress: Array<[string, boolean]>;
  api: {
    fetchFarmDetails: () => void;
    fetchAccountDetails: () => void;
  };
  meta: {
    isLoading: boolean;
  };
}

const DefaultContext = createContext<ContextType>({
  accountDetails: {},
  farmDetails: {},
  dhms: {},
  progress: [],
  api: {
    fetchFarmDetails: _.noop,
    fetchAccountDetails: _.noop
  },
  meta: {
    isLoading: true
  }
});

/**
 * Context provider for account & farm details
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _AccountContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { event } = useCalendlyAppointment();

  const {
    meta: { isSignupCompleted }
  } = useCurrentUser();

  const { dhms, accountDetails, farmDetails } = useSelector<EnterpriseAppState, AccountState>(
    (state) => state.account
  );

  /**
   * Get the farm details fetch status
   * @type {[boolean, boolean, boolean]}
   */
  const farmDetailsFetchStatus = useSelector<EnterpriseAppState, [boolean, boolean, boolean]>((state) => [
    isDispatched(state, Farms.types.FETCH_USER_FARM),
    isPending(state, Farms.types.FETCH_USER_FARM),
    isFulfilled(state, Farms.types.FETCH_USER_FARM)
  ]);

  const [fdFetchDispatched, fdFetchPending, fdFetchFulfilled] = farmDetailsFetchStatus;

  /**
   * Get the account details fetch status
   * @type {[boolean, boolean, boolean]}
   */
  const accountDetailsFetchStatus = useSelector<EnterpriseAppState, [boolean, boolean, boolean]>((state) => [
    isDispatched(state, User.types.FETCH_ACCOUNT_DETAILS),
    isPending(state, User.types.FETCH_ACCOUNT_DETAILS),
    isFulfilled(state, User.types.FETCH_ACCOUNT_DETAILS)
  ]);

  const [adFetchDispatched, adFetchPending, adFetchFulfilled] = accountDetailsFetchStatus;

  /**
   * Fetch the context information
   */
  useEffect(() => {
    if (!fdFetchDispatched) {
      dispatch(Farms.actions.fetchUserFarm.start());
    }

    if (!adFetchDispatched) {
      dispatch(User.actions.fetchAccountDetails.start());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fdFetchDispatched, adFetchDispatched]);

  /**
   * Check list for progress
   * @type {boolean[]}
   */
  const progressCheckList: Array<[string, boolean]> = [
    ["farmDetails", !_.isEmpty(farmDetails)],
    ["dhms", !_.isEmpty(dhms)],
    [
      "accountDetails",
      _.size(_.pickBy(accountDetails, _.negate(_.isEmpty))) >=
        5 /* implicitly check whether all fields are filled in */
    ],
    ["appointment", isSignupCompleted || !!event]
  ];

  return (
    <DefaultContext.Provider
      value={{
        accountDetails,
        farmDetails,
        dhms,
        progress: progressCheckList,
        api: {
          fetchFarmDetails: () => dispatch(Farms.actions.fetchUserFarm.start()),
          fetchAccountDetails: () => dispatch(User.actions.fetchAccountDetails.start())
        },
        meta: {
          isLoading: fdFetchPending || adFetchPending || (!fdFetchDispatched && !adFetchDispatched)
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const AccountContext = {
  Provider: _AccountContextProvider,
  Consumer: DefaultContext.Consumer
};

export const useAccount = (): ContextType => useContext(DefaultContext);

import { FC, ComponentType, PropsWithChildren } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";

import { getDisplayName } from "@ctra/utils";
import { SeriesColorContext } from "../../providers";

/**
 * Wrap component with series coloring
 * @param {React.ComponentType<P>} WrappedComponent
 * @return {React.FC<P>}
 */
export function withSeriesColor<P extends PropsWithChildren<Record<string, unknown>>>(
  WrappedComponent: ComponentType<P>
): FC<P> {
  const WithSeriesColor: FC<P> = (props: P) => {
    return (
      <SeriesColorContext.Provider>
        <WrappedComponent {...props} />
      </SeriesColorContext.Provider>
    );
  };

  WithSeriesColor.displayName = `WithSeriesColor(${getDisplayName(WrappedComponent)})`;
  return hoistNonReactStatics(WithSeriesColor, WrappedComponent);
}

import { FC } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Auth, Enterprise } from "@ctra/api";
import { Modal, CloseCircleOutlined } from "@ctra/components";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";

import styles from "./AcceptInviteModal.module.less";

/**
 * Accept Invite Modal component
 * @constructor
 *
 * @todo Wait for copy from design
 */
export const AcceptInviteModal: FC = () => {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    acceptInvite: { modal }
  } = Content;

  const params = new URLSearchParams(search);

  const opportunityID = params.get("opportunity");
  const sourceFirstName = params.get("sourceFirstName");

  const handleAccept = () => {
    dispatch(Auth.actions.acceptInvite.start(opportunityID as string));

    handleCancel();
  };

  const handleCancel = () => {
    params.delete("opportunity");
    params.delete("sourceFirstName");

    Enterprise.history.push({ pathname, search: params.toString() });
  };

  return opportunityID ? (
    <Modal
      centered
      visible
      wrapClassName={styles.Wrapper}
      closable={true}
      closeIcon={<CloseCircleOutlined />}
      bodyStyle={{ height: "100%" }}
      onCancel={handleCancel}
      onOk={handleAccept}
      okText={t<string>(modal.actions.ok)}
      cancelText={t<string>(modal.actions.cancel)}
      title={t<string>(modal.title, { sourceFirstName })}
    >
      {t<string>(modal.body, { sourceFirstName })}
    </Modal>
  ) : null;
};

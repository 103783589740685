import { FC, useEffect } from "react";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Form, Input, Button, Space, MailOutlined, message } from "@ctra/components";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { EnterpriseAppState, Invitation, ReferralFormValues } from "@ctra/api";
import { isFulfilled, isPending, isRejected } from "@ctra/utils";
import { GACategories } from "@network";

const {
  overview: {
    inviteFarm: { label, submit }
  }
} = Content;

/**
 * Invite farm form
 * @returns {JSX.Element}
 * @constructor
 */
export const InviteFarmForm: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  /**
   * Tell if the invitation has been sent
   * @type {boolean}
   */
  const isInvitationSent = useSelector<EnterpriseAppState, boolean>((state) =>
    isFulfilled(state, Invitation.types.CREATE_INVITATION)
  );

  /**
   * Tell if the invitation has failed
   * @type {boolean}
   */
  const isInvitationFailed = useSelector<EnterpriseAppState, boolean>((state) =>
    isRejected(state, Invitation.types.CREATE_INVITATION)
  );

  /**
   * Tell if the invitation is loading
   * @type {boolean}
   */
  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Invitation.types.CREATE_INVITATION)
  );

  useEffect(() => {
    if (isInvitationSent) {
      message.success(t<string>(Content.overview.inviteFarm.success));
    }

    if (isInvitationFailed) {
      message.error(t<string>(Content.overview.inviteFarm.error));
    }
  }, [isInvitationSent, isInvitationFailed]);

  return (
    <Formik<Pick<ReferralFormValues, "email">>
      initialValues={{
        email: ""
      }}
      onSubmit={({ email }, { setSubmitting, resetForm }) => {
        dispatch(Invitation.actions.requestFarmAccess.start(email));
        resetForm();

        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form layout="vertical">
          <Form.Item required name="email">
            <Space.Compact>
              <Input
                data-gtm-category={GACategories.network}
                data-gtm-action="Email"
                size="large"
                prefix={<MailOutlined />}
                placeholder={t<string>(label)}
                required
                disabled={isSubmitting || isLoading}
                type="text"
                name="email"
              />
              <Button
                data-gtm-category={GACategories.network}
                data-gtm-action="Request farm access"
                size="large"
                loading={isSubmitting || isLoading}
                disabled={isSubmitting || isLoading}
                type="primary"
                htmlType="submit"
              >
                {t<string>(submit)}
              </Button>
            </Space.Compact>
          </Form.Item>
        </Form>
      )}
    </Formik>
  );
};

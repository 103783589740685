/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { FC } from "react";
import * as _ from "lodash";

import { Button, PlusCircleOutlined } from "@ctra/components";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { useGoogleAnalytics } from "@ctra/analytics";
import { GAActions, useCreateEventDialog } from "@events";
import { useFarm } from "@farms";

interface AddEventButtonProps {
  reference: string;
  compact?: boolean;
}

/**
 * Add a new event
 * @param reference
 * @param compact
 * @constructor
 */
export const AddEventButton: FC<AddEventButtonProps> = ({ reference, compact = false }) => {
  const { t } = useTranslation();
  const { trackEvent } = useGoogleAnalytics();
  const { farm } = useFarm();

  const {
    api: { open }
  } = useCreateEventDialog();

  const {
    events: {
      list: {
        headers: { addEvent }
      }
    }
  } = Content;

  return (
    <Button
      type="primary"
      icon={<PlusCircleOutlined />}
      onClick={() => {
        /* istanbul ignore next */
        trackEvent(GAActions.openAddEvent, { label: _.defaultTo(farm?.name, void 0) });
        open(reference, farm?.id);
      }}
    >
      {compact ? null : t<string>(addEvent)}
    </Button>
  );
};

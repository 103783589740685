import { FC, useEffect, createContext, useContext, Key } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import { isDispatched, isPending } from "@ctra/utils";

import {
  Enterprise,
  EnterpriseAppState,
  SavedCards,
  SavedCardList,
  MetricEntity,
  Group,
  Filter,
  V3DisplayFilter,
  SavedCard
} from "@ctra/api";

import { useFarm } from "@farms";

export type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

type ContextType = {
  savedCharts: SavedCardList;
  api: {
    addTab: (groupName: string) => void;
    updateTabOrder: (order: Array<Key>) => void;
    removeTab: (targetKey: TargetKey) => void;
    renameTab: (targetKey: TargetKey, newName: string) => void;
    saveChart: (
      variantID: MetricEntity["id"],
      groupID: Group["id"],
      groupName: Group["groupName"],
      meta: {
        dateRange: Array<string>;
        chartType: string;
        dataFilters: Array<Filter>;
        displayFilters?: Array<V3DisplayFilter>;
      }
    ) => void;
    saveChartChanges: (groupID: Group["id"], card: SavedCard) => void;
    removeChart: (id: SavedCard["id"], groupID: Group["id"]) => void;
    updateChartOrder: (order: Array<Key>, groupID: Group["id"]) => void;
  };
  meta: {
    isLoading: boolean;
    isUpdating: boolean;
    tabsUpdating: boolean;
  };
};

/**
 * Default SavedCharts Context
 */
const DefaultContext = createContext<ContextType>({
  savedCharts: [],
  api: {
    addTab: _.noop,
    removeTab: _.noop,
    renameTab: _.noop,
    updateTabOrder: _.noop,
    saveChart: _.noop,
    saveChartChanges: _.noop,
    removeChart: _.noop,
    updateChartOrder: _.noop
  },
  meta: {
    isLoading: false,
    isUpdating: false,
    tabsUpdating: false
  }
});

/**
 * Fetch saved charts for the given farm or the dashboard
 * @constructor
 */
export const _SavedChartsProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { farm } = useFarm();

  /**
   * Either get saved charts for a farm or all farms i.e. main
   */
  const pageKey = farm?.id ? _.toString(farm.id) : "main";

  /**
   * Attempt to get the saved charts
   */
  const savedCharts = useSelector<EnterpriseAppState, SavedCardList>((state) =>
    Enterprise.entities.getSavedCards(state, { pageKey })
  );

  /**
   * Tell whether the fetching action has been dispatched
   */
  const dispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, SavedCards.types.FETCH_SAVED_CHARTS)
  );

  /**
   * Tell whether the fetching action is pending
   */
  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, SavedCards.types.FETCH_SAVED_CHARTS)
  );

  /**
   * Tell whether chart list is being updated
   */
  const isUpdating = useSelector<EnterpriseAppState, boolean>(
    (state) => isPending(state, SavedCards.types.UPDATE_CHARTS) || isPending(state, SavedCards.types.ADD_CARD)
  );

  /**
   * Tell whether tabs are being updated
   */
  const tabsUpdating = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, SavedCards.types.UPDATE_TABS)
  );

  useEffect(() => {
    if (!dispatched) {
      dispatch(SavedCards.actions.fetchSavedCharts.start());
    }
  }, [dispatch, dispatched]);

  /**
   * Add a new tab
   * @param groupName
   */
  const addTab = (groupName: string) => {
    if (_.isArray(savedCharts)) {
      const updatedCharts = [...savedCharts];
      //@ts-ignore
      updatedCharts.push({ groupName, cards: [] });
      dispatch(SavedCards.actions.addTab.start(pageKey, groupName));
    }
  };

  /**
   * Remove a tab
   * @param {TargetKey} targetKey
   */
  const removeTab = (targetKey: TargetKey) => {
    const updatedCharts = _.filter(savedCharts, ({ id }) => !_.isEqual(id, targetKey));

    dispatch(SavedCards.actions.updateTabs.start(pageKey, updatedCharts));
  };

  /**
   * Change the order of the tabs
   * @param order
   */
  const updateTabOrder = (order: Array<Key>) => {
    const currentOrder = _.map(savedCharts, "id");

    if (!_.isEqual(order, currentOrder)) {
      const sortedTabs = _.sortBy(savedCharts, ({ id }) => _.findIndex(order, (key) => _.isEqual(key, id)));

      dispatch(SavedCards.actions.updateTabs.start(pageKey, sortedTabs));
    }
  };

  /**
   * Change the name of the tab
   * @param {TargetKey} targetKey
   * @param {string} newName
   */
  const renameTab = (targetKey: TargetKey, newName: string) => {
    const updatedTabs = _.map(savedCharts, (group) =>
      group.id === targetKey ? { ...group, groupName: newName } : group
    );

    dispatch(SavedCards.actions.updateTabs.start(pageKey, updatedTabs));
  };

  /**
   * Save a chart to dashboard
   * @param {MetricEntity["id"]} variantID
   * @param {Group["groupID"]} groupID
   * @param {{dateRange: Array<string>, chartType: string, dataFilters: Array<Filter>, displayFilters?: Array<V3DisplayFilter>}} meta
   */
  const saveChart: ContextType["api"]["saveChart"] = (variantID, groupID, groupName, meta) => {
    const newCard = { variantId: variantID, ...meta };

    dispatch(SavedCards.actions.addCard.start(pageKey, groupID, groupName, newCard));
  };

  /**
   * Update existing chart state
   */
  const saveChartChanges: ContextType["api"]["saveChartChanges"] = (id, chartCard) => {
    const updatedCharts = _.cloneDeep(savedCharts);

    const group = _.find(updatedCharts, { id });
    if (group) {
      const chartIndex = _.findIndex(group.cards, { id: chartCard.id });
      if (chartIndex !== -1) {
        group.cards[chartIndex] = chartCard;
      }
    }

    dispatch(SavedCards.actions.updateCharts.start(pageKey, updatedCharts));
  };

  /**
   * Save a chart to dashboard
   * @param {MetricEntity["id"]} variantID
   * @param {Group["groupID"]} groupID
   */
  const removeChart = (id: SavedCard["id"], groupID: Group["id"]) => {
    /**
     * Update the chart cards within the group
     */
    const updatedCards = _.map(savedCharts, (group) =>
      _.isEqual(group.id, groupID)
        ? {
            ...group,
            cards: _.filter(group.cards, (card) => !_.isEqual(card.id, id))
          }
        : group
    );
    dispatch(SavedCards.actions.updateCharts.start(pageKey, updatedCards));
  };

  /**
   * Change the order of the charts
   * @param {Array<React.Key>} order
   * @param {Group["groupID"]} id
   */
  const updateChartOrder = (order: Array<Key>, id: Group["id"]) => {
    const group = _.find(savedCharts, { id });
    const currentOrder = group && _.map(group.cards, "id");
    if (!_.isEqual(order, currentOrder)) {
      const sortedCharts = _.sortBy(group?.cards, ({ id }) =>
        _.findIndex(order, (key) => _.isEqual(key, id))
      );
      const updatedGroup = { ...group, cards: sortedCharts };
      const reordered = _.map(savedCharts, (group) => (_.isEqual(group.id, id) ? updatedGroup : group));

      dispatch(SavedCards.actions.updateCharts.start(pageKey, reordered));
    }
  };

  return (
    <DefaultContext.Provider
      value={{
        savedCharts,
        api: {
          addTab,
          removeTab,
          renameTab,
          updateTabOrder,
          saveChart,
          saveChartChanges,
          removeChart,
          updateChartOrder
        },
        meta: { isLoading, isUpdating, tabsUpdating }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const SavedChartsContext = {
  Provider: _SavedChartsProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook for SavedCharts context
 * @returns
 */
export const useSavedCharts = (): ContextType => useContext<ContextType>(DefaultContext);

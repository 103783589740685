// istanbul ignore file - this is for testing only

import { Enterprise, EnterpriseAppState } from "@ctra/api";
import * as _ from "lodash";

declare global {
  interface Window {
    WebDriver: {
      isFetchingCompleted: () => boolean;
    };
  }
}

type RecursiveObject = {
  [key: string]: string | RecursiveObject;
};

/**
 * Get the flat values of a recursive object
 * @param object
 */
const valuesDeep = (object: RecursiveObject): Array<string> =>
  _.flatMap(_.values(object), (v) => (_.isString(v) ? v : valuesDeep(v)));

/**
 * Tell if the every item in the async state is in a "fulfilled" state
 */
const isFetchingCompleted = (): boolean => {
  const { async: asyncState }: EnterpriseAppState = Enterprise.store.getState();

  console.info("[WEBDRIVER]", "[Async state]", JSON.stringify(asyncState));

  return _.every(valuesDeep(asyncState as RecursiveObject), _.matches("fulfilled"));
};

window.WebDriver = {
  isFetchingCompleted
};

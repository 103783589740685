import { createContext, FC, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import {
  Enterprise,
  EnterpriseAppState,
  GenericInsightEntity,
  InsightValidation,
  InsightValidationEntity,
  Insights,
  InsightWorkflowState
} from "@ctra/api";

import { Epoch, isPending, Optional, TS } from "@ctra/utils";

import { useCurrentUser } from "@auth";

interface ProviderProps {
  genericInsightID: GenericInsightEntity["id"];
}

interface ContextType {
  validate: (value: InsightValidation) => void;
  validation: InsightValidationEntity;
  meta: {
    isLoading: boolean;
  };
}

const DefaultContext = createContext<ContextType>({
  validate: _.noop,
  validation: {
    id: null,
    validation: InsightValidation.na,
    validatedBy: null
  } as InsightValidationEntity,
  meta: {
    isLoading: true
  }
});

/**
 * Insight validation API
 * @param genericInsightID
 * @param children
 * @private
 */
const _InsightValidationContextProvider: FC<ProviderProps> = ({ genericInsightID, children }) => {
  const dispatch = useDispatch();

  const {
    user,
    meta: { isLoading: isUserLoading }
  } = useCurrentUser();

  const defaultValidationEntity: InsightValidationEntity = {
    id: null,
    genericInsightID,
    validation: InsightValidation.na,
    validatedBy: null
  };

  /**
   * Use a ref as it needs to be updated later
   */
  const validationRef = useRef<InsightValidationEntity>(defaultValidationEntity);

  /**
   * Get user validation for the specific insight
   */
  const validationEntity = useSelector<EnterpriseAppState, Optional<InsightValidationEntity>>((state) =>
    Enterprise.entities.getUserValidation(state, { genericInsightID })
  );

  if (validationEntity) {
    validationRef.current = validationEntity;
  }

  /**
   * Tell if a validation is being created
   */
  const isCreating = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Insights.types.CREATE_INSIGHT_VALIDATION)
  );

  /**
   * Tell if a validation is being updated
   */
  const isUpdating = useSelector<EnterpriseAppState, boolean>(
    (state) =>
      !!validationRef.current.id &&
      isPending(state, Insights.types.UPDATE_INSIGHT_VALIDATION, { primaryValue: validationRef.current.id })
  );

  /**
   * Reset the validation ref when the genericInsightID changes
   */
  useEffect(() => {
    validationRef.current = defaultValidationEntity;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericInsightID]);

  useEffect(() => {
    if (!validationRef.current.id && !validationRef.current.validatedBy && user.id) {
      validationRef.current = {
        ...validationRef.current,
        validatedBy: user.id
      };
    }

    if (validationEntity) {
      validationRef.current = validationEntity;
    }
  }, [user, validationRef, validationEntity]);

  /**
   * Validate the insight
   * update the new workflow state of the insight
   * @param {InsightValidation} value
   * @param {InsightWorkflowState} workflowState
   */
  const validate = (value: InsightValidation, workflowState?: InsightWorkflowState): void => {
    const { id, genericInsightID } = validationRef.current;

    if (id) {
      dispatch(
        Insights.actions.updateInsightValidation.start(validationRef.current, {
          validatedBy: user.id,
          validation: value
        })
      );
    } else {
      dispatch(
        Insights.actions.createInsightValidation.start({
          ...validationRef.current,
          validation: value
        })
      );
    }

    /**
     * Update the new workflow state for the insight
     */
    dispatch(
      Insights.actions.updateInsight.start(genericInsightID, {
        workflowState: workflowState || Insights.utils.getWorkflowState(value),
        workflowStateLastModifiedDate: TS.serialize(Epoch.now())
      })
    );
  };

  return (
    <DefaultContext.Provider
      value={{
        validate,
        validation: validationRef.current,
        meta: {
          isLoading: isUserLoading || isCreating || isUpdating
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const InsightValidationContext = {
  Provider: _InsightValidationContextProvider,
  Consumer: DefaultContext.Consumer
};

export const useInsightValidation = (): ContextType => useContext(DefaultContext);

import * as _ from "lodash";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";

import { EventOrigin, GroupedEventList, Enterprise, EventEntity } from "@ctra/api";
import { Col, Collapse, InnerJoin, Row, Space, Table, Typography, ImpactTracking } from "@ctra/components";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";

import { useEventList, EventIcon, useEvent, EventContext } from "@events";
import { CompiledRoutes } from "@routes";

import styles from "./OverlapEvent.module.less";

const { EventOriginIcon } = ImpactTracking;
const { Text, Title, Link } = Typography;
const { Panel } = Collapse;

/**
 * Overlapping event list component
 * @param {string} startTime
 * @param {string} duration
 * @returns {JSX.Element | null}
 * @constructor
 */
export const OverlapEvent: FC<{
  duration: string;
}> = ({ duration }) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const {
    event: { id, startAt }
  } = useEvent();

  const {
    impactTracking: { overlap }
  } = Content;

  /**
   * Get the number of days in given duration
   * @type {number}
   */
  const durationLength = moment.duration(duration).days();
  const durationStart = moment(startAt).subtract(durationLength, "days");
  const durationEnd = moment(startAt).add(durationLength, "days");
  const { events: groupedEvents } = useEventList<GroupedEventList>();

  /**
   * Find the overlapping events
   * @type {ExtendedEventEntity<EventContext>[]}
   */
  const overlappingEvents = _.flatMap(groupedEvents, (eventList) => {
    return _.filter(eventList, (event) => {
      const startMoment = moment(event.startAt);

      return (
        event.id !== id && startMoment.isSameOrAfter(durationStart) && startMoment.isSameOrBefore(durationEnd)
      );
    });
  });

  useEffect(() => {
    if (!overlappingEvents.length) {
      setIsExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  /**
   * Event item component
   * @param {string} id
   * @param {EventCategory} category
   * @returns {JSX.Element}
   * @constructor
   */
  const EventTitle = ({ id, category }: EventEntity): JSX.Element => {
    const { getTitle } = useEvent();

    return (
      <Row justify="start" align="middle" gutter={[8, 0]} wrap={false}>
        <Col className={styles.NoHeight}>
          <EventIcon withCircle className={styles.Icon} category={category} />
        </Col>
        <Col>{getTitle({ id })}</Col>
      </Row>
    );
  };

  /**
   * Column definitions for the table
   * @type {({dataIndex: string, width: string, render: (event: EventEntity) => JSX.Element, key: string} | {width: string, render: (event: EventEntity) => JSX.Element} | {width: string, render: (record: EventEntity) => JSX.Element})[]}
   */
  const columns: ColumnsType<EventEntity> = [
    {
      dataIndex: "title",
      key: "title",
      render: (value, event: EventEntity) => (
        <EventContext.Provider eventID={event.id} key={event.id}>
          <EventTitle {...event} />
        </EventContext.Provider>
      )
    },
    {
      render: (value, event: EventEntity) => (
        <Row align="middle" justify="center" wrap={false} gutter={[8, 0]}>
          <Col className={styles.NoHeight}>
            <EventOriginIcon type={event.context?.userID ? EventOrigin.manual : EventOrigin.automated} />
          </Col>
          <Col>
            <Text type="secondary">{moment(event.startAt).format("MMMM DD")}</Text>
          </Col>
        </Row>
      )
    },
    {
      render: (value, { id }) => (
        <Row justify="end">
          <Col>
            <Link
              onClick={() => {
                Enterprise.history.push(CompiledRoutes.app.timeline.event.index({ id }));
              }}
              className={styles.ShowText}
            >
              {t(overlap.goToEvent)}
            </Link>
          </Col>
        </Row>
      )
    }
  ];

  return overlappingEvents.length ? (
    <Row gutter={[8, 0]}>
      <Col span={24}>
        <Collapse
          expandIcon={() => null}
          onChange={() => setIsExpanded(!isExpanded)}
          className={styles.Collapse}
          ghost
        >
          <Panel
            className={styles.Panel}
            header={
              <Row align="middle" justify="space-between">
                <Col className={styles.NoHeight}>
                  <Space align="center">
                    <InnerJoin />
                    <Text className={styles.Small}>{t<string>(overlap.header)}</Text>
                  </Space>
                </Col>
                <Row align="middle">
                  <Col>
                    <Text underline className={styles.Small}>
                      {isExpanded ? t<string>(overlap.hide) : t<string>(overlap.show)}
                    </Text>
                  </Col>
                </Row>
              </Row>
            }
            key="1"
          >
            <div>
              <Table
                pagination={{ pageSize: 5, hideOnSinglePage: true }}
                expandable={{ showExpandColumn: false }}
                dataSource={overlappingEvents}
                columns={columns}
                showHeader={false}
                className={styles.Table}
              />
            </div>
          </Panel>
        </Collapse>
      </Col>
    </Row>
  ) : null;
};

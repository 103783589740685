import { FC } from "react";
import { Route, Switch } from "react-router-dom";

import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { GAContext } from "@ctra/analytics";
import { Breadcrumbs } from "@ctra/api";

import { DataDictionaryContext } from "@base";
import { Routes } from "@routes";
import { useFarm } from "@farms";

import { GACategories, SectionVariant } from "@analytics";

import { BrowsePage, MetricSelectorPage } from "..";

import styles from "./AnalyticsPage.module.less";
import { DashboardPage } from "../DashboardPage";
import { AddChartPage } from "../AddChartPage";

const {
  components: { Breadcrumb }
} = Breadcrumbs;
/**
 * Analytics route page
 * @constructor
 */
const AnalyticsPage: FC = () => {
  const { t } = useTranslation();
  const { farm } = useFarm();

  const {
    navigation: {
      main: { analytics }
    }
  } = Content;

  return (
    <DataDictionaryContext.Provider>
      <Breadcrumb
        path={Routes.app.analytics.index}
        title={t<string>(analytics, {
          variant: farm ? SectionVariant.farm : SectionVariant.overview
        })}
      />
      <section className={styles.PageContent}>
        <Switch>
          <Route exact path={Routes.app.analytics.dashboard.addChart.index}>
            <GAContext.Provider value={{ category: GACategories.configurator }}>
              <MetricSelectorPage />
            </GAContext.Provider>
          </Route>
          <Route exact path={Routes.app.analytics.dashboard.addChart.metric}>
            <GAContext.Provider value={{ category: GACategories.configurator }}>
              <AddChartPage />
            </GAContext.Provider>
          </Route>
          <Route exact path={Routes.app.analytics.dashboard.browse.chart}>
            <GAContext.Provider value={{ category: GACategories.configurator }}>
              <BrowsePage />
            </GAContext.Provider>
          </Route>
          <Route path={[Routes.app.analytics.dashboard.index, Routes.app.analytics.index]}>
            <GAContext.Provider value={{ category: GACategories.dashboards }}>
              <DashboardPage />
            </GAContext.Provider>
          </Route>
        </Switch>
      </section>
    </DataDictionaryContext.Provider>
  );
};

export default AnalyticsPage;

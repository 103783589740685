import { useEffect, useMemo, useState, Dispatch, SetStateAction } from "react";
import * as _ from "lodash";

import { Optional } from "@ctra/utils";
import { getStorageKey } from "../../../../utils/versioning";

export const featureTagKey = `${getStorageKey({ persist: true })}.feature`;

export type FeatureTagEntry = {
  seen: boolean;
};

/**
 * Uses a localKey to store a tag to localStorage
 * @param localKey
 * @returns
 */
export const useTagStore = (
  localKey: string
): {
  tag: Optional<FeatureTagEntry>;
  setTag: Dispatch<SetStateAction<Optional<FeatureTagEntry>>>;
  storeTag: (tag: FeatureTagEntry) => void;
} => {
  const localKeySlug = _.kebabCase(localKey);

  const featuresTags: Record<string, FeatureTagEntry> = useMemo(
    () => JSON.parse(localStorage.getItem(featureTagKey) as string) || {},
    []
  );
  const [tag, setTag] = useState<Optional<FeatureTagEntry>>(featuresTags[localKeySlug]);

  const storeTag = (tag: FeatureTagEntry): void => {
    localStorage.setItem(featureTagKey, JSON.stringify({ ...featuresTags, [localKeySlug]: tag }));
  };

  useEffect(() => {
    if (!featuresTags.hasOwnProperty(localKeySlug)) {
      const tag: FeatureTagEntry = { seen: false };
      setTag(tag);
      storeTag(tag);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featuresTags, localKeySlug]);

  return {
    tag,
    setTag,
    storeTag
  };
};

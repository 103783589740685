import * as React from "react";
import Icon from "@ant-design/icons";

import { ReactComponent as Area } from "./svg/area.svg";
import { ReactComponent as StackedArea } from "./svg/area-stacked.svg";
import { ReactComponent as StackedArea100 } from "./svg/area-stacked-100.svg";

import { ReactComponent as Bar } from "./svg/bar.svg";
import { ReactComponent as HorizontalBar } from "./svg/bar-horizontal.svg";
import { ReactComponent as StackedBar } from "./svg/bar-stacked.svg";
import { ReactComponent as StackedBar100 } from "./svg/bar-stacked-100.svg";

import { ReactComponent as StackedColumn } from "./svg/column-stacked.svg";
import { ReactComponent as StackedColumn100 } from "./svg/column-stacked-100.svg";

import { ReactComponent as Donut } from "./svg/donut.svg";
import { ReactComponent as GeospatialScatter } from "./svg/geospatial-scatter.svg";
import { ReactComponent as Heatmap } from "./svg/heatmap.svg";
import { ReactComponent as Line } from "./svg/line.svg";
import { ReactComponent as NumberSVG } from "./svg/number.svg";
import { ReactComponent as Scatter } from "./svg/scatter.svg";
import { ReactComponent as Table } from "./svg/table.svg";
import { ReactComponent as WordCloud } from "./svg/word-cloud.svg";

export const chartViewIconMap = {
  area: Area,
  "stacked-area": StackedArea,
  "stacked-area-100": StackedArea100,
  bar: Bar,
  basicBar: HorizontalBar,
  "horizontal-bar": HorizontalBar,
  "stacked-bar": StackedBar,
  "stacked-bar-100": StackedBar100,
  "stacked-column": StackedColumn,
  basicColumn: StackedColumn,
  "stacked-column-100": StackedColumn100,
  stackedPercentColumn: StackedColumn100,
  donut: Donut,
  "geospatial-scatter": GeospatialScatter,
  heatmap: Heatmap,
  line: Line,
  basicLine: Line,
  number: NumberSVG,
  scatter: Scatter,
  table: Table,
  "word-cloud": WordCloud
};

export type ChartViewIconProps = React.ComponentProps<typeof Icon> & {
  /**
   * chart view
   */
  view: keyof typeof chartViewIconMap;
};

/**
 * Custom chart view icons for Mongo Charts
 */
const ChartViewIcon: React.FC<ChartViewIconProps> = ({ view, ...rest }) => {
  return <Icon component={chartViewIconMap[view]} {...rest} />;
};

export default ChartViewIcon;

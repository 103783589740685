import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import html2pdf from "html2pdf.js";

import {
  Enterprise,
  EnterpriseAppState,
  Events,
  EventScope,
  EventSourceType,
  EventOrigin,
  GroupedEventList
} from "@ctra/api";

import {
  Button,
  DeleteOutlined,
  ImpactTracking,
  Spin,
  EllipsisOutlined,
  EditOutlined,
  DownloadOutlined,
  Modal,
  ExclamationCircleOutlined,
  Dropdown
} from "@ctra/components";

import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { isPending, Optional } from "@ctra/utils";
import { GAContext } from "@ctra/analytics";

import {
  GACategory,
  EventIcon,
  useEvent,
  useEventList,
  CreateEventURLParams,
  useCreateEventDialog
} from "@events";

import { Routes } from "@routes";
import { useUserList } from "@base";
import { useFarm } from "@farms";

import styles from "./EventPage.module.less";

const { Header } = ImpactTracking;
const { useModal } = Modal;

/**
 * Event page
 * @return {JSX.Element}
 * @constructor
 */
export const EventPage: FC = () => {
  const dispatch = useDispatch();
  const { event, getTitle, getStartMoment, getDescription, getComponents } = useEvent();
  const { Page } = getComponents();
  const { t } = useTranslation();
  const { farm } = useFarm();
  const [modal, contextHolder] = useModal();
  const { users } = useUserList();

  const {
    api: { open }
  } = useCreateEventDialog();

  const {
    meta: { hash }
  } = useEventList<GroupedEventList>();

  const {
    events: {
      entity: { ctaText, actions },
      list: {
        entry: {
          meta: { source },
          cta: {
            remove: { confirm, yes, no }
          }
        }
      }
    }
  } = Content;

  const {
    id: eventId,
    source: { type, id: sourceId },
    context
  } = event;

  /**
   * Tell if the given event is being deleted
   */
  const isDeleting = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Events.types.DELETE_EVENT, { primaryValue: eventId })
  );

  let extra: Optional<JSX.Element> = void 0;

  /**
   * Download event details as PDF
   */
  const downloadEvent = () => {
    const eventDetailsElement = document.getElementById("eventDetailsPage");
    const fileName = getTitle();
    const pdfFileName = _.replace(fileName, / /g, "_");

    const htmlToPdfOptions = {
      margin: 0.5,
      pagebreak: {
        mode: "avoid-all"
      },
      filename: pdfFileName,
      image: {
        type: "jpeg",
        quality: 1
      },
      html2canvas: {
        scale: 2,
        useCORS: true,
        dpi: 192,
        letterRendering: true
      },
      jsPDF: {
        unit: "in",
        format: "letter",
        orientation: "portrait"
      }
    };

    html2pdf().set(htmlToPdfOptions).from(eventDetailsElement).save();
  };

  /**
   * Event action menu items
   */
  const eventActionMenuItems = _[event.context?.userID ? "identity" : "dropRight"]([
    {
      label: t<string>(actions.edit),
      key: "edit",
      icon: <EditOutlined />,
      onClick: () => {
        open(hash, farm?.id, CreateEventURLParams.editEventKey);
      }
    },
    // {
    //   label: t<string>(actions.download),
    //   key: "download",
    //   icon: <DownloadOutlined />,
    //   onClick: () => {
    //     downloadEvent();
    //   }
    // },
    {
      label: t<string>(actions.delete),
      key: "delete",
      icon: <DeleteOutlined />,
      onClick: () => {
        modal.confirm({
          onOk: () => {
            dispatch(Events.actions.deleteEvent.start(eventId, type, sourceId));
          },
          icon: <ExclamationCircleOutlined />,
          content: t<string>(confirm),
          okText: t<string>(yes),
          cancelText: t<string>(no)
        });
      }
    }
  ]);

  /**
   * Event actions
   * @type {JSX.Element}
   */
  const toolbar = (
    <Dropdown
      trigger={["click"]}
      placement="bottomRight"
      menu={{
        items: eventActionMenuItems
      }}
    >
      <Button type="text" icon={<EllipsisOutlined className={styles.EventActionButton} />} />
    </Dropdown>
  );

  switch (type) {
    case EventSourceType.genericInsight:
      extra = [EventScope.herdGroup, EventScope.pen].includes(event.scope.type) ? (
        void 0
      ) : (
        <Button
          type="secondary"
          onClick={() => {
            const search = new URLSearchParams();
            search.append("genericInsightID", sourceId.toString());

            Enterprise.history.push({
              pathname: Routes.app.insights.index,
              search: search.toString()
            });
          }}
        >
          {t(ctaText(EventSourceType.genericInsight))}
        </Button>
      );
      break;
  }

  const userID = event.context?.userID;
  const user = _.isString(userID) ? _.get(users, [userID]) : void 0;
  const humanReadable = user ? _.trim(`${user.firstName} ${user.lastName}`) : null;

  const label = t<string>(source, {
    user: humanReadable,
    origin: userID ? EventOrigin.manual : EventOrigin.automated
  });

  return (
    <Spin spinning={isDeleting}>
      {contextHolder}
      <div id="eventDetailsPage" data-reason-of-existence="pdf-export">
        <Header
          title={getTitle()}
          description={getDescription()}
          date={getStartMoment()}
          icon={<EventIcon withCircle category={event.category} />}
          extra={extra}
          toolbar={toolbar}
          eventOrigin={event.context?.userID ? EventOrigin.manual : EventOrigin.automated}
          eventSource={label}
        />
        <GAContext.Provider value={{ category: GACategory.eventPage }}>
          <Page />
        </GAContext.Provider>
      </div>
    </Spin>
  );
};

import { FC } from "react";
import * as _ from "lodash";

import { Direction } from "@ctra/api";

import styles from "./Arrow.module.less";

import { ArrowDownOutlined, ArrowUpOutlined, MinusOutlined } from "../../../index";
import { classname } from "@ctra/utils";

export interface ArrowProps {
  /**
   * arrow direction
   */
  direction: Direction;
  /**
   * arrow size
   */
  size?: "small" | "large";
}

/**
 * Arrows to indicate increase/decrease or no change
 * @param {Direction} direction
 * @param {"small" | "large" | undefined} size
 * @return {JSX.Element}
 * @constructor
 */
const Arrow: FC<ArrowProps> = ({ direction, size = "large" }) => {
  const Icon = {
    [Direction.increase]: ArrowUpOutlined,
    [Direction.decrease]: ArrowDownOutlined,
    [Direction.stagnate]: MinusOutlined
  }[direction];

  return (
    <Icon className={classname(_.get(styles, _.upperFirst(direction)), _.get(styles, _.upperFirst(size)))} />
  );
};

export default Arrow;

import { FC, useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { useCurrentUser } from "@auth";
import { Routes } from "@routes";
import { FarmContext } from "@farms";

import { ReferralPage } from "../ReferralPage";
import { ReferralListPage } from "../ReferralListPage";
import { RequestAccessPage } from "../RequestAccessPage";
import styles from "./NetworkPage.module.less";
import { UserListContext } from "@base";

const SentryRoute = Sentry.withSentryRouting(Route);

/**
 * Network page
 * @returns {JSX.Element}
 * @constructor
 */
const NetworkPage: FC = (): JSX.Element => {
  const { user } = useCurrentUser();
  const farmId = user.farmAccess?.farmId;

  /**
   * Make a context for the user's own farm
   * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
   * @returns {JSX.Element}
   * @constructor
   */
  const ContextWrapper: FC = useMemo(
    () =>
      // eslint-disable-next-line react/display-name
      ({ children }) =>
        farmId ? (
          <FarmContext.Provider farmID={farmId} local>
            {children}
          </FarmContext.Provider>
        ) : (
          <>{children}</>
        ),
    [farmId]
  );

  return (
    <ContextWrapper>
      <UserListContext.Provider>
        <section className={styles.PageContent}>
          <Switch>
            <SentryRoute path={Routes.app.network.requestAccess} component={RequestAccessPage} />
            <SentryRoute
              path={[Routes.app.network.invite.new, Routes.app.network.invite.edit]}
              component={ReferralPage}
            />
            <SentryRoute path={Routes.app.network.index} component={ReferralListPage} />
          </Switch>
        </section>
      </UserListContext.Provider>
    </ContextWrapper>
  );
};

export default NetworkPage;

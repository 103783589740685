import moment from "moment";
import * as _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, createContext, useContext, FC, useRef } from "react";

import {
  AnnotationList,
  AnnotationResults,
  AnnotationState,
  Enterprise,
  EnterpriseAppState,
  ImpactTracking
} from "@ctra/api";
import { isPending, isRejected } from "@ctra/utils";

interface ContextType {
  results: AnnotationResults["results"]["timeSpan"];
  otherResults: AnnotationResults["results"]["timeSpan"];
  meta: {
    isLoading: boolean;
    isRejected: boolean;
    isProcessing: boolean;
    isAdding: boolean;
    isIntervalSupported: boolean;
  };
}

/**
 * Impact tracking context
 */
export const DefaultImpactTrackingContext = createContext<ContextType>({
  results: {},
  otherResults: {},
  meta: {
    isLoading: false,
    isRejected: false,
    isProcessing: false,
    isAdding: false,
    isIntervalSupported: false
  }
});

/**
 * Annotation Context that takes in ID and returns results for it
 * @param {string} annotationID
 * @param {string | undefined} timeSpan
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 * @constructor
 */
const ImpactTrackingContextProvider: FC<{ annotationID: AnnotationResults["id"]; timeSpan?: string }> = ({
  annotationID,
  timeSpan,
  children
}) => {
  const dispatch = useDispatch();

  /**
   * Get the list of annotations
   */
  const annotationList = useSelector<EnterpriseAppState, AnnotationList>((state) =>
    Enterprise.entities.getAnnotationList(state)
  );

  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, ImpactTracking.types.FETCH_ANNOTATION_RESULTS, { primaryValue: annotationID })
  );

  const isFailed = useSelector<EnterpriseAppState, boolean>((state) =>
    isRejected(state, ImpactTracking.types.FETCH_ANNOTATION_RESULTS, { primaryValue: annotationID })
  );

  const isAdding = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, ImpactTracking.types.ADD_METRIC, { primaryValue: annotationID })
  );

  /**
   * Check if annotation is in processing state
   */
  const isProcessing = _.isEqual(_.get(annotationList, [annotationID, "state"]), AnnotationState.processing);

  /**
   * This will ensure it only triggers the dispatch once, when its in processing
   */
  const processingTrigger = useRef(isProcessing);

  useEffect(() => {
    if (isProcessing) {
      processingTrigger.current = true;
    }
  }, [isProcessing]);

  /**
   * Dispatch if not already fetched, or if its in processing state
   */
  useEffect(() => {
    if (!annotationList[annotationID] || processingTrigger.current) {
      dispatch(ImpactTracking.actions.fetchAnnotationResults.start(annotationID));
      processingTrigger.current = false;
    }
  }, [annotationID, annotationList, dispatch]);

  /**
   * Convert moment to days e.g. P3D to 3
   */
  const days = moment.duration(timeSpan).asDays();

  /**
   * supported time intervals
   */
  const supportedIntervals = _.get(annotationList, [annotationID, "timeSpanDays"]);
  /**
   * Check if interval is actually supported from BE
   */
  const isIntervalSupported = _.includes(supportedIntervals, days);

  /**
   * results for a particular time period
   */
  const allResults = _.get(annotationList, [annotationID, "results", days]);

  /**
   * Hard coded data descriptors that are to be shown as 'what else has changed'
   * DIM and THI
   * @type {string[]}
   */
  const otherDescriptors = [
    "60997b74ee5779d6f5a3a97f",
    "653aaf7288e5d7525a8f4aa0", // Average DIM
    "64df384495995c0be51eeffd"
  ];

  const results = _.omit(allResults, otherDescriptors);
  const otherResults = _.pick(allResults, otherDescriptors);

  return (
    <DefaultImpactTrackingContext.Provider
      value={{
        results,
        otherResults,
        meta: { isLoading, isRejected: isFailed, isProcessing, isAdding, isIntervalSupported }
      }}
    >
      {children}
    </DefaultImpactTrackingContext.Provider>
  );
};

export const ImpactTrackingContext = {
  Provider: ImpactTrackingContextProvider,
  Consumer: DefaultImpactTrackingContext.Consumer
};

/**
 * Hook for impact tracking context
 * @returns {void}
 */
export const useImpactTracking = (): ContextType => useContext<ContextType>(DefaultImpactTrackingContext);

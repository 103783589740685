import { ComponentProps, FC, useMemo } from "react";
import * as Sentry from "@sentry/react";
import { Datum } from "@ant-design/charts";

import { Divider, Typography, Insights, Skeleton, Row, Col } from "@ctra/components";
import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { classname } from "@ctra/utils";
import { Scatter } from "@ctra/charts";

import { InsightValidation } from "../../../insight-validation";
import { useInsight } from "../../../insight";
import { OnboardingFooter } from "../../../insight-extensions";

import baseStyles from "../InsightBody/InsightBody.module.less";
import { OnboardingInsight } from "../../../processing/OnboardingInsight";
import * as _ from "lodash";

const { Text, Paragraph } = Typography;
const { FarmProgress } = Insights;

const {
  insights: {
    extensions: { onboarding, score: scoreCopy }
  }
} = Content;

/**
 * Body for the onboarding insight in 2 variants
 * @param {"card" | "dialog"} variant
 * @returns {JSX.Element}
 * @constructor
 */
export const OnboardingInsightBody: FC<{ variant: "card" | "dialog" }> = ({ variant }) => {
  const { t } = useTranslation();

  const {
    getDescription,
    getMetadata,
    getMetricName,
    getMetric,
    meta: { isLoading }
  } = useInsight<OnboardingInsight>();

  const { percentile, data } = getMetadata();
  const metric = getMetric();
  const metricName = getMetricName();

  /**
   * Chart configuration
   * @type {{pointStyle: {fill: string, stroke: string, lineWidth: number}, yAxis: {title: {text: string}}, xField: string, xAxis: {title: {text: string}}, data: any, size: number, yField: string, tooltip: {formatter: (datum: Datum) => {name: any, value: any}}, regressionLine: {type: string}, autoFit: boolean}}
   */
  const chartConfig: ComponentProps<typeof Scatter> = useMemo(
    () => ({
      data,
      animation: false,
      xField: "x",
      yField: "y",
      size: 3,
      pointStyle: {
        stroke: "#777777",
        lineWidth: 1,
        fill: "#5B8FF9"
      },
      autoFit: true,
      regressionLine: {
        style: { lineWidth: 3 },
        type: "quad" // linear, exp, loess, log, poly, pow, quad
      },
      xAxis: {
        title: {
          text: t<string>(onboarding.chart.axis.x, { metric: metricName })
        }
      },
      yAxis: {
        label: {
          formatter: (value: string) => {
            return _.round(parseFloat(value), metric.valueProperties.decimals);
          }
        },
        title: {
          text: t<string>(onboarding.chart.axis.y, { metric: metricName })
        }
      },
      tooltip: {
        formatter: (datum: Datum) => {
          return { name: datum.x, value: datum.y };
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }),
    [data]
  );

  return variant === "card" ? (
    <>
      <Text size="md">{getDescription()}</Text>
      <Divider />
      <FarmProgress label={t<string>(scoreCopy.label)} progress={percentile} />
    </>
  ) : (
    <Skeleton active loading={isLoading}>
      <Row className={classname(baseStyles.Row, baseStyles.Padded)}>
        <Col flex={1}>
          <FarmProgress label={t<string>(scoreCopy.label)} progress={percentile} />
        </Col>
      </Row>
      <Row className={classname(baseStyles.Row, baseStyles.Padded)}>
        <Col flex={1}>
          <InsightValidation />
        </Col>
      </Row>
      <Sentry.ErrorBoundary fallback={<>{null}</>}>
        <Row className={classname(baseStyles.Row, baseStyles.PaddedChart)}>
          <Col span={24}>
            <Paragraph>{t<string>(onboarding.chart.title, { metric: metricName })}</Paragraph>
          </Col>
          <Col flex={1}>
            <Scatter {...chartConfig} />
          </Col>
        </Row>
      </Sentry.ErrorBoundary>
      <Row className={classname(baseStyles.Gray, baseStyles.Row, baseStyles.LastRow, baseStyles.Padded)}>
        <Col>
          <OnboardingFooter />
        </Col>
      </Row>
    </Skeleton>
  );
};

/**
 * Resize an image to a specific width
 * @param {string} base64String
 * @param {number} width
 * @returns {Promise<unknown>}
 */
export const resizeBase64Image = (base64String: string, width: number) => {
  return new Promise(async function (resolve, reject) {
    const img = new Image();

    img.src = base64String;

    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = width;
      canvas.height = img.height * (width / img.width);

      if (ctx) {
        ctx.save();
        ctx.fillStyle = "#FFFFFF";
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        // @ts-expect-error - this
        ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
      }

      const dataURI = canvas.toDataURL("image/jpeg", 0.85);

      resolve(dataURI);
    };

    img.src = base64String;
  });
};

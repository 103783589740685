import { useContext, createContext, ReactElement, PropsWithChildren, ComponentProps, Consumer } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Enterprise,
  EnterpriseAppState,
  Events,
  EventListQueryParams,
  FarmEntity,
  GroupedEventList,
  ExtendedEventList,
  ChartEventsList
} from "@ctra/api";

import { Debug, isDispatched, isPending, Optional } from "@ctra/utils";
import { useDeepCompareEffect } from "use-deep-compare";

interface ProviderProps extends Partial<EventListQueryParams> {
  farmID?: FarmEntity["id"];
  grouped?: boolean;
  limit?: number;
}

type ContextType<T extends Optional<ExtendedEventList | GroupedEventList>> = {
  events: T;
  selectedEvents: ChartEventsList;
  meta: {
    count: number;
    isLoading: boolean;
    hash: string;
  };
};

/**
 * Make a default context for event list values
 */
const DefaultContext = createContext<ContextType<ExtendedEventList | GroupedEventList>>({
  events: {},
  selectedEvents: [],
  meta: {
    count: 0,
    isLoading: true,
    hash: ""
  }
});

/**
 * Get a list of events from the back-end
 * @param farmID
 * @param children
 * @param grouped
 * @param limit
 * @param params
 */
const _EventListProvider = ({
  farmID,
  children,
  grouped,
  limit,
  ...params
}: PropsWithChildren<ProviderProps>): ReactElement => {
  const dispatch = useDispatch();
  const hash = Events.utils.makeEventHash(farmID, params);

  /**
   * Get the event count for the given list
   */
  const count = useSelector<EnterpriseAppState, number>((state) =>
    Enterprise.entities.getEventCount(state, { hash })
  );

  /**
   * Pull the events in the requested format (plain or grouped) from the store
   */
  const events = useSelector<EnterpriseAppState, ExtendedEventList | GroupedEventList>((state) => {
    Debug.farmTimeline.info("Retrieving event list from store", { hash, _grouped: grouped, limit, params });

    return Enterprise.entities.getEventList(state, { hash, grouped, limit, exclude: params.exclude });
  });

  const selectedEvents: ChartEventsList = [];

  /**
   * Tell if the events have already been fetched.
   */
  const hasFetched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, Events.types.FETCH_EVENTS, { primaryValue: hash })
  );

  /**
   * Tell whether the events are loading
   */
  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Events.types.FETCH_EVENTS, { primaryValue: hash })
  );

  useDeepCompareEffect(() => {
    if (farmID && !hasFetched) {
      Debug.farmTimeline.info("Fetching event list", { farmID, params, hash });
      dispatch(Events.actions.fetchEvents.start(farmID, params));
    }
  }, [dispatch, farmID, hasFetched, params, hash]);

  return (
    <DefaultContext.Provider value={{ events, selectedEvents, meta: { count, isLoading, hash } }}>
      {children}
    </DefaultContext.Provider>
  );
};

/**
 * Make a typed consumer
 * @param children
 */
const _EventListConsumer = <T extends ExtendedEventList | GroupedEventList>({
  children
}: ComponentProps<Consumer<ContextType<T>>>): ReactElement => (
  <DefaultContext.Consumer>
    {children as ComponentProps<typeof DefaultContext.Consumer>["children"]}
  </DefaultContext.Consumer>
);

export const EventListContext = {
  Provider: _EventListProvider,
  Consumer: _EventListConsumer
};

/**
 * Make a hook to access the context in FCs
 */
export function useEventList<T extends ExtendedEventList | GroupedEventList>(): ContextType<T> {
  return useContext(DefaultContext) as ContextType<T>;
}

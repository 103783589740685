import { ComponentProps, FC } from "react";

import { Enterprise, useTranslation } from "@ctra/i18n";
import { Charts, DownOutlined, Space } from "@ctra/components";
import { ChartEntity } from "@ctra/api";
import { useGoogleAnalytics } from "@ctra/analytics";

import { GAActions } from "../../analytics";
import styles from "./ChartViewFilter.module.less";

const { ChartViewIcon, SimpleDropdown } = Charts;
type ChartView = ComponentProps<typeof ChartViewIcon>["view"];

/**
 * Resolve the chart view (for the icon) from the mongo chart view
 * @param mongoChartView
 */
const getIconName = (mongoChartView: ChartEntity["view"]): ChartView => {
  const map: Record<ChartEntity["view"], ChartView> = {
    "100StackedArea": "stacked-area-100",
    "100StackedBar": "stacked-bar-100",
    "100StackedColumn": "stacked-column-100",
    area: "area",
    stackedArea: "stacked-area",
    dataTable: "table",
    donut: "donut",
    geospatialScatter: "geospatial-scatter",
    groupedBar: "horizontal-bar",
    heatmap: "heatmap",
    line: "line",
    lineContinuous: "line",
    number: "number",
    scatter: "scatter",
    stackedBar: "stacked-bar",
    stackedColumn: "stacked-column",
    wordCloud: "word-cloud",
    basicLine: "basicLine",
    basicBar: "basicBar",
    basicColumn: "basicColumn",
    table: "table",
    stackedPercentColumn: "stackedPercentColumn"
  };

  if (!map[mongoChartView]) {
    throw new Error(`"${mongoChartView}" could not be mapped in ${JSON.stringify(map)}.`);
  }

  return map[mongoChartView];
};

interface ChartViewFilterProps {
  /**
   * current view
   */
  view: ChartEntity["view"];
  /**
   * available views/chart ids
   */
  views: Array<ChartEntity["view"]>;
  /**
   * handle view change
   * @param view
   */
  onViewChange: (view: ChartView) => void;
}

/**
 * Chart view switch
 * @param views
 * @param view
 * @param onViewChange
 * @constructor
 */
const ChartViewFilter: FC<ChartViewFilterProps> = ({ views, view, onViewChange }) => {
  const { t } = useTranslation();
  const { trackEvent, label } = useGoogleAnalytics<{ label: string }>();

  return (
    <SimpleDropdown<ChartEntity["view"]>
      values={views}
      currentValue={view}
      onChange={({ key }) => {
        /* istanbul ignore next */
        trackEvent(GAActions.changeChartType, { label: `${label} - ${key}` });
        onViewChange(key as ChartView);
      }}
      showLabel={true}
      getLabel={(key, { isToggle }) => (
        <Space align="center">
          <ChartViewIcon view={getIconName(key as ChartEntity["view"])} />
          {isToggle ? (
            <DownOutlined className={styles.Caret} />
          ) : (
            t<string>(Enterprise.chart.view(key as ChartEntity["view"]))
          )}
        </Space>
      )}
    />
  );
};

export default ChartViewFilter;

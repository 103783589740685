import * as _ from "lodash";
import { FC } from "react";

import { DairyCowEntity, Enterprise, EnterpriseAppState, HerdGroupEntity } from "@ctra/api";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { Row, Col, Typography, Button, RollbackOutlined } from "@ctra/components";

import { testIds, useInsight } from "@insights";
import styles from "./ValidationQuestion.module.less";
import { useSelector } from "react-redux";

const { Text } = Typography;

interface ValidationQuestionProps extends Record<string, unknown> {
  step?: number;
  rollback?: () => void;
  highlighted?: boolean;
}

/**
 * Multi-row validation question to bee used as `title` prop for AntD `Step`
 * @param onClick
 * @param step
 * @param rollback
 * @param highlighted
 * @param rest
 * @constructor
 */
export const ValidationQuestion: FC<ValidationQuestionProps> = ({ step, rollback, highlighted, ...rest }) => {
  const { t } = useTranslation();

  const {
    insightValidations: {
      steps: { question, rollback: rollbackText }
    }
  } = Content;

  const {
    insight: { insightType: typeName, dairyCow: dairyCowID, herdGroup: herdGroupID }
  } = useInsight();

  /**
   * Get dairy cow
   * @type {DairyCowEntity | void}
   */
  const dairyCow = useSelector<EnterpriseAppState, DairyCowEntity | void>((state) =>
    dairyCowID
      ? Enterprise.entities.getDairyCow(state, {
          id: dairyCowID
        })
      : void 0
  );

  /**
   * Get herd group
   * @type {HerdGroupEntity | void}
   */
  const herdGroup = useSelector<EnterpriseAppState, HerdGroupEntity | void>((state) =>
    herdGroupID ? Enterprise.entities.getHerdGroup(state, { id: herdGroupID }) : void 0
  );

  /**
   * Get copy key params
   * @param type
   * @example
   * ```ts
   * question("KPIInsightFarm", getParams("secondary))
   * // insightValidations-steps-question#step1-secondary
   * ```
   */
  const getParams = (type = "primary") => _.compact([step ? `step${step}` : null, type]);

  const primary = t<string>(question(typeName, ...getParams()), {
    ...rest,
    cowTagNumber: _.get(dairyCow, "cowTagNumber"),
    herdGroup: _.get(herdGroup, "name", null)
  });

  const secondary = t<string>(question(typeName, ...getParams("secondary")), {
    ...rest,
    cowTagNumber: _.get(dairyCow, "cowTagNumber"),
    herdGroup: _.get(herdGroup, "name", null)
  });

  return (
    <Row gutter={[0, 4]} className={styles.Wrapper}>
      <Row wrap={false} style={{ width: "100%" }}>
        <Col flex={1}>
          <Text className={highlighted ? styles.Primary : void 0}>{primary}</Text>
        </Col>
        {_.isFunction(rollback) && (
          <Col>
            <Button
              data-testid={testIds.entity.validation.rollback}
              type="link"
              icon={<RollbackOutlined />}
              onClick={_.ary(rollback, 0)} // kill any arguments passed by the onClick
            >
              {t<string>(rollbackText)}
            </Button>
          </Col>
        )}
      </Row>
      {!!secondary && highlighted && (
        <Row>
          <Col flex={1}>{<Text className={styles.Secondary}>{secondary}</Text>}</Col>
        </Row>
      )}
    </Row>
  );
};

import { FC } from "react";
import { AjinomotoTwoStepValidation } from "../AjinomotoTwoStepValidation";

/**
 * Nutrition recommendation insight
 * @param {React.PropsWithChildren<{}>} props
 * @return {JSX.Element}
 * @constructor
 */
export const AjinomotoNutritionRecommendationInsightValidation: FC = (props) => (
  <AjinomotoTwoStepValidation {...props} />
);

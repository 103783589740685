import { FC, useState, createContext, useContext } from "react";
import * as _ from "lodash";

import { Charts } from "@ctra/api";
import { useFilter, useISODuration, useTimePeriod } from "@chart";

import { ContextType, DateMethod, FilterMetadata, FilterProviderProps } from "./typings";
import { useUserPreferences } from "@base";

/**
 * Default filter metadata
 * @type {{offset: number, flags: {isBackendAggregated: boolean}, supportedISODurations: string[], supportedfarmIDs: any[], showFilters: boolean, filterDefinitions: any[]}}
 */
const defaultMetadata: FilterMetadata = {
  supportedISODurations: Charts.utils.supportedDurations,
  showFilters: true,
  filterDefinitions: [],
  flags: { isBackendAggregated: false },
  offset: 86400,
  supportedfarmIDs: []
};

/**
 * Default filter context value
 * @type {{metadata: FilterMetadata, series: any[], timePeriod: {}, filters: {}, api: {setMetadata: (...args: any[]) => void, setISODuration: (...args: any[]) => void, reset: (...args: any[]) => void, setCurrentDateMethod: (...args: any[]) => void, setTimePeriod: (...args: any[]) => void, setFilter: (...args: any[]) => void}, isoDuration: null}}
 */
export const defaultFilterContextValue: ContextType = {
  filters: {},
  timePeriod: {},
  isoDuration: null,
  metadata: defaultMetadata,
  series: {
    available: {},
    active: []
  },
  api: {
    reset: _.noop,
    setSeries: _.noop,
    setISODuration: _.noop,
    setFilter: _.noop,
    setTimePeriod: _.noop,
    setMetadata: _.noop,
    setCurrentDateMethod: _.noop
  }
};

const DefaultContext = createContext<ContextType>(defaultFilterContextValue);

/**
 * Provides API for filtering
 * Values come in from props, section/chart that call the setter APIs down the components tree
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @param {{}} initialFilters
 * @param {any[]} initialSeries
 * @param {string | null | undefined} initialISODuration
 * @param {{}} initialTimePeriod
 * @param {{isoDuration?: Nullable<ISODuration>, timePeriod?: ChartTimePeriod} | undefined} currentValues
 * @param {Array<ISODuration> | undefined} durationOptions
 * @returns {JSX.Element}
 */
const _ChartFilterProvider: FC<FilterProviderProps> = ({
  children,
  filters: initialFilters = {},
  series: initialSeries = [],
  isoDuration: initialISODuration,
  timePeriod: initialTimePeriod = {},
  currentValues,
  durationOptions
}) => {
  const {
    preferences: {
      sandbox: { isEnabled: isSandboxEnabled }
    }
  } = useUserPreferences();

  const [isoDuration, setISODuration] = useISODuration(initialISODuration);

  const [series, setSeries] = useState<{
    available: Record<string, string>;
    active: Array<string>;
  }>({ available: {}, active: initialSeries });

  const dateMethod = initialISODuration ? DateMethod.iso : DateMethod.custom;
  const [currentDateMethod, setCurrentDateMethod] = useState<DateMethod>(dateMethod);

  const [metadata, setMetadata] = useState<FilterMetadata>(
    durationOptions ? { ...defaultMetadata, supportedISODurations: durationOptions } : defaultMetadata
  );

  const [timePeriod, setTimePeriod] = useTimePeriod(
    {
      isoDuration: currentValues?.isoDuration || isoDuration,
      timePeriod: currentValues?.timePeriod || initialTimePeriod,
      currentDateMethod: dateMethod
    },
    _.defaultTo(metadata.offset, 86400)
  );

  const [filters, setFilter, reset] = useFilter(
    Charts.utils.getDefaultFilters(metadata.filterDefinitions),
    initialFilters
  );

  return (
    <DefaultContext.Provider
      value={{
        filters: isSandboxEnabled ? _.omit(filters, "dimGroupKey") : filters,
        series,
        timePeriod,
        isoDuration: currentValues?.isoDuration || isoDuration,
        metadata,
        currentDateMethod,
        api: {
          reset,
          setSeries,
          setFilter,
          setISODuration,
          setTimePeriod,
          setMetadata,
          setCurrentDateMethod
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const ChartFilterContext = {
  Provider: _ChartFilterProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook for Base Filter context
 * @returns
 */
export const useChartFilters = (): ContextType => useContext(DefaultContext);

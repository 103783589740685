import { memoize } from "@ctra/utils";
import { Bar } from "@ctra/charts";
import { Enterprise, i18nTranslate } from "@ctra/i18n";

import { DataPointsBase } from "./DataPointsBase";
import { ChartViewBase } from "./Base";

/**
 * Bar Chart View class
 */
class BarChart extends DataPointsBase {
  /**
   * get the chart component
   */
  @memoize
  getComponent(): ReturnType<typeof ChartViewBase.prototype.getComponent> {
    return Bar;
  }

  /**
   * get the meta data
   * @return {M & {axis: {x: {title: string}, y: {}}}}
   */
  @memoize
  getMetadata(): ReturnType<typeof ChartViewBase.prototype.getMetadata> {
    const {
      bar: { axisTitle }
    } = Enterprise.chart;

    return {
      ...super.getMetadata(),
      axis: {
        x: {
          title: i18nTranslate(axisTitle.x)
        },
        y: {
          ...super.getMetadata().axis?.y
        }
      }
    };
  }
}

export { BarChart };

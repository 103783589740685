import { useState, useContext, createContext, forwardRef, ForwardedRef, PropsWithChildren } from "react";
import * as _ from "lodash";
import useDeepCompareEffect from "use-deep-compare-effect";
import { Options } from "@ant-design/charts";

import {
  ChartData,
  DataType,
  MetaType,
  ExtendedEventList,
  ExtendedEventEntity,
  ChartViewOptions,
  ChartDataOptions,
  ChartEntity,
  EventSourceType
} from "@ctra/api";

import { SeriesType } from "../../typings";
import { TS } from "@ctra/utils";

type ChartProviderProps = {
  entity: ChartEntity;
  data: ChartData["data"];
  meta: ChartData["meta"];
  viewOptions?: ChartViewOptions;
  dataOptions?: ChartDataOptions;
  config?: Partial<Options>;
  series?: SeriesType;
  events?: ExtendedEventList;
};

type ContextType<T extends ChartData> = {
  ref: ForwardedRef<any>;
  data: T["data"];
  meta: T["meta"];
  entity: ChartEntity;
  series: SeriesType;
  viewOptions: ChartViewOptions;
  dataOptions: ChartDataOptions;
  config: Partial<Options>;
  api: {
    toggleEvents: (on: Record<EventSourceType, boolean>) => void;
    toggleAnnotations: (on: boolean) => void;
    toggleAnomalies: (on: boolean) => void;
  };
  events: Record<string, Array<ExtendedEventEntity>>;
};

/**
 * Default custom config
 */
const defaultConfig: Partial<Options> = {
  // legend: { position: "top" }
  // height: 220
};

/**
 * Default view options
 * @type {{zoomEnabled: boolean, zoomed: boolean, height: number}}
 */
const defaultViewOptions: ChartViewOptions = {
  zoomed: false,
  zoomEnabled: true
};

const DefaultContext = createContext<ContextType<ChartData<DataType, MetaType>>>({
  ref: null,
  data: [],
  meta: {},
  entity: {} as ChartEntity,
  series: {},
  dataOptions: {},
  viewOptions: defaultViewOptions,
  config: defaultConfig,
  api: {
    toggleEvents: _.noop,
    toggleAnnotations: _.noop,
    toggleAnomalies: _.noop
  },
  events: {}
});

/**
 * Group events
 * @param {ExtendedEventList} events
 * @return {Dictionary<[unknown, ...unknown[]]>}
 */
const groupEvents = (events: ExtendedEventList) =>
  _.groupBy(events, (event) => TS.asMoment(event.startAt).format("YYYY-MM-DD"));

/**
 * @note Instead of filtering first, we are sending all the info down so that different charts can compose what they need.
 * So, in summary: Context -> (Filter + Config + Data + Chart) as against Context -> (Filter + Config + Data) -> Chart
 * @param {ChartEntity} entity
 * @param {Array<{x: number, y: string, seriesField: string}> | Array<{x: string, y: number, seriesField: string}> | Array<{x: string, y: Nullable<number>, seriesField: string, anomaly?: {prediction?: number, type?: string}}> | Array<{x: string | number, y: string, z: number}> | Array<Record<string, string | number | string[] | null | undefined>>} data
 * @param {(DataPointsMetaSource & TranslatedMeta) | TableMeta} meta
 * @param {any[]} series
 * @param {{}} events
 * @param {{}} viewOptions
 * @param {{}} dataOptions
 * @param {{}} config
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @returns {JSX.Element}
 */
const _ChartContextProvider = forwardRef<unknown, PropsWithChildren<ChartProviderProps>>(
  (
    {
      entity,
      data,
      meta,
      series = {},
      events = {},
      viewOptions = {},
      dataOptions = {},
      config = {},
      children
    },
    ref
  ) => {
    const [guardedEntity, setGuardedEntity] = useState<ChartEntity>(entity);
    const [guardedConfig, setGuardedConfig] = useState<Partial<Options>>(_.merge({}, defaultConfig, config));
    const [guardedData, setGuardedData] = useState(data);
    const [guardedEvents, setGuardedEvents] = useState(groupEvents(events));
    const [guardedMeta, setGuardedMeta] = useState(meta);
    const [guardedSeries, setGuardedSeries] = useState(series);
    const [guardedDataOptions, setGuardedDataOptions] = useState(dataOptions);
    const [guardedViewOptions, setGuardedViewOptions] = useState<ChartViewOptions>({
      ...defaultViewOptions,
      ...viewOptions
    });

    useDeepCompareEffect(() => {
      setGuardedEntity(entity);
    }, [entity]);

    useDeepCompareEffect(() => {
      setGuardedConfig((previousConfig) => _.merge({}, previousConfig, config));
    }, [config]);

    useDeepCompareEffect(() => {
      setGuardedViewOptions((previousViewOptions) => ({ ...previousViewOptions, ...viewOptions }));
    }, [viewOptions]);

    useDeepCompareEffect(() => {
      setGuardedDataOptions((previousDataOptions) => _.merge({}, previousDataOptions, dataOptions));
    }, [dataOptions]);

    useDeepCompareEffect(() => {
      setGuardedData(data);
    }, [data]);

    useDeepCompareEffect(() => {
      setGuardedEvents(groupEvents(events));
    }, [events]);

    useDeepCompareEffect(() => {
      setGuardedMeta(_.defaultTo(meta, {}));
    }, [meta]);

    useDeepCompareEffect(() => {
      setGuardedSeries(series);
    }, [series]);

    return (
      <DefaultContext.Provider
        value={{
          ref,
          entity: guardedEntity,
          series: guardedSeries,
          config: guardedConfig,
          data: guardedData,
          meta: guardedMeta,
          viewOptions: guardedViewOptions,
          dataOptions: guardedDataOptions,
          events: guardedEvents,
          api: {
            toggleEvents: (on) => {
              setGuardedViewOptions((viewOptions) => ({ ...viewOptions, showEvents: on }));
            },
            toggleAnnotations: (on) => {
              setGuardedViewOptions((viewOptions) => ({ ...viewOptions, showAnnotations: on }));
            },
            toggleAnomalies: (on) => {
              setGuardedViewOptions((viewOptions) => ({ ...viewOptions, showAnomalies: on }));
            }
          }
        }}
      >
        {children}
      </DefaultContext.Provider>
    );
  }
);

export const ChartContext = {
  Provider: _ChartContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook for chart data, series and config provider context
 * @returns
 */
export const useChartContext = <T extends ChartData>(): ContextType<T> =>
  useContext(DefaultContext) as ContextType<T>;

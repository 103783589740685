import { FC } from "react";
import * as _ from "lodash";

import { ReferralEntity, ReferralStatus, UserEntity } from "@ctra/api";
import { Button, UserDeleteOutlined, Table, TableProps } from "@ctra/components";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";

const { Column } = Table;

const {
  network: {
    users: { table }
  }
} = Content;

/**
 * Table view of users
 * @param {React.PropsWithChildren<TableProps<UserEntity>>} props
 * @returns {JSX.Element}
 * @constructor
 */
export const UserAccessTable: FC<TableProps<UserEntity>> = (props) => {
  const { t } = useTranslation();

  return (
    <Table<UserEntity> {...props}>
      <Column<UserEntity>
        width="50%"
        title={t<string>(table.columns.name)}
        key="name"
        sorter={(a, b) => _.defaultTo(a.firstName, "").localeCompare(_.defaultTo(b.firstName, ""))}
        render={(value, { firstName, lastName }) => _.join(_.compact([firstName, lastName]), " ") || "-"}
      />
      <Column<UserEntity>
        width="50%"
        title={t<string>(table.columns.email)}
        dataIndex="email"
        key="email"
        sorter={(a, b) => _.defaultTo(a.email, "").localeCompare(_.defaultTo(b.email, ""))}
      />
      {/*<Column<UserEntity>*/}
      {/*  title={t<string>(table.columns.userGroup)}*/}
      {/*  key="userGroup"*/}
      {/*  render={(value, entity) => _.map(entity.userGroups, "name").join(", ")}*/}
      {/*/>*/}
      {/*<Column<UserEntity>*/}
      {/*  align="right"*/}
      {/*  width="25%"*/}
      {/*  title={t<string>(table.columns.actions)}*/}
      {/*  render={() => (*/}
      {/*    <Button type="link" size="small" icon={<UserDeleteOutlined />}>*/}
      {/*      {t<string>(table.actions.revoke)}*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*/>*/}
    </Table>
  );
};

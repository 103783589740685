import { FC } from "react";
import * as _ from "lodash";
import { useSelector } from "react-redux";

import {
  Enterprise as Data,
  EnterpriseAppState,
  InsightResolutionEntity,
  InsightResolutionType,
  ValidationLookup,
  InsightValidation
} from "@ctra/api";

import { Col, Row, Insights as InsightsUI } from "@ctra/components";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";

import { useInsightValidation, useInsight, testIds } from "@insights";

const {
  List: { Resolution }
} = InsightsUI;

interface ResolutionTagListProps {
  includeValidation?: boolean;
}

/**
 * List of resolutions as tags and a validation value
 * @constructor
 */
export const ResolutionTagList: FC<ResolutionTagListProps> = ({ includeValidation = true }) => {
  const { t } = useTranslation();

  const {
    insightResolutions: { title: resolutionTitle },
    insightValidations: { validationDescription }
  } = Content;

  const {
    insight: { id, insightType: typeName }
  } = useInsight();

  const {
    validation: { validation: validationValue }
  } = useInsightValidation();

  /**
   * Resolve the resolutions for the current insight
   */
  const resolutionsOfInsight = useSelector<
    EnterpriseAppState,
    Record<InsightResolutionEntity["id"], InsightResolutionEntity>
  >((state) => Data.entities.getResolutionsOfInsight(state, { id }));

  return (
    <Row wrap={false} gutter={[8, 0]}>
      <Col flex={1}>
        {_.isEmpty(resolutionsOfInsight) ? (
          validationValue === InsightValidation.na ? null : (
            <Resolution
              validation={includeValidation ? validationValue : void 0}
              label={t<string>(validationDescription(typeName), { value: ValidationLookup[validationValue] })}
            />
          )
        ) : (
          _.map(resolutionsOfInsight, ({ id, source, title }) => (
            <Resolution
              validation={includeValidation ? validationValue : void 0}
              data-testid={testIds.list.entry.resolution}
              key={id}
              label={
                source === InsightResolutionType.preset
                  ? t<string>(resolutionTitle(id), { insightType: typeName })
                  : title
              }
            />
          ))
        )}
      </Col>
    </Row>
  );
};

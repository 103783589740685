import { FC } from "react";

import { useInsight } from "@insights";

/**
 * Insight body factory
 * @constructor
 */
export const InsightBody: FC = () => {
  const { getComponents } = useInsight();
  const BodyComponent = getComponents().Body;

  return <BodyComponent />;
};

export const auth = {
  _root: "/auth",
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  acceptInvite: "/invitation",
  welcome: "/welcome",
  websiteSignup: "/website-signup",
  signup: "/signup"
};

import { ChartDataSourceType, ChartEntity, ChartType, MetaType } from "@ctra/api";

import { ChartViewBase } from "./Base";
import { LineChart } from "./LineChart";
import { BarChart } from "./BarChart";
import { AreaChart } from "./AreaChart";
import { Table } from "./Table";
import { Heatmap } from "./Heatmap";
import { ColumnChart } from "./ColumnChart";

type RegisteredChartViews =
  | typeof LineChart
  | typeof BarChart
  | typeof AreaChart
  | typeof ColumnChart
  | typeof Table
  | typeof Heatmap
  | typeof ChartViewBase;

/**
 * A factory to create a nice API around the chart view types
 * @example
 * ```ts
 * Factory.create(type);
 * // instanceof BarChartView extends BaseView
 * ```
 */
class Factory {
  private static _registry = new Map();

  static register<I extends RegisteredChartViews>(type: ChartType, cls: I): void {
    if (!Factory._registry.has(type)) {
      Factory._registry.set(type, cls);
    } else {
      throw new Error(`${type} is already set in the registry`);
    }
  }

  /**
   * Create an instance object
   * @param {ChartEntity} chart
   * @param {MetaType} metadata
   * @returns {InstanceType<RegisteredChartViews>}
   */
  static create(chart: ChartEntity, metadata: MetaType): InstanceType<RegisteredChartViews> {
    const { view } = chart as ChartEntity;

    let cls = Factory._registry.get(view);

    if (!cls) {
      console.warn(`${view} is not yet in the registry. Using ChartView Base as constructor`);
      cls = ChartViewBase;
    }

    return new cls(chart, metadata);
  }

  /**
   * Create an empty instance
   * @returns {InstanceType<RegisteredChartViews>}
   */
  static createEmpty(): InstanceType<RegisteredChartViews> {
    return new ChartViewBase({} as ChartEntity, {} as MetaType);
  }
}

Factory.register(ChartType.basicLine, LineChart);
Factory.register(ChartType.basicBar, BarChart);
Factory.register(ChartType.stackedArea, AreaChart);
Factory.register(ChartType.table, Table);
Factory.register(ChartType.heatmap, Heatmap);
Factory.register(ChartType.basicColumn, ColumnChart);
Factory.register(ChartType.stackedColumn, ColumnChart);
Factory.register(ChartType.stackedPercentColumn, ColumnChart);

/**
 * @deprecated Remove once all descriptors are re-configured on the BE
 */
Factory.register(ChartType.line, LineChart);
Factory.register(ChartType.lineContinuous, LineChart);
Factory.register(ChartType.stackedBar, BarChart);
Factory.register(ChartType.dataTable, Table);

export { Factory };

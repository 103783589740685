import { FC } from "react";
import { useLocation } from "react-router-dom";

import { GenericInsightEntity } from "@ctra/api";
import { Nullable } from "@ctra/utils";
import { MediaQueryDynamicImport } from "@ctra/components";

import { DataDictionaryContext } from "@base";
import { testIds } from "@insights";

/**
 * InsightDetails component to render when the search params match on any route
 * @constructor
 */
export const InsightDetails: FC = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  /**
   * Insight to pull from the insights
   */
  const insightID: Nullable<GenericInsightEntity["id"]> = Number(searchParams.get("genericInsightID"));

  return insightID ? (
    <DataDictionaryContext.Provider>
      <div data-testid={testIds.entity.details.wrapper}>
        <MediaQueryDynamicImport mobile={() => import("./Mobile")} desktop={() => import("./Desktop")}>
          {(Details) => <Details insightID={insightID} />}
        </MediaQueryDynamicImport>
      </div>
    </DataDictionaryContext.Provider>
  ) : null;
};

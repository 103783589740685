import moment from "moment";

import { memoize } from "@ctra/utils";
import { i18n } from "@ctra/i18n";

import { Base } from "./Base";

/**
 * X axis data type day class
 */
class Day extends Base {
  /**
   * Get the translated label
   * @param label
   * @param long
   * @returns
   */
  @memoize
  getTranslatedLabel(label: string, options: { long: boolean }): string {
    /**
     * Set the moment locale
     */
    moment.locale(i18n.language);

    return moment(label).format(options?.long ? "DD-MMM-YYYY" : "D MMM");
  }
}

export { Day };

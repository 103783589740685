import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import * as Sentry from "@sentry/react";

import { Enterprise, EnterpriseAppState, Notifications } from "@ctra/api";
import { Nullable } from "@ctra/utils";

export const oneSignalUserIdKey = "persist:ctra.enterprise.v3.oneSignalUserId";
export const appName = "oneida";

/**
 * Register the user with OneSignal
 * @constructor
 */
export const OneSignal: FC = () => {
  const isLoggedIn = useSelector<EnterpriseAppState, boolean>(Enterprise.entities.isLoggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      /**
       * Get the OneSignal user id from local storage
       */
      const oneSignalUserID: Nullable<string> = localStorage.getItem(oneSignalUserIdKey);

      if (oneSignalUserID) {
        Sentry.captureMessage(`OneSignal user id (${oneSignalUserID}) is being sent to Hermes.`, {
          tags: {
            diagnostics: "oneSignalLogging"
          }
        });

        Notifications.Push.register(appName, oneSignalUserID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return null;
};

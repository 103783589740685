/* eslint-disable @typescript-eslint/no-explicit-any */

import { ComponentType, FC, ReactElement, useEffect, useState } from "react";
import * as _ from "lodash";
import { Nullable } from "@ctra/utils";

export interface ResolvedImportType {
  default: ComponentType<any>;
}

interface MediaQueryDynamicImportProps {
  /**
   * Mobile component prop to be lazily loaded
   */
  mobile: () => Promise<ResolvedImportType>;
  /**
   * Desktop component prop to be lazily loaded
   */
  desktop: () => Promise<ResolvedImportType>;
  /**
   * Use the children prop when the Mobile and Desktop view have the same API, else
   * use the renderMobile and renderDesktop
   */
  children?: (
    Component: ComponentType<Record<string, unknown>>,
    options: { isMobile: boolean }
  ) => ReactElement;
  /**
   * Render prop for mobile view
   */
  renderMobile?: (Component: ComponentType<Record<string, unknown>>) => ReactElement;
  /**
   * Render prop for desktop view
   */
  renderDesktop?: (Component: ComponentType<Record<string, unknown>>) => ReactElement;
}

const MediaQueryDynamicImport: FC<MediaQueryDynamicImportProps> = ({
  mobile,
  desktop,
  renderMobile,
  renderDesktop,
  children = () => null
}) => {
  const [ResolvedComponent, setResolvedComponent] = useState<Nullable<ResolvedImportType>>(null);

  /**
   * This media query matches the @media @phone query used in the less stylesheets
   */
  const isMobile = window.matchMedia("only screen and (max-width: 767px)").matches;

  useEffect(() => {
    const callback = isMobile ? mobile : desktop;

    callback().then((Component) => {
      setResolvedComponent(Component);
    });
  }, [mobile, desktop, isMobile]);

  const Component = ResolvedComponent ? ResolvedComponent.default : () => null;

  if (isMobile && _.isFunction(renderMobile)) {
    return renderMobile(Component);
  }

  if (!isMobile && _.isFunction(renderDesktop)) {
    return renderDesktop(Component);
  }

  return children(Component, { isMobile });
};

export default MediaQueryDynamicImport;

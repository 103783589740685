import { FC } from "react";

import { Barn, Icon, Spin, Tag, Typography } from "@ctra/components";
import { useFarmList } from "@farms";

import styles from "./FarmTag.module.less";

const { Text } = Typography;

/**
 * Farm tag
 * @param id
 * @return {JSX.Element}
 * @constructor
 */
export const FarmTag: FC<{ id: number }> = ({ id }) => {
  const { farmList, isLoading } = useFarmList();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const farm = farmList[id!];

  return isLoading ? (
    <Spin />
  ) : (
    <Tag color="purple" className={styles.Tag}>
      <Text type="secondary">
        <Icon component={Barn} /> {farm.name}
      </Text>
    </Tag>
  );
};

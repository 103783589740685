import * as _ from "lodash";

import { memoize } from "@ctra/utils";
import { Enterprise as App, EventStatus } from "@ctra/api";
import { Enterprise as Content, i18nTranslate } from "@ctra/i18n";

import { EventBase } from "./Base";

const {
  kpi: { displayName }
} = Content;

/**
 * Ida Classic label (group, farm or cow) event
 */
class AIModelEvent extends EventBase {
  /**
   * Get the metadata for the event
   */
  @memoize
  getContext(): {
    status: EventStatus;
  } {
    const {
      context: { impactTrackingState }
    } = this.entity;

    return {
      status: impactTrackingState as EventStatus
    };
  }

  @memoize
  getDescription(params?: Record<string, unknown>): string {
    const { description: originalDescription } = this.entity;
    const parts = _.trim(originalDescription).split(/\[|\]/);

    const metrics = _.mapValues(
      _.pick(_.keyBy(App.entities.getDataDictionary(App.store.getState()), "dataProperties.typeName"), parts),
      (v, k) => i18nTranslate(displayName(k))
    );

    return _.join(
      _.map(parts, (part) => metrics[part] || part),
      ""
    );
  }

  /**
   * Get the components for the events
   */
  @memoize
  getComponents(): ReturnType<typeof EventBase.prototype.getComponents> {
    return {
      ...super.getComponents(),
      ExpandIcon: () => null,
      CTA: () => null
    };
  }
}

export { AIModelEvent };

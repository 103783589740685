import React, { ComponentProps, ComponentType } from "react";
import * as _ from "lodash";
import { Space, Select } from "antd";

import { classname } from "@ctra/utils";

import styles from "./Dropdown.module.less";
import { Icon } from "../../../../elements";

export type DropdownProps = Omit<ComponentProps<typeof Select>, "onSelect" | "onChange"> & {
  /**
   * placeholder
   */
  placeholder?: string | React.ReactNode;
  /**
   * possible values
   */
  values: Array<{ value: string; label: string }>;
  /**
   * selected values
   */
  selection: string;
  /**
   * Handler to call when the user clicks on the list items
   */
  onChange: (selection: string) => void;
  /**
   * open state (mainly for unit tests)
   */
  open?: boolean;
  /**
   * filter options by input
   */
  filterOption?: ComponentProps<typeof Select>["filterOption"];
  /**
   * disabled
   */
  disabled?: ComponentProps<typeof Select>["disabled"];
  /**
   * Icon
   */
  icon?: ComponentType;
};

/**
 * Dropdown with simple API
 */
function Dropdown({
  values,
  selection,
  placeholder,
  onChange,
  open,
  icon,
  className,
  ...rest
}: React.PropsWithChildren<DropdownProps>): React.ReactElement {
  /**
   * Handle change of selection
   */
  const handleChange = (value: string) => {
    onChange(value);
  };

  return (
    <Space direction="horizontal" className={icon ? styles.Space : ""}>
      <Icon component={icon} className={styles.Icon} />
      <Select
        open={open}
        className={classname("ctra-charts-Dropdown", styles.Select, className)}
        // @ts-ignore
        value={selection}
        style={{ minWidth: 200 }}
        placeholder={placeholder}
        showArrow
        onChange={handleChange}
        {...rest}
      >
        {_.map(values, ({ label, value }) => (
          <Select.Option key={value} value={value}>
            {label}
          </Select.Option>
        ))}
      </Select>
    </Space>
  );
}

export default Dropdown;

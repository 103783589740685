import { FC } from "react";
import { AjinomotoTwoStepValidation } from "../AjinomotoTwoStepValidation";

/**
 * Feeding schedule insight validation
 * @return {React.ReactNode}
 * @constructor
 */
export const AjinomotoFeedingScheduleInsightValidation: FC = (props) => (
  <AjinomotoTwoStepValidation {...props} />
);

import * as _ from "lodash";

import { EventSourceType, ExtendedEventEntity } from "@ctra/api";
import { getInheritedPropertyNames } from "@ctra/utils";

import { GenericInsightEvent } from "./GenericInsightEvent";
import { CustomEvent } from "./CustomEvent";
import { AIModelEvent } from "./AIModelEvent";
import { EventBase } from "./Base";

type RegisteredEvents = typeof GenericInsightEvent | typeof CustomEvent | typeof AIModelEvent;

/**
 * A factory to create a nice API around the events
 * @example
 * ```ts
 * Factory.create(eventObject, extra, info, to, pass);
 * // instanceof GenericInsightEvent extends BaseEvent
 * ```
 */
class Factory {
  /**
   * Keep all the registered event here
   */
  private static _registry = new Map();

  /**
   * Register a class of event
   * @param source
   * @param cls
   */
  static register<I extends RegisteredEvents>(source: EventSourceType, cls: I): void {
    if (!Factory._registry.has(source)) {
      Factory._registry.set(source, cls);
    } else {
      throw new Error(`${source} is already set in the registry`);
    }
  }

  /**
   * Create an instance object
   * @param event
   * @param rest
   * @returns
   */
  static create<I extends RegisteredEvents>(event: ExtendedEventEntity, ...rest: unknown[]): InstanceType<I> {
    const type = _.get(event, ["source", "type"]);

    if (type) {
      const cls = Factory._registry.get(type);

      if (!cls) {
        throw new Error(`${type} is not yet in the registry`);
      }

      return new cls(event, ...rest);
    }

    console.error("Event type is not defined", event);
    throw new Error("Event type is not defined. See console.error for more details.");
  }

  /**
   * Serialize any event object to {} for allowing spread operations
   * @param instance
   */
  static serialize = <T extends InstanceType<RegisteredEvents>>(instance: T): Record<keyof T, T[keyof T]> =>
    _.reduce<keyof T, Record<keyof T, T[keyof T]>>(
      getInheritedPropertyNames(instance, EventBase) as Array<keyof T>,
      (result, key) => {
        // @ts-ignore - binding is necessary
        result[key] = _.isFunction(instance[key]) ? instance[key].bind(instance) : instance[key];

        return result;
      },
      {} as Record<keyof T, T[keyof T]>
    );
}

Factory.register(EventSourceType.genericInsight, GenericInsightEvent);
Factory.register(EventSourceType.customEvent, CustomEvent);
Factory.register(EventSourceType.genericLabel, CustomEvent);
Factory.register(EventSourceType.aiModel, CustomEvent);

export { Factory };

import { makeTestIds } from "@ctra/utils";

/**
 * Make test ids for game plans
 */
export const testIds = makeTestIds("timeline", {
  createEvent: {
    wizard: {
      container: "container"
    },
    modal: {
      desktop: "desktop",
      mobile: "mobile"
    },
    question: {
      rollback: "rollback"
    },
    labelPicker: {
      container: "container",
      addNewButton: "addNewButton",
      dropdown: "dropdown",
      dropdownToggle: "dropdownToggle",
      label: "label",
      category: "category",
      customLabel: {
        input: "input",
        submit: "submit"
      },
      inactive: {
        tag: "tag"
      }
    },
    datepicker: {
      radio: "radio"
    },
    herdGroupSelector: {
      inactive: "inactive",
      select: "select"
    },
    description: {
      textarea: "textarea",
      inactive: "inactive",
      submit: "submit"
    },
    summary: {
      description: "description"
    }
  },
  cta: {
    wrapper: "wrapper",
    button: {
      delete: "delete"
    }
  },
  entity: {
    entry: {
      description: "description",
      view: "view",
      wrapper: "wrapper"
    }
  },
  expandIcon: "expandIcon",
  list: {
    header: "header",
    wrapper: "wrapper"
  }
});

import { useSelector } from "react-redux";
import * as _ from "lodash";

import { Enterprise, DataDescriptorEntity, EnterpriseAppState, FarmEntity } from "@ctra/api";

/**
 * Tell which of the given farms have support for the given KPI
 * @param dataDescriptorID
 * @param farmIDList
 */
export const useKPISupport = (
  dataDescriptorID: DataDescriptorEntity["id"],
  farmIDList: Array<FarmEntity["id"]> = []
): Record<"hasSupport" | "hasNoSupport", Array<FarmEntity["id"]>> => {
  /**
   * Resolve data descriptor from data descriptor id
   */
  const dataDescriptor = useSelector<EnterpriseAppState, DataDescriptorEntity>((state) =>
    Enterprise.entities.getDataDescriptor(state, { id: dataDescriptorID })
  );

  if (!dataDescriptor) {
    throw new Error(`Data descriptor "${dataDescriptorID}" could not be located in the store.`);
  }

  /**
   * Get all the farm ids which are associated with the app
   */
  const availableFarmIDList = useSelector<EnterpriseAppState, Array<FarmEntity["id"]>>((state) =>
    _.map(Enterprise.entities.getFarmList(state), "id")
  );

  const { supportedFarms } = dataDescriptor;

  /**
   * Tell which farms support the KPI
   */
  const supportedBy = _.intersection(
    _.isEmpty(farmIDList) ? availableFarmIDList : farmIDList,
    supportedFarms
  );

  /**
   * Tell which farms does not support the KPI
   */
  const blockedBy = _.difference(_.isEmpty(farmIDList) ? availableFarmIDList : farmIDList, supportedFarms);

  return {
    hasSupport: supportedBy,
    hasNoSupport: blockedBy
  };
};

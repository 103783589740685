import React, { FC } from "react";

import { DownOutlined } from "@ctra/components";

interface CustomEventExpandIconProps {
  /**
   * Angle of rotation for the icon
   */
  rotate: number;
}

/**
 * Custom event expand icon
 * @param {React.PropsWithChildren<CustomEventExpandIconProps>} props
 * @return {JSX.Element}
 * @constructor
 */
export const CustomEventExpandIcon: FC<CustomEventExpandIconProps> = ({ rotate }) => {
  return <DownOutlined rotate={rotate} />;
};

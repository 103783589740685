import { FC, useEffect, useContext, createContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EnterpriseAppState, InsightTypeEntity, Insights, Enterprise } from "@ctra/api";
import { isDispatched, isPending } from "@ctra/utils";

type ContextType = {
  insightTypes: Record<InsightTypeEntity["typeName"], InsightTypeEntity>;
  meta: {
    isLoading: boolean;
  };
};

/**
 * Make a default context
 */
const DefaultContext = createContext<ContextType>({
  insightTypes: {},
  meta: {
    isLoading: true
  }
});

/**
 * Fetch the insight types from the API
 * @param children
 */
const _InsightTypeListProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  const insightTypeList = useSelector<EnterpriseAppState, ContextType["insightTypes"]>(
    Enterprise.entities.getInsightTypes
  );

  const hasFetched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, Insights.types.FETCH_INSIGHT_TYPES)
  );

  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Insights.types.FETCH_INSIGHT_TYPES)
  );

  useEffect(() => {
    if (!hasFetched) {
      dispatch(Insights.actions.fetchInsightTypes.start());
    }
  }, [dispatch, hasFetched]);

  return (
    <DefaultContext.Provider value={{ insightTypes: insightTypeList, meta: { isLoading } }}>
      {children}
    </DefaultContext.Provider>
  );
};

export const InsightTypeContext = {
  Provider: _InsightTypeListProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Make a hook to access the context in FCs
 */
export const useInsightTypes = (): ContextType => useContext(DefaultContext);

export const insights = {
  _root: "/insights",
  manage: {
    _root: "/manage",
    farm: {
      _root: "/:farmID",
      enable: "/enable",
      insight: {
        _root: "/:dataDescriptorID",
        edit: { _root: "/edit", group: "/:group" }
      }
    }
  }
};

import { makeTestIds } from "@ctra/utils";

/**
 * Make test ids for hocs
 */
export const testIds = makeTestIds("hocs", {
  withChartWrapper: {
    wrapper: "wrapper"
  },
  withSeriesColor: {
    wrapper: "wrapper"
  },
  withLegend: {
    wrapper: "wrapper"
  }
});

import { FC, Key, useState, useRef, createRef } from "react";
import * as _ from "lodash";

import {
  Col,
  Row,
  Analytics,
  Charts,
  DndProvider,
  HTML5Backend,
  CtraLayout,
  Button,
  Modal,
  Space,
  DeleteOutlined
} from "@ctra/components";

import { GAEvent } from "@ctra/analytics";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { Enterprise, SavedCardState, SavedCardsPayload } from "@ctra/api";
import { isMutableRef, resizeBase64Image } from "@ctra/utils";

import { CompiledRoutes } from "@routes";

import { useFarm } from "@farms";
import { useDataDictionary } from "@base";
import { GACategories, formatFilters, useSavedCharts } from "@analytics";
import { Chart, ChartAPIContext, ChartFilterContext } from "@chart";

import { commonModalProps } from "../DashboardPage";
import styles from "./ChartList.module.less";
import { useDeepCompareEffect } from "use-deep-compare";

const {
  V3: { ChartCard, Empty }
} = Analytics;

const { ContentWrapper } = CtraLayout;
const { confirm } = Modal;
interface ChartListProps {
  charts: SavedCardsPayload;
  groupID: string;
  groupName: string;
}

/**
 * List of chart cards
 * @returns
 */
export const ChartList: FC<ChartListProps> = ({ charts, groupID, groupName }) => {
  const { t } = useTranslation();
  const { farm } = useFarm();
  const [order, setOrder] = useState<Key[]>([]);

  const {
    dataDescriptors,
    api: { extractSource, extractChartInfo },
    meta: { isLoading }
  } = useDataDictionary();

  const {
    api: { removeChart, updateChartOrder }
  } = useSavedCharts();

  const {
    analytics: {
      config: {
        metric: { displayName },
        variant: variantCopy,
        source: sourceCopy,
        pageTitle
      },
      v3: {
        config: { cta },
        empty,
        confirm: { removeChart: removeChartCopy }
      }
    },
    gamePlan: {
      actions: {
        delete: { confirm: confirmCopy }
      }
    }
  } = Content;

  /**
   * Route to configurator with the chart
   */
  const handleClick = (chartID: SavedCardState["id"]) => {
    if (chartID) {
      Enterprise.history.push({
        state: { editChart: { chartID, dashboard: groupID } }
      });
    }
  };

  const moveNode = (dragKey: Key, hoverKey: Key) => {
    const newOrder = order.slice();

    charts.forEach((item) => {
      if (item.id && newOrder.indexOf(item.id) === -1) {
        newOrder.push(item.id);
      }
    });

    const dragIndex = newOrder.indexOf(dragKey);
    const hoverIndex = newOrder.indexOf(hoverKey);

    newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, dragKey);

    updateChartOrder(newOrder, groupID);
    setOrder(newOrder);
  };

  /**
   * Delete handler with pop confirm
   * @param chartID
   * @param groupID
   */
  const handleDelete = (chartID: string, groupID: string, metric: string) => {
    /**
     * Opens up a confirmation modal
     */
    confirm({
      ...commonModalProps,
      okText: t(confirmCopy.yes),
      cancelText: t(confirmCopy.no),
      title: (
        <Space>
          <DeleteOutlined />
          {t(removeChartCopy.title)}
        </Space>
      ),
      content: t(removeChartCopy.description),
      onOk() {
        GAEvent(GACategories.dashboards, "Remove metric", JSON.stringify({ metric, dashboard: groupName }));

        removeChart(chartID, groupID);
      }
    });
  };

  /**
   * Order the charts based on the provided order state
   */
  const orderedCharts = _.sortBy(charts, (chart) => {
    const orderIndex = _.indexOf(order, chart.id);
    return orderIndex !== -1 ? orderIndex : charts.indexOf(chart);
  });

  const [refs, setRefs] = useState<Record<string, any>>(
    _.reduce(orderedCharts, (acc, { id }) => ({ ...acc, [id]: createRef() }), {})
  );

  useDeepCompareEffect(() => {
    setRefs((prevState) => ({
      ..._.reduce(orderedCharts, (acc, { id }) => ({ ...acc, [id]: createRef() }), {}),
      ..._.omit(prevState, _.difference(_.keys(prevState), _.map(orderedCharts, "id")))
    }));
  }, [orderedCharts]);

  return (
    <Row gutter={[24, 24]}>
      {!isLoading &&
        !_.isEmpty(dataDescriptors) &&
        _.map(orderedCharts, (savedCard) => {
          const { id, variantId: variantID, dataFilters, displayFilters, dateRange, chartType } = savedCard;
          const chart = extractSource(variantID);
          const chartInfo = chart && extractChartInfo(chart);
          /**
           * dateRange can either b iso duration in an array e.g ["P1M"] or a custom range e.g. ["2023-05-01", "2023-06-01"]
           * Supply props to api context based on length of array
           */
          const isoDuration = dateRange && _.size(dateRange) === 1 ? _.first(dateRange) : null;
          const [startDate, endDate] = dateRange && _.size(dateRange) === 2 ? dateRange : [];
          const durationProps = isoDuration
            ? { isoDuration }
            : startDate && { timePeriod: { startDate, endDate }, isoDuration: null };

          /**
           * Filters
           */
          const filters = dataFilters && formatFilters(dataFilters);
          const seriesFilter = _.find(displayFilters, { type: "series" });
          const seriesValues = _.get(seriesFilter, "values", []);

          const metricDisplayName = chartInfo?.nameToDisplay
            ? t(displayName(chartInfo?.nameToDisplay), { makeDefaultValue: true })
            : "";

          return (
            chart && (
              <Col
                /**
                 * Using ID doesn't re-render the component with filter/daterange changes
                 */
                key={JSON.stringify(savedCard)}
                lg={12}
                span={24}
                data-gtm-category={GACategories.configurator}
                data-gtm-action="Go to chart configurator"
                data-gtm-info={JSON.stringify({ metric: metricDisplayName })}
              >
                <ChartFilterContext.Provider series={seriesValues} filters={filters} {...durationProps}>
                  <ChartFilterContext.Consumer>
                    {({ timePeriod }) => (
                      <ChartAPIContext.Provider
                        dataDescriptorID={variantID}
                        farmID={farm?.id}
                        defaultView={chartType}
                      >
                        <ChartAPIContext.Consumer>
                          {({
                            chart,
                            dataDescriptor: {
                              dataProperties: { typeName }
                            }
                          }) => (
                            <DndProvider backend={HTML5Backend}>
                              <ChartCard
                                onClick={(e) => {
                                  Enterprise.history.push(
                                    CompiledRoutes.app.analytics.dashboard.browse.chart({
                                      dashboard: groupID,
                                      chartID: id
                                    })
                                  );
                                }}
                                handleReport={
                                  isMutableRef<any>(refs[id])
                                    ? async (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        const base64 = await resizeBase64Image(
                                          refs[id].current.toDataURL("image/png"),
                                          1280
                                        );

                                        Enterprise.history.push({
                                          state: {
                                            feedback: {
                                              sourceID: chart.id,
                                              sourceType: "Chart",
                                              context: {
                                                timePeriod,
                                                typeName
                                              },
                                              base64Content: base64
                                            }
                                          }
                                        });
                                      }
                                    : void 0
                                }
                                index={id}
                                className={styles.Card}
                                metric={metricDisplayName}
                                variant={chartInfo?.variantType ? t(variantCopy(chartInfo?.variantType)) : ""}
                                source={chartInfo?.sourceName && t(sourceCopy(chartInfo?.sourceName))}
                                labels={{
                                  remove: t(cta.save, { isSaved: true }),
                                  edit: t<string>(pageTitle),
                                  report: t<string>(cta.report)
                                }}
                                handleDrag={moveNode}
                                data-gtm-info={JSON.stringify({
                                  metric: metricDisplayName,
                                  dashboard: groupName
                                })}
                                handleConfigure={(event) => {
                                  event.stopPropagation();
                                  if (_.toLower(_.get(event, ["target", "tagName"])) !== "canvas") {
                                    handleClick(id);
                                  }
                                }}
                                handleDelete={(e) => {
                                  e.stopPropagation();
                                  handleDelete(id, groupID, metricDisplayName);
                                }}
                              >
                                <Col flex={1} style={{ padding: 0 }}>
                                  <Chart
                                    variant={Charts.ChartVariant.Embedded}
                                    ref={refs[id]}
                                    config={{
                                      onReady: (plot) => {
                                        if (refs[id]) {
                                          refs[id].current = plot;
                                        } else {
                                          console.warn("Ref not found");
                                        }
                                      }
                                    }}
                                  />
                                </Col>
                              </ChartCard>
                            </DndProvider>
                          )}
                        </ChartAPIContext.Consumer>
                      </ChartAPIContext.Provider>
                    )}
                  </ChartFilterContext.Consumer>
                </ChartFilterContext.Provider>
              </Col>
            )
          );
        })}
      <Col
        lg={12}
        span={24}
        data-gtm-category={GACategories.configurator}
        data-gtm-action="Go to chart selector from empty"
        data-gtm-info={JSON.stringify({ dashboard: groupName })}
      >
        <ContentWrapper>
          <Empty
            className={styles.Empty}
            variant="charts"
            title={t(empty.charts.title)}
            description={
              <Button
                type="link"
                onClick={() =>
                  Enterprise.history.push({
                    pathname: CompiledRoutes.app.analytics.dashboard.addChart.index({ dashboard: groupID })
                  })
                }
              >
                {t(empty.charts.cta)}
              </Button>
            }
          />
        </ContentWrapper>
      </Col>
    </Row>
  );
};

import { createContext, FC, useContext, useEffect } from "react";
import * as _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useFarm } from "@farms";
import { Enterprise, EnterpriseAppState, User, UserList } from "@ctra/api";
import { isDispatched, isPending } from "@ctra/utils";

interface ContextType {
  users: UserList;
  meta: {
    isLoading: boolean;
  };
}

const DefaultContext = createContext<ContextType>({
  users: {},
  meta: {
    isLoading: true
  }
});

/**
 * User list context provider
 * @param {"compat" | "consent"} source
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _UserListContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const { farm } = useFarm();
  const farmId = _.defaultTo(farm?.id, -1);

  /**
   * Get all the users of the app
   */
  const users = useSelector<EnterpriseAppState, UserList>((state) =>
    Enterprise.entities.getUserList(state, { farmID: farm?.id })
  );

  /**
   * Tell whether the fetching action has been dispatched
   */
  const dispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, User.types.FETCH_USER_LIST, { primaryValue: farmId })
  );

  /**
   * Tell if the data request is in progress
   */
  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, User.types.FETCH_USER_LIST, { primaryValue: farmId })
  );

  useEffect(() => {
    if (!dispatched) {
      dispatch(User.actions.fetchUserList.start(farmId > -1 ? "consent" : "compat", farmId));
    }
  }, [dispatch, dispatched, farmId]);

  return (
    <DefaultContext.Provider
      value={{
        users,
        meta: { isLoading }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const UserListContext = {
  Provider: _UserListContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Make a hook to access the context in FCs
 */
export const useUserList = (): ContextType => useContext(DefaultContext);

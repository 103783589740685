import { Component, ErrorInfo } from "react";

import { Result, Button } from "@ctra/components";
import { withTranslation, WithTranslation, Enterprise } from "@ctra/i18n";
import { Enterprise as App } from "@ctra/api";

interface State {
  hasError: boolean;
}

/**
 * Copy-paste error boundary from the react docs
 */
class Boundary extends Component<WithTranslation> {
  state: State;

  constructor(props: WithTranslation) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error): State {
    return {
      hasError: true
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    /**
     * @todo log to Sentry
     */
  }

  render() {
    const { t } = this.props;
    const { title, subtitle, action } = Enterprise.errors.jsError;

    if (this.state.hasError) {
      return (
        <Result
          status="error"
          title={t<string>(title)}
          subTitle={t<string>(subtitle)}
          extra={
            <Button
              type="primary"
              onClick={() => {
                App.store.reset();
                window.location.reload();
              }}
            >
              {t<string>(action)}
            </Button>
          }
        />
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withTranslation()(Boundary);

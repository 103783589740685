import { FC, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";

import {
  EnterpriseAppState,
  Invitation,
  ReferralFormValues,
  Breadcrumbs,
  Enterprise as API,
  ReferralType
} from "@ctra/api";

import {
  CtraLayout,
  message,
  Row,
  Col,
  Alert,
  Button,
  Typography,
  Form,
  Input,
  MailOutlined
} from "@ctra/components";

import { useTranslation, Enterprise as Content } from "@ctra/i18n";
import { isPending, isFulfilled, useDidMount, isRejected, Nullable } from "@ctra/utils";

import { Routes } from "@routes";
import { useUserPreferences } from "@base";
import { GACategories } from "@network";

import styles from "./RequestAccessPage.module.less";

const { WidgetWrapper, ContentWrapper } = CtraLayout;
const { Title, Paragraph } = Typography;

const {
  components: { Breadcrumb }
} = Breadcrumbs;

const {
  overview: {
    inviteFarm: { label }
  },
  settings: {
    users: {
      title,
      description,
      form: { error, success, submit, cancel, placeholders }
    }
  }
} = Content;

/**
 * Invite new user or edit existing invitation
 * @return {JSX.Element}
 * @constructor
 */
export const RequestAccessPage: FC = () => {
  const [mayRedirect, setMayRedirect] = useState<boolean>(false);
  const [isMounting] = useDidMount();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    preferences: { sandbox }
  } = useUserPreferences();

  /**
   * Check if the form is currently submitting
   * @type {boolean}
   */
  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Invitation.types.CREATE_INVITATION)
  );

  /**
   * Check if the form has been submitted successfully
   * @type {boolean}
   */
  const isSuccessful = useSelector<EnterpriseAppState, boolean>((state) =>
    isFulfilled(state, Invitation.types.CREATE_INVITATION)
  );

  /**
   * Check if the form submission has been rejected
   */
  const [isRequestRejected, payload] = useSelector<
    EnterpriseAppState,
    [boolean, Nullable<{ error: string; statusCode: number }>]
  >((state) =>
    isRejected(state, Invitation.types.CREATE_INVITATION, {
      withPayload: true
    })
  );

  /**
   * Display a message to the user based on the form submission status
   */
  useEffect(() => {
    if (isRequestRejected) {
      setMayRedirect(false);
    }

    if (isSuccessful) {
      message.success(t<string>(success, { variant: "create" }));
      setMayRedirect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRequestRejected, isSuccessful]);

  return mayRedirect && !isMounting && !isLoading ? (
    <Redirect to={Routes.app.network.index} />
  ) : (
    <WidgetWrapper
      className={styles.Wrapper}
      loading={isLoading}
      title={
        <section>
          <Title level={5}>{t<string>(title, { referralType: ReferralType.userToFarmer })}</Title>
          <Paragraph>{t<string>(description, { referralType: ReferralType.userToFarmer })}</Paragraph>
        </section>
      }
    >
      <Breadcrumb
        path={Routes.app.network.requestAccess}
        title={t<string>(title, { referralType: ReferralType.userToFarmer })}
      />
      <Formik<Pick<ReferralFormValues, "email">>
        initialValues={{
          email: ""
        }}
        onSubmit={({ email }, { setSubmitting }) => {
          dispatch(Invitation.actions.requestFarmAccess.start(email));
          setSubmitting(false);
        }}
      >
        {({ submitForm, values: { email }, isSubmitting }) => {
          return (
            <ContentWrapper
              footer={
                <section>
                  <Row gutter={[16, 0]}>
                    <Col>
                      <Button
                        type="primary"
                        data-gtm-category={GACategories.network}
                        data-gtm-action="Submit referral form - request access to farm"
                        disabled={!!sandbox.isEnabled || !email || isSubmitting}
                        loading={isSubmitting}
                        htmlType="submit"
                        onClick={submitForm}
                      >
                        {t<string>(submit, {
                          variant: "create"
                        })}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        data-gtm-category={GACategories.network}
                        data-gtm-action="Cancel request access to farm"
                        onClick={() => API.history.push(Routes.app.network.index)}
                      >
                        {t<string>(cancel)}
                      </Button>
                    </Col>
                  </Row>
                </section>
              }
            >
              <Form layout="vertical" requiredMark="optional">
                <Form.Item required name="email" label={t<string>(label)}>
                  <Input
                    required
                    type="email"
                    disabled={isSubmitting}
                    prefix={<MailOutlined />}
                    placeholder={t<string>(placeholders.email)}
                    name="email"
                    data-gtm-category={GACategories.network}
                    data-gtm-action="Email"
                  />
                </Form.Item>
              </Form>
              {isRequestRejected && (
                <Alert
                  closable
                  type="error"
                  message={t<string>(error, {
                    error: payload?.error,
                    statusCode: payload?.statusCode
                  })}
                />
              )}
            </ContentWrapper>
          );
        }}
      </Formik>
    </WidgetWrapper>
  );
};

import { memoize } from "@ctra/utils";

import { CustomEventView, CustomEventCta, CustomEventExpandIcon, ImpactTrackedEventPage } from "@events";
import { EventStatus } from "@ctra/api";

import { EventBase } from "./Base";

/**
 * Ida Classic label (group, farm or cow) event
 */
class CustomEvent extends EventBase {
  /**
   * Get the metadata for the event
   */
  @memoize
  getContext(): {
    status: EventStatus;
  } {
    const {
      context: { impactTrackingState }
    } = this.entity;

    return {
      status: impactTrackingState as EventStatus
    };
  }

  /**
   * Get the components for the events
   */
  @memoize
  getComponents(): ReturnType<typeof EventBase.prototype.getComponents> {
    return {
      Page: ImpactTrackedEventPage,
      ExpandIcon: CustomEventExpandIcon,
      View: CustomEventView,
      CTA: CustomEventCta
    };
  }
}

export { CustomEvent };

import { FC, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { GAContext } from "@ctra/analytics";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { Breadcrumbs } from "@ctra/api";
import { CtraLayout, Typography, Alert, Row, Spin, LoadingOutlined } from "@ctra/components";

import { Routes } from "@routes";
import { UserPreferences } from "@base";
import { InsightNotificationContext, DataIntegrationsContext, GACategories } from "@settings";
import { GACategories as InsightsGACategories } from "@insights";
import { useCurrentUser } from "@auth";
import { useFarm } from "@farms";

import styles from "./SettingsPage.module.less";

const Notifications = lazy(() => import("../Notifications"));
const DataIntegrations = lazy(() => import("../DataIntegrations"));
const Insights = lazy(() => import("../Insights"));

const SentryRoute = Sentry.withSentryRouting(Route);
const { WidgetWrapper, ContentWrapper } = CtraLayout;
const { Paragraph } = Typography;

const {
  navigation: { main },
  settings: {
    accountDetails,
    notifications,
    dataIntegrations,
    dataIntegrations: { alerts }
  }
} = Content;

/**
 * Settings page
 * @constructor
 */
const SettingsPage: FC = () => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const { farm } = useFarm();

  const routes = Routes.app.settings;

  return (
    <Suspense
      fallback={
        <Row justify="center" align="middle" style={{ height: "50vh" }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />} />
        </Row>
      }
    >
      <section className={styles.PageWrapper}>
        <Switch>
          <SentryRoute path={Routes.app.insights.manage.index}>
            <GAContext.Provider
              value={{ category: `${GACategories.settings} - ${InsightsGACategories.kpiInsights}` }}
            >
              <Insights />
            </GAContext.Provider>
          </SentryRoute>
          <SentryRoute>
            <Breadcrumbs.components.Breadcrumb path={routes.index} title={t<string>(main.settings)} />
            <WidgetWrapper title={t<string>(accountDetails.title)}>
              <ContentWrapper>
                <Paragraph strong className={styles.Label}>
                  {t<string>(accountDetails.user.name)}
                </Paragraph>
                <Paragraph>{[user.firstName, user.lastName].join(" ")}</Paragraph>
                <Paragraph strong className={styles.Label}>
                  {t<string>(accountDetails.user.email)}
                </Paragraph>
                <Paragraph>{user.username}</Paragraph>
                <Paragraph strong className={styles.Label}>
                  {t<string>(accountDetails.user.locale)}
                </Paragraph>
                <UserPreferences />
              </ContentWrapper>
            </WidgetWrapper>
            <InsightNotificationContext.Provider>
              <InsightNotificationContext.Consumer>
                {({ meta: { isLoading } }) => (
                  <WidgetWrapper loading={isLoading} title={t<string>(notifications.title)}>
                    <Notifications />
                  </WidgetWrapper>
                )}
              </InsightNotificationContext.Consumer>
            </InsightNotificationContext.Provider>
            {farm?.id ? (
              <DataIntegrationsContext.Provider farmID={farm.id}>
                <WidgetWrapper title={t<string>(dataIntegrations.title)}>
                  <DataIntegrations />
                </WidgetWrapper>
              </DataIntegrationsContext.Provider>
            ) : (
              <WidgetWrapper title={t<string>(dataIntegrations.title)}>
                <Alert
                  type="info"
                  showIcon
                  message={t<string>(alerts.selectFarm.title)}
                  description={t<string>(alerts.selectFarm.description)}
                />
              </WidgetWrapper>
            )}
          </SentryRoute>
        </Switch>
      </section>
    </Suspense>
  );
};

export default SettingsPage;

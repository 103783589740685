import { FC, memo } from "react";
import * as _ from "lodash";
import { compose } from "lodash/fp";

import { Column as AntBar } from "@ant-design/charts";

import { useBarConfig } from "./useBarConfig";

import { withChartWrapper, withSeriesColor } from "../../../hocs";

/**
 * Bar Component
 * @returns
 */
const BarComponent: FC = memo(
  () => {
    const { config } = useBarConfig();

    return <AntBar {...config} />;
  },
  (prev, next) => _.isEqual(prev, next)
);

BarComponent.displayName = "Bar";

/**
 * Full Bar chart
 */
export const Bar: FC = compose(withChartWrapper, withSeriesColor)(BarComponent);

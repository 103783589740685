import { ComponentProps, FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import { Button, useSpinner } from "@ctra/components";
import { Auth, Enterprise, EnterpriseAppState, Session, TokenEntity } from "@ctra/api";
import { classname, isFulfilled, isPending, isProduction, Nullable } from "@ctra/utils";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";

import { useUserPreferences } from "@base";

import styles from "./SandboxFlipSwitch.module.less";

/**
 * Hook to handle the sandbox flip switch mechanism
 * @return {readonly [(null | undefined | boolean), (() => void)]}
 */
export const useSandboxFlipSwitch = (): readonly [null | undefined | boolean, () => void] => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);

  const {
    ui: { loaders }
  } = Content;

  const {
    api: { toggle }
  } = useSpinner();

  const {
    api: { setUserPreferences },
    meta: { isLoading, isUpdating, delayed },
    preferences: {
      sandbox: { isEnabled }
    }
  } = useUserPreferences();

  /**
   * Store the previous value of the sandbox switch
   * @type {React.MutableRefObject<boolean>}
   */
  const switchRef = useRef<Nullable<boolean>>(isEnabled);

  /**
   * Get the access token from the store
   */
  const { refresh, accessToken } = useSelector<EnterpriseAppState, TokenEntity>(Enterprise.entities.getToken);

  /**
   * Tell if the token has been refreshed
   * @type {boolean}
   */
  const isTokenRefreshed = useSelector<EnterpriseAppState, boolean>((state) =>
    isFulfilled(state, Auth.types.RENEW_TOKEN)
  );

  /**
   * Tell if the token is being refreshed
   * @type {boolean}
   */
  const isTokenRefreshing = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Auth.types.RENEW_TOKEN)
  );

  /**
   * Tell if the session has been reset
   * @type {boolean}
   */
  const isSessionReset = useSelector<EnterpriseAppState, boolean>(Enterprise.entities.isSessionReset);

  /**
   * Set the sandbox preferences
   */
  const handleSwitchFlip = () => {
    setClicked(true);

    setUserPreferences({
      sandbox: {
        isEnabled: !isEnabled
      }
    });
  };

  /**
   * Toggle the spinner on the app
   */
  useEffect(() => {
    const switchFlipping = delayed && (isLoading || isUpdating);

    toggle("sandboxFlipSwitch", isTokenRefreshing || switchFlipping, {
      size: "large",
      tip: switchFlipping
        ? t<string>(loaders.longRequest)
        : isTokenRefreshing
        ? t<string>(loaders.renewToken)
        : void 0
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenRefreshing, isLoading, isUpdating, delayed]);

  /**
   * Refresh the token if the switch has been flipped
   */
  useEffect(
    () => {
      if (_.isBoolean(switchRef.current) && switchRef.current !== isEnabled) {
        dispatch(Auth.actions.renewToken.start(accessToken, refresh, null));
      }

      /**
       * Before/while fetching the user prefs, ths value is set to null.
       * With this we enable the switch.
       * @type {null | undefined | boolean}
       */
      switchRef.current = isEnabled;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEnabled, switchRef.current]
  );

  /**
   * Reset the session if the token has been refreshed
   */
  useEffect(() => {
    if (clicked && isTokenRefreshed) {
      dispatch(Session.actions.reset());
      setClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenRefreshed]);

  /**
   * In production, reload the app with a new subdomain
   */
  useEffect(() => {
    if (isSessionReset && window) {
      toggle("reload", true, {
        size: "large",
        tip: t<string>(loaders.reload)
      });

      if (isProduction()) {
        window.location.assign(`https://${isEnabled ? "demo." : ""}app.connecterra.ai`);
      } else {
        window.location.reload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSessionReset]);

  return [isEnabled, handleSwitchFlip] as const;
};

/**
 * Sandbox flip switch button
 * @param {React.PropsWithChildren<{}>} props
 * @returns {JSX.Element}
 * @constructor
 */
export const SandboxFlipSwitch: FC<ComponentProps<typeof Button>> = (props) => {
  const { t } = useTranslation();
  const [sandboxEnabled, handleSwitchFlip] = useSandboxFlipSwitch();

  const {
    navigation: {
      demo: { on, off }
    }
  } = Content;

  const textOn = t<string>(on);
  const textOff = t<string>(off);

  return (
    <Button
      {...props}
      data-gtm-category="Demo farm"
      data-gtm-action="Toggle demo farm"
      className={classname(styles.Button, sandboxEnabled ? styles.Enabled : null)}
      onClick={handleSwitchFlip}
    >
      {sandboxEnabled ? textOff : textOn}
    </Button>
  );
};

import { FC } from "react";
import * as _ from "lodash";

import {
  Button,
  Col,
  DeleteOutlined,
  Popconfirm,
  Row,
  Table,
  TableProps,
  Typography
} from "@ctra/components";

import { ReferralEntity, ReferralStatus, ReferralType } from "@ctra/api";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";

import { useInvitation, useUserPreferences } from "@base";
import { useCurrentUser } from "@auth";

const { Text } = Typography;
const { Column } = Table;

const {
  settings: {
    users: {
      actions: { confirmDelete },
      table: { values: tableValues, columns: columnHeaderCopy }
    }
  }
} = Content;

/**
 * Table view of referrals
 * @param {React.PropsWithChildren<TableProps<ReferralEntity | UserEntity>>} props
 * @returns {JSX.Element}
 * @constructor
 */
export const FarmReferralTable: FC<TableProps<ReferralEntity>> = (props) => {
  const { t } = useTranslation();
  const { user: currentUser } = useCurrentUser();
  const isFarmer = !!currentUser.farmAccess?.farmId;

  const {
    api: { handleDelete },
    meta: { isLoading }
  } = useInvitation();

  const getPointerName = (entity: ReferralEntity) => {
    return isFarmer && entity.type === ReferralType.farmerToUser ? "sourceUser" : "targetUser";
  };

  const {
    preferences: { sandbox }
  } = useUserPreferences();

  return (
    <Table<ReferralEntity> {...props}>
      <Column<ReferralEntity>
        width="50%"
        title={t<string>(columnHeaderCopy.email)}
        dataIndex="email"
        key="email"
        sorter={(a, b) =>
          _.defaultTo(a[getPointerName(a)].email, "").localeCompare(
            _.defaultTo(b[getPointerName(b)].email, "")
          )
        }
        render={(value, entity) => {
          const user = entity[getPointerName(entity)];

          return _.trim(user.email);
        }}
      />
      <Column<ReferralEntity>
        width="50%"
        title={t<string>(columnHeaderCopy.invitation)}
        dataIndex="status"
        key="status"
        render={(value, entity) => {
          const user = entity[getPointerName(entity)];
          const received = currentUser.email === user.email;
          const { id } = entity;

          return (
            <Row justify="space-between">
              <Col>
                <Text>{t<string>(tableValues.status(value), { received })}</Text>
              </Col>
              {value === ReferralStatus.sent && (
                <Col>
                  {/*<Popconfirm*/}
                  {/*  placement="left"*/}
                  {/*  title={t<string>(confirmDelete.text)}*/}
                  {/*  okButtonProps={{ loading: isLoading }}*/}
                  {/*  onConfirm={() => {*/}
                  {/*    // GA log in context*/}
                  {/*    handleDelete(id);*/}
                  {/*  }}*/}
                  {/*  okText={t<string>(confirmDelete.ok)}*/}
                  {/*  cancelText={t<string>(confirmDelete.cancel)}*/}
                  {/*>*/}
                  {/*  <Button*/}
                  {/*    disabled={!!sandbox.isEnabled || isLoading}*/}
                  {/*    type="link"*/}
                  {/*    icon={<DeleteOutlined />}*/}
                  {/*  />*/}
                  {/*</Popconfirm>*/}
                </Col>
              )}
            </Row>
          );
        }}
      />
    </Table>
  );
};

export enum GACategories {
  help = "Help",
  productUpdates = "Product updates",
  sidebarMenu = "Sidebar menu",
  userPreferencesSelector = "User preferences selector",
  fab = "Floating action button",
  mainFarmDropdown = "Main farm dropdown",
  gettingStarted = "Getting started"
}

export enum GAActions {
  backToAllFarms = "Back to all farms",
  collapseSidebar = "Collapse sidebar",
  expandSidebar = "Expand sidebar",
  goToAnalytics = "Go to Analytics",
  goToIOFC = "Go to IOFC",
  goToHelpCenter = "Go to Help Center",
  goToInsights = "Go to Insights",
  goToOverview = "Go to Overview",
  goToSettings = "Go to Settings",
  goToMyConnections = "Go to My Connections",
  goToTimeline = "Go to Timeline",
  goToSummaries = "Go to Farm Summaries",
  openAccountDropdown = "Open account dropdown",
  openChat = "Open chat",
  closeChat = "Close chat",
  openFarmSelector = "Open farm selector",
  closeFarmSelector = "Close farm selector",
  openUserPreferencesSelector = "Open user preferences selector",
  selectLanguage = "Select language",
  selectUnitSystem = "Select unit system",
  openFab = "Open FAB",
  closeFab = "Close FAB",
  dragFab = "Drag FAB out of the way"
}

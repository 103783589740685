import { ComponentProps, FC } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Epoch, isPending, TS } from "@ctra/utils";
import { Enterprise, useTranslation } from "@ctra/i18n";
import { EnterpriseAppState, InsightWorkflowState, Insights } from "@ctra/api";
import { Menu, Insights as InsightsUI } from "@ctra/components";

import { useInsight } from "@insights";

const {
  Dialog: { StatusDropdown }
} = InsightsUI;

/**
 * A dropdown for changing insight state, remains disabled in "To-Check"
 */
export const InsightStateSwitcher: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    insight: { workflowState: currentState, id }
  } = useInsight();

  const {
    insights: {
      workflowState: { toCheck, toFollowUp, done }
    }
  } = Enterprise;

  const insightStatesMap: { [index in InsightWorkflowState]: string } = {
    [InsightWorkflowState.toCheck]: t<string>(toCheck),
    [InsightWorkflowState.toFollowUp]: t<string>(toFollowUp),
    [InsightWorkflowState.done]: t<string>(done)
  };

  const isUnchecked = currentState === InsightWorkflowState.toCheck;

  /**
   * Tell if the insight is being updated
   */
  const isResolving = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Insights.types.UPDATE_INSIGHT, { primaryValue: id })
  );

  /**
   * Handle workflow state change
   */
  const handleClick: ComponentProps<typeof Menu>["onClick"] = ({ key }) => {
    dispatch(
      Insights.actions.updateInsight.start(id, {
        workflowState: key as InsightWorkflowState,
        workflowStateLastModifiedDate: TS.serialize(Epoch.now())
      })
    );
  };

  return (
    <StatusDropdown
      disabled={isUnchecked}
      loading={isResolving}
      selected={currentState}
      labels={insightStatesMap}
      onClick={handleClick}
    />
  );
};

import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Insights } from "@ctra/api";
import { useInsight } from "@insights";
import { useCurrentUser } from "@auth";

/**
 * Drop-in component to mark an insight as seen
 * @constructor
 */
export const MarkAsSeen: FC = () => {
  const dispatch = useDispatch();
  const { user } = useCurrentUser();

  const {
    isSeen,
    insight: { seenBy, id },
    meta: { isLoading, isUpdating }
  } = useInsight();

  useEffect(() => {
    if (!isLoading && !isUpdating && user.id && !isSeen(user.id)) {
      dispatch(
        Insights.actions.updateInsight.start(id, {
          seenBy: [...seenBy, user.id]
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isUpdating, user.id, id]);

  return null;
};

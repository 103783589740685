import moment from "moment";
import * as _ from "lodash/fp";

import { memoize } from "@ctra/utils";
import { i18n } from "@ctra/i18n";

import { Base } from "./Base";

/**
 * X axis data type weekday class
 */
class WeekDay extends Base {
  /**
   * Get the translated label
   * @param label
   * @param long
   * @returns
   */
  @memoize
  getTranslatedLabel(label: string, options: { long: boolean }): string {
    /**
     * Set the moment locale
     */
    moment.locale(i18n.language);

    return moment()
      .isoWeekday(label)
      .format(options?.long ? "dddd" : "ddd");
  }

  /**
   * Get the config for x axis
   *
   * @returns
   */
  @memoize
  getConfig(): Record<string, unknown> {
    return {
      meta: {
        x: {
          min: 1,
          values: _.range(0, 8)
        }
      }
    };
  }
}

export { WeekDay };

import { FC } from "react";

import { FarmEntity, UserEntity } from "@ctra/api";
import { Table, TableProps, Button, ExportOutlined } from "@ctra/components";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";

const { Column } = Table;

const {
  network: {
    farms: { table }
  }
} = Content;

/**
 * Table view of users
 * @param {React.PropsWithChildren<TableProps<UserEntity>>} props
 * @returns {JSX.Element}
 * @constructor
 */
export const FarmAccessTable: FC<TableProps<FarmEntity>> = (props) => {
  const { t } = useTranslation();

  return (
    <Table<FarmEntity> {...props}>
      <Column<FarmEntity> width="50%" title={t<string>(table.columns.name)} key="name" dataIndex="name" />
      <Column<FarmEntity> title={t<string>(table.columns.status)} key="status" dataIndex="status" />
      {/*<Column<UserEntity>*/}
      {/*  align="right"*/}
      {/*  title={t<string>(table.columns.actions)}*/}
      {/*  render={() => (*/}
      {/*    <Button type="link" size="small" icon={<ExportOutlined />}>*/}
      {/*      {t<string>(table.actions.leave)}*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*/>*/}
    </Table>
  );
};

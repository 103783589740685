import * as _ from "lodash";
import { ReactElement, useEffect, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isDispatched } from "@ctra/utils";

import { Enterprise, DataDictionary, DataDescriptorList, EnterpriseAppState } from "@ctra/api";

interface DataDictionaryProviderProps {
  children: (dataDescriptors: DataDescriptorList, meta: { isLoading: boolean }) => ReactElement | null;
}

/**
 * Fetch data dictionary
 * @param children
 * @constructor
 * @deprecated in favor of DataDictionaryContext
 */
export const DataDictionaryProvider: FC<DataDictionaryProviderProps> = ({ children }) => {
  const dispatch = useDispatch();

  /**
   * Attempt to get data descriptors
   */
  const dataDictionary = useSelector<EnterpriseAppState, DataDescriptorList>(
    Enterprise.entities.getDataDictionary
  );

  /**
   * Tell whether the fetching action has been dispatched
   */
  const dispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, DataDictionary.types.FETCH_METRICS)
  );

  useEffect(() => {
    if (_.isEmpty(dataDictionary) && !dispatched) {
      dispatch(DataDictionary.actions.fetchMetrics.start());
    }
  }, [dispatch, dispatched, dataDictionary]);

  return children(dataDictionary, { isLoading: _.isEmpty(dataDictionary) });
};

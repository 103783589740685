import React from "react";

/**
 * Returns the displayName of a component/function. This is important for when working with hocs
 * https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging
 *
 * @param WrappedComponent
 * @returns {string}
 */
export function getDisplayName<P>(WrappedComponent: React.ComponentType<P>): string {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

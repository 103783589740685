import { FC, ComponentType, PropsWithChildren } from "react";
import hoistNonReactStatic from "hoist-non-react-statics";

import { classname, getDisplayName } from "@ctra/utils";
import { Row, Col } from "@ctra/components";

import { testIds } from "../testing";
import { useChartContext } from "../../providers";

import styles from "./withChartWrapper.module.less";

/**
 * Most charts share the same wrapper height and width.
 * This hoc justs abstracts the shared wrapper and wraps the charts with it
 * @returns
 */
export function withChartWrapper<P extends PropsWithChildren<Record<string, unknown>>>(
  WrappedComponent: ComponentType<P>,
  classNames = { wrapper: styles.Wrapper, zoomed: styles.Zoom }
): FC<P> {
  const WithChartWrapper: FC<P> = (props: P) => {
    const {
      viewOptions: { zoomed }
    } = useChartContext();

    return (
      <Row
        className={classname("ctra-chart-wrapper", classNames.wrapper, zoomed ? classNames.zoomed : null)}
        data-testid={testIds.withChartWrapper.wrapper}
      >
        <Col span={24}>
          <WrappedComponent {...props} />
        </Col>
      </Row>
    );
  };

  WithChartWrapper.displayName = `WithChartWrapper(${getDisplayName(WrappedComponent)})`;
  hoistNonReactStatic(WithChartWrapper, WrappedComponent);

  return WithChartWrapper;
}

import { FC } from "react";
import * as _ from "lodash";

import { Icon, Skeleton, Grid, Insights as InsightsUI } from "@ctra/components";
import { classname, TS } from "@ctra/utils";
import { InsightType, InsightWorkflowState } from "@ctra/api";

import { useFarmList } from "@farms";
import { useInsight, InsightStateSwitcher } from "@insights";

import { useInsightModalNavigation } from "../../../insight/hooks";
import { GenericInsightBase } from "../../../processing/Base";

import styles from "./InsightHeader.module.less";

const {
  Dialog: { Header, NavigationWrapper },
  TimePeriod
} = InsightsUI;

/**
 * Insight header
 * @todo scale up to different type of insights when needed, use a factory
 * @constructor
 */
export const InsightHeader: FC = () => {
  const { md } = Grid.useBreakpoint();
  const { previous, next } = useInsightModalNavigation();
  const { farmList } = useFarmList();

  const {
    insight: { insightType, farm, workflowStateLastModifiedDate, workflowState, startEpoch, endEpoch },
    getComponents,
    getDescription,
    getTitle,
    getStateDescription,
    meta: { isLoading }
  } = useInsight<GenericInsightBase>();

  /**
   * Tell if we need to add a 3rd date to the time period
   * @type {boolean}
   */
  const hasTimePeriodExtra =
    workflowState !== InsightWorkflowState.toCheck && !!workflowStateLastModifiedDate;

  /**
   * Onboarding insights do not have a toolbar
   * @type {boolean}
   */
  const hasToolbar = insightType !== InsightType.onboarding;

  return (
    <Skeleton active loading={isLoading}>
      <NavigationWrapper
        enabled={!md}
        className={classname(styles.Bordered, styles.Padded, styles.HeaderWrapper)}
        previous={previous}
        next={next}
      >
        <Header
          className={md ? classname(styles.Bordered, styles.Padded, styles.HeaderWrapper) : void 0}
          farm={_.get(_.keyBy(farmList, "id"), [farm, "name"], "...")}
          label="Your insight"
          icon={<Icon component={getComponents().Icon} />}
          title={getTitle()}
          description={getDescription()}
          period={
            <TimePeriod
              withIcon={false}
              from={startEpoch}
              until={endEpoch}
              extra={
                hasTimePeriodExtra ? (
                  <>
                    {", "}
                    {getStateDescription({
                      lastDate: TS.asMoment(workflowStateLastModifiedDate).format("LL")
                    })}
                  </>
                ) : null
              }
            />
          }
          toolbar={hasToolbar ? <InsightStateSwitcher /> : null}
        />
      </NavigationWrapper>
    </Skeleton>
  );
};

import { createContext, FC, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { EnterpriseAppState, FarmEntity, Farms, Integration } from "@ctra/api";
import { isPending } from "@ctra/utils";

import { useFarmList } from "@farms";

interface ContextType {
  integrations: Array<Integration>;
  meta: {
    isLoading: boolean;
  };
}

const DefaultContext = createContext<ContextType>({
  integrations: [],
  meta: {
    isLoading: true
  }
});

/**
 * Fetch insight mappings from the server
 * @param insightTypeID
 * @param children
 * @private
 */
const ContextProvider: FC<{ farmID: FarmEntity["id"] }> = ({ farmID, children }) => {
  const dispatch = useDispatch();
  const { farmList } = useFarmList();
  const farm = _.find(farmList, { id: farmID });

  useEffect(() => {
    dispatch(Farms.actions.fetchDataIntegrations.start(farmID));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farmID]);

  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Farms.types.FETCH_DATA_INTEGRATIONS, { primaryValue: farmID })
  );

  const integrations = _.filter(_.defaultTo(farm?.integrations, []), ({ name }) => name !== "tap");

  return (
    <DefaultContext.Provider
      value={{
        integrations,
        meta: { isLoading }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const DataIntegrationsContext = {
  Provider: ContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Grab the insight mappings from the context
 */
export const useDataIntegrations = (): ContextType => useContext(DefaultContext);

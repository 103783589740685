import { FC } from "react";

import { Empty } from "../../../layout";
import { EmptyProps } from "../../../layout/Empty";
import { ReactComponent as NoCharts } from "./assets/no-charts.svg";
import { ReactComponent as NoDashboards } from "./assets/no-dashboards.svg";

/**
 * Empty state with illustration variants
 * @param variant
 * @return {JSX.Element}
 * @constructor
 */
const WithIllustrationVariant: FC<Omit<EmptyProps, "illustration"> & { variant: "dashboards" | "charts" }> =
  ({ variant, ...props }) => {
    return <Empty {...props} illustration={variant === "charts" ? <NoCharts /> : <NoDashboards />} />;
  };

export default WithIllustrationVariant;

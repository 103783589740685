import * as _ from "lodash";

import { Bar, ChartDataPointXAxisType, Column, DataPointsMeta, Line, TableMeta } from "@ctra/api";
import { Optional } from "@ctra/utils";

import { SeriesType } from "../typings";

/**
 * Filter data which has `seriesField` in the items
 * @param {T} data
 * @param {DataPointsMeta} meta
 * @param {SeriesType} series
 * @returns {T | ({x: string, y: Nullable<number>, seriesField: string, anomaly?: {prediction?: number, type?: string}} | {x: string, y: number, seriesField: string})[]}
 */
const filter = <T extends Line | Bar | Column>(data: T, meta: DataPointsMeta, series: SeriesType) => {
  const seriesKeys = _.get(meta, ["series", "keys"], {});

  return _.isEmpty(series) || _.size(series) === _.size(seriesKeys)
    ? data
    : _.filter(data, (datum: T[number]) => _.has(series, datum.seriesField));
};

/**
 * Filter line data by the given series
 * @param {Line} data
 * @param {DataPointsMeta} meta
 * @param {SeriesType} series
 * @returns {Line | ({x: string, y: Nullable<number>, seriesField: string, anomaly?: {prediction?: number, type?: string}} | {x: string, y: number, seriesField: string})[]}
 */
export const filterLineBySeries = (data: Line, meta: DataPointsMeta, series: SeriesType): Line =>
  filter<Line>(data, meta, series);

/**
 * Filter bar data by the given series
 * @param {Bar} data
 * @param {DataPointsMeta} meta
 * @param {SeriesType} series
 * @returns {Bar}
 */
export const filterBarBySeries = (data: Bar, meta: DataPointsMeta, series: SeriesType): Bar =>
  filter<Bar>(data, meta, series);

/**
 * Filter column data by the given series
 * @param {BarData} data
 * @param {DataPointsMeta} meta
 * @param {SeriesType} series
 * @returns {Column | ({x: string, y: Nullable<number>, seriesField: string, anomaly?: {prediction?: number, type?: string}} | {x: string, y: number, seriesField: string})[]}
 */
export const filterColumnBySeries = (data: Column, meta: DataPointsMeta, series: SeriesType): Column =>
  filter<Column>(data, meta, series);

/**
 * Filter table data by the given series
 * @param {TableMeta["columns"]} columns
 * @param {string} key
 * @param {SeriesType} series
 * @returns {TableMeta["columns"]}
 */
export const filterTableBySeries = (
  columns: TableMeta["columns"],
  key: Optional<ChartDataPointXAxisType>,
  series: SeriesType
): TableMeta["columns"] => {
  if (!_.isString(key) || _.isEmpty(series)) {
    return columns;
  }

  return _.filter(columns, ({ key: k }) => k === key || _.has(series, k as string));
};

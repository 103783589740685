import { createContext, FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDeepCompareEffect } from "use-deep-compare";

import { RemoteSession, Signup, Enterprise, EnterpriseAppState } from "@ctra/api";
import { isDispatched, isPending, isFulfilled, Nullable } from "@ctra/utils";

import { Routes } from "@routes";
import { useUserPreferences } from "../UserPreferencesContext";

interface ContextType {
  meta: {
    isInitialLoading: boolean;
    isLoading: boolean;
    dataIntegrated: boolean;
  };
  session: Partial<RemoteSession>;
}

const DefaultContext = createContext<ContextType>({
  meta: {
    isInitialLoading: true,
    isLoading: true,
    dataIntegrated: false
  },
  session: {}
});

/**
 * Data integration context provider
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @return {JSX.Element}
 */
const _DataIntegrationContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const [initial, setInitial] = useState<boolean>(true);
  const [remoteSession, setRemoteSession] = useState<Partial<RemoteSession>>({});

  const {
    preferences: { sandbox }
  } = useUserPreferences();

  /**
   * Check if the remote session has been dispatched
   * @type {boolean}
   */
  const hasDispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, Signup.types.GET_REMOTE_SESSION)
  );

  /**
   * Check if the remote session is loading
   * @type {boolean}
   */
  const isLoading = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Signup.types.GET_REMOTE_SESSION)
  );

  /**
   * Check the remote session status
   */
  const [, response] = useSelector<EnterpriseAppState, [boolean, Nullable<Partial<RemoteSession>>]>((state) =>
    isFulfilled(state, Signup.types.GET_REMOTE_SESSION, { withPayload: true })
  );

  useEffect(() => {
    if (!hasDispatched) {
      dispatch(Signup.actions.getRemoteSession.start());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDispatched]);

  /**
   * Set initial to false when remote session is available
   */
  useDeepCompareEffect(() => {
    if (response) {
      setRemoteSession(response);

      if (initial) {
        setInitial(false);
      }
    }
  }, [response]);

  /**
   * Any status but "Closed" will redirect to overview
   */
  // useEffect(() => {
  //   if (remoteSession.status !== "Closed") {
  //     Enterprise.history.push(Routes.app.overview.index);
  //   }
  // }, [remoteSession.status]);

  return (
    <DefaultContext.Provider
      value={{
        meta: {
          isInitialLoading: isLoading && initial,
          isLoading: isLoading,
          dataIntegrated:
            sandbox.isEnabled ||
            (!!remoteSession.status && ["Closed", "Unknown"].includes(remoteSession.status))
        },
        session: remoteSession
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const DataIntegrationContext = {
  Provider: _DataIntegrationContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Use data integration
 * @return {ContextType}
 */
export const useDataIntegration = (): ContextType => useContext(DefaultContext);

import { useDispatch, useSelector } from "react-redux";
import { FC, useEffect, useState } from "react";
import * as _ from "lodash";
import Markdown from "react-markdown";

import {
  Charts,
  Correlations,
  DataDescriptorEntity,
  Enterprise,
  EnterpriseAppState,
  CorrelationList as CorrelationsList
} from "@ctra/api";

import {
  Analytics,
  Button,
  Row,
  Col,
  Tag,
  Typography,
  Alert,
  LinkOutlined,
  Space,
  QuestionCircleOutlined,
  Tooltip
} from "@ctra/components";

import { useTranslation, Enterprise as Content, parseLocoTemplate } from "@ctra/i18n";
import { Nullable, Optional } from "@ctra/utils";

import { useFarm } from "@farms";
import { useDataDictionary } from "@base";

import { GACategories } from "@analytics";
import { closestSupportedInterval, correlationDelta, correlationStrength } from "./utils";
import styles from "./CorrelationList.module.less";

const { CorrelationMetric } = Analytics;
const { Text } = Typography;

interface CorrelationListProps {
  /**
   * Iso duration
   */
  isoDuration: Nullable<string>;
  /**
   * List of data descriptors
   */
  variantList: Array<DataDescriptorEntity["id"]>;
  /**
   * chart to show correlations for
   */
  variantID: DataDescriptorEntity["id"];
  /**
   * handle adding this to the chart list
   * @param {DataDescriptorEntity["id"]} dataDescriptorID
   */
  onAdd: (dataDescriptorID: DataDescriptorEntity["id"]) => void;
}

const {
  gamePlan: {
    list: {
      headers: { kpi }
    }
  },
  analytics: {
    correlations: { headers }
  },
  analytics: {
    correlations: { show, add, trend, correlation, tooltip, alert, selectedChart }
  },
  layouts: {
    section: { markedBeta }
  },
  kpi: descriptor
} = Content;

/**
 * Correlation list header
 * @return {JSX.Element}
 * @constructor
 */
const TableHeader: FC = () => {
  const { t } = useTranslation();

  return (
    <Row className={styles.Header} wrap={false}>
      <Col xs={2} lg={1} />
      <Col xs={6} lg={10}>
        {t(kpi)}
      </Col>
      <Col xs={4} lg={3}>
        {t(headers.trend)}
      </Col>
      <Col xs={6} lg={4}>
        {t(headers.correlation)}
      </Col>
      <Col flex={1} />
    </Row>
  );
};

/**
 * Correlation list for the selected variant/data descriptor id
 * @param {dataDescriptors} variants
 * @param {string} variantID
 * @param {(dataDescriptorID: DataDescriptorEntity["id"]) => void} onAdd
 * @return {JSX.Element}
 * @constructor
 */
export const CorrelationList: FC<CorrelationListProps> = ({ isoDuration, variantList, variantID, onAdd }) => {
  const { farm } = useFarm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dataDescriptors } = useDataDictionary();
  const [activeDescriptor, setActiveDescriptor] = useState<DataDescriptorEntity["id"]>();
  const [correlationsView, toggleCorrelationsView] = useState(false);
  const [description, note] = _.defaultTo(parseLocoTemplate(t(tooltip)), []);

  /**
   * Correlations on different farms
   */
  const correlations = useSelector<EnterpriseAppState, CorrelationsList>((state) =>
    Enterprise.entities.getCorrelations(state)
  );

  /**
   * Fetch correlations
   */
  useEffect(() => {
    if (farm) {
      if (!_.get(correlations, [farm.id, "index"])) {
        dispatch(Charts.actions.fetchFarmCorrelations.start(farm.id));
      }

      if (variantList.length > 0) {
        const [excluded, current] =
          variantList.length === 1
            ? [void 0, _.first(variantList)]
            : [_.initial(variantList), _.last(variantList)];

        if (current) {
          dispatch(Charts.actions.fetchChartCorrelations.start(farm.id, current, excluded));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantID, farm]);

  /**
   * get combined descriptor id's if they are available
   * e.g for a descriptor called eating and ruminating time, the combined descriptors will have
   * an array of eating time descriptor id and ruminating time descriptor id
   */
  const combinedDescriptors = dataDescriptors[variantID]?.dataProperties?.combinedDescriptors;

  /**
   * If there are combined descriptors, the active descriptor should be from the list
   * otherwise use the chart descriptor id
   */
  useEffect(() => {
    setActiveDescriptor(_.isEmpty(combinedDescriptors) ? variantID : _.first(combinedDescriptors));
  }, [combinedDescriptors, variantID]);

  /**
   * Fetch the correlated charts
   */
  const supportedDescriptors = useSelector<EnterpriseAppState, Optional<Array<DataDescriptorEntity["id"]>>>(
    (state) =>
      Enterprise.entities.getSupportedDescriptorsForInterval(state, {
        farmID: farm?.id,
        interval: closestSupportedInterval(isoDuration)
      })
  );

  /**
   * Check if the data descriptor has suggestions enabled
   */
  const suggestionsEnabled = _.includes(supportedDescriptors, activeDescriptor);

  /**
   * Fetch the correlated charts
   */
  const correlatedCharts = useSelector<
    EnterpriseAppState,
    Record<DataDescriptorEntity["id"], Optional<Correlations>>
  >((state) =>
    suggestionsEnabled
      ? _.reduce(
          [variantID, ..._.defaultTo(combinedDescriptors, [])],
          (result, id) => {
            const correlatedCharts = Enterprise.entities.getCorrelatedCharts(state, {
              dataDescriptorID: id,
              farmID: farm?.id,
              interval: closestSupportedInterval(isoDuration)
            });

            if (correlatedCharts) {
              result[id] = correlatedCharts;
            }

            return result;
          },
          {} as Record<DataDescriptorEntity["id"], Optional<Correlations>>
        )
      : {}
  );

  return _.isEmpty(correlatedCharts) ? null : (
    <Alert
      className={styles.Alert}
      description={
        <>
          <Row align={"middle"} className={styles.Title}>
            <Col flex={1}>
              <Row align="middle">
                <LinkOutlined /> <Text>{t(alert)}</Text>
                <Tag color="#595959">{t(markedBeta)}</Tag>
              </Row>
            </Col>
            <Button
              data-gtm-category={GACategories.configurator}
              data-gtm-action="Toggle correlations view"
              size="small"
              type="link"
              onClick={() => toggleCorrelationsView(!correlationsView)}
            >
              {t(show, { visible: correlationsView })}
            </Button>
          </Row>
          {correlationsView && (
            <Row>
              <Col flex={1} className={styles.Wrapper}>
                {_.map(correlatedCharts, (charts, id) => (
                  <Row gutter={16} key={id} className={styles.Table}>
                    <Col span={24} className={styles.RelatedTitle}>
                      <Space align="center">
                        <Tooltip
                          title={
                            <>
                              {/* kind of defeats the purpose TODO fix */}
                              <Markdown>{t(description)}</Markdown>
                              <br />
                              <br />
                              <i>
                                <Markdown>{t(note)}</Markdown>
                              </i>
                            </>
                          }
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                        <Text>{t(selectedChart)}</Text>
                        <Text strong>
                          {t<string>(descriptor.displayName(dataDescriptors[id].dataProperties.typeName), {
                            case: null,
                            variant: null,
                            makeDefaultValue: true
                          })}
                        </Text>
                      </Space>
                    </Col>
                    <Col span={24} className={styles.Metrics}>
                      <TableHeader />
                      {_.map(
                        _.take(_.toArray(correlatedCharts[id]), 3),
                        ({ dataDescriptorID, correlationValue, pctChange }) => {
                          const correlationType =
                            CorrelationMetric.CorrelationType[correlationDelta(pctChange)];

                          const typeName = _.get(dataDescriptors, [
                            dataDescriptorID,
                            "dataProperties",
                            "typeName"
                          ]);

                          const title = t<string>(descriptor.displayName(typeName), {
                            makeDefaultValue: true,
                            case: null,
                            variant: null
                          });

                          const absoluteValue = Math.abs(correlationValue);
                          const strength = correlationStrength(absoluteValue);

                          return (
                            <CorrelationMetric
                              key={dataDescriptorID}
                              className={styles.Entry}
                              data-gtm-category={GACategories.configurator}
                              data-gtm-action="Add correlation to comparison"
                              data-gtm-info={JSON.stringify({ dataDescriptorID })}
                              onClick={() => onAdd(dataDescriptorID)}
                              correlationType={correlationType}
                              trend={t(trend, { correlationType })}
                              correlation={t(correlation, { strength })}
                              correlationPercent={strength}
                              title={title}
                              extra={
                                <Button className={styles.AddChartLink} type="link">
                                  {t<string>(add)}
                                </Button>
                              }
                            />
                          );
                        }
                      )}
                    </Col>
                  </Row>
                ))}
              </Col>
            </Row>
          )}
        </>
      }
    />
  );
};

import { FC, createContext, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Enterprise, EnterpriseAppState, EventEntity, ExtendedEventEntity } from "@ctra/api";
import { Optional } from "@ctra/utils";
import { EventBase, GenericEvent } from "@events";
import { useDeepCompareEffect } from "use-deep-compare";

interface ProviderProps {
  /**
   * Event ID
   */
  eventID: EventEntity["id"];
}

/**
 * Base meta which contains the permanent keys
 */
type BaseMeta = {
  isLoading: boolean;
};

/**
 * Base context with optional event and meta with added keys
 */
type BaseContext<M extends Optional<Record<string, unknown>> = undefined> = {
  event?: ExtendedEventEntity;
  meta: M extends undefined ? BaseMeta : BaseMeta & M;
};

type ContextType<
  /**
   * event interface to implement
   */
  C extends EventBase = EventBase,
  /**
   * props passed to the provider ending up in the context as metadata
   */
  M extends Optional<Record<string, unknown>> = undefined
> = C & {
  event: ExtendedEventEntity;
} & BaseContext<M>;

const DefaultContext = createContext<BaseContext>({ meta: { isLoading: true } });

/**
 * Event context provider
 * @param children
 * @param eventID
 * @private
 */
const _EventProvider: FC<ProviderProps> = ({ eventID, children }) => {
  let serializedEvent: ReturnType<typeof GenericEvent.serialize> = {} as ReturnType<
    typeof GenericEvent.serialize
  >;

  /**
   * Get the event from the store
   */
  const event = useSelector<EnterpriseAppState, ExtendedEventEntity>(
    (state) => Enterprise.entities.getEvent(state, { id: eventID }) as ExtendedEventEntity
  );

  try {
    if (event) {
      serializedEvent = GenericEvent.serialize(GenericEvent.create(event));
    }
  } catch (e) {
    console.error(e, event);
  }

  /**
   * Make an API from the event
   */
  const [apiState, setApiState] = useState<ReturnType<typeof GenericEvent.serialize>>(serializedEvent);

  useDeepCompareEffect(() => {
    if (event) {
      setApiState(GenericEvent.serialize(GenericEvent.create(event)));
    }
  }, [event]);

  return (
    <DefaultContext.Provider value={{ event, ...apiState, meta: { isLoading: !event } }}>
      {children}
    </DefaultContext.Provider>
  );
};

export const EventContext = {
  Provider: _EventProvider,
  Consumer: DefaultContext.Consumer
};

export const useEvent = <
  C extends EventBase = EventBase,
  M extends Optional<Record<string, unknown>> = undefined
>(): M extends undefined ? ContextType<C> : ContextType<C, M> =>
  useContext(DefaultContext) as M extends undefined ? ContextType<C> : ContextType<C, M>;

import { FC } from "react";
import * as _ from "lodash";

import {
  Alert,
  Button,
  CtraLayout,
  PlusOutlined,
  TableProps,
  Tabs,
  Typography,
  Badge
} from "@ctra/components";

import { Breadcrumbs, Enterprise as API, ReferralEntity, ReferralStatus, ReferralType } from "@ctra/api";
import { Enterprise as Content, useTranslation } from "@ctra/i18n";
import { isProduction } from "@ctra/utils";

import { Routes } from "@routes";
import { useInvitation, useUserList } from "@base";
import { useFarm, useFarmList } from "@farms";
import { useCurrentUser } from "@auth";

import { FarmAccessTable } from "../FarmAccessTable";
import { UserAccessTable } from "../UserAccessTable";
import { UserReferralTable } from "../UserReferralTable";
import { FarmReferralTable } from "../FarmReferralTable";

import styles from "./ReferralListPage.module.less";
import { GAEvent } from "@ctra/analytics";
import { GACategories } from "@network";

const { WidgetWrapper, ContentWrapper } = CtraLayout;
const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

const {
  components: { Breadcrumb }
} = Breadcrumbs;

const {
  ui: { table: tableLocale },
  network: {
    farms: { title: farmTitle, description: farmDescription, tabs: farmTabs, actions: farmActions },
    users: { title: userTitle, description: userDescription, tabs: userTabs }
  },
  settings: {
    users: {
      alerts: { selectFarm, referral },
      actions: { invite },
      table: { empty }
    }
  }
} = Content;

/**
 * Filter the referral list by the referral status
 * @param {Array<ReferralEntity | UserEntity>} dataSource
 * @param {ReferralStatus} status
 * @returns {(ReferralEntity | UserEntity)[]}
 * @note if the entity is a user, it will be considered as an accepted referral
 */
const filterByReferralStatus = (
  dataSource: Array<ReferralEntity>,
  status: ReferralStatus[]
): ReferralEntity[] => _.filter(dataSource, (item) => status.includes(item.status));

/**
 * Referral list page
 * @return {JSX.Element}
 * @constructor
 */
export const ReferralListPage: FC = () => {
  const { t } = useTranslation();
  const { farm } = useFarm();
  const { farmList } = useFarmList();

  const {
    users,
    meta: { isLoading: isUserListLoading }
  } = useUserList();

  const {
    sent,
    received,
    meta: { isLoading }
  } = useInvitation();

  const { user } = useCurrentUser();
  const isFarmer = !!user.farmAccess?.farmId;

  /**
   * Filtered user list
   * @type {Array<UserList[keyof UserList]>}
   */
  const filteredUserList = _.filter(users, ({ email }) =>
    isProduction()
      ? !_.includes(email, "connecterra") && !_.includes(email, user.email)
      : !_.includes(email, user.email)
  );

  /**
   * Referral list
   * @type {InvitationList}
   */
  const referralList = { ...sent, ...received };

  /**
   * Common table props
   * @type {{pagination: {hideOnSinglePage: boolean, pageSize: number, position: string[]}, locale: {[p: string]: TDefaultResult} & {emptyText: string}}}
   */
  const getTableProps = (referralType: ReferralType): Partial<TableProps<any>> => ({
    locale: {
      ..._.mapValues(tableLocale, _.unary(t)),
      emptyText: t<string>(empty, { referralType })
    },
    pagination: {
      hideOnSinglePage: true,
      position: ["bottomCenter"],
      pageSize: 10
    }
  });

  /**
   * Farmer to user referrals
   * @type {ReferralEntity[]}
   */
  const userReferrals = filterByReferralStatus(_.values(referralList), [
    ReferralStatus.sent,
    ReferralStatus.declined
  ]);

  /**
   * User to farmer referrals
   * @type {ReferralEntity[]}
   */
  const farmReferrals = filterByReferralStatus(_.values(referralList), [
    ReferralStatus.sent,
    ReferralStatus.declined
  ]);

  return (
    <>
      <Breadcrumb
        path={Routes.app.network.index}
        title={isFarmer ? t<string>(userTitle) : t<string>(farmTitle)}
      />

      {/* farmer to user referrals */}
      {isFarmer && _.size(farmList) ? (
        <WidgetWrapper
          className={styles.Wrapper}
          loading={isUserListLoading || isLoading}
          title={
            <section>
              <Title level={5}>{t<string>(userTitle)}</Title>
              <Paragraph>{t<string>(userDescription, { farmName: farm?.name || null })}</Paragraph>
            </section>
          }
          toolbar={
            farm?.id ? (
              <Button
                data-gtm-category={GACategories.network}
                data-gtm-action="Invite new user"
                type="secondary"
                icon={<PlusOutlined />}
                onClick={() => API.history.push(Routes.app.network.invite.new)}
              >
                {t<string>(invite)}
              </Button>
            ) : (
              void 0
            )
          }
        >
          {farm?.id ? (
            <Tabs
              className={styles.Tabs}
              defaultActiveKey={ReferralStatus.sent}
              onChange={(activeKey) => {
                GAEvent(GACategories.network, `Switch referral tab - ${activeKey}`);
              }}
            >
              <TabPane tab={t<string>(userTabs.users)} key="users">
                <ContentWrapper padded={false} style={{ overflow: "hidden" }}>
                  <UserAccessTable
                    {...getTableProps(ReferralType.farmerToUser)}
                    dataSource={filteredUserList}
                    rowKey="id"
                  />
                </ContentWrapper>
              </TabPane>
              {!!_.size(userReferrals) && (
                <TabPane
                  tab={
                    <Badge size="small" count={_.size(userReferrals)}>
                      <Text>{t<string>(userTabs.invitations)}</Text>
                    </Badge>
                  }
                  key="user-referrals"
                >
                  <ContentWrapper padded={false} style={{ overflow: "hidden" }}>
                    <UserReferralTable
                      {...getTableProps(ReferralType.farmerToUser)}
                      dataSource={userReferrals}
                      rowKey="id"
                    />
                  </ContentWrapper>
                </TabPane>
              )}
            </Tabs>
          ) : (
            <Alert
              type="info"
              showIcon
              message={t<string>(selectFarm.title)}
              description={t<string>(selectFarm.description)}
            />
          )}
        </WidgetWrapper>
      ) : isFarmer && !_.size(farmList) ? (
        <Alert
          type="info"
          showIcon
          message={t<string>(referral.title)}
          description={t<string>(referral.description)}
        />
      ) : null}

      {/* user to farmer referrals */}
      {!isFarmer ? (
        <WidgetWrapper
          className={styles.Wrapper}
          loading={isLoading}
          title={
            <section>
              <Title level={5}>{t<string>(farmTitle)}</Title>
              <Paragraph>{t<string>(farmDescription)}</Paragraph>
            </section>
          }
          toolbar={
            <Button
              data-gtm-category={GACategories.network}
              data-gtm-action="Request access from farmer"
              type="secondary"
              icon={<PlusOutlined />}
              onClick={() => API.history.push(Routes.app.network.requestAccess)}
            >
              {t<string>(farmActions.invite)}
            </Button>
          }
        >
          <Tabs
            className={styles.Tabs}
            defaultActiveKey="farms"
            onChange={(activeKey) => {
              GAEvent(GACategories.network, `Switch referral tab - ${activeKey}`);
            }}
          >
            <TabPane tab={t<string>(farmTabs.farms)} key="farms">
              <ContentWrapper padded={false} style={{ overflow: "hidden" }}>
                <FarmAccessTable
                  {...getTableProps(ReferralType.userToFarmer)}
                  dataSource={_.values(farmList)}
                  rowKey="id"
                />
              </ContentWrapper>
            </TabPane>
            {!!_.size(farmReferrals) && (
              <TabPane
                tab={
                  <Badge size="small" count={_.size(farmReferrals)}>
                    <Text>{t<string>(farmTabs.invitations)}</Text>
                  </Badge>
                }
                key="farm-requests"
              >
                <ContentWrapper padded={false} style={{ overflow: "hidden" }}>
                  <FarmReferralTable
                    {...getTableProps(ReferralType.userToFarmer)}
                    dataSource={farmReferrals}
                    rowKey="id"
                  />
                </ContentWrapper>
              </TabPane>
            )}
          </Tabs>
        </WidgetWrapper>
      ) : null}
    </>
  );
};

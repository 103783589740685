import * as _ from "lodash";

import { EventEntity, GenericInsightEventContext, Enterprise as App, EventScope } from "@ctra/api";
import { i18nTranslate, Enterprise, Enterprise as Content } from "@ctra/i18n";
import { memoize, makeList } from "@ctra/utils";
import { EventOrigin, GenericInsightEventView, ImpactTrackedEventPage } from "@events";

import { EventBase } from "./Base";
import { GenericInsight } from "@insights";

/**
 * Generic Insight Event
 */
class GenericInsightEvent extends EventBase<
  GenericInsightEventContext & {
    insightResolutionIds: string;
  }
> {
  /**
   * Get the metadata for the event
   */
  @memoize
  getContext(): {
    urlParams: Map<string, EventEntity["source"]["id"]>;
    ctaText: string;
  } & GenericInsightEventContext {
    const {
      source: { id, type },
      context: { insightResolutionIds = "", impactTrackingState, ...restOfContext }
    } = this.entity;

    const {
      events: {
        entity: { ctaText: cta }
      }
    } = Enterprise;

    /**
     * Split the comma separated string, then filter out numeric ids (custom resolutions)
     */
    const processedResolutionIds = _.compact(
      _.filter(_.map(insightResolutionIds.split(","), _.trim), (id) => isNaN(parseFloat(id)))
    );

    return {
      insightResolutionIds: processedResolutionIds,
      status: impactTrackingState,
      ...restOfContext,
      urlParams: new Map([["genericInsightID", id]]),
      ctaText: i18nTranslate(cta(type))
    };
  }

  /**
   * Get the components for the event
   */
  @memoize
  getComponents(): ReturnType<typeof EventBase.prototype.getComponents> {
    return {
      ...super.getComponents(),
      Page: ImpactTrackedEventPage,
      View: GenericInsightEventView
    };
  }

  /**
   * Make a title from the insight resolution
   * @param params
   */
  @memoize
  getTitle(params?: Record<string, unknown>): string {
    const {
      title,
      source: { type: sourceType },
      scope: { type: scopeType, ids: scopeIDs }
    } = this.entity;

    const {
      events: {
        entity: { title: makeInsightEventTitleCopy }
      },
      insightResolutions: { title: makeResolutionTitleCopy },
      grammar: {
        conjunction: { and }
      }
    } = Content;

    const { insightResolutionIds } = this.getContext();

    if (!insightResolutionIds.length) {
      return super.getTitle();
    }

    let herdGroup = null;

    if (scopeType === EventScope.herdGroup) {
      const herdGroups = _.pick(App.entities.getHerdGroups(App.store.getState()), scopeIDs);

      if (!_.isEmpty(herdGroups)) {
        herdGroup = _.join(_.map(herdGroups, "name"), ", ");
      }
    }

    /**
     * Collect the ids and the corresponding copy keys
     */
    const copyKeys = _.zip(
      insightResolutionIds,
      _.map(insightResolutionIds, (id) => makeResolutionTitleCopy(id))
    );

    /**
     * Make a list of translated resolution titles with a fallback to the humanized id
     */
    const translated = _.map(copyKeys, ([id, copyKey]) => {
      const fromLoco = i18nTranslate(copyKey as string);

      return fromLoco && fromLoco === copyKey ? _.lowerCase(id) : fromLoco;
    });

    return i18nTranslate(makeInsightEventTitleCopy("GenericInsight", "resolved"), {
      insightResolution: _.upperFirst(makeList(translated, i18nTranslate(and))),
      title: _.defaultTo(title, null),
      scope: scopeType,
      herdGroup,
      sourceType,
      ...params
    });
  }

  /**
   * Get event description
   * @param params {{}}
   */
  @memoize
  getDescription(params?: Record<string, unknown>): string {
    const {
      description: originalDescription,
      source: { type }
    } = this.entity;

    const {
      entity: { description }
    } = Content.events;

    return i18nTranslate(description(type), {
      ...params,
      originalDescription: _.trim(originalDescription) || null,
      origin: this.isSupported() ? EventOrigin.enterprise : EventOrigin.classic
    });
  }

  /**
   * Match the source type of the event to the registry we have locally
   */
  @memoize
  isSupported(): boolean {
    const { insightTypeID } = this.getContext();
    const insightTypes = App.entities.getInsightTypes(App.store.getState());
    const insightType = _.find(insightTypes, ["id", Number(insightTypeID)]);
    const registeredInsights = GenericInsight.getRegisteredInsights();

    return !!insightType && registeredInsights.includes(insightType.typeName);
  }
}

export { GenericInsightEvent };

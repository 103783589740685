import { makeTestIds } from "@ctra/utils";

/**
 * Make test ids for the analytics module
 */
export const testIds = makeTestIds("analytics", {
  sectionDashboard: "sectionDashboard",
  stackNavigation: {
    dropDown: "dropDown"
  },
  sectionToolbar: {
    dimGroupFilter: "dim-group-filter",
    herdGroupFilter: "herd-group-filter",
    isoDurationFilter: "iso-duration-filter",
    penFilter: "pen-filter"
  }
});

import { createContext, useContext, FC, useState, useMemo, useCallback, useEffect } from "react";
import * as _ from "lodash";

import { HeatmapData } from "@ctra/api";

import { useChartContext } from "../ChartContext";
import { getMaxValue, getMinValue } from "../../utils";

type RangeType = { min: number; max: number };

type ContextType = {
  range: RangeType;
  initialRange: RangeType;
  api: {
    setRange: (range: RangeType) => void;
    filterByRange: (data: HeatmapData["data"]) => NonNullable<HeatmapData["data"]>;
  };
};

export const defaultRange = { min: Number.MIN_SAFE_INTEGER, max: Number.MAX_SAFE_INTEGER };

const defaultContextValue = {
  range: defaultRange,
  initialRange: defaultRange,
  api: {
    setRange: _.noop,
    filterByRange: () => []
  }
};

const DefaultContext = createContext<ContextType>(defaultContextValue);

/**
 * Range Filter Provider
 * This provides range value context
 * @param param0
 * @returns
 */
const _RangeFilterProvider: FC = ({ children }) => {
  const { data = [] } = useChartContext<HeatmapData>();
  /**
   * Recalculate the initial min and max value only when the input data changes
   */
  const { min, max } = useMemo(() => ({ min: getMinValue(data, "z"), max: getMaxValue(data, "z") }), [data]);

  const [range, setRange] = useState<RangeType>({ min, max });

  useEffect(() => {
    setRange({ min, max });
  }, [min, max]);

  /**
   * Callback for filtering by range
   */
  const filterByRange = useCallback(
    (data: HeatmapData["data"] = []) => {
      return data.filter((datum) => datum.z >= range.min && datum.z <= range.max);
    },
    [range]
  );

  return (
    <DefaultContext.Provider
      value={{
        range,
        initialRange: { min, max },
        api: {
          setRange,
          filterByRange
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const RangeFilterContext = {
  Provider: _RangeFilterProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook for the ranger color context
 */
export const useRangeFilter = (): ContextType => useContext(DefaultContext);

export const overview = {
  _root: "/overview"
};

/**
 * /dashboards for users
 * who still have it in their browser history/bookmarks
 * @deprecated in favour of /overview
 */
export const dashboards = {
  _root: "/dashboards"
};

import { FC } from "react";
import * as _ from "lodash";

import { Icon, Smile, Typography, Steps } from "@ctra/components";
import { InsightValidation as ValidationValues, ValidationLookup } from "@ctra/api";
import { Enterprise, useTranslation } from "@ctra/i18n";

import {
  useInsightResolutions,
  useInsightValidation,
  ValidationQuestion,
  ValidationButtons
} from "@insights";

import styles from "./LelyNoMilkDropInsightValidation.module.less";

const { Step } = Steps;
const { Text } = Typography;

/**
 * LelyNoMilkDrop insight validation
 * @constructor
 */
export const LelyNoMilkDropInsightValidation: FC = () => {
  const { t } = useTranslation();

  const { validation: validationEntity, validate } = useInsightValidation();
  const { unresolve } = useInsightResolutions();

  const {
    insightValidations: { validationValue }
  } = Enterprise;

  const { validation } = validationEntity;

  /**
   * Handle validation attempt
   * make sure the unresolve doesn't update workflow state because its already being updated via validation
   * @param value
   */
  const onValidate = _.flow(validate, _.partial(unresolve, { updatesWorkflowState: false }));

  return (
    <Steps current={0} className={styles.Steps} direction="vertical">
      <Step
        icon={<Icon component={Smile} className={styles.Smile} />}
        title={
          <ValidationQuestion
            highlighted={validation === ValidationValues.na}
            step={1}
            rollback={
              validation !== ValidationValues.na ? _.partial(onValidate, ValidationValues.na) : void 0
            }
          />
        }
        description={
          validation === ValidationValues.na ? (
            <ValidationButtons options={[ValidationValues.yes, ValidationValues.no]} />
          ) : (
            <Text type="secondary" className={styles.Link}>
              {t<string>(validationValue[ValidationLookup[validation]])}
            </Text>
          )
        }
      />
    </Steps>
  );
};

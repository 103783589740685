import { ComponentProps, FC } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import * as _ from "lodash";

import { Button, BugOutlined } from "@ctra/components";

type DebuggerProps = RouteComponentProps &
  ComponentProps<typeof Button> & {
    /**
     * Show a bug icon instead of text
     */
    icon?: boolean;
  } & Record<string, unknown>;

/**
 * Debugger button which outputs any content to the console.
 * It may be kept in production when the "#debug" hash is used in the URL.
 */
const Debugger = withRouter<DebuggerProps, FC<DebuggerProps>>(
  ({ icon, history, match, staticContext, location: { hash }, ...rest }) => {
    /**
     * Filter the Button props
     */
    const props = _.omitBy(rest, (v, k) => _.startsWith(k, "$"));

    /**
     * Pick the props starting with $,
     * those should be listed as debug
     */
    const debug = _.pickBy(rest, (v, k) => _.startsWith(k, "$"));

    return hash.includes("debug") ? (
      <Button {...props} icon={icon ? <BugOutlined /> : null} onClick={() => console.log(debug)}>
        {icon ? "" : "Debug"}
      </Button>
    ) : null;
  }
);

export default Debugger;

import { useState, useEffect, useRef } from "react";
import * as _ from "lodash";

import { ISODuration } from "@ctra/api";
import { Nullable } from "@ctra/utils";

export type ISODurationSetter = (isoDuration: Nullable<ISODuration>) => void;

/**
 * Custom hook for managing the ISO duration settings for charts
 *
 * The ISODuration can be set from 3 points namely: via props,
 * via API contexts (ChartAPIContext and SectionContext),
 * and from the select dropdowns by the user.
 *
 * When the props is set and on first render, we want it to be the initial value, which can then be updated via the dropdowns
 * Other times, we want the value from the chart/section contexts  to be the initial value
 *
 * So, we are using a ref to keep track of the first render.
 * After the first render, other channels can go on to update the ISODuration
 *
 * @param {Nullable<ISODuration>} initialISODuration
 * @return {[Nullable<ISODuration>, ISODurationSetter]}
 */
export const useISODuration = (
  initialISODuration?: Nullable<ISODuration>
): [Nullable<ISODuration>, ISODurationSetter] => {
  const firstUpdate = useRef<boolean>(true);

  const [currentDuration, setCurrentDuration] = useState<Nullable<ISODuration>>(
    _.defaultTo(initialISODuration, null)
  );

  /**
   * @param {Nullable<ISODuration>} isoDuration
   */
  const updateCurrentDuration: ISODurationSetter = (isoDuration) => {
    if (!firstUpdate.current || _.isUndefined(initialISODuration)) {
      setCurrentDuration(isoDuration);
    }
  };

  useEffect(() => {
    firstUpdate.current = false;
  }, []);

  return [currentDuration, updateCurrentDuration];
};

import React from "react";
import { useLocation } from "react-router-dom";

import { MediaQueryDynamicImport } from "@ctra/components";

import { URIState } from "@routes";
import { testIds } from "@chart";

import { DataDictionaryContext } from "@base";
import { useFarm } from "@farms";
import { EventListContext } from "@events";

/**
 * Edit chart view per card
 * @constructor
 */
export const ChartEdit = (): JSX.Element | null => {
  const { state } = useLocation<URIState>();
  const { farm: farmContext } = useFarm();

  if (state) {
    const { editChart } = state;

    return editChart && editChart.chartID ? (
      <div data-testid={testIds.chartEdit.wrapper}>
        {/** @todo replace the desktop import with mobile import and make a proper mobile view of it */}
        <MediaQueryDynamicImport mobile={() => import("./Desktop")} desktop={() => import("./Desktop")}>
          {(Component) => (
            <DataDictionaryContext.Provider>
              <EventListContext.Provider farmID={farmContext?.id}>
                <Component chartID={editChart.chartID} dashboard={editChart.dashboard} />
              </EventListContext.Provider>
            </DataDictionaryContext.Provider>
          )}
        </MediaQueryDynamicImport>
      </div>
    ) : null;
  }
  return null;
};

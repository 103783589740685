import moment from "moment";

/**
 * Get a date string in the past using an ISO 8601 duration string and a custom offset value.
 * @param durationString
 * @param offset
 * @example
 * ```ts
 * import { DAY } from "@ctra/utils";
 *
 * iso8601Ago("P1M"); // 1 month ago as date string (utc)
 * iso8601Ago("P1M", DAY); // 1 month and 1 day ago as date string
 * ```
 */
export const iso8601Ago = (durationString: string, offset = 0): string => {
  const now = moment().utc().startOf("day");
  const duration = moment.duration(durationString).asSeconds();

  return moment(+now.subtract(duration + offset, "seconds")).toISOString();
};

/**
 * Create a human readable duration string
 * @param seconds
 */
export const getDuration = (seconds: number): Record<"days" | "hours" | "minutes", number> => {
  const duration = moment.duration(seconds, "seconds");
  const functions = ["days", "hours", "minutes"] as const;

  return functions.reduce(
    (result, fn) => {
      result[fn] = duration[fn]();

      return result;
    },
    {
      days: 0,
      hours: 0,
      minutes: 0
    }
  );
};

/**
 * Convert minutes to a time of day string
 * @param {number} minutes
 * @return {string}
 */
export const getTimeOfDay = (minutes: number): string =>
  moment.utc(moment.duration(minutes, "minutes").as("milliseconds")).format("LT");

import * as _ from "lodash";
import { InsightResolutionEntity, InsightResolutionType } from "@ctra/api";

/**
 * Make a custom insight resolution from string
 * @param title
 * @returns
 */
export const makeCustomInsightResolution = (title: string): InsightResolutionEntity => {
  return {
    id: _.kebabCase(title),
    title,
    version: 2,
    source: InsightResolutionType.custom,
    flags: {}
  };
};

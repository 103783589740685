import { FC } from "react";
import * as _ from "lodash";

import { Row } from "@ctra/components";

import { ChartType } from "@ctra/api";
import { classname } from "@ctra/utils";
import { useChartContext } from "@ctra/charts";

import styles from "./ChartFooter.module.less";
import { AnomalyToggle } from "../AnomalyToggle";

/**
 * Chart footer with anomaly view switch
 * @return {JSX.Element}
 * @constructor
 */
export const ChartFooter: FC = () => {
  const { meta } = useChartContext();

  const { hasAnomaly, hasMissingData } = _.defaultTo(meta, {});

  return (hasAnomaly || hasMissingData) && _.isEqual(meta.type, ChartType.basicLine) ? (
    <Row className={classname("ctra-anomaly-toggle", styles.Wrapper)}>
      <AnomalyToggle />
    </Row>
  ) : null;
};

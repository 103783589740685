import { createContext, FC, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import {
  Insights,
  EnterpriseAppState,
  Enterprise,
  InsightResolutionEntity,
  InsightTypeEntity,
  InsightWorkflowState,
  InsightValidation
} from "@ctra/api";

import { Epoch, isDispatched, isFulfilled, isPending, Nullable, TS } from "@ctra/utils";
import { useInsight, useInsightValidation } from "@insights";

interface ContextType {
  resolutions: Record<InsightResolutionEntity["id"], InsightResolutionEntity>;
  resolve: (resolutions: Record<string, InsightResolutionEntity>) => void;
  unresolve: (options?: { updatesWorkflowState: boolean }) => void;
  meta: {
    isFetching: boolean;
    isResolving: boolean;
    isResolved: boolean;
  };
}

const DefaultContext = createContext<ContextType>({
  resolutions: {},
  resolve: _.noop,
  unresolve: _.noop,
  meta: {
    isFetching: true,
    isResolving: false,
    isResolved: false
  }
});

/**
 * Fetch the resolutions from the server
 * @private
 */
const _InsightResolutionContextProvider: FC = ({ children }) => {
  const dispatch = useDispatch();

  const {
    insight: { id, insightType, resolution }
  } = useInsight();

  /**
   * Get the insight type
   */
  const insightTypeEntity = useSelector<EnterpriseAppState, Nullable<InsightTypeEntity>>((state) =>
    Enterprise.entities.getInsightType(state, { typeName: insightType })
  );

  /**
   * This will never fail as the insight validation context has a pretty good default value
   */
  const { validation: validationEntity } = useInsightValidation();

  /**
   * Get the insight resolutions which match the query
   */
  const insightResolutions = useSelector<
    EnterpriseAppState,
    Record<InsightResolutionEntity["id"], InsightResolutionEntity>
  >((state) =>
    Enterprise.entities.getInsightResolutions(state, {
      typeName: insightTypeEntity?.typeName,
      validation: validationEntity.validation
    })
  );

  const hasDispatched = useSelector<EnterpriseAppState, boolean>((state) =>
    isDispatched(state, Insights.types.FETCH_INSIGHT_RESOLUTIONS)
  );

  /**
   * Tell if the insight is resolved
   *
   * @note Check if there are resolutions attached to the insight OR
   * if the validation is not NA AND it has no resolutions list from the source
   */
  const isResolved =
    !!resolution.length ||
    (validationEntity.validation !== InsightValidation.na && _.isEmpty(insightResolutions));

  /**
   * Tell if the resolutions are being fetched
   */
  const isFetching = useSelector<EnterpriseAppState, boolean>(
    (state) => !isFulfilled(state, Insights.types.FETCH_INSIGHT_RESOLUTIONS)
  );

  /**
   * Tell if the insight is being updated
   */
  const isResolving = useSelector<EnterpriseAppState, boolean>((state) =>
    isPending(state, Insights.types.UPDATE_INSIGHT, { primaryValue: id })
  );

  useEffect(() => {
    if (!hasDispatched) {
      dispatch(Insights.actions.fetchInsightResolutions.start());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Resolve the insight, by batch updating the resolutions
   * @param resolutions
   */
  const resolve = (resolutions: Record<string, InsightResolutionEntity>): void => {
    /**
     * Already applied resolutions
     */
    const appliedResolutions = _.pick(insightResolutions, resolution);

    dispatch(
      /**
       * This is a bit of a hard candy to swallow as the action uses denormalize
       */
      Insights.actions.updateInsight.start(
        id,
        /**
         * keys as in source
         * when you resolve, the state of the insight will always be done
         */
        {
          resolution: _.keys(resolutions),
          workflowState: InsightWorkflowState.done,
          workflowStateLastModifiedDate: TS.serialize(Epoch.now())
        },
        /**
         * keys as in schema definition
         */
        {
          insightResolution: _.mapValues(
            { ...appliedResolutions, ...resolutions },
            /**
             * Pick the keys to be saved to the BE
             * @param id
             * @param source
             * @param title
             * @param version
             * @param flags
             */
            ({ id, source, title, version, flags }) => ({
              id,
              title,
              source,
              version,
              flags
            })
          )
        }
      )
    );
  };

  /**
   * Stage resolution changes for insight updating
   */
  const stageResolution = (changes = {}) => ({
    ...changes,
    resolution: []
  });

  /**
   * Stage workflow state changes for insight updating
   */
  const stageWorkflowState = (changes = {}) => ({
    ...changes,
    workflowState: InsightWorkflowState.toFollowUp,
    workflowStateLastModifiedDate: TS.serialize(Epoch.now())
  });

  /**
   * update insight only when it has some resolutions
   */
  const updateInsight = (changes = {}) =>
    !_.isEmpty(resolution) && dispatch(Insights.actions.updateInsight.start(id, changes));

  /**
   * workflow state updation is optional
   * update resolution to []
   */
  const unresolve = (options: { updatesWorkflowState: boolean } = { updatesWorkflowState: true }) => {
    /**
     * workflow state can be update via unresolving when:
     * 1) the optional updatesWorkflowState parameter is true
     * 2) the current validation is not "NO" i.e.
     * NO validation means the insight is always DONE, regardless of the resolution, hence we dont change the state
     */
    const updateRequired =
      options.updatesWorkflowState && validationEntity.validation !== InsightValidation.no;

    _.flow([updateRequired ? stageWorkflowState : _.noop, stageResolution, updateInsight])();
  };

  return (
    <DefaultContext.Provider
      value={{
        resolutions: insightResolutions,
        resolve,
        unresolve,
        meta: { isFetching, isResolving, isResolved }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const InsightResolutionContext = {
  Provider: _InsightResolutionContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook for accessing insight resolutions
 */
export const useInsightResolutions = (): ContextType => useContext<ContextType>(DefaultContext);

import moment from "moment";

import { memoize, TS } from "@ctra/utils";
import { i18n } from "@ctra/i18n";

import { Base } from "./Base";

/**
 * X axis data type month class
 */
class Month extends Base {
  /**
   * Get the translated label
   * @param {string} label
   * @param {{long: boolean}} options
   * @return {string}
   */
  @memoize
  getTranslatedLabel(label: string, options: { long: boolean }): string {
    /**
     * Set the moment locale
     */
    moment.locale(i18n.language);

    return TS.asMoment(label).format(options?.long ? "MMMM YYYY" : "MMM");
  }
}

export { Month };

import { FC, useEffect, useRef } from "react";
import * as _ from "lodash";

import { Timeline } from "@ctra/components";
import { classname } from "@ctra/utils";

import { useEvent, testIds, EventIcon, useEventList } from "@events";
import { Debugger } from "@base";

import styles from "./EventEntry.module.less";

/**
 * Event list entry
 * @constructor
 */
export const EventEntry: FC<{ highlighted?: boolean }> = ({ highlighted }) => {
  const { getComponents, event } = useEvent();
  const { View } = getComponents();
  const highlightedRef = useRef<HTMLDivElement>(null);
  const { selectedEvents } = useEventList();

  useEffect(() => {
    if (highlighted && highlightedRef.current) {
      highlightedRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "nearest"
      });
    }
  }, [highlighted]);

  return (
    <Timeline.Item
      dot={<EventIcon withCircle category={event.category} />}
      className={styles.Event}
      data-testid={testIds.entity.entry.wrapper}
      style={{ background: _.includes(selectedEvents, event.id) ? "#cccccc" : undefined }}
    >
      <div className={classname(highlighted ? styles.Highlight : null)} ref={highlightedRef}>
        <Debugger $event={event} />
        <View open={highlighted} />
      </div>
    </Timeline.Item>
  );
};

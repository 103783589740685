import { G2 } from "@ant-design/plots";
import { IGroup } from "@ant-design/charts";
import * as _ from "lodash";

/**
 * How much I hate importing stuff like this, but as they are not exposed and
 * I don't want to duplicate the code, I have to do it this way.
 * PS. `getActionClass` does not yield the proper type
 */
import { getCurrentElement } from "@antv/g2/lib/interaction/action/util";
import ElementSingleSelected from "@antv/g2/lib/interaction/action/element/single-selected";

/**
 * Highlight the farm event point on click
 */
class FarmEventPointAction extends ElementSingleSelected {
  toggle() {
    super.toggle();
    const element = getCurrentElement(this.context);

    if (element && _.isFunction(_.get(element, ["shape", "getChildren"]))) {
      if (element.getStates().includes(this.stateName)) {
        const [, path, circle, text] = (element.shape as IGroup).getChildren();

        circle.animate(
          {
            fill: "#007180"
          },
          {
            duration: 200,
            easing: "easeLinear"
          }
        );

        text.animate(
          {
            fill: "#ffffff"
          },
          {
            duration: 200,
            easing: "easeLinear"
          }
        );

        path.attr({
          stroke: "#007180",
          lineDash: [0, 0]
        });
      }
    }
  }
}

/**
 * Register the farm event point action
 */
G2.registerAction("farm-event-point-action", FarmEventPointAction);

/**
 * Register the farm event point interaction
 */
G2.registerInteraction("farm-event-point-interaction", {
  start: [{ trigger: "element:click", action: "farm-event-point-action:toggle" }]
});

/**
 * Show a pointer cursor when hovering over a chart element
 */
G2.registerInteraction("hover-cursor", {
  showEnable: [
    { trigger: "element:mouseenter", action: "cursor:pointer" },
    { trigger: "element:mouseleave", action: "cursor:default" }
  ]
});

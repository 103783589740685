import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import * as _ from "lodash";

import { Enterprise, EnterpriseAppState, GenericInsightEntity } from "@ctra/api";
import { Nullable } from "@ctra/utils";

type NavigationAPI = {
  previous?: () => void;
  next?: () => void;
};

export const useInsightModalNavigation = (): NavigationAPI => {
  const { search, pathname } = useLocation();
  const searchParams = new URLSearchParams(search);

  /**
   * Insight to pull from the insights
   */
  const insightID: Nullable<GenericInsightEntity["id"]> = Number(searchParams.get("genericInsightID"));

  /**
   * Insight list reference for navigation
   */
  const list: Nullable<string> = searchParams.get("insightList");

  /**
   * Get insight lists that match the list name
   */
  const insightList = useSelector<EnterpriseAppState, Array<GenericInsightEntity>>((state) => {
    return list ? (Enterprise.entities.getInsightList(state, { list }) as Array<GenericInsightEntity>) : [];
  });

  /**
   * The index of the current insight in the list
   */
  const currentIndex = _.findIndex(insightList, (item) => item.id === insightID);

  /**
   * Is true if the current index is great than zero
   */
  const hasPrevious = currentIndex > 0;

  /**
   * Is true if the index of the current insight + 1 is less than the number of insights in the list
   */
  const hasNext = currentIndex + 1 < insightList.length;

  /**
   * Jump to specific index
   * @param index
   */
  const jump = (index: number) => {
    if (insightList[index]) {
      searchParams.set("genericInsightID", insightList[index].id as unknown as string);

      Enterprise.history.push({
        pathname,
        search: searchParams.toString()
      });
    }
  };

  /**
   * Go to the previous insight
   */
  const previous = hasPrevious
    ? (): void => {
        jump(currentIndex - 1);
      }
    : void 0;

  /**
   * Go to the next insight
   */
  const next = hasNext
    ? (): void => {
        jump(currentIndex + 1);
      }
    : void 0;

  return {
    previous,
    next
  };
};

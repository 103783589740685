import * as React from "react";
import { Popover } from "antd";
import { TooltipPlacement } from "antd/es/tooltip";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Markdown from "react-markdown";

import styles from "./WithSuperScript.module.less";

export interface WithSuperScriptProps {
  /**
   * popover title
   */
  title?: string | React.ReactNode;
  /**
   * popover content
   */
  content?: string | React.ReactNode;
  /**
   * popover placement
   */
  popoverPlacement?: TooltipPlacement | undefined;
  /**
   * popover mouseenter
   */
  onMouseEnter?: () => void;
}

/**
 * Text with question mark popover in superscript
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} children
 * @param {string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} title
 * @param {string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined} content
 * @param {string} popoverPlacement
 * @param {(() => void) | undefined} onMouseEnter
 * @returns {JSX.Element}
 * @constructor
 */
const WithSuperScript: React.FC<WithSuperScriptProps> = ({
  children,
  title,
  content,
  popoverPlacement = "bottomLeft",
  onMouseEnter
}) => {
  return (
    <>
      {children}
      <sup>
        <Popover
          placement={popoverPlacement}
          title={title}
          content={<section className={styles.Popover}>{content}</section>}
        >
          <QuestionCircleOutlined className={styles.Icon} onMouseEnter={onMouseEnter} />
        </Popover>
      </sup>
    </>
  );
};

export default WithSuperScript;

import keymirror from "key-mirror-nested";

/**
 * Make a `data-testid` tree for better testing experience
 * @param module
 * @param tree
 */
export const makeTestIds = <T extends Record<string, unknown> = Record<string, unknown>>(
  module: string,
  tree: T
): T =>
  keymirror<T, T>(tree, {
    connChar: "-",
    /**
     * Customize output
     * @param oldVal
     * @param newVal
     */
    custFunc: (oldVal: string, newVal: string): string => `${module}.${newVal}`
  });

import { createContext, FC, useContext } from "react";
import categories from "./categories";

interface BaseContext {
  meta: {
    categories: typeof categories;
  };
}

const DefaultContext = createContext<BaseContext>({
  meta: {
    categories: []
  }
});

/**
 * Manage form changes via a Provider
 * @param children
 */
const _CreateEventContextProvider: FC = ({ children }) => {
  return (
    <DefaultContext.Provider
      value={{
        meta: {
          categories
        }
      }}
    >
      {children}
    </DefaultContext.Provider>
  );
};

export const CreateEventContext = {
  Provider: _CreateEventContextProvider,
  Consumer: DefaultContext.Consumer
};

/**
 * Hook for create event context
 * @returns {void}
 */
export const useCreateEvent = (): BaseContext => useContext<BaseContext>(DefaultContext);

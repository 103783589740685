import { ColorType, hslToHex } from "@ctra/utils";

/**
 * useColorGradient hook
 *
 * @param color
 * @returns
 */
export const useColorGradient = (color: ColorType): Array<string> => {
  const { hue } = color;

  const lightestShade = hslToHex({ hue, saturation: 10, luminosity: 90 });
  const midShade = hslToHex({ hue, saturation: 50, luminosity: 50 });
  const darkestShade = hslToHex({ hue, saturation: 90, luminosity: 10 });

  return [lightestShade, midShade, darkestShade];
};

import { memoize } from "@ctra/utils";
import { Heatmap as BaseHeatmap, HeatmapConfig } from "@ctra/charts";
import { Enterprise, i18nTranslate } from "@ctra/i18n";
import { HeatmapMeta } from "@ctra/api";

import { DataPointsBase } from "./DataPointsBase";
import { XAxisType } from "./xAxis";
import { ChartViewBase } from "./Base";

/**
 * Heatmap view class
 */
class Heatmap extends DataPointsBase<HeatmapMeta> {
  /**
   * Get the chart component
   * @return {ReturnType<typeof ChartViewBase.prototype.getComponent>}
   */
  @memoize
  getComponent(): ReturnType<typeof ChartViewBase.prototype.getComponent> {
    return BaseHeatmap;
  }

  /**
   * Get the meta data
   * @return {ReturnType<typeof ChartViewBase.prototype.getMetadata>}
   */
  @memoize
  getMetadata(): ReturnType<typeof ChartViewBase.prototype.getMetadata> {
    const {
      heatmap: { seriesTitle }
    } = Enterprise.chart;

    return {
      ...super.getMetadata(),
      series: {
        title: i18nTranslate(seriesTitle),
        keys: {}
      }
    };
  }

  /**
   * Get the config
   * @return {Partial<HeatmapConfig>}
   */
  @memoize
  getConfig(): Partial<HeatmapConfig> {
    const xAxisType = XAxisType.create(super.getMetadata().xType);

    return xAxisType.getConfig();
  }
}

export { Heatmap };

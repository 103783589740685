import { FC } from "react";
import * as _ from "lodash/fp";

import { Area as AntArea } from "@ant-design/charts";

import { useAreaConfig } from "./useAreaConfig";

import { withChartWrapper, withSeriesColor } from "../../../hocs";

/**
 * Area Component
 * @returns
 */
const AreaComponent: FC = () => {
  const { config } = useAreaConfig();

  return <AntArea {...config} />;
};

/**
 * Full Area chart
 */
export const Area: FC = _.compose(withChartWrapper, withSeriesColor)(AreaComponent);

import { ComponentProps, FC } from "react";
import { Checkbox, Col, Popover, Row, Space } from "antd";
import * as _ from "lodash";

import { Button } from "../../../elements";

import styles from "./CheckboxMenu.module.less";

export interface CheckboxMenuProps extends Omit<ComponentProps<typeof Button>, "onChange" | "value"> {
  /**
   * group size
   */
  groupSize?: number;
  /**
   * item definitions
   */
  items: Array<{ label: string; value: string }>;
  /**
   * change handler
   */
  onChange: ComponentProps<typeof Checkbox.Group>["onChange"];
  /**
   * checked values
   */
  value: ComponentProps<typeof Checkbox.Group>["value"];
}

/**
 * Find the best match of col width for the given number of items
 * @param {number} x
 * @returns {number}
 */
const findClosest = (x: number) => {
  const options = [1, 2, 3, 4, 6, 8, 12, 24];

  const pick = _.reduce(
    options,
    (best: number, current: number) => (current >= x && (!best || current < best) ? current : best),
    24
  );

  return 24 / pick;
};

/**
 * Checkbox menu
 * @param {React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | Iterable<React.ReactNode> | React.ReactPortal | boolean | null | undefined | (React.ReactElement<any, string | React.JSXElementConstructor<any>> & undefined) | (Iterable<React.ReactNode> & undefined) | (React.ReactPortal & undefined)} children
 * @param {number} groupSize
 * @param {Array<{label: string, value: string}>} items
 * @param {Array<CheckboxValueType> | undefined} value
 * @param onChange
 * @param rest
 * @constructor
 */
const CheckboxMenu: FC<CheckboxMenuProps> = ({
  children,
  groupSize = 8,
  items,
  value,
  onChange,
  ...rest
}) => {
  /**
   * Make groups from the items
   * @type {({label: string, value: string}[] | null)[]}
   */
  const groups = _.compact(
    _.map(items, ({ label, value }, i) => (i % groupSize === 0 ? _.slice(items, i, i + groupSize) : null))
  );

  const colWidth = findClosest(groups.length);

  return (
    <Popover
      overlayClassName={styles.CheckboxMenu}
      color="white"
      trigger="click"
      placement="bottomLeft"
      content={
        <Checkbox.Group value={value} onChange={onChange}>
          <Row gutter={[16, 0]} style={{ width: "100%" }}>
            {_.map(groups, (group, i) =>
              !!group ? (
                <Col key={i} span={colWidth}>
                  <Space direction="vertical">
                    {_.map(group, (item) => (
                      <Checkbox style={{ whiteSpace: "nowrap" }} key={item.value} value={item.value}>
                        {item.label}
                      </Checkbox>
                    ))}
                  </Space>
                </Col>
              ) : null
            )}
          </Row>
        </Checkbox.Group>
      }
    >
      <Button {...rest}>{children}</Button>
    </Popover>
  );
};

export default CheckboxMenu;
